import { t } from "i18next";

const TitleHeader = ({ clone, loading, title, name, id }) => {

  const action = clone
    ? t("caymland.core.form.clone")
    : id
    ? t("caymland.core.form.edit")
    : t("caymland.core.form.new");
  return loading ? `${action} ${name}` : `${action} ${name} ${title ? `- ${title}` : ""}`;
};

export default TitleHeader;
