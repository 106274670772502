import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Error, Success } from "../../../reusableComponents/Alerts";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import MainContent from "./mainContent";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useAddNewSmsMutation, useGetSmsQuery, useUpdateSmsesMutation } from "../../../../redux/api/smsApi";

function AddEditSMS(state) {
  const navigate = useNavigate();
  const location = useLocation();
  const isNew = location.pathname.includes("new");
  const ClonePath = location.pathname.includes("clone");
  const id = location?.state?.id || location?.state || useParams().id;

  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "plugin:infobip:sms",
  });

  const [modifiedData, setModifiedData] = useState({
    name: "",
    message: "",
    smsType: "template",
    isPublished: true,
    category: null,
    lists: [],
    properties: {
      communicationStartDate: null,
      communicationStartTime: null,
      deliveryStartTime: null,
      deliveryEndTime: null,
      deliveryDays: [],
    },
  });
  
  const [addNewSms, { isLoading: isAddLoading }] = useAddNewSmsMutation();
  const [updateSmses, { isLoading: isEditLoading }] = useUpdateSmsesMutation(id);
  const { data, isLoading } = useGetSmsQuery(id, { skip: !id });
  const isSaving = isEditLoading || isAddLoading;

  const [newItem, setNewItem] = useState();

  useEffect(() => {
    if (id && data?.sms) {
      let smsData = { ...data?.sms };
      if (ClonePath) {
        smsData = { ...smsData, isPublished: false };
      }
      setModifiedData({
        ...smsData,
        category: smsData?.category
          ? {
              label: smsData?.category?.title,
              value: smsData?.category?.id,
            }
          : null,
      });
    }
  }, [id, data]);

  const handleCancel = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/sms");
      } else {
        return;
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditSMS("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditSMS(),
    },
  ];

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const validateSMSData = () => {
    let hasErrors = false;
    let newErrors = {};
    console.log("newErrors", newErrors);

    if (!modifiedData.name || modifiedData.name.trim().length === 0) {
      newErrors.name = "Name is required";
      hasErrors = true;
    }

    if (!modifiedData.message || modifiedData.message.trim().length === 0) {
      newErrors.message = "Message content is required";
      hasErrors = true;
    }

    if (modifiedData.smsType === "list" && modifiedData.lists.length === 0) {
      newErrors.lists = "Lists are required for list type";
      hasErrors = true;
    }

    setErrors(newErrors);
    return hasErrors;
  };

  const handleAddEditSMS = async (params) => {
    if (validateSMSData()) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    }

    try {
      let response;

      const payload = {
        ...modifiedData,
        lists: modifiedData.lists.map((item) => item.id.toString()),
        category:
          modifiedData.category && typeof modifiedData.category === "object"
            ? modifiedData.category.value
            : modifiedData.category,
      };

      if (modifiedData.smsType === "template") {
        payload.properties = {
          communicationStartDate: null,
          communicationStartTime: null,
          deliveryStartTime: null,
          deliveryEndTime: null,
          deliveryDays: [],
        };
        payload.lists = [];
      }

      if (id && !ClonePath) {
        response = await updateSmses({ id, ...payload });
      } else {
        response = await addNewSms(payload);
      }

      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed && params === "save") {
            navigate(`/sms/view/${response?.data?.sms?.id}`);
          } else {
            navigate(`/sms/edit/${response.data.sms.id}`);
          }
        });
      } else {
        Error(t("caymland.message.error"));
      }
    } catch (e) {
      Error(t("caymland.message.error"));
    }
  };

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isSaving}
          alertMessage={alertMessage}
          showAlert={showAlert}
          title={!ClonePath && id ? t("caymland.sms.send.edit.sms") : t("caymland.sms.send.new.sms")}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          <>
            <MainContent
              errors={errors}
              setErrors={setErrors}
              isFetching={isLoading}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
            />
          </>
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          <>
            <Sidebar
              errors={errors}
              setErrors={setErrors}
              isFetching={isLoading}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              modalOptions={modalOptions}
              setModalOptions={setModalOptions}
              setNewItem={setNewItem}
            />
          </>
        }
      />
    </>
  );
}

export default AddEditSMS;
