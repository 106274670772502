export const retrieveCampaigns = (inputValue, bundle, trigger = null, includeGlobal = true) =>
  new Promise(async (resolve, reject) => {
    if (typeof trigger !== "function" || trigger === null) {
      resolve([]);
      return;
    }

    const val = [];

    if (bundle) {
      val.push({ expr: "eq", col: "bundle", val: bundle });
    }

    if (includeGlobal) {
      val.push({ expr: "eq", col: "bundle", val: "global" });
    }
    const query = {};

    if (inputValue.length) {
      query.search = inputValue;
    }
    const response = await trigger({}).unwrap();
    console.log(response);
    if (!response.error) {
      const data = response.data.filter((campaign) => campaign.isPublished);
      const dataArray = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      console.log(dataArray);
      resolve(dataArray);
    }
  });
