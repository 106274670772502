
export const Edges = ({ sourceId, targetId, source, target }) => {

    const edges = {
        sourceId,   
        targetId,
        anchors: {
            source,
            target
        }
    }

    return edges;
}

