import React from "react";
import { useParams } from "react-router-dom";

function SingleSegmentForModalForSegments({
  segmentName,
  checked,
  addContactToSegment,
  removeContactFromSegment,
  id,
}) {
  const userID = useParams().id;
  const handleChange = (e) => {
    if (e.target.checked) {
      addContactToSegment(id);
    } else removeContactFromSegment(id);
  };

  return (
    <div
      style={{
        width: "100%",
        border: "0.5px solid lightgrey",
        display: "flex",
        padding: "1em 0 1em 1em",
        alignItems: "center",
        justifyContent: "start",
        gap: "1em",
        borderCollapse: "collapse",
      }}
    >
      <div
        class="switch-toggle"
        style={{ display: "flex", alignItems: "center" }}
      >
        <input
          type="checkbox"
          id={id}
          defaultChecked={checked}
          onChange={handleChange}
        />
        <label className="toggle-label" for={id}></label>
      </div>
      <span>{segmentName}</span>
    </div>
  );
}

export default SingleSegmentForModalForSegments;
