import React from "react";
import { Select, Text } from "../../../reusableComponents/Inputs";
import { t } from "i18next";
import { useGetRolesQuery } from "../../../../redux/api/usersApi";
import { useEffect, useState } from "react";

const Details = ({ modifiedData, setModifiedData, handleChange, errors, setErrors }) => {
  const { data } = useGetRolesQuery();
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    if (data?.data) {
      const mappedData = data?.data?.map?.((role) => ({
        label: role.name,
        value: role.id,
      }));
      setRoleData(mappedData);
    }
  }, [data]);

  const findRoleById = () => {
    if (!modifiedData.role || typeof modifiedData.role !== "object") {
      return modifiedData.role ? roleData.find((r) => r.value === modifiedData.role) : null;
    }
    return roleData.find((r) => r.value === modifiedData.role.id);
  };

  return (
    <>
      <div className="d-flex gap-10 w-100">
        <Text
          name="firstName"
          value={modifiedData.firstName}
          onChange={handleChange}
          label={t("caymland.core.firstname")}
          required={true}
          wFull="w-50"
          errors={errors.firstName}
        />

        <Text
          name="lastName"
          value={modifiedData.lastName}
          onChange={handleChange}
          label={t("caymland.core.lastname")}
          required={true}
          wFull="w-50"
          errors={errors.lastName}
        />
      </div>

      <div className="w-50" style={{ paddingRight: "5px" }}>
        <Select
          name="role"
          label={t("caymland.user.role")}
          required={true}
          value={findRoleById()}
          options={roleData}
          errors={errors.role}
          onChange={(e) => {
            const newRole = e === null ? null : e.value;
            setModifiedData((prev) => ({
              ...prev,
              role: newRole,
            }));
            if (errors.role) {
              setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors.role;
                return newErrors;
              });
            }
          }}
        />
      </div>

      <div className="d-flex gap-10 w-100">
        <Text name="position" value={modifiedData.position} onChange={handleChange} label="Position" wFull="w-50" />
        <Text
          name="signature"
          value={modifiedData.signature}
          onChange={handleChange}
          label={t("caymland.email.token.signature")}
          wFull="w-50"
        />
      </div>

      <hr
        style={{
          gridColumnStart: "1",
          gridColumnEnd: "3",
          marginTop: "2em",
          marginBottom: "20px",
          width: "100%",
          border: 0,
          borderTop: "1px solid #47535f",
        }}
      />

      <div className="d-flex gap-10 w-100">
        <Text
          name="username"
          value={modifiedData.username}
          onChange={handleChange}
          label={t("caymland.core.username")}
          required={true}
          wFull="w-50"
          errors={errors.username}
        />

        <Text
          type="password"
          name="password"
          value={modifiedData?.plainPassword?.password ?? null}
          onChange={(e) => {
            const newPassword = e.target.value;
            setModifiedData((prevData) => ({
              ...prevData,
              plainPassword: {
                ...prevData.plainPassword,
                password: newPassword,
              },
            }));
            if (errors.password) {
              setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors.password;
                return newErrors;
              });
            }
          }}
          label={t("caymland.core.password")}
          required={true}
          wFull="w-50"
          errors={errors.password}
        />
      </div>

      <div className="d-flex gap-10 w-100">
        <Text
          type="email"
          name="email"
          value={modifiedData.email}
          onChange={handleChange}
          label={t("caymland.core.theme.feature.email")}
          required={true}
          wFull="w-50"
          errors={errors.email}
        />
        <Text
          type="password"
          name="confirm"
          value={modifiedData?.plainPassword?.confirm ?? null}
          onChange={(e) =>
            setModifiedData((prevData) => ({
              ...prevData,
              plainPassword: {
                ...prevData.plainPassword,
                confirm: e.target.value,
              },
            }))
          }
          label={t("caymland.user.user.form.passwordconfirm")}
          required={true}
          wFull="w-50"
          errors={errors.confirm}
        />
      </div>
    </>
  );
};

export default Details;
