import Select from "react-select";
import { useEffect, useState } from "react";
import { t } from "i18next";

export const FiltersFieldSelector = ({
  options,
  handleOnChange,
  additionalData,
  value,
  isDisabled,
  loading,
  fields,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  function getOption(fields, valueField) {
    return fields.find((f) => f.columnName === valueField) || null;
  }

  function getFieldValue(value) {
    return typeof value === "object" && value !== null ? value.field : value;
  }

  useEffect(() => {
    const valueField = getFieldValue(value);
    setSelectedOption(getOption(fields, valueField));
  }, [fields, value]);

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#135f95",
      primary: "#135f95",
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      padding: "1px 0",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: base["&:hover"].borderColor,
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      color: "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  return (
    <div style={{ width: "30%", alignSelf: "center" }}>
      <Select
        size="lg"
        isDisabled={isDisabled}
        isLoading={loading}
        options={fields}
        value={loading ? null : selectedOption}
        placeholder={loading ? t("caymland.core.loading") : t("caymland.query.builder.select.placeholder")}
        onChange={(e) => {
          handleOnChange(
            e?.operators
              ? {
                  field: e?.columnName,
                  type: e?.type,
                  input: e?.type,
                  object: e?.object,
                  id: e?.id,
                  operators: e?.operators,
                  list: e?.properties?.list ? e?.properties?.list : [],
                }
              : {
                  field: e?.columnName,
                  type: e?.type,
                  input: e?.type,
                  object: e?.object,
                  id: e?.id,
                  operators: e?.operators,
                }
          );
        }}
        theme={selectTheme}
        styles={selectStyles}
      />
    </div>
  );
};
