import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { t } from "i18next";
import { translationMap } from "./translationKeys";

const DetailsPanel = ({ data }) => {
  console.log(data);
  const formatKey = (key) => {
    // Check if the translation for the key exists in the map
    if (translationMap[key]) {
      // Translate the key and return

      return t(translationMap[key]);
    } else {
      // If no translation exists, apply formatting rules
      key = key.replace(/^[crnbd]/, "");
      key = key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };
  
  // Define the keys for price information
  const priceKeys = [
    "rOrderDeliveryFeeTotal",
    "rOrderDiscountTotal",
    "rOrderFeeTotal",
    "rOrderItemTotal",
    "rOrderPaymentMethodFeeTotal",
    "rOrderTotal",
    // ... add other price keys as needed
  ];

  // Define the keys you want to include in the billing information
  const billingKeys = [
    "cCollectedPaymentMethodNames",
    "cOrderBillingAddress1",
    "cOrderBillingCity",
    "cOrderBillingCountryName",
    "cOrderBillingEmail",
    "cOrderBillingFirstName",
    "cOrderBillingName",
    "cOrderBillingSalutation",
    "cOrderBillingZIP",
    // ... add other billing keys as needed
  ];
  const deliveryKeys = [
    "cOrderDeliverySalutation",
    "cOrderDeliveryFirstName",
    "cOrderDeliveryName",
    "cOrderDeliveryAddress1",
    "cOrderDeliveryZIP",
    "cOrderDeliveryCity",
    "cOrderDeliveryCountryName",
    "cOrderDeliveryEmail",
    // ... add other delivery keys as needed
  ];
  const otherKeys = ["cSalesChannelCompanyName", "cSaleschannelTypeName", "cSaleschannelUser"];

  const renderAccordionForItem = (item, index) => {
    const title = `Ticket Information #${item.nOrderItemID}`;

    const renderValue = (key, value) => {
      // Check if the key suggests a date or time value
      const isDate = key.toLowerCase().includes("date");
      const isTime = key.toLowerCase().includes("time");

      if (isDate && typeof value === "string") {
        // Format the date using moment.js
        return moment(value).format("DD.MM.YYYY");
      } else if (isTime && typeof value === "string") {
        // Format the time using moment.js
        return moment(value).format("HH:mm");
      } else if (key === "nPerformanceID" && value !== null && typeof value === "object") {
        // Existing logic for performanceID
        return value.performanceID;
      } else if (typeof value === "object" && value !== null) {
        // Existing logic for other object types
        return JSON.stringify(value);
      } else {
        // Return the value as is for other types
        return value;
      }
    };

    return (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="detail-table">
            <div className="detail-row header-row">
              <div className="detail-cell">{t("caymland.showare.table.object")}</div>
              <div className="detail-cell">{t("caymland.showare.table.value")}</div>
            </div>
            {Object.entries(item).map(([key, value]) => {
              if (value != null) {
                return (
                  <div key={key} className="detail-row">
                    <div className="detail-cell">{formatKey(key)}</div>
                    <div className="detail-cell">{renderValue(key, value)}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className="d-flex items-start justify-start" style={{ gap: "20px" }}>
      <div className="d-flex flex-col w-50">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("caymland.showare.panel.billing")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="detail-table">
              <div className="detail-row header-row">
                <div className="detail-cell">{t("caymland.showare.table.object")}</div>
                <div className="detail-cell">{t("caymland.showare.table.value")}</div>
              </div>
              {billingKeys.map((key) => {
                const value = data[key];
                console.log(value);
                if (value != null) {
                  // Skip null values
                  return (
                    <div key={key} className="detail-row">
                      <div className="detail-cell">{formatKey(key)}</div>
                      <div className="detail-cell">{value}</div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("caymland.showare.panel.delivery")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="detail-table">
              <div className="detail-row header-row">
                <div className="detail-cell">{t("caymland.showare.table.object")}</div>
                <div className="detail-cell">{t("caymland.showare.table.value")}</div>
              </div>
              {deliveryKeys.map((key) => {
                const value = data[key];
                if (value != null) {
                  // Skip null values
                  return (
                    <div key={key} className="detail-row">
                      <div className="detail-cell">{formatKey(key)}</div>
                      <div className="detail-cell">{value} </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("caymland.showare.panel.price")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="detail-table">
              <div className="detail-row header-row">
                <div className="detail-cell">{t("caymland.showare.table.object")}</div>
                <div className="detail-cell">{t("caymland.showare.table.value")}</div>
              </div>
              {priceKeys.map((key) => {
                let value = data[key];
                if (value != null) {
                  // Add 'CHF' prefix to all values except for 'Order Item Total'
                  if (key !== "rOrderItemTotal") {
                    value = `CHF ${value}`;
                  }
                  return (
                    <div key={key} className="detail-row">
                      <div className="detail-cell">{formatKey(key)}</div>
                      <div className="detail-cell">{value}</div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("caymland.showare.panel.other")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <div className="detail-table">
              <div className="detail-row header-row">
                <div className="detail-cell">{t("caymland.showare.table.object")}</div>
                <div className="detail-cell">{t("caymland.showare.table.value")}</div>
              </div>
              {otherKeys.map((key) => {
                const value = data[key];
                if (value != null) {
                  // Skip null values
                  return (
                    <div key={key} className="detail-row">
                      <div className="detail-cell">{formatKey(key)}</div>
                      <div className="detail-cell">{value} </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="d-flex flex-col w-50">{data?.items?.map(renderAccordionForItem)}</div>
    </div>
  );
};

export default DetailsPanel;
