import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { FieldsRenderSection } from "../contacts/FieldsRenderer";
import StaticSelectsInForns from "./StaticSelectsInForns";

function CoreTab({ control, setContactImg, contactFields, watch, setValue, errors, getValues }) {
  const [filteredFields, setFilteredFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contactFields) {
      const sortedAndFilteredFields = [...contactFields]
        .sort((a, b) => a.order - b.order)
        .filter((x) => x.isPublished === true && x.group === "core" && x.isVisible === true);
      setFilteredFields(sortedAndFilteredFields);
    }
  }, [contactFields]);

  const nameAndEmailAliases = ["firstname", "lastname", "email", "company", "title"];
  const companyAddressAliases = ["address1", "zipcode", "city", "state", "country", "position"];
  const excludedAliases = new Set([...nameAndEmailAliases, ...companyAddressAliases]);

  const remainingAliases = filteredFields
    .filter((field) => !excludedAliases.has(field.alias))
    .map((field) => field.alias);

  return (
    <Form>
      <div>
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Loading />
          </div>
        ) : (
          <div className="gap-10" style={{ display: "grid", padding: "10px" }}>
            <FieldsRenderSection
              errors={errors}
              aliases={nameAndEmailAliases}
              filteredFields={filteredFields}
              control={control}
              setValue={setValue}
              setContactImg={setContactImg}
              getValues={getValues}
              isW50={true}
              watch={watch}
            />
            <FieldsRenderSection
              errors={errors}
              aliases={companyAddressAliases}
              filteredFields={filteredFields}
              control={control}
              isW50={true}
              watch={watch}
            />
            <FieldsRenderSection
              errors={errors}
              aliases={remainingAliases}
              filteredFields={filteredFields}
              control={control}
              isW50={true}
              watch={watch}
            />
            {contactFields && <StaticSelectsInForns control={control} watch={watch} setValue={setValue} />}
          </div>
        )}
      </div>
    </Form>
  );
}

export default CoreTab;
