import React from "react";
import Moment from "moment";
import { t } from "i18next";

function Details({  email }) {
  const detailsData = [
    { label: t("caymland.email.subject"), value: email?.subject },
    { label: t("caymland.core.category"), value: email?.category ? email?.category.title : "Uncategorized" },
    { label: t("caymland.core.createdby"), value: email?.createdByUser },
    { label: t("caymland.core.created"), value: Moment(email?.dateAdded).format("DD.MM.YY hh:mm") },
    { label: t("caymland.core.modifiedby"), value: email?.modifiedByUser },
    { label: t("caymland.core.modified"), value: Moment(email?.dateModified).format("DD.MM.YY hh:mm") },
    { label: t("caymland.dynamicContent.publish.up"), value: Moment(email?.publishUp).format("DD.MM.YY hh:mm") },
    { label: t("caymland.dynamicContent.publish.down"), value: Moment(email?.publishDown).format("DD.MM.YY hh:mm") },
    { label: t("caymland.core.id"), value: email?.id },
    { label: t("caymland.core.filter.themes"), value: email?.template },
    { label: t("caymland.email.stat.pending"), value: email?.pendingCount },
    { label: t("caymland.email.stat.sent"), value: email?.sentCount },
    { label: t("caymland.email.stat.read"), value: email?.readCount },
  ];

  return (
    email && (
      <div className="details-container-accordion">
        {detailsData.map((item, index) => (
          <div key={index} className="details-item gap-5px">
            <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
          </div>
        ))}
      </div>
    )
  );
}

export default Details;
