import { useParams } from "react-router-dom";

function EmailPreview() {
  const { id } = useParams();
  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;

  return (
    <iframe
      style={{ width: "100vw", height: "100vh", border: "none", padding: 0, margin: 0, overflow: "hidden" }}
      src={`${api}/email/preview/${id}`}
    />
  );
}

export default EmailPreview;
