import _ from "lodash";

const fieldsToTransform = ["category", "intervalExecution"];

const unsetFields = ["createdBy", "createdByUser", "dateAdded", "dateModified", "modifiedBy", "modifiedByUser"];

// export function transformFields(obj) {
//     if (typeof obj === 'object' && obj !== null) {
//         let transformed = {};

//         for (let [key, value] of Object.entries(obj)) {
//             if (value === null) {
//                 transformed[key] = null;
//                 continue;
//             }

//             if (fieldsToTransform.includes(key)) {
//                 if (Array.isArray(value)) {
//                     transformed[key] = value.map(item => {
//                         return ("id" in item) ? item.id : ("value" in item) ? item.value : transformFields(item, fieldsToTransform);
//                     });
//                 } else if (typeof value === 'object') {
//                     transformed[key] = ("id" in value) ? value.id : ("value" in value) ? value.value : transformFields(value, fieldsToTransform);
//                 }
//             } else {
//                 transformed[key] = value;
//             }
//         }
//         return transformed;
//     }

//     return obj;
// }

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function transformFields(obj) {
  if (typeof obj === "object" && obj !== null) {
    let transformed = {};

    for (let [key, value] of Object.entries(obj)) {
      if (value === null) {
        transformed[key] = null;
        continue;
      }

      if (isValidDate(value)) {
        transformed[key] = value.toISOString();
        continue;
      }

      if (key === "additional_data" || key === "headers") {
        transformed[key] = value;
        continue;
      }

      //  if (key === 'category') {
      //     transformed[key] = (typeof value === 'object' && value !== null && 'id' in value) ? value.id : null;
      //     continue;
      // }

      if (Array.isArray(value)) {
        transformed[key] = value.map((item) => {
          return typeof item === "object" && item !== null && "value" in item ? item.value : item;
        });
      } else if (typeof value === "object") {
        transformed[key] = "value" in value ? value.value : transformFields(value);
      } else {
        transformed[key] = value;
      }
    }
    return transformed;
  }

  return obj;
}

export function unsetUnnecessaryField(data) {
  for (const field of unsetFields) {
    if (_.has(data, field)) {
      _.unset(data, field);
    }
  }
  return data;
}
