import React, { Fragment, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiAddToQueue, BiImport, BiHistory } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { useDeleteCompaniesMutation, useGetCompaniesQuery } from "../../../redux/api/companiesApi";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { useTranslation } from "react-i18next";

function Companies() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const name = "Companies";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const location = useLocation();

  const { data, isFetching, refetch } = useGetCompaniesQuery(query);
  const [deleteCompanies] = useDeleteCompaniesMutation();

  const actionButtons = ActionButtons({
    link: "companies",
    deleteItem: deleteCompanies,
  });

  const columns = [
    {
      accessorKey: "companyname",
      accessorFn: (row) => row.name,
      header: t("caymland.brochures.order.product.list.name"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => {
        const rowData = cell?.row?.original;
        return <LinkComponent data={rowData} loading={isFetching} link="companies" view={true} />;
      },
    },
    {
      accessorKey: "companyemail",
      accessorFn: (row) => row.email,
      header: t("caymland.tomas.field.Email"),
      enableColumnActions: false,
      size: 150,
      Cell: ({ cell }) => cell?.row?.original?.fields?.core?.companyemail?.value,
    },
    {
      accessorKey: "companywebsite",
      accessorFn: (row) => row.companywebsite,
      header: t("caymland.company.website"),
      enableColumnActions: false,
      size: 180,
      Cell: ({ cell }) => cell?.row?.original?.fields?.core?.companywebsite?.value,
    },
    {
      accessorKey: "Score",
      accessorFn: (row) => row.score,
      header: t("caymland.company.score"),
      enableColumnActions: false,
      size: 80,
      Cell: ({ cell }) => {
        const { score } = cell?.row?.original;
        return (
          <p
            className="badge badge-dark"
            style={{
              textAlign: "center",
              margin: "0",
              fontSize: "0.75",
              fontWeight: "400",
              border: "1px solid #55607F",
              color: "#55607F",
              background: "white",
            }}
          >
            {score}
          </p>
        );
      },
    },
    {
      accessorKey: "companycontacts",
      accessorFn: (row) => row.contacts,
      header: t("caymland.lead.leads"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
      Cell: ({ cell }) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/contacts`, {
                state: { search: `company:"${cell?.row?.original?.fields.core.companyname.value}"` },
              });
            }}
            className="result-component"
          >
            {t("caymland.core.permissions.view")} {cell?.row?.original?.leadCount || 0} {t("caymland.lead.leads")}
          </div>
        );
      },
    },
  ];

  const actionBatchButtons = ActionBatchButtons({
    link: "companies",
    refetch: refetch,
  });

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={15} />,
      onClick: () => navigate("/companies/new"),
      childs: [
        {
          name: "import",
          title: t("caymland.lead.lead.import"),
          icon: <BiImport size={17} />,
          onClick: () => navigate("/companies/import/new"),
        },
        {
          name: "importhistory",
          title: t("caymland.lead.lead.import.index"),
          icon: <BiHistory size={17} />,
          onClick: () => navigate("/companies/import/history", { state: { myData: "company" } }),
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.lead.lead.companies")} />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        link="companies"
        isFetching={isFetching}
      />
    </Fragment>
  );
}

export default Companies;
