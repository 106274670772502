import { t } from "i18next";
import React, { useMemo } from "react";
import Select from "react-select";

export const IncomingTypeReusible = (props) => {
  const { field, handleOnChange, value, fields, loading, isDisabled } = props;

  const operatorsOptions = useMemo(() => {
    let operators;

    if (typeof field === "string" && typeof field !== "object") {
      const foundField = fields?.find((v) => v.columnName === field);
      operators = foundField ? foundField?.operators : [];
    }

    const o = operators ? operators : field?.operators;
    return Object.entries(o)?.map(
      ([key, value]) => (
        console.log(value),
        {
          value: key,
          label: t(`caymland.core.operator.${value}`),
        }
      )
    );
  }, [field, fields]);

  const selectedValue = useMemo(() => {
    return operatorsOptions?.find((o) => o.value === value) || null;
  }, [value, operatorsOptions]);

  const handleChange = (selectedOption) => {
    if (handleOnChange) {
      handleOnChange(selectedOption?.value);
    }
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#135f95",
      primary: "#135f95",
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#347AA6" : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  return (
    <div style={{ width: "30%" }}>
      <Select
        isLoading={loading}
        isDisabled={isDisabled}
        options={operatorsOptions}
        placeholder={loading ? t("caymland.core.loading") : t("caymland.query.builder.select.placeholder")}
        value={selectedValue ?? null}
        onChange={(selectedOption) => {
          handleOnChange(selectedOption?.value);
        }}
        theme={selectTheme}
        styles={selectStyles}
      />
    </div>
  );
};
