import { TextArea } from "../../../../../reusableComponents/Inputs";

export const TextEditor = ({ label, fullEditor, entity, setEntity, name, required }) => {
  return (
    <>
      <TextArea
        value={(entity && entity[name]) || ""}
        label={label}
        required={required}
        onChange={(data) => {
            setEntity((prev) => ({ ...prev, description: data }));
        }}
      />
    </>
  );
};
