import React, { Fragment, useEffect, useState } from "react";
import NewCategoryPopUp from "../../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Checkbox, Select, Text, DatePicker } from "../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../reusableComponents/retriveCategoriesByBundle";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../redux/api/categoriesApi";
import { t } from "i18next";
import ModalGenerator from "../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../reusableComponents/ModalGenerator/ModalGeneratorData";

function Sidebar({ modifiedData, setModifiedData }) {
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "stage",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "text" || type === "number")
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  return (
    <Fragment>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Text
        name={"weight"}
        value={modifiedData?.weight}
        onChange={(e) => handleInputChange(e)}
        label={t("caymland.stage.action.weight")}
        type="number"
        tooltip={t("caymland.stage.action.weight.help")}
      />
      <Select
        name="category"
        label={t("caymland.lead.lead.category")}
        options={retrieveCategories}
        trigger={triggerCategories}
        value={modifiedData?.category ?? null}
        onChange={handleSelectChange}
        isCreatable
        bundle="stage"
        isClearable
      />
      <div style={{ marginBottom: "20px" }}>
        <Checkbox
          name="isPublished"
          value={modifiedData?.isPublished}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.core.form.published")}
        />
      </div>
      <DatePicker
        label={t("caymland.core.form.publishup")}
        name={"Publish at (date/time)"}
        value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
        maxDate={modifiedData?.publishDown}
      />
      <DatePicker
        label={t("caymland.core.form.publishdown")}
        name={"Unpublish at (date/time)"}
        value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
        minDate={modifiedData?.publishUp}
      />
    </Fragment>
  );
}

export default Sidebar;
