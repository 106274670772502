import React from "react";
import Editor from "@monaco-editor/react";

const CustomEditor = ({ value, onChange, defLanguage }) => {
  return (
    <Editor
      className="monacoEditor"
      height="45vh"
      defaultLanguage={defLanguage}
      options={{
        unusualLineTerminators: "auto",
      }}
      value={value}
      theme="vs-dark"
      //   onChange={(e) => setModifiedData({ ...modifiedData, customHtml: e })}
      onChange={onChange}
    />
  );
};

export default CustomEditor;
