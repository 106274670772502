import React, { Fragment } from "react";
import LineChart from "../../../../reusableComponents/ECharts/LineChart/LineEChart";
import { DragHandle } from "../../widgetGenerator/index";
import { Label } from "../../../../reusableComponents/Inputs";
import EditDeleteMenu from "../../EditDeleteMenu";

function MyLineChart(props) {
  return (
    <div  style={{ width: "100%"}}>
      <div className={`"w-100 ${props.noCardStyle ? "" : ""}`} style={{ marginBottom: "0px" }}>
        <div className="chart-header">
          <DragHandle />
          <div style={{ marginTop: "8px", marginLeft: "10px" }} className="title-chart">
            <Label name={props.name} label={props.name} />
          </div>
          <EditDeleteMenu
            handleEdit={() => props.handleEdit(props.id)}
            handleDelete={() => props.handleDelete(props.id)}
          />
        </div>
        {props?.chartData && (
          <div>
            <LineChart
              width={props.width}
              height={props.height}
              isFetching={props.isFetching}
              labelData={props?.chartData?.labels || []}
              dataSeries={
                props?.chartData?.datasets?.map((dataset, index) => ({
                  name: dataset.label,
                  data: dataset.data.map((value, i) => ({
                    value,
                    itemStyle: { color: dataset.backgroundColor },
                  })),
                })) || []
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MyLineChart;
