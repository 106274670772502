import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Error, Success } from "../../reusableComponents/Alerts";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { useGetTimezonesQuery } from "../../../redux/api/geographicApi";
import Details from "./helpes/Details";
import SidebarUser from "./helpes/SidebarUser";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import {
  useCreateUserMutation,
  useGetAllUsersQuery,
  useGetUserQuery,
  useSendInvitationMutation,
  useUpdateUserMutation,
} from "../../../redux/api/usersApi";
import { SlideAlert } from "../../reusableComponents/Alerts";
import TitleHeader from "../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

function AddUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isEdit = location.pathname.includes("edit");

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [errors, setErrors] = useState({});
  const [modifiedData, setModifiedData] = useState({
    firstName: "",
    lastName: "",
    role: "",
    position: "",
    signature: "",
    username: "",
    email: "",
    confirm: "",
    timezone: null,
    locale: null,
    department: "",
    street: "",
    zip: "",
    city: "",
    phone1: "",
    phone2: "",
    fax: "",
    template: null,
    defaultFolder: 0,
    defaultAddress: "",
    isPublished: 0,
    plainPassword: {
      password: null,
      confirm: null,
    },
  });

  const [createUser, { isLoading: isAddLoading }] = useCreateUserMutation();
  const [updateUser, { isLoading: isEditLoading }] = useUpdateUserMutation(id);
  const isSaving = isEditLoading || isAddLoading;

  const { data: emailsData } = useGetAllUsersQuery();
  const { data: getTimezones } = useGetTimezonesQuery();
  const { data, isFetching, isLoading } = useGetUserQuery(id, { skip: !id });

  useEffect(() => {
    if (data && id) {
      setModifiedData(data.user);
    }
  }, [data, id]);

  console.log(modifiedData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setModifiedData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked === true ? 1 : 0) : value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const title =
    modifiedData?.firstName || modifiedData?.lastName ? modifiedData?.firstName + " " + modifiedData?.lastName : null;

  const handleAddEditUser = async (params) => {
    if (modifiedData.password !== modifiedData.passwordConfirmation) {
      alert(t("caymland.message.alert.password"));
      return;
    }

    let newErrors = {};

    if (modifiedData?.firstName?.length < 1) {
      newErrors.firstName = true;
    }
    if (modifiedData?.lastName?.length < 1) {
      newErrors.lastName = true;
    }
    if (modifiedData?.role === "") {
      newErrors.role = true;
    }
    if (modifiedData?.username?.length < 1) {
      newErrors.username = true;
    }
    if (modifiedData?.plainPassword?.password?.length < 1) {
      newErrors.password = true;
    }
    if (modifiedData?.email?.length < 1) {
      newErrors.email = true;
    }
    if (modifiedData?.plainPassword?.confirm?.length < 1 || modifiedData?.plainPassword?.confirm === null) {
      newErrors.confirm = true;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (modifiedData?.email && !emailRegex.test(modifiedData.email)) {
      newErrors.email = "Invalid email format";
      setShowAlert(true);
      setAlertMessage(`Invalid email format format: exemple@exemple.com`);
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 7000);
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    if (Object.keys(errors).length === 0) {
      try {
        let response;
        const payload = {
          ...modifiedData,
          onlineStatus: "online",
          role: modifiedData.role && typeof modifiedData.role === "object" ? modifiedData.role.id : modifiedData.role,
        };

        if (id) {
          response = await updateUser(payload);
        } else {
          response = await createUser(payload);
        }
        if (!response.error) {
          Success(
            t("caymland.core.success"),
            `${
              id
                ? t("caymland.category.notice.updated", { name: title })
                : t("caymland.category.notice.created", { name: title, id: response.data.user.id })
            }`
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navigate(`/users`);
            } else {
              navigate(`/users/edit/${response?.data?.user?.id}`);
            }
          });
        } else {
          setShowAlert(true);
          setAlertMessage(response.error.data.errors[0].message);
          setTimeout(() => {
            setAlertMessage("");
            setShowAlert(false);
          }, 7000);
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };

  const [sendInvitation] = useSendInvitationMutation();

  const url = window.location.hostname;
  console.log(url);

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/users"),
    },
    ...(isEdit
      ? [
          {
            name: "SendInvitation",
            title: t("caymland.user.send.sendInvitation"),
            icon: <KeyOutlinedIcon size={15} />,
            onClick: () => {
              const response = sendInvitation({ email: modifiedData?.email, url: url });
              if (!response.error) {
                setShowAlert(true);
                setAlertMessage(t("caymland.email.status.completed"));
                setTimeout(() => {
                  setAlertMessage("");
                  setShowAlert(false);
                }, 7000);
              }
            },
          },
        ]
      : []),
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditUser("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditUser(),
    },
  ];

  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      {isLoading ? (
        <HeaderSkeleton hasBuilderButton={true} />
      ) : (
        <Header
          buttons={buttons}
          loading={isSaving}
          title={<TitleHeader name="User" isClone={null} title={title} loading={isLoading} id={id} />}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton />
          ) : (
            <Details
              handleChange={handleChange}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <SidebarUser
              handleChange={handleChange}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              getTimezones={getTimezones}
              emailsData={emailsData}
              errors={errors}
            />
          )
        }
      />
    </>
  );
}

export default AddUser;
