import {
  ButtonGroup,
  Checkbox,
  DatePicker,
  Label,
  Select,
  Text,
  TimePicker,
} from "../../../../reusableComponents/Inputs";
import { Controller } from "react-hook-form";
import React, { useCallback } from "react";
import Button from "../../../../reusableComponents/Buttons/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SortableList from "./SortableListFields/SortableList";
import { arrayMoveImmutable } from "array-move";
import { FaEdit } from "react-icons/fa";
import { BiAddToQueue } from "react-icons/bi";
import QueryFiltersReusible from "./seeTicket/QueryFiltersReusible";
import { t } from "i18next";
import CustomCreatableSelectInput from "../../../../reusableComponents/Inputs/InputSelect";
import { useAddTagMutation } from "../../../../../redux/api/tagsApi";

export default function RenderField({ control, field, errors, reset, setId, extraOptions, watch }) {
  switch (field?.name) {
    case "fields": {
      return (
        <Controller
          control={control}
          name={`properties.filters`}
          rules={{ required: field.required }}
          defaultValue={{
            combinator: "and",
            rules: [{ field: "", id: "", operator: "", valueSource: "", value: "" }],
          }}
          render={({ field: { onChange, value, name } }) => (
            <QueryFiltersReusible
              name={name}
              query={value && typeof value === "string" ? JSON.parse(value) : value}
              setQuery={(newQuery) => onChange(newQuery)}
              fields={JSON.parse(field?.data)}
            />
          )}
        />
      );
    }
  }
  const [addTag] = useAddTagMutation();
  switch (field.childType || field?.name) {
    case "number":
    case "text":
    case "email":
    case "tel":
    case "url":
    case "textarea": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, value, onChange } }) => (
            <Text
              name={name}
              value={field.disabled ? null : value}
              required={field.required}
              label={field.label}
              tooltip={field.attr?.tooltip}
              onChange={onChange}
              disabled={field?.disabled}
              errors={errors && errors?.properties && errors?.properties[field.name]}
            />
          )}
        />
      );
    }
    // case "datetime":
    case "date": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, value, onChange } }) => (
            <DatePicker
              name={name}
              value={field.disabled ? null : value}
              required={field.required}
              disabled={field?.disabled}
              label={field.label}
              datestring
              tooltip={field.attr?.tooltip}
              onChange={onChange}
              errors={errors && errors?.properties && errors?.properties[field.name]}
            />
          )}
        />
      );
    }
    case "time": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, onChange, value } }) => (
            <TimePicker
              label={field.label}
              name={name}
              value={field.disabled ? null : value}
              required={field.required}
              disabled={field?.disabled}
              onChange={onChange}
              errors={errors && errors?.properties && errors?.properties[field.name]}
            />
          )}
        />
      );
    }

    case "choice":
    case "country":
    case "region":
    case "locale":
    case "boolean":
    case "select":
    case "multiselect":
    case "lookup":
    case "timezone": {
      let formattedChoices = [];

      if (field.name === "add_tags" || field.name === "remove_tags") {
        formattedChoices = field.choices.map((choice) => ({
          label: choice.label,
          value: choice.label,
        }));
      } else {
        // Your existing logic for other field types
        if (
          field.childType == "country" ||
          field.childType == "locale" ||
          field.childType == "multiselect" ||
          field.childType == "lookup"
        ) {
          formattedChoices = Object.entries(field?.choices || []).map(([key, value]) => ({
            label: value,
            value: key,
          }));
        } else if (field.childType == "region" || field.childType == "timezone") {
          formattedChoices = Object.entries(field.choices || []).map(([country, regions]) => ({
            label: country,
            options: Object.entries(regions).map(([regionKey, regionValue]) => ({
              label: regionValue,
              value: regionKey,
            })),
          }));
        } else if (field.childType == "boolean") {
          formattedChoices = field.choices.map((choice) => ({
            label: choice,
            value: choice.toLowerCase(),
          }));
        } else if (field.childType == "select") {
          formattedChoices = Object.entries(field.choices || []).map(([key, value]) => ({
            label: value,
            value: key,
          }));
        } else if (field?.name !== "field" && !Array.isArray(field.choices) && typeof field.choices === "object") {
          formattedChoices = Object.entries(field.choices)?.map(([key, group]) => {
            if (group.options) {
              return group;
            }
            return { label: group.label, options: [group] };
          });
        } else {
          formattedChoices = field?.choices;
        }
      }

      formattedChoices = formattedChoices?.filter((choice) => Object.keys(choice).length > 0);
      console.log(watch());

      // Then proceed with your rendering logic using formattedChoices
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, value, onChange } }) => {
            let transformedValue = value;
            const flattenOptions = (choices) => {
              return choices?.flatMap((choice) => (choice.options ? choice.options : [choice]));
            };

            if (field.name === "useremail") {
              if (value && "email" in value) {
                const flatChoices = formattedChoices?.flatMap((group) => group.options);
                const matchingChoice = flatChoices?.find((choice) => choice.value === value.email);
                transformedValue = matchingChoice || { label: value.email, value: value.email };
              }
            } else if (
              (field.multiple && Array.isArray(value)) ||
              field.childType == "multiselect" ||
              (watch()?.properties?.operator == "in" && name == "properties.value") ||
              (watch()?.properties?.operator == "!in" && name == "properties.value")
            ) {
              const flatChoices = flattenOptions(formattedChoices);

              // Ensure value is treated as an array
              let valuesArray;

              try {
                // Parse the value if it is a string in JSON format
                valuesArray = typeof value === "string" ? JSON.parse(value) : value;
              } catch (e) {
                // Fallback if parsing fails, handle error if needed
                valuesArray = [];
              }

              transformedValue = Array.isArray(valuesArray)
                ? valuesArray.map((val) => {
                    return flatChoices?.find((choice) => choice.value === val) || { label: val.toString(), value: val };
                  })
                : [];

              // Convert array to string format for storage
              // const formattedStringValue = `[${valuesArray.map((item) => `"${item}"`).join(", ")}]`;

              // Use formattedStringValue to save or submit as needed
            } else if (formattedChoices?.some((choice) => choice.options)) {
              const flatChoices = flattenOptions(formattedChoices);
              const matchingChoice = flatChoices?.find((choice) => choice.value === value);
              transformedValue = matchingChoice || value;
            }

            const handleCreateOption = async (inputValue) => {
              const newTag = { label: inputValue, value: inputValue };

              // Add the new tag to the formattedChoices array
              formattedChoices = [...formattedChoices, newTag];

              // Determine the new value based on the current field configuration
              let newValue;

              // Check if the field allows multiple selections
              if (field.multiple || field.childType === "multiselect") {
                // Handle multi-select by appending the new tag value to the existing array
                newValue = Array.isArray(transformedValue)
                  ? [...transformedValue?.map((item) => item.value), newTag.value]
                  : [newTag.value];
              } else {
                // Handle single selection by setting the new tag value directly
                newValue = newTag.value;
              }

              // Update the value using the onChange handler
              onChange(newValue);

              // Optionally, save the new tag to the server or perform other actions
              await addTag({ tag: inputValue });
            };

            console.log(errors);

            return field.name == "add_tags" ? (
              <CustomCreatableSelectInput
                name={name}
                value={field.disabled ? null : transformedValue}
                label={field.label}
                required={field.required}
                options={formattedChoices}
                tooltip={field.attr?.tooltip}
                isDisabled={field?.disabled}
                labelField="label"
                valueField="value"
                onCreateOption={handleCreateOption}
                onChange={(e) => {
                  let newValue;
                  if (field.name === "useremail") {
                    newValue = e ? { email: e.value } : {};
                  } else if (field.multiple || field.childType == "multiselect") {
                    newValue = e ? e?.map((option) => option.value) : [];
                  } else {
                    newValue = e ? e.value : null;
                  }
                  onChange(newValue);
                }}
                isMulti={field.multiple || field.childType == "multiselect"}
                errors={errors && errors.properties && errors.properties[field.name]}
              />
            ) : (
              <Select
                name={name}
                value={field.disabled ? null : transformedValue}
                label={field.label}
                required={field.required}
                options={formattedChoices}
                tooltip={field.attr?.tooltip}
                isDisabled={field?.disabled}
                onChange={(e) => {
                  let newValue;
                  if (field.name === "useremail") {
                    newValue = e ? { email: e.value } : {};
                  } else if (
                    field.multiple ||
                    (field.childType == "multiselect" &&
                      !watch()?.properties?.operator == "in" &&
                      name == "properties.value") ||
                    (!watch()?.properties?.operator == "!in" && name == "properties.value")
                  ) {
                    newValue = e ? e?.map((option) => option.value) : [];
                  } else if (
                    (watch()?.properties?.operator == "in" && name == "properties.value") ||
                    (watch()?.properties?.operator == "!in" && name == "properties.value")
                  ) {
                    const valuesArray = Array.isArray(e) ? e : e ? [e] : [];

                    // Adjust to ensure value format is like '["mr", "herr"]'
                    newValue = `[${valuesArray.map((option) => `"${option.value}"`).join(", ")}]`;
                  } else {
                    newValue = e ? e.value : null;
                  }
                  onChange(newValue);
                }}
                isMulti={
                  field.multiple ||
                  field.childType == "multiselect" ||
                  (watch()?.properties?.operator == "in" && name == "properties.value") ||
                  (watch()?.properties?.operator == "!in" && name == "properties.value")
                }
                errors={errors && errors.properties && errors.properties[field.name]}
              />
            );
          }}
        />
      );
    }
    case "toggle": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, value, onChange } }) => (
            <Checkbox
              name={name}
              value={value}
              onChange={onChange}
              label={field.label}
              tooltip={field.attr.tooltip}
              errors={errors && errors.properties}
              marginBottom={"mb-20px"}
            />
          )}
        />
      );
    }
    case "button":
    case "_campaignevent_properties_newEmailButton":
    case "_campaignevent_properties_editEmailButton":
    case "_campaignevent_properties_previewEmailButton": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, value, onChange } }) => (
            <Button
              buttons={[
                {
                  name: name,
                  title: field?.label,
                  icon:
                    field?.name === "newEmailButton" ? (
                      <BiAddToQueue size={17} />
                    ) : field?.name === "editEmailButton" ? (
                      <FaEdit size={15} />
                    ) : (
                      <VisibilityIcon />
                    ),
                  onClick: async () => {
                    let id =
                      control?._formValues?.properties?.email || control?._formValues?.properties?.useremail?.email;
                    let url =
                      field?.name === "newEmailButton"
                        ? "/emails/new"
                        : field?.name === "editEmailButton"
                        ? `/emails/edit/${id}`
                        : `/email/preview/${id}`;
                    if (field?.name === "newEmailButton" || id) {
                      window.open(url, "_blank");
                    }
                  },
                  not_active:
                    field?.name !== "newEmailButton" &&
                    !control?._formValues?.properties?.email &&
                    !control?._formValues?.properties?.useremail?.email,
                  float: "left",
                  margin: "0 10px 0 0",
                },
              ]}
            />
          )}
        />
      );
    }
    case "button_group": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { name, value, onChange } }) => (
            <ButtonGroup
              buttons={field.choices}
              value={value}
              name={name}
              onChange={onChange}
              errors={errors && errors.properties}
            />
          )}
        />
      );
    }
    case "sortablelist": {
      return (
        <Controller
          control={control}
          name={`properties.${field.name}`}
          rules={{ required: field.required }}
          render={({ field: { value: { list = [] } = {}, name, onChange: onFormChange } }) => {
            const prepareForBackend = useCallback((currentList) => {
              return currentList?.map(({ id, position, ...rest }) => rest);
            }, []);

            const handleFormChange = useCallback(
              (updatedList) => {
                const listForBackend = prepareForBackend(updatedList);
                onFormChange({ list: listForBackend });
              },
              [prepareForBackend, onFormChange]
            );

            const onItemChange = useCallback(
              (index, newItem) => {
                const updatedList = list?.map((item, i) => (i === index ? newItem : item));
                handleFormChange(updatedList);
              },
              [list, handleFormChange]
            );

            const handleOptionsAdd = () => {
              const newHeader = {
                id: Math.floor(Math.random() * 10000000) + 1,
                label: "",
                value: "",
                position: list.length + 1,
              };
              const newList = [...list, newHeader];
              handleFormChange(newList);
            };

            const onSortEnd = ({ oldIndex, newIndex }) => {
              let sortedArray = arrayMoveImmutable(list, oldIndex, newIndex);
              sortedArray.forEach((item, index) => {
                item.position = index + 1;
              });
              onFormChange(sortedArray);
            };

            return (
              <div className="mb-20px" style={{ flex: 1 }}>
                <div className="flex items-center p-no-margin gap-5px">
                  <Label
                    name={name}
                    label={field?.label}
                    // tooltip=""
                  />
                </div>
                <button type="button" onClick={handleOptionsAdd} className="addOptions-btn">
                  <span>{`${t("caymland.core.form.add")} ${t("caymland.showare.table.value")}`}</span>
                </button>
                <SortableList items={list} onSortEnd={onSortEnd} axis="y" onItemChange={onItemChange} />
              </div>
            );
          }}
        />
      );
    }
  }
}
