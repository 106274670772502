import React, { useState } from "react";
import { Checkbox, Select } from "../../../reusableComponents/Inputs";
import { usersApi } from "../../../../redux/api/usersApi";
import { t } from "i18next";

function Sidebar({
  modifiedData,
  setModifiedData,
}) {
  const [owners, setOwners] = useState([]);
  const [triggerUsersQuery] = usersApi.useLazyGetUsersQuery();


  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    const isSettings = Object.keys(modifiedData.settings).includes(name);
    isSettings &&
      setModifiedData({
        ...modifiedData,
        settings: {
          ...modifiedData.settings,
          [name]: checked,
        },
      });
    !isSettings &&
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
  };

  const handleSelectChange = (e) => {
    setModifiedData((prev) => ({
      ...prev,
      createdBy: e.value ?? null,
    }));
  };

  const retrieveOwners = (inputValue) =>
  new Promise(async (resolve, reject) => {
    if (!triggerUsersQuery) resolve([]);
    const query = {};
    if (inputValue?.length) {
      query.search = inputValue;
    }

    const response = await triggerUsersQuery(query);
    if (!response.error) {
      const ownersData = response?.data?.data;

      const ownersArray = ownersData?.map((owners) => ({
        label: owners?.firstName + " " + owners?.lastName,
        value: owners?.id,
      }));
      setOwners(ownersArray)
      resolve(ownersArray);
    }
  });

  return (
    <>
          <Checkbox
            name={"isPublished"}
            value={modifiedData?.isPublished}
            onChange={(e) => handleToggleChange(e)}
            label={t("caymland.core.form.published")}
          />
          <Checkbox
            name={"system"}
            value={modifiedData?.system}
            onChange={(e) => handleToggleChange(e)}
            label={t("caymland.report.report.form.issystem")}
            // tooltip="A system report is available to all users. Setting this to no will make it visible to the creator and roles with permission to view other users' reports."
          />
            <Select
              name="owner"
              label={t("caymland.report.report.form.owner")}
              isClearable={false}
              options={retrieveOwners}
              value={
                owners?.find(
                  (option) => option.value === modifiedData?.createdBy
                ) ?? null
              }
              onChange={(selectedOption) => handleSelectChange(selectedOption)}
            />
        <div style={{ borderTop: "1px solid #ebebeb", padding: "20px 0" }}>
          <h5 style={{ marginBottom: "20px" }}>{t("caymland.report.report.form.display.dynamic.filters.settings")}</h5>
            <Checkbox
              name={"showDynamicFilters"}
              value={modifiedData?.settings?.showDynamicFilters}
              onChange={(e) => handleToggleChange(e)}
              label={t("caymland.report.report.form.display.show.dynamic.filters")}
            />
            <Checkbox
              name={"hideDateRangeFilter"}
              value={modifiedData?.settings?.hideDateRangeFilter}
              onChange={(e) => handleToggleChange(e)}
              label={t("caymland.report.report.form.display.hide.date.ranges")}
            />
        </div>
    </>
  );
}

export default Sidebar;
