import React from "react";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import { Checkbox, DatePicker, Label, Select, TimePicker } from "../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../reusableComponents/retriveCategoriesByBundle";
import Button from "../../../reusableComponents/Buttons/Button";
import { categoriesApi, useAddCategoryMutation } from "../../../../redux/api/categoriesApi";
import ModalGenerator from "../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import moment from "moment";
import { segmentsApi } from "../../../../redux/api/segmentsApi";
import { t } from "i18next";

const Sidebar = ({
  isFetching,
  modifiedData,
  setModifiedData,
  modalOptions,
  setModalOptions,
  setNewItem,
  errors,
  setErrors,
}) => {
  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();
  const [triggerSegments] = segmentsApi.useLazyGetSegmentsQuery();

  const retrieveSegments = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerSegments) resolve([]);

      const query = {};
      if (inputValue?.length) {
        query.search = inputValue;
      }

      try {
        const response = await triggerSegments(query).unwrap();
        const data = response?.data;

        const options = data.map((x) => ({
          id: x.id,
          name: x.name,
        }));

        resolve(options);
      } catch (error) {
        reject(error);
      }
    });

  const daysInWeek = [
    { label: "Monday", value: "MONDAY" },
    { label: "Tuesday", value: "TUESDAY" },
    { label: "Wednesday", value: "WEDNESDAY" },
    { label: "Thursday", value: "THURSDAY" },
    { label: "Friday", value: "FRIDAY" },
    { label: "Saturday", value: "SATURDAY" },
    { label: "Sunday", value: "SUNDAY" },
  ];

  return isFetching ? (
    <SidebarSkeleton />
  ) : (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Label
        name="type"
        required={true}
        label={"Message type"}
        tooltip="A template SMS message is automatically sent by campaigns, forms, point triggers, etc. These can be edited but cannot be sent to a contact segment."
      />
      <div className="flex items-center justify-center w-full" style={{ marginBottom: "25px" }}>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "template",
                title: "Template",
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    smsType: "template",
                  });
                },
              },
            ]}
            variant={modifiedData?.smsType === "template" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "segment",
                title: "Segment",
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    smsType: "list",
                  });
                },
              },
            ]}
            variant={modifiedData?.smsType === "list" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
      </div>
      <Select
        name="category"
        label={t("caymland.page.tracking.form.category")}
        bundle="asset"
        options={retrieveCategories}
        value={modifiedData?.category ?? null}
        placeholder={t("caymland.core.select")}
        onChange={(selectedOption) => {
          if (selectedOption?.value === "create_new") {
            setModalOptions((prev) => ({ ...prev, isOpen: true }));
            return;
          }
          setModifiedData((prev) => ({
            ...prev,
            category: selectedOption,
          }));
        }}
        trigger={triggerCategories}
        isClearable
        isCreatable
      />

      {modifiedData.smsType === "list" && (
        <>
          <Select
            name="lists"
            label={t("caymland.email.form.list")}
            isMulti={true}
            required
            errors={errors.lists}
            options={retrieveSegments}
            labelField="name"
            valueField="id"
            value={modifiedData.lists ?? null}
            placeholder={t("caymland.core.select")}
            onChange={(e) => {
              if (errors[name]) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: undefined,
                }));
              }
              setModifiedData((prevState) => ({
                ...prevState,
                lists: e,
              }));
            }}
            isClearable
          />
        </>
      )}

      <div className="switch-holder pb-20">
        <div className="switch-toggle">
          <Checkbox
            name="isPublished"
            value={modifiedData?.isPublished}
            onChange={(e) => {
              const { name, checked } = e.target;
              setModifiedData((prev) => ({
                ...prev,
                [name]: checked,
              }));
            }}
            label={t("caymland.core.form.published")}
          />
        </div>
      </div>
      {modifiedData.smsType === "list" && (
        <>
          <div style={{ padding: "10px 0" }}> </div>
          <Label name={"communicationStartDate"} label={t("caymland.sms.communication_start")} />
          <div style={{ marginBottom: "20px" }}> </div>
          <DatePicker
            label={t("caymland.infobip.sms.type.communication.start.date")}
            name={"communicationStartDate"}
            value={
              modifiedData.properties?.communicationStartDate
                ? moment(modifiedData.properties?.communicationStartDate, "DD.MM.YYYY").toDate()
                : null
            }
            onChange={(date) => {
              const formattedDate = date ? moment(date).format("DD.MM.YYYY") : null;
              setModifiedData({
                ...modifiedData,
                properties: {
                  ...modifiedData.properties,
                  communicationStartDate: formattedDate,
                },
              });
            }}
            showTimeSelect={false}
          />
          <TimePicker
            value={modifiedData?.properties?.communicationStartTime}
            label={t("caymland.infobip.sms.type.communication.start.time")}
            name={"communicationStartTime"}
            onChange={(e) => {
              if (e === null) {
                setModifiedData((prevState) => ({
                  ...prevState,
                  properties: {
                    ...prevState.properties,
                    communicationStartTime: null,
                  },
                }));
              } else {
                setModifiedData((prevState) => ({
                  ...prevState,
                  properties: {
                    ...prevState.properties,
                    communicationStartTime: e,
                  },
                }));
              }
            }}
          />
          <div style={{ padding: "10px 0" }}> </div>
          <Label name={"deliveryTimeWindow"} label={t("caymland.sms.delivery_time_window")} />
          <div style={{ marginBottom: "20px" }}> </div>
          <TimePicker
            value={modifiedData?.properties?.deliveryStartTime}
            label={t("caymland.infobip.viber.form.delivery_window_start_time")}
            name={"deliveryStartTime"}
            maxTime={modifiedData?.properties?.deliveryEndTime}
            onChange={(e) => {
              setModifiedData((prevState) => ({
                ...prevState,
                properties: {
                  ...prevState.properties,
                  deliveryStartTime: e ? e : null,
                },
              }));
            }}
          />
          <TimePicker
            value={modifiedData?.properties?.deliveryEndTime}
            label={t("caymland.infobip.viber.form.delivery_window_end_time")}
            name={"deliveryEndTime"}
            minTime={modifiedData?.properties?.deliveryStartTime}
            onChange={(e) => {
              setModifiedData((prevState) => ({
                ...prevState,
                properties: {
                  ...prevState.properties,
                  deliveryEndTime: e ? e : null,
                },
              }));
            }}
          />
          <Select
            name="deliveryDays"
            label={t("caymland.infobip.sms.type.delivery.days")}
            isMulti={true}
            required
            options={daysInWeek}
            value={daysInWeek.filter((day) => modifiedData?.properties?.deliveryDays?.includes(day.value))}
            placeholder={t("caymland.core.select")}
            onChange={(selectedOptions) => {
              setModifiedData((prevState) => ({
                ...prevState,
                properties: {
                  ...prevState.properties,
                  deliveryDays: selectedOptions ? selectedOptions.map((option) => option.value) : [],
                },
              }));
            }}
            isClearable
          />
        </>
      )}
    </>
  );
};

export default Sidebar;
