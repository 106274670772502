import { t } from "i18next";
import { Checkbox } from "../../../reusableComponents/Inputs";

function SidebarCustomField({ modifiedData, setModifiedData }) {
  return (
    <>
      <div className="flex gap-10 w-100 justify-between mb-2">
        <Checkbox
          rows={true}
          name="Published"
          value={modifiedData.isPublished}
          onChange={() =>
            setModifiedData({
              ...modifiedData,
              isPublished: !modifiedData.isPublished,
            })
          }
          label={t("caymland.core.form.published")}
        />
        <div style={{ marginRight: "12px" }}>
          <Checkbox
            rows={true}
            name="required"
            value={modifiedData.isRequired}
            onChange={() =>
              setModifiedData({
                ...modifiedData,
                isRequired: !modifiedData.isRequired,
              })
            }
            label={t("caymland.core.required")}
          />
        </div>
      </div>

      <div className="flex gap-10 w-100 justify-between mb-2">
        <Checkbox
          rows={true}
          name="visible-on-forms"
          value={modifiedData.isVisible}
          onChange={() =>
            setModifiedData({
              ...modifiedData,
              isVisible: !modifiedData.isVisible,
            })
          }
          label={t("caymland.lead.field.form.isvisible")}
        />
        <Checkbox
          rows={true}
          name="visible-on-short-forms"
          value={modifiedData.isShortVisible}
          onChange={() =>
            setModifiedData({
              ...modifiedData,
              isShortVisible: !modifiedData.isShortVisible,
            })
          }
          label={t("caymland.lead.field.form.isshortvisible")}
        />
      </div>

      <div className="flex gap-10 w-100 justify-between mb-2">
        <Checkbox
          rows={true}
          name="available-for-segments"
          value={modifiedData.isListable}
          onChange={() =>
            setModifiedData({
              ...modifiedData,
              isListable: !modifiedData.isListable,
            })
          }
          label={t("caymland.lead.field.form.islistable")}
        />
        <Checkbox
          rows={true}
          name="publicly-updatable"
          value={modifiedData.isPubliclyUpdatable}
          onChange={() =>
            setModifiedData({
              ...modifiedData,
              isPubliclyUpdatable: !modifiedData.isPubliclyUpdatable,
            })
          }
          label={t("caymland.lead.field.form.ispubliclyupdatable")}
        />
      </div>

      <div className="flex gap-10 w-100 justify-between mb-2">
        <Checkbox
          rows={true}
          name="is-unique-identifier"
          value={modifiedData.isUniqueIdentifier}
          onChange={() =>
            setModifiedData({
              ...modifiedData,
              isUniqueIdentifier: !modifiedData.isUniqueIdentifier,
            })
          }
          label={t("caymland.lead.field.form.isuniqueidentifer")}
        />
      </div>

      <div className={`warning-text ${modifiedData.isUniqueIdentifier ? "display-b" : ""}`}>
        {t("caymland.lead.field.form.isuniqueidentifer.warning")}
      </div>
    </>
  );
}

export default SidebarCustomField;
