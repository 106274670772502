import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "formiojs/dist/formio.full.css";
import Fields from "./AddForm/Fields";
import Actions from "./AddForm/Actions";
import Sidebar from "./AddForm/Sidebar";
import { Error, Success } from "../../../reusableComponents/Alerts";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import { useAddFormsMutation, useGetAddEditFormsQuery, useUpdateFormsMutation } from "../../../../redux/api/formsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import Swal from "sweetalert2";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

const filterActions = (response, setActions) => {
  let initActions = Object.values(response.filedsAndActions.actions);
  let groupedActions = initActions.map((x) => Object.entries(x));
  let finalActions = groupedActions.map((x) => x.map((y) => ({ value: y[0], options: y[1] })));
  let arr = [];

  finalActions.map((x) => x.map((y) => arr.push(y)));
  setActions(arr);
};

const filterFields = (response, setFields) => {
  let initFields = Object.entries(response.filedsAndActions.fields);
  let finalFields = initFields.map((y) => ({ value: y[0], label: y[1] }));

  setFields(finalFields);
};

function AddNewForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const ClonePath = location.pathname.includes("/clone");
  const EditPath = location.pathname.includes("/edit");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [errors, setErrors] = useState({});
  const [actions, setActions] = useState([]);
  const [fields, setFields] = useState([]);
  const [backgorundToast, setBackgroundToast] = useState("");
  const query = {};

  const { data, isFetching, isLoading } = useGetAddEditFormsQuery({ id, query });
  const [editForm, { isLoading: isEditLoading }] = useUpdateFormsMutation();
  const [addForm, { isLoading: isAddLoading }] = useAddFormsMutation();

  // Use combined loading state
  const isSaving = isEditLoading || isAddLoading;

  const [modifiedData, setModifiedData] = useState({
    name: "",
    description: "",
    noIndex: false,
    inKioskMode: false,
    renderStyle: true,
    hashcodeMode: false,
    isPublished: true,
    fields: [
      {
        tempFieldId: `${Math.floor(Math.random() * 100000)}`,
        label: t("caymland.core.form.submit"),
        type: "button",
        inputAttributes: 'class="btn btn-default"',
        containerAttributes: null,
      },
    ],
    actions: [],
    postAction: "return",
    postActionProperty: "",
    template: null,
    formType: "standalone",
    category: null,
    publishUp: null,
    publishDown: null,
  });
  console.log(modifiedData);
  useEffect(() => {
    if (data) {
      filterActions(data, setActions);
      filterFields(data, setFields);
    }

    if (data && id) {
      let formData = data?.forms?.form;
      if (ClonePath) {
        formData = { ...formData, isPublished: false };
      }
      setModifiedData({
        ...formData,
        category: {
          label: formData?.category?.title,
          value: formData?.category?.id,
        },
      });
    }
  }, [data, id, ClonePath]);

  const handleAddForm = async (params) => {
    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }
    if (errors.name) {
      setShow(true);
      setMessage(t("caymland.core.error.not.valid"));
      setBackgroundToast("2");
      setTimeout(() => {
        setMessage("");
        setShow(false);
        setBackgroundToast("1");
      }, 3000);
    }
    try {
      const nonButtonFields = modifiedData.fields
        .filter((field) => field.type !== "button")
        .map((field, index) => ({ ...field, order: index + 1 }));

      const buttonField = modifiedData.fields.find((field) => field.type === "button");

      const updatedButtonField = buttonField ? { ...buttonField, order: nonButtonFields.length + 1 } : null;

      const { createdBy, createdByUser, dateAdded, dateModified, ...dataToProcess } = modifiedData;

      // ... rest of the code ...
      const dataToSend = {
        ...dataToProcess,
        fields: updatedButtonField ? [...nonButtonFields, updatedButtonField] : [...nonButtonFields],
        category: dataToProcess?.category?.value,
      };
      console.log(dataToSend);
      let response;
      if (EditPath) {
        response = await editForm({ id, ...dataToSend }).unwrap();
      } else {
        response = await addForm(dataToSend);
      }
      if (!response.error) {
        Success(
          t("caymland.core.update.step.success"),
          t("caymland.message.type.successfully", {
            type: EditPath
              ? t("caymland.core.type.edited")
              : ClonePath
              ? t("caymland.core.type.cloned")
              : t("caymland.dashboard.create.past.tense"),
          })
        ).then((result) => {
          if (result.isConfirmed && params === "back") {
            navigate(`/forms/view/${response?.data?.form?.id || response?.form?.id}`);
          } else {
            navigate(`/forms/edit/${response?.data?.form?.id || response?.form?.id}`);
          }
        });
      } else {
        t("caymland.core.error"), t("caymland.message.error");
      }
    } catch (e) {
      Error(t("caymland.message.error"));
    }
  };

  const handleCancel = () => {
    Swal.fire({
      title: t("caymland.mailbox.message.delete.title"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/forms");
      } else {
        return;
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
      show: false,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddForm("back"),
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddForm(""),
      show: false,
    },
  ];

  return (
    <>
      <Fragment>
        {isLoading ? (
          <HeaderSkeleton />
        ) : (
          <Header
            buttons={buttons}
            background={backgorundToast}
            loading={isSaving}
            alertMessage={message}
            showAlert={show}
            title={
              <TitleHeader
                name={`${t("caymland.core.theme.feature.form")}`}
                clone={ClonePath}
                title={modifiedData?.name}
                loading={isLoading}
                id={id}
              />
            }
          />
        )}
        <Wrapper
          childrenClassOne={"wrapper-childOne no-padding"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton applyPadding />
            ) : (
              <>
                {" "}
                <Nav className="nav-primary w-full flex" tabs>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 1 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(1)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.form.tab.fields")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 2 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(2)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.form.tab.actions")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={primarycolorTab}>
                  <TabPane tabId={1}>
                    <Fields
                      setModifiedData={setModifiedData}
                      modifiedData={modifiedData}
                      loading={isFetching}
                      setFields={setFields}
                      fields={fields}
                    />
                  </TabPane>
                  <TabPane tabId={2}>
                    <Actions
                      setModifiedData={setModifiedData}
                      modifiedData={modifiedData}
                      loading={isFetching}
                      actions={actions}
                    />
                  </TabPane>
                </TabContent>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwo"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton />
            ) : (
              <>
                {" "}
                <Sidebar
                  errors={errors}
                  setErrors={setErrors}
                  modifiedData={modifiedData}
                  setModifiedData={setModifiedData}
                />
              </>
            )
          }
        />
      </Fragment>
    </>
  );
}

export default AddNewForm;
