import React from "react";
import { RiLineChartLine } from "react-icons/ri";
import DateFromTo from "../../../../dashboard/defaultCompo/dateFromTo";
import LineChart from "../../../../reusableComponents/ECharts/LineChart/LineEChart";
import { t } from "i18next";

function DwcStats({ extras, handleDateChange, type, loading }) {
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5px" style={{ width: "30%", color: "#8c8c8c", borderBottom: "20px" }}>
          <RiLineChartLine color="#8c8c8c" size={18} />
          {t("caymland.dynamicContent.views")}
        </div>
        <div className="flex items-center gap-5px">
          <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} loading={loading}/>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <LineChart
          labelData={extras?.views?.labels || []}
          dataSeries={
            extras?.views?.datasets?.map((dataset, index) => ({
              name: dataset.label,
              data: dataset.data.map((value, i) => ({
                value,
                itemStyle: { color: dataset.backgroundColor },
              })),
            })) || []
          }
        />
      </div>
    </>
  );
}

export default DwcStats;
