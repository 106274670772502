import moment from "moment";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiClock } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import { useUpdatePageMutation } from "../../../../../redux/api/pagesApi";
import { t } from "i18next";

function Translations({ page, extras }) {
  const { id } = useParams();
  const [localLoading, setLocalLoading] = useState(-1);

  const [updatePage] = useUpdatePageMutation();

  // const handleTogglePublished = async (id, page) => {
  //     let obj = page;

  //     if (obj.category) {
  //         obj.category = page.category.id;
  //     }

  //     let tempParent
  //     if (obj.translationParent) {
  //         tempParent = obj.translationParent
  //         obj.translationParent = page.translationParent.id;
  //     }

  //     let tempChildren
  //     if (obj.translationChildren) {
  //         tempChildren = obj.translationChildren
  //         obj.translationChildren = page.translationChildren.map((x) => x.id)
  //     }
  //     await updatePage({id, ...obj});

  //     page.translationParent = tempParent
  //     page.translationChildren = tempChildren
  // }

  const handleTogglePublished = async (pageId, pageData) => {
    // Toggle the isPublished state
    const updatedPage = {
      ...pageData,
      isPublished: !pageData.isPublished,
    };

    // Handle the category, translationParent, and translationChildren, if they exist
    if (updatedPage.category) {
      updatedPage.category = updatedPage.category.id;
    }
    if (updatedPage.translationParent) {
      updatedPage.translationParent = updatedPage.translationParent.id;
    }
    if (updatedPage.translationChildren) {
      updatedPage.translationChildren = updatedPage.translationChildren.map((child) => child.id);
    }

    // Update the page through the API
    await updatePage({ id: pageId, ...updatedPage });

    // Optionally, update local state here, if needed
    // For example:
    // setLocalPageData(updatedPage);
  };

  return (
    <div>
      {extras?.translations?.parent && (
        <div>
          <div
            className="flex items-center justify-between"
            style={{ padding: "15px", border: "1px solid #f0f0f0", background: "#fbfbfb" }}
          >
            <div className="flex items-center gap-5px">
              {moment(extras?.translations?.parent?.publishUp).format("DD.MM.YYYY HH:mm") <
                moment(new Date()).format("DD.MM.YYYY HH:mm") && extras?.translations?.parent?.isPublished ? (
                <FiClock
                  title={t("caymland.core.form.expired")}
                  color="#f86b4f"
                  size={20}
                  style={{ marginRight: "25px" }}
                />
              ) : localLoading === extras?.translations?.parent?.id ? (
                <ClipLoader size={14} />
              ) : (
                <div className="checkbox-wrapper-22">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={extras?.translations?.parent?.isPublished}
                      onChange={() =>
                        handleTogglePublished(extras?.translations?.parent.id, extras?.translations?.parent)
                      }
                    />
                    <div className="slider round"></div>
                  </label>
                </div>
              )}
              <Link to={`/pages/view/${extras?.translations?.parent?.id}`} style={{ color: "#1a1a1a" }}>
                {extras?.translations?.parent?.title}
              </Link>
              <button
                className="info-box-button"
                style={{
                  backgroundColor: "#ffbb33",
                }}
              >
                {t("caymland.core.parent")}
              </button>
              {extras?.translations?.parent?.id == id && (
                <button
                  className="info-box-button"
                  style={{
                    backgroundColor: "#04B76B",
                  }}
                >
                  {t("caymland.core.current")}
                </button>
              )}
            </div>
            <em style={{ color: "#b8b8b8" }}>{extras?.translations?.parent?.language}</em>
          </div>
        </div>
      )}

      {extras?.translations.children ? (
        <div>
          {Object.entries(extras?.translations?.children).map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between mt-10"
              style={{ padding: "15px", border: "1px solid #f0f0f0", background: "#fbfbfb" }}
            >
              <div className="flex items-center gap-5px">
                {moment(item[1]?.publishUp).format("DD.MM.YYYY HH:mm") <
                  moment(new Date()).format("DD.MM.YYYY HH:mm") && item[1]?.isPublished ? (
                  <FiClock
                    title={t("caymland.core.form.expired")}
                    color="#f86b4f"
                    size={20}
                    style={{ marginRight: "25px" }}
                  />
                ) : localLoading === item[1].id ? (
                  <ClipLoader size={14} />
                ) : (
                  <div className="checkbox-wrapper-22">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={item[1].isPublished}
                        onChange={() => handleTogglePublished(item[1].id, item[1])}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                )}
                <Link to={`/pages/view/${item[1]?.id}`} style={{ color: "#1a1a1a" }}>
                  {item[1]?.title}
                </Link>

                {item[1]?.id == id && (
                  <button
                    className="info-box-button"
                    style={{
                      backgroundColor: "#04B76B",
                    }}
                  >
                    {t("caymland.core.current")}
                  </button>
                )}
              </div>
              <em style={{ color: "#b8b8b8" }}>{item[1]?.language}</em>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default Translations;
