import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const webHooksApi = createApi({
  reducerPath: "webHooksApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Hooks"],
  endpoints: (build) => ({
    getHooks: build.query({
      query: customBaseQuery((q) => `/api/hooks?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Hooks"],
    }),
    updateHook: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/hooks/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Hooks", id }],
    }),
    deleteHook: build.mutation({
      query: (id) => ({
        url: `/api/hooks/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          webHooksApi.util.updateQueryData("getHooks", undefined, (draft) => {
            draft.data = draft.data.filter((Hooks) => Hooks.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Hooks", id }],
    }),
    listAvailableWebHooks: build.query({
      query: () => "/api/hooks/triggers",
    }),
    getHook: build.query({
      query: (id) => `/api/hooks/${id}`,
      providesTags: (result, error, id) => [{ type: "Hooks", id }],
    }),
    getExtras: build.query({
      query: (id) => `/api/hooks/${id}/extras`,
      providesTags: (result, error, id) => [{ type: "Hooks", id }],
    }),
    createHook: build.mutation({
      query: (hook) => {
        delete hook.id;
        return {
          url: `/api/hooks/new`,
          method: "POST",
          body: hook,
        };
      },
      invalidatesTags: ["Hooks"],
    }),
    sendTestHook: build.mutation({
      query: ({ url, types }) => ({
        url: `/api/hooks/sendTestHook`,
        method: "POST",
        body: {
          url,
          types,
        },
      }),
      invalidatesTags: ["Hooks"],
    }),
  }),
});

export const {
  useGetHookQuery,
  useGetExtrasQuery,
  useGetHooksQuery,
  useDeleteHookMutation,
  useUpdateHookMutation,
  useCreateHookMutation,
  useListAvailableWebHooksQuery,
  useSendTestHookMutation
} = webHooksApi;
