import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import flattenDeep from "lodash/flattenDeep";
import NotFoundPage from "../NotFound/NotFoundPage";

const generateFlattenRoutes = (routes, hasPermission, inheritedPermissionKey = null) => {
  if (!routes) return [];
  return flattenDeep(
    routes.map(({ children: subRoutes, permissionKey, permissionValue, ...rest }) => {
      const currentPermissionKey = permissionKey ?? inheritedPermissionKey; // Use child's permissionKey or inherit from parent
      if (currentPermissionKey && !hasPermission(currentPermissionKey, permissionValue)) {
        return []; // Skip this route if permission is not granted
      }
      return [
        { ...rest, permissionKey: currentPermissionKey, permissionValue },
        generateFlattenRoutes(subRoutes, hasPermission, currentPermissionKey), // Pass down currentPermissionKey to children
      ];
    }),
  );
};

export const RoutesComponent = ({ mainRoutes }) => {
  const { rawPermissions: permissions = {}, isAdmin } = useSelector((state) => state?.auth?.user?.role || {});

  const hasPermission = (permissionKey, permissionValue) => {
    if (isAdmin) {
      return true;
    }
    if (permissionKey in permissions) {
      if (!permissionValue || permissions[permissionKey].includes("full")) {
        return true;
      }
      return permissions[permissionKey].includes(permissionValue);
    }
    return false;
  };

  return (
    <ReactRoutes>
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      {mainRoutes.map(({ layout: Layout, routes: subRoutes, permissionKey }, index) => {
        const subRoutes2 = generateFlattenRoutes(subRoutes, hasPermission, permissionKey); // Pass down permissionKey to generateFlattenRoutes
        return (
          <Route key={index} element={<Layout />}>
            {subRoutes2.map(({ element, path, name }) => (
              <Route key={name} path={path} element={element} />
            ))}
          </Route>
        );
      })}
      <Route path="*" element={<NotFoundPage />} /> {/* Display NotFound component for unmatched routes */}
    </ReactRoutes>
  );
};
