import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import CustomTable from "../../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function Contacts({ extras, setDateFrom, setDateTo, query, setQuery }) {
  const { id } = useParams();
  useEffect(() => {
    setDateFrom(localStorage.getItem("fromDate"));
    setDateTo(localStorage.getItem("toDate"));
  }, [localStorage]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) => row.firstname || row.ipAddresses[0].ip,
        header: t("caymland.core.name"),
        enableColumnActions: false,
      },
      {
        accessorKey: "email",
        accessorFn: (row) => row.email,
        header: t("caymland.core.searchcommand.email"),
        enableColumnActions: false,
      },
      {
        accessorKey: "Location",
        accessorFn: (row) => row.location,
        header: t("caymland.lead.lead.activity.location"),
        enableColumnActions: false,
      },
    ],
    []
  );
  return (
    extras?.results && (
      <CustomTable columns={columns} query={query} setQuery={setQuery} data={extras} viewTable={true} showDetailPanel />
    )
  );
}

export default Contacts;
