import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "./Inputs";

function MultipleSelects({
  widgetDataOptions,
  GroupHeading,
  selectedOptions,
  handleOptionsChange,
  singleWidgetData,
  setSingleWidgetData,
  selectTheme,
  selectStyles,
}) {
  // let options;
  // if (singleWidgetData) {
  //   if (singleWidgetData.options) {
  //     options = Object.entries(singleWidgetData.options).map((x) => ({
  //       key: x[0],
  //       values: x[1],
  //     }));
  //   }
  // }

  // let values = options?.map((x) => x.values);
  // let selectedParams;

  // if (singleWidgetData.params) {
  //   selectedParams = Object.entries(singleWidgetData.params).map((x) => ({
  //     label: x[0] ? x[0] : "Select",
  //     value: x[1] ? x[1] : null,
  //   }));
  // }

  const getDefaultValues = (options) => {
    const defaultValues = singleWidgetData.params;
    const result = options[1].values.filter((option) => option.value == defaultValues[options[0]]);
    return result.length ? result[0] : {};
  };

  return widgetDataOptions?.length ? (
    <div className="col-sm-6 col-md-6  w-full">
      {widgetDataOptions?.map((widget, i) => (
        <>
          <Label name={"validationDefault02"} label={widget[1].label === "" ? widget[0] : widget[1].label } />
          <Select
            id="validationDefault02"
            required={widget[1].required}
            isSearchable
            isMulti={widget[1].multiple}
            isClearable
            className="mb-20px"
            options={widget[1].values}
            components={{ GroupHeading }}
            onChange={(e) => handleOptionsChange(e, widget[0], widget[1].multiple)}
            theme={selectTheme}
            styles={selectStyles}
          />
        </>
      ))}
    </div>
  ) : singleWidgetData.options ? (
    <div className="col-sm-6 col-md-6  w-full">
      {Object.entries(singleWidgetData.options).map((option) => (
        <>
          <Label name={"validationDefault02"} label={option[1].label} />

          <Select
            id="validationDefault02"
            required={option[1].required}
            isMulti={option[1].multiple}
            isSearchable
            className="mb-20px"
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            isClearable
            options={option[1].values}
            defaultValue={getDefaultValues(option)}
            components={{ GroupHeading }}
            onChange={(e) => handleOptionsChange(e, option[0], option[1].multiple)}
            theme={selectTheme}
            styles={selectStyles}
          />
        </>
      ))}

      {/* {options?.map((option, i) => (
        <>
          <label
            htmlFor="validationDefault02 capitalize"
            style={{ marginTop: "20px" }}
          >
            {option.label}
          </label>
          <Select
            id="validationDefault02"
            required
            isSearchable
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            isClearable
            defaultValue={{
              label: selectedParams[i].value,
              value: selectedParams[i].value,
            }}
            options={values[0]}
            components={{ GroupHeading }}
            onChange={(e) => handleOptionsChange(e, option.key)}
          />
        </>
      ))} */}
    </div>
  ) : (
    <></>
  );
}

export default MultipleSelects;
