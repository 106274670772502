import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { FieldsRenderSection } from "./FiledsRenderReusable";
import { useTranslation } from "react-i18next";

function CoreTabAddCompany({ newCompanyObject, requiredFieldsText, companyFields, setFormChanged, control, errors }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filteredFields, setFilteredFields] = useState([]);

  useEffect(() => {
    if (companyFields) {
      // Create a copy of data.data and then sort and filter it
      const sortedAndFilteredFields = [...companyFields]
        .sort((a, b) => a.order - b.order)
        .filter((x) => x.isPublished === true && x.group === "core" && x.isVisible === true);

      // Update the state with the sorted and filtered array
      setFilteredFields(sortedAndFilteredFields);
    }
  }, [companyFields]);

  const nameAndEmailAliases = ["companyname", "companyemail"];
  const otherAliases = ["companyphone", "companywebsite", "grundungsjahr"];
  const companyAddressAliases = [
    "companystate",
    "companyaddress1",
    "companyaddress2",
    "companycity",
    "companycountry",
    "companyzipcode",
  ];

  const excludedAliases = new Set([...nameAndEmailAliases, ...otherAliases, ...companyAddressAliases]);

  const remainingAliases = filteredFields
    .filter((field) => !excludedAliases.has(field.alias))
    .map((field) => field.alias);

  return (
    <Form>
      <div>
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Loading />
          </div>
        ) : (
          <div className="gap-10" style={{ display: "grid", padding: "20px" }}>
            <FieldsRenderSection
              aliases={nameAndEmailAliases}
              filteredFields={filteredFields}
              isW50={true}
              setFormChanged={setFormChanged}
              requiredFieldsText={requiredFieldsText}
              control={control}
              errors={errors}
            />
            <FieldsRenderSection
              aliases={companyAddressAliases}
              filteredFields={filteredFields}
              isW50={true}
              control={control}
              errors={errors}
            />
            <FieldsRenderSection
              aliases={otherAliases}
              filteredFields={filteredFields}
              isW50={true}
              control={control}
              errors={errors}
            />
            {remainingAliases?.length > 0 && (
              <FieldsRenderSection
                aliases={remainingAliases}
                filteredFields={filteredFields}
                isW50={true}
                control={control}
                errors={errors}
              />
            )}
          </div>
        )}
      </div>
    </Form>
  );
}

export default CoreTabAddCompany;
