import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import AgGridTable from "../../../../reusableComponents/DataTables/AgGridTable";
import calculateTableHeight from "../../../../reusableComponents/calculateTableHeight";
import CustomTable from "../../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function Contacts({ extras, handlePageChange, handlePerRowsChange, contactsLength, tableLoading, rowsPerPage, page }) {
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
  });
  const [tableData, setTableData] = useState([]);
  const LinkComponent = (props) => {
    return (
      <div className="flex items-center justify-between">
        <div>
          <Link className="table-links" to={`/contacts/view/${props.data.id}`}>
            {props.data.fields.core.firstname.value + " " + props.data.fields.core.lastname.value}
          </Link>
        </div>
      </div>
    );
  };

  const EmailComponent = (props) => {
    return props.data.fields.core.email.value;
  };

  useEffect(() => {
    if (extras?.contacts) {
      setTableData(Object.values(extras?.contacts?.results));
    }
  }, [extras]);

  const LocationComponent = (props) => {
    let city = props.data.fields.core.city ? props.data.fields.core.city.value : "";
    let state = props.data.fields.core.state ? props.data.fields.core.state.value : "";
    let country = props.data.fields.core.country ? props.data.fields.core.country.value : "";

    let flagLetters = props.data.fields.core.country
      ? props.data.fields.core.country.value === "Switzerland"
        ? "ch"
        : props.data.fields.core.country?.value?.toLowerCase()
      : "";

    return props.data.fields.core.state || props.data.fields.core.city || props.data.fields.core.country ? (
      <div>
        {flagLetters && <i className={`flag-icon flag-icon-${flagLetters}`}></i>} {city}
        {city && state && ", "} {state}
        {!city && !state && country}
      </div>
    ) : (
      <></>
    );
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "ip",
        accessorFn: (row) => row.ipAddresses[0].ip,
        header: t("caymland.core.name"),
        enableColumnActions: false,
      },
      {
        accessorKey: "email",
        accessorFn: (row) => row.email,
        header: t("caymland.core.type.email"),
        enableColumnActions: false,
      },
      {
        accessorKey: "Location",
        accessorFn: (row) => row.location,
        header: t("caymland.lead.lead.thead.location"),
        enableColumnActions: false,
      },
    ],
    []
  );
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Name",
      field: "firstname",
      flex: 1,
      minWidth: 150,
      cellRenderer: LinkComponent,
      cellClass: "overflow-hidden",
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 150,
      flex: 1,
      cellRenderer: EmailComponent,
      cellClass: "overflow-hidden",
    },
    {
      headerName: "Location",
      field: "location",
      minWidth: 150,
      flex: 1,
      cellClass: "overflow-hidden",
      cellRenderer: LocationComponent,
    },
  ]);

  const defaultColDef = useMemo(() => ({
    filter: false,
    sortable: false,
  }));

  return (
    <div>
      <div
        style={{
          borderBottom: "1px solid #ebebeb",
          minHeight: "100px",
        }}
      >
        <CustomTable columns={columns} data={tableData} query={query} setQuery={setQuery} viewTable={true} />
      </div>
    </div>
  );
}

export default Contacts;
