import React, { useEffect, useState } from "react";
import { Select } from "../../reusableComponents/Inputs";
import { usersApi } from "../../../redux/api/usersApi";
import { retrieveUsers } from "../../reusableComponents/retrieveUsers/retrieveUsersFunc";
import { Controller } from "react-hook-form";
import { retrieveTags } from "../../reusableComponents/retrieveTags/retrieveTagsFunc";
import { tagsApi, useAddTagMutation } from "../../../redux/api/tagsApi";
import { stagesApi } from "../../../redux/api/stagesApi";
import { retrieveStages } from "../../reusableComponents/retrieveStages/retrieveStages";
import { t } from "i18next";
import CustomCreatableSelectInput from "../../reusableComponents/Inputs/InputSelect";

const StaticSelectsInForns = ({ control, watch, setValue, noBorder = false }) => {

  const [tags, setTags] = useState([]);
  const [initialTags, setInitalTags] = useState([]);
  const [triggerUsers] = usersApi.useLazyGetUsersQuery();
  const [triggerStages] = stagesApi.useLazyGetStagesQuery();
  const [triggerTags] = tagsApi.useLazyGetTagsQuery();
  const [addTag] = useAddTagMutation();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagsArray = await retrieveTags("", null, triggerTags);
        setTags(tagsArray);
        setInitalTags(tagsArray);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, [triggerTags]);

  const handleChange = (selectedOptions) => {
    const selectedTags = selectedOptions.map((option) => option.value);
    const removedTags = initialTags.filter((tag) => !selectedTags.includes(tag.value));
    const updatedTags = selectedOptions.concat(removedTags.map((tag) => ({ ...tag, value: "-" + tag.value })));

    setValue("tags", updatedTags?.map((tag) => tag.value) || []);
  };

  const handleCreateOption = async(inputValue) => {
    const newTag = { label: inputValue, value: inputValue };
    setTags((prevTags) => [...prevTags, newTag]);
    setValue('tags', [...(watch('tags') || []), inputValue]);
    await addTag({ tag: inputValue });
  };

  return (
    <div
      className="group-fields-style"
      style={{
        border: noBorder ? "none" : "1px solid #135f9517 ",
        padding: noBorder ? "0 25px 0 25px" : "20px 25px 0 25px",
      }}
    >
      <Controller
        control={control}
        name="stage"
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            label={t("caymland.lead.stage.label")}
            isClearable
            className="w-full"
            options={retrieveStages}
            trigger={triggerStages}
            value={value}
            onChange={(selectedOption) => onChange(selectedOption?.value)}
          />
        )}
      />

      <div className="w-full d-flex" style={{ gap: "20px" }}>
        <Controller
          control={control}
          name="owner"
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              label={t("caymland.lead.list.filter.owner")}
              isClearable
              className="w-full"
              options={retrieveUsers}
              trigger={triggerUsers}
              value={value ? value : watch("owner_id")}
              onChange={(selectedOption) => onChange(selectedOption?.value)}
            />
          )}
        />
        <Controller
          control={control}
          name="tags"
          render={({ field: { name, onChange, value } }) => (
            <CustomCreatableSelectInput
            name={name}
            label={t("caymland.lead.list.filter.tags")}
            isClearable
            isMulti={true}
            className="w-full"
            options={retrieveTags}
            value={tags.filter((tagOption) => value?.includes(tagOption.value))}
            onChange={handleChange}
            onCreateOption={handleCreateOption}
            trigger={triggerTags}
          />
          )}
        />
      </div>
    </div>
  );
};

export default StaticSelectsInForns;
