import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";
import moment from "moment";

export const focusItemsApi = createApi({
  reducerPath: "focusItemsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["FocusItems"],
  endpoints: (build) => ({
    getFocusItems: build.query({
      query: customBaseQuery((q) => `/api/focus?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["FocusItems"],
    }),
    updateFocusItems: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/focus/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "FocusItems", id }],
    }),
    deleteFocusItems: build.mutation({
      query: (id) => ({
        url: `/api/focus/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          focusItemsApi.util.updateQueryData("getFocusItems", undefined, (draft) => {
            draft.data = draft.data.filter((FocusItems) => FocusItems.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "FocusItems", id }],
    }),
    addNewFocusItem: build.mutation({
      query: (body) => ({
        url: `/api/focus/new`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "FocusItems", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    getFocusData: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        let focusData, builderPreviewData, themesData;

        if (id) {
          const focusResult = await baseQuery({ url: `api/focus/${id}` });
          focusData = focusResult?.data;
        } else {
          focusData = null;
        }

        const builderPreviewResult = await baseQuery({ url: `api/focus/builderpreview` });
        builderPreviewData = builderPreviewResult.data;

        const themesResult = await baseQuery({ url: `api/focus/themes` });
        themesData = themesResult.data;

        const data = {
          focusData,
          builderPreviewData,
          themesData,
        };

        return { data: data };
      },
      providesTags: (result, error, id) => [{ type: "FocusItems", id }],
    }),
    getExtras: build.query({
      query: ({ id, dateFrom, dateTo }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        });
        return `api/focus/${id}/extras?${params}`;
      },
      providesTags: (result, error, { id }) => [{ type: "FocusItems", id }],
    }),
    getFocusEditorOption: build.query({
      query: () => ({
        url: `/api/focus/editor/options`,
      }),
      keepUnusedDataFor: 60,
      providesTags: ["FocusItems"],
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderFocusItems: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=focus`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderFocusItems: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderFocusItems: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["FocusItems"],
    }),
    //DELETE FOLDER
    deleteFolderFocusItems: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateFocusItemsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/focus/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["FocusItems"],
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeFocusItemsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/focus/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetFocusItemsQuery,
  useDeleteFocusItemsMutation,
  useUpdateFocusItemsMutation,
  useAddNewFocusItemMutation,
  useGetFocusDataQuery,
  useGetExtrasQuery,
  useGetFocusEditorOptionQuery,
  //FOLDERS
  useGetFolderFocusItemsQuery,
  useUpdateFolderFocusItemsMutation,
  useDeleteFolderFocusItemsMutation,
  useAddFolderFocusItemsMutation,
  useUpdateFocusItemsToFoldersMutation,
  useRemoveFocusItemsToFoldersMutation,
} = focusItemsApi;
