import { createSlice } from "@reduxjs/toolkit";
import { settingsApi, useSettingsQuery } from "../api/settingsApi"; // Adjust the import path as needed

const initialState = {
  data: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(settingsApi.endpoints.settings.matchFulfilled, (state, { payload }) => {
      state.data = payload;
    });
    // You can also handle other states like pending and rejected here
  },
});

export const { setData } = settingsSlice.actions;

export default settingsSlice.reducer;
