import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../reusableComponents/Header/Header";
import Sidebar from "./viewHooks/Sidebar";
import Details from "./viewHooks/Details";
import Logs from "./viewHooks/Logs";
import { FaEdit, FaRegTimesCircle, FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { useDeleteHookMutation, useGetExtrasQuery, useGetHookQuery } from "../../../redux/api/webHooksApi";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import CustomizedAccordions from "../../reusableComponents/Accordion/Accordion";
import { Success } from "../../reusableComponents/Alerts";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

function ViewHooks() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const [extraInfo, setExtraInfo] = useState(null);
  const [hook, setHook] = useState({});

  const { data, isFetching, isLoading } = useGetHookQuery(id);
  const { data: getExtras } = useGetExtrasQuery(id);
  const [deleteHook] = useDeleteHookMutation();

  useEffect(() => {
    if (data && id && data.hook) {
      setHook(data?.hook);
    }
    if (getExtras && id && getExtras) {
      setExtraInfo(getExtras);
    }
  }, [data, getExtras, id]);

  const handleDelete = () => {
    Swal.fire({
      title: t("caymland.mailbox.message.delete.title"),
      text: t("caymland.webhook.form.confirmdelete", { name: hook.name }),
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteHook(id);
        console.log("response", response);
        if (response) {
          Success("Success, this segment has been deleted!");
          navigate("/webhooks");
        }
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/webhooks"),
    },
    {
      name: "edit",
      title: t("caymland.core.form.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/webhooks/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.core.permissions.delete"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details hook={hook} />,
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <HeaderSkeleton isView={true} />
      ) : (
        <Header buttons={buttons} title={hook.name} published={hook.isPublished} showPublished loading={isFetching} />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={isLoading ? <DetailsSkeleton /> : <Logs hook={hook} extras={extraInfo} />}
        childrenClassTwo={"wrapper-childTwoNoMargin"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton applyPadding={true} />
          ) : (
            <>
              {hook && hook?.description ? <CustomizedAccordions accordions={accordions} isOpen={true} /> : null}
              <Sidebar hook={hook} extras={extraInfo} />
            </>
          )
        }
      />
    </Fragment>
  );
}

export default ViewHooks;
