import React from "react";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";

function Documentation() {
  return (
    <>
      <Header title="Caymland Documenatation" />
      <Wrapper
        childrenClassOne={"w-full"}
        childrenOne={
          <iframe
            src="https://handbuch.m-4.ch/"
            marginheight="0"
            marginwidth="0"
            style={{
              padding: "12px",
              height: "100%",
              width: "100%",
            }}
            width="100%"
            frameborder="0"
          >
            Your browser doesn't support iframes
          </iframe>
        }
      />
    </>
  );
}

export default Documentation;
