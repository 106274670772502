import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Points = ({ data, pointsValue }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="right-info" style={{ minWidth: "120px" }}>
        <p style={{ padding: "0 20px" }}>{t("caymland.lead.graph.line.points")}</p>
        <p
          className="badge badge-light tag-pills-sm-mb"
          style={{
            fontSize: "0.75",
            fontWeight: "400",
            border: "1px solid #55607F",
            color: "#55607F",
            background: "white",
          }}
        >
          {pointsValue ? pointsValue : data?.contact?.points}
        </p>
      </div>
    </>
  );
};

export default Points;
