import { Fade, IconButton, Tooltip, Zoom } from "@mui/material";
import { RiQuestionFill } from "react-icons/ri";
import ClipLoader from "react-spinners/ClipLoader";
import React from "react";
import { t } from "i18next";

export default function ({ name, label, tooltip, required, isLoading, errors, extraStyle, rows = false, labelHidden }) {
  const labelStyle = {
    color: "#6c757d",
    maxWidth: rows ? '74px' : 'none',
    opacity: labelHidden ? 0 : 1
  };
  return (
    <label
      className={`toggle-label`}
      htmlFor={name}
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: "13px",
        gap: "3px",
        justifyContent: "space-between",
        marginRight: extraStyle?.marginRight || '10px'
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "center" }}>
        <span 
          className={`font-13 ${required && "required"}`} 
          style={labelStyle}
        >
          {label?.charAt(0)?.toUpperCase() + label?.slice(1)}{" "}
        </span>

        {tooltip && tooltip.length && (
          <Tooltip title={tooltip} arrow followCursor placement="top" TransitionComponent={Zoom} PopperProps={{
            disablePortal: true,
          }}>
            <IconButton style={{ cursor: "help", padding: 0 }}>
              <RiQuestionFill size={16} />
            </IconButton>
          </Tooltip>
        )}

        {isLoading && (
          <div>
            <ClipLoader color="#686868" size={13} />
          </div>
        )}
      </div>

      {errors && (
        <div>
          <span
            style={{
              fontSize: "11px",
              color: "#d32f2f",
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              lineHeight: 1.66,
              letterSpacing: "0.03333em",
            }}
          >
            {t("caymland.core.fieldrequired")}
          </span>
        </div>
      )}
    </label>
  );
}
