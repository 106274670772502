import React from "react";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { t } from "i18next";

function Sidebar({ extras }) {
  return (
    <div className="px-20 mt-20px">
      <div
        style={{
          paddingBottom: "20px",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {t("caymland.core.recent.activity")}
      </div>
      <div className="card-body px-20">
        <div className="activity">
          {extras?.recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{ marginRight: "15px", background: activity.action === "create" ? "limegreen" : "#e9e7ed" }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity.action === "create"
                    ? t("caymland.core.create.by.past.tense")
                    : t("caymland.core.update.by.past.tense")}{" "}
                  <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{moment(activity.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
