import React from "react";
import Moment from "moment";
import { t } from "i18next";

function Details({ dwc }) {
  const details = [
    { label: t("caymland.page.tracking.form.category"), value: dwc?.category ? dwc?.category.title : "Uncategorized" },
    { label: t("caymland.showare.field.created_by"), value: dwc?.createdByUser },
    {
      label: t("caymland.core.created"),
      value: dwc?.dateAdded ? Moment(dwc?.dateAdded).format("DD.MM.YY hh:mm") : "Invalid Date",
    },
    { label: t("caymland.core.modifiedby"), value: dwc?.modifiedByUser },
    {
      label: t("caymland.core.modified"),
      value: dwc?.dateModified ? Moment(dwc?.dateModified).format("DD.MM.YY hh:mm") : "Invalid Date",
    },
    { label: t("caymland.core.id"), value: dwc?.id },
    { label: t("caymland.dwc.form.is_campaign_based"), value: dwc?.isCampaignBased ? "Yes" : "No" },
  ];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px">
          <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
        </div>
      ))}
    </div>
  );
}

export default Details;
