import React, { Fragment, useEffect, useRef, useState } from "react";
import ColorPicker from "../../../reusableComponents/Inputs/ColorPicker";
import IconPicker from "../../../reusableComponents/iconPicker/IconPicker";
import { Label, Text, TextArea } from "../../../reusableComponents/Inputs";
import { t } from "i18next";

function Details({ errors, setErrors, modifiedData, setModifiedData }) {
  const [chosenIcon, setChosenIcon] = useState("");

  const colorPickerRef = useRef();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "name") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }
    if (type === "text")
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  useEffect(() => {
    setModifiedData((prev) => ({
      ...prev,
      icon: chosenIcon,
    }));
  }, [chosenIcon]);

  const handleColorChange = (color) => {
    setModifiedData((prev) => ({
      ...prev,
      color: color.hex,
    }));
  };

  const handleClickIcon = (e) => {
    setModifiedData((prev) => ({
      ...prev,
      icon: e,
    }));
  };


  return (
    <Fragment>
      <div className="flex flex-row w-full gap-10">
        <div className="flex flex-col w-50">
            <Text
              name="name"
              value={modifiedData?.name}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.name")}
              errors={errors?.name}
              required={true}
            />
          <Label 
            name="icon"
            label={t("caymland.stage.action.icon")}
          />
          <IconPicker
            chosenIcon={chosenIcon || modifiedData?.icon}
            setChosenIcon={setChosenIcon}
            onClick={(e) => handleClickIcon(e)}
          />
        </div>
        <div className="flex flex-col w-50">
        <div className="w-full" ref={colorPickerRef}>
              <ColorPicker 
            type="text"
            id="colorInput"
            name="colorInput"
            value={modifiedData?.color}
            onChange={handleColorChange}
            colorPickerRef={colorPickerRef}
            label={t("caymland.stage.action.color")}
            wFull='w-full'
          />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-100">
        <TextArea
          label={t("caymland.lottery.table.row.description")}
          name="Description"
          value={modifiedData?.description ?? ""}
          onChange={(data) => {
            setModifiedData((prev) => ({
              ...prev,
              description: data,
            }));
          }}
        />
      </div>
    </Fragment>
  );
}

export default Details;
