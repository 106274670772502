import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import DetailsAndFilters from "./viewReport/DetailsAndFilters";
import Downloads from "./viewReport/Downloads";
import { FaTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { useGetReportViewQuery } from "../../../redux/api/reportsApi";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import Swal from "sweetalert2";
import { useDeleteAssetsMutation } from "../../../redux/api/assetsApi";
import { t } from "i18next";
import { Error } from "../../reusableComponents/Alerts";

function ViewReport() {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [dateFrom, setDateFrom] = useState(moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
  const [filters, setFilters] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    dateFrom,
    dateTo,
    filters: {},
    orderByDir: "desc",
  });

  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);

  const { data, isLoading, isFetching } = useGetReportViewQuery({ id, ...filters });
  const [deleteReport] = useDeleteAssetsMutation();

  useEffect(() => {
    if (data) {
      if (initialLoad) {
        setReport(data?.report);
      }
      setInitialLoad(false);
      setExtraInfo(data?.graphs);
      setDataTable(data?.table);
    }
  }, [id, data]);

  const handleDateChange = async () => {
    setDateFrom(localStorage.getItem("fromDate"));
    setDateTo(localStorage.getItem("toDate"));

    const dynamicFilters = Object.fromEntries(
      Object.entries(report?.filters || {}).filter(([_, value]) => value.dynamic === 1)
    );

    const transformedFilters = Object.keys(dynamicFilters).reduce((acc, key) => {
      const filter = dynamicFilters[key];
      acc[filter.column] = { ...filter };
      return acc;
    }, {});

    setFilters((prev) => ({
      ...prev,
      dateFrom: localStorage.getItem("fromDate"),
      dateTo: localStorage.getItem("toDate"),
      filters: transformedFilters,
    }));
  };

  const updateFilters = async (key, field, newValue) => {
    setReport((prevData) => ({
      ...prevData,
      filters: {
        ...prevData.filters,
        [key]: { ...prevData.filters[key], [field]: newValue },
      },
    }));
  };
  const handleDelete = async () => {
    setLoading(true);
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.message.delete", { name: report?.name }),
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const response = await deleteReport(report.id);
        if (!response.error) {
          setLoading(false);
          navigate("/reports");
        }
      }
    });
    setLoading(false);
  };

  const navigateHTMLView = () => {
    const url = `/reports/view/${id}/html/export`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleDownloadClick = async (format) => {
    try {
      const url = `${process.env.REACT_APP_DEV_URL}/api/reports/${id}/export/${format}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic Q2F5bWxhbmQ6VWx0cmFzb2Z0",
        },
        body: JSON.stringify({
          dateFrom: dateFrom,
          dateTo: dateTo,
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const blob = await response.blob();

      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      const currentDateTime = new Date().toISOString().replace(/T/, "_").replace(/\..+/, "").replace(/:/g, "-");
      link.download = `${currentDateTime}_${report?.name}.${format}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Error downloading file:", err);
    }
  };

  let exportToHTMLButton = null;

  // Check if extraInfo is not null and, if it's an array, it's not empty
  if (extraInfo && (!Array.isArray(extraInfo) || extraInfo.length > 0)) {
    exportToHTMLButton = {
      name: "exportToHTML",
      title: `${t("caymland.email.report.export")} ${t("caymland.focus.form.html")}`,
      icon: <i className="fa fa-file-code-o" />,
      onClick: () => navigateHTMLView(),
    };
  }
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/reports"),
    },
    {
      name: "edit",
      title: t("caymland.core.form.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/reports/edit/${id}`),
      childs: [
        {
          name: "exportToCSV",
          title: t("caymland.core.export.csv"),
          icon: <i className="fa fa-file-text-o" />,
          onClick: () => handleDownloadClick("csv"),
        },
        {
          name: "exportToExcel",
          title: t("caymland.core.export.xls"),
          icon: <i className="fa fa-file-excel-o" />,
          onClick: () => handleDownloadClick("xlsx"),
        },
        exportToHTMLButton,
        {
          name: "delete",
          title: t("caymland.core.permissions.delete"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ].filter(Boolean),
    },
  ];

  return (
    <div className="Reports">
      {isLoading ? (
        <HeaderSkeleton isView={true} />
      ) : (
        <Header
          buttons={buttons}
          title={report?.name}
          loading={isLoading}
          showIsPublished={true}
          isPublished={report?.isPublished}
          data={report}
        />
      )}
      <Wrapper
        childrenClassOne={isLoading ? "w-75" : "reportViewChildOne"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton applyPadding={true} />
          ) : (
            <Downloads
              extras={extraInfo}
              dataTable={dataTable}
              setFilters={setFilters}
              filters={filters}
              report={report}
              isFetching={isFetching}
            />
          )
        }
        childrenClassTwo={"wrapper-childTwoNoMargin"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton applyPadding={true} />
          ) : (
            <DetailsAndFilters report={report} updateFilters={updateFilters} handleDateChange={handleDateChange} />
          )
        }
      />
    </div>
  );
}

export default ViewReport;
