import React from "react";
import Moment from "moment";
import { useTranslation } from "react-i18next";

function Details({ page }) {
  const { t } = useTranslation();
  const details = [
    {
      label: t("caymland.page.tracking.form.category"),
      value: page?.category ? page?.category.title : "Uncategorized",
    },
    { label: t("caymland.showare.field.created_by"), value: page?.createdByUser },
    {
      label: t("caymland.core.created"),
      value: page?.dateAdded ? Moment(page?.dateAdded).format("DD.MM.YY hh:mm") : "",
    },
    { label: t("caymland.core.modifiedby"), value: page?.modifiedByUser },
    {
      label: t("caymland.core.modified"),
      value: page?.dateModified ? Moment(page?.dateModified).format("DD.MM.YY hh:mm") : "",
    },
    {
      label: t("caymland.dynamicContent.publish.up"),
      value: page?.publishUp ? Moment(page?.publishUp).format("DD.MM.YY hh:mm") : "",
    },
    {
      label: t("caymland.core.permissions.publishown"),
      value: page?.publishDown ? Moment(page?.publishDown).format("DD.MM.YY hh:mm") : "",
    },
    { label: t("caymland.core.id"), value: page?.id },
  ];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px">
          <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
        </div>
      ))}
    </div>
  );
}

export default Details;
