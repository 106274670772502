import moment from "moment";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { BiLinkExternal } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";

function Sidebar({ page, extras }) {
  const { t } = useTranslation();

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;

  return (
    <Fragment>
      <div
        style={{
          padding: "20px",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {t("caymland.page.point.action.form.page.url")}
      </div>
      <div class="input-container ic1 flex px-20">
        <input
          id="download-url"
          style={{
            borderRadius: "6px 0 0 6px",
            background: "#f4f4f4",
            padding: "0 10px",
          }}
          className="jump-input cursor-disabled"
          type="text"
          disabled
          value={api + `/${page?.alias}`}
          placeholder=" "
        />
        <a rel="noreferrer" href={api + `/${page?.alias}`} target="_blank" className="download-button">
          <BiLinkExternal color="#333" />
        </a>
      </div>
      <div
        style={{
          padding: "20px",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {t("caymland.page.preview.url")}
      </div>
      <div class="input-container ic1 flex px-20">
        <input
          id="download-url"
          style={{
            borderRadius: "6px 0 0 6px",
            background: "#f4f4f4",
            padding: "0 10px",
          }}
          className="jump-input cursor-disabled"
          type="text"
          disabled
          value={api + `/page/preview/${page?.id}`}
          placeholder=" "
        />
        <a rel="noreferrer" href={api + `/page/preview/${page?.id}`} target="_blank" className="download-button">
          <BiLinkExternal color="#333" />
        </a>
      </div>
      <div
        style={{
          padding: "20px",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {t("caymland.core.recent.activity")}
      </div>
      <div className="card-body px-20" style={{ overflow: "hidden" }}>
        <div className="activity">
          {extras?.recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{ marginRight: "15px", background: activity.action === "create" ? "limegreen" : "#e9e7ed" }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity.action === "create"
                    ? t("caymland.showare.field.created_by")
                    : t("caymland.core.update.by.past.tense")}{" "}
                  <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{moment(activity.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Sidebar;
