import { t } from "i18next";
import { Runtime } from "../HelperHistory";

const Status = (props) => {
  const status = props?.status;

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return { color: "rgb(5,30,52)", label: t("caymland.mailbox.list.action.new") };
      case 2:
        return { color: "#fb3", label: t("caymland.lead.import.status.2") };
      case 3:
        return { color: "#04B76B", label: t("caymland.lead.export.status.3") };
      default:
        return { color: "black", label: "asdf" };
    }
  };

  const { color, label } = getStatusColor(status);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "150px" }}>
      <span className="label-history" style={{ border: `1px solid ${color}`, color: color, marginTop: "10px" }}>
        {label}
      </span>
      <Runtime {...props} />
    </div>
  );
};

export default Status;
