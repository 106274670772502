import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../reusableComponents/loading";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Contacts from "./viewEmail/Contacts";
import ClickCounts from "./viewEmail/ClickCounts";
import Details from "./viewEmail/Details";
import Sidebar from "./viewEmail/Sidebar";
import EmailStats from "./viewEmail/EmailStats";
import moment from "moment";
import Translations from "./viewEmail/Translations";
import MainModal from "../../../reusableComponents/MainModal";
import SendEmailContent from "./viewEmail/SendEmailContent";
import { Error, Success } from "../../../reusableComponents/Alerts";
import Header from "../../../reusableComponents/Header/Header";
import Swal from "sweetalert2";
import { FaTrashAlt, FaEdit, FaRegTimesCircle, FaPaperPlane, FaBan } from "react-icons/fa";
import { AiOutlineBarChart } from "react-icons/ai";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import {
  useDeleteEmailMutation,
  useGetAddEditEmailsQuery,
  useGetEmailsQuery,
  useSendExampleMutation,
  useSendSegmentEmailMutation,
  useStopSegmentEmailMutation,
} from "../../../../redux/api/emailsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import { useSelector, useDispatch } from "react-redux";
import { useGetUserSelfQuery, useGetUsersQuery, useUserSelfQuery } from "../../../../redux/api/usersApi";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { TbSend } from "react-icons/tb";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { emailsApi } from "../../../../redux/api/emailsApi";
import dayjs from "dayjs";

function ViewEmail(props) {
  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const [sendExampleOpen, setSendExampleOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [dateFrom, setDateFrom] = useState(localStorage.getItem("fromDate"));
  const [dateTo, setDateTo] = useState(localStorage.getItem("toDate"));
  const [applyLoading, setApplyLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(null);
  const [buttonVisibility, setButtonVisibility] = useState({
    showSendButton: false,
    showStopButton: false,
    showResumeButton: false,
    isExpired: false,
  });

  const id = location?.state?.id || location?.state || useParams().id;
  const query = `&dateFrom=${moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD")}&dateTo=${moment(
    localStorage.getItem("toDate")
  ).format("YYYY-MM-DD")}`;

  const navigate = useNavigate();
  const userEmail = useSelector((state) => state.auth.user);

  const { data, refetch, isFetching, isLoading } = useGetAddEditEmailsQuery({ id, query });
  const [sendExample] = useSendExampleMutation();
  const [deleteEmail] = useDeleteEmailMutation();
  const [_sendSegmentEmail] = useSendSegmentEmailMutation();
  const [_stopSegmentEmail] = useStopSegmentEmailMutation();
  const [getEmail] = emailsApi.useLazyGetEmailQuery();

  const [sendEmailList, setSendEmailList] = useState([{ id: 1, email: userEmail.email, position: 1 }]);

  const stopRefetching = () => {
    if (refetchInterval) {
      clearInterval(refetchInterval);
      setRefetchInterval(null);
    }
  };

  const stopSegmentEmail = async (id) => {
    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.email.stopsend.emails"),
      icon: "question",
      customClass: {
        confirmButton: "confirm-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      showCancelButton: true,
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
    });
    if (result.isConfirmed) {
      await _stopSegmentEmail({ id });
      refetch();
      stopRefetching();
    }
  };

  const sendSegmentEmail = async (id) => {
    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.email.send.emails", { number: data?.email?.email?.pendingCount }),
      icon: "question",
      customClass: {
        confirmButton: "confirm-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      showCancelButton: true,
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
    });
    if (result.isConfirmed) {
      await _sendSegmentEmail({ id });
      await refetch();

      stopRefetching();

      const interval = setInterval(async () => {
        // await refetch();
        const email = await getEmail(id);
        if (email?.data?.email?.status === 3) {
          clearInterval(interval);
          setRefetchInterval(null);
        }
      }, 5000);
      setRefetchInterval(interval);
    }
  };

  const changeDynamicTab = (index, tabTitle) => {
    setprimarycolorTab(index);
  };

  useEffect(() => {
    if (data?.email && id) {
      fetchEmail();
      setSendEmailList([
        {
          id: 1,
          email: userEmail.email,
          postion: 1,
        },
      ]);
    }
  }, [data, id]);

  const handleDateChange = () => {
    setApplyLoading(true);
    setDateFrom(localStorage.getItem("fromDate"));
    setDateTo(localStorage.getItem("toDate"));
    fetchEmail(true);
  };

  const fetchEmail = async (localLoading = false) => {
    if (!localLoading) {
      setLoading(true);
    }
    const response = data.email.email;
    const extras = data.extras;

    setExtraInfo(extras);

    setEmail(response);
    setLoading(false);
    setApplyLoading(false);
  };

  const navBack = () => {
    navigate("/emails");
  };

  const editNavigate = () => {
    navigate(`/emails/edit/${id}`);
  };

  const advancedReportNavigate = () => {
    navigate(`/emails/advanced-report/${id}`);
  };

  const handleSubmit = async () => {
    try {
      setSendLoading(true);

      // Structure the payload to match the required format
      const payload = {
        emails: sendEmailList.map((x) => x.email),
      };

      // Send the request with the structured payload
      const response = await sendExample({ id, body: payload }).unwrap();

      if (!response.error) {
        Success(t("caymland.email.notice.test_sent_multiple.success")).then(() => {
          setSendExampleOpen(false);
        });
      } else {
        Error(t("caymland.message.error"));
      }
      setSendLoading(false);
    } catch (e) {
      setSendLoading(false);
      Error(t("caymland.message.error"));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: email?.name })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteEmail(email?.id);
        if (!response.error) {
          setAlertMessage(t("caymland.message.type.successfully", { type: t("caymland.dashboard.delete.past.tense") }));
          navBack();
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 7000);
        }
      }
    });
  };

  useEffect(() => {
    if (data?.email && id) {
      const emailType = data?.email?.email?.emailType === "list";
      const isPublished = data?.email?.email?.isPublished;
      const isExpired = dayjs().isAfter(dayjs(data?.email?.email?.publishDown));

      setButtonVisibility({
        showSendButton:
          isPublished &&
          emailType &&
          !isExpired &&
          data.email.email?.status === 0 &&
          parseFloat(data.email.email?.pendingCount) > 0,
        showStopButton: isPublished && emailType && !isExpired && data.email.email?.status === 1,
        showResumeButton:
          isPublished &&
          emailType &&
          !isExpired &&
          ((data.email.email?.status === 2 && parseFloat(data.email.email?.pendingCount) > 0) ||
            (data.email.email?.status === 3 && parseFloat(data.email.email?.pendingCount) > 0)),
      });
    }
  }, [data, id]);

  const shouldShowButton = Object.values(buttonVisibility).some((value) => value === true);

  const buttons = [
    ...(shouldShowButton
      ? [
          (() => {
            const baseButton = {
              name: buttonVisibility.showSendButton
                ? t("caymland.email.send")
                : buttonVisibility.showStopButton
                ? t("caymland.email.stat.stop")
                : buttonVisibility.showResumeButton
                ? t("caymland.core.resume")
                : "",
              title: buttonVisibility.showSendButton
                ? t("caymland.email.send")
                : buttonVisibility.showStopButton
                ? t("caymland.email.stat.stop")
                : buttonVisibility.showResumeButton
                ? t("caymland.core.resume")
                : "",
              icon: buttonVisibility.showSendButton ? (
                <TbSend size={15} />
              ) : buttonVisibility.showStopButton ? (
                <FaBan size={15} color="#f86b4f" />
              ) : buttonVisibility.showResumeButton ? (
                <RestartAltIcon size={15} color="#f86b4f" />
              ) : null,
            };

            if (buttonVisibility.showSendButton || buttonVisibility.showResumeButton) {
              baseButton.onClick = () => {
                sendSegmentEmail(id);
              };
            } else if (buttonVisibility.showStopButton) {
              baseButton.onClick = () => {
                stopSegmentEmail(id);
              };
            }

            return baseButton;
          })(),
        ]
      : []),
    { name: "close", title: t("caymland.core.close"), icon: <FaRegTimesCircle size={15} />, onClick: () => navBack() },
    {
      name: "SendExample",
      title: t("caymland.email.send.example"),
      icon: <FaPaperPlane size={15} />,
      onClick: () => showSendEmail(),
    },

    {
      name: "edit",
      title: t("caymland.core.form.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => editNavigate(),
      childs: [
        {
          name: "delete",
          title: t("caymland.core.form.delete"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];

  if (email?.sentCount > 0) {
    buttons.splice(1, 0, {
      name: "advancedReport",
      title: t("caymland.email.button.report"),
      icon: <AiOutlineBarChart size={21} />,
      onClick: () => advancedReportNavigate(),
    });
  }

  const showSendEmail = () => {
    setSendExampleOpen(true);
    setSendEmailList([{ id: 1, email: userEmail.email, position: 1 }]);
  };
  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} email={email} />,
    },
  ];

  return (
    <Fragment>
      <>
        {isLoading ? (
          <HeaderSkeleton isView={true} viewBigButton={true} />
        ) : (
          <Header
            buttons={buttons}
            title={email?.name}
            showIsPublished={true}
            isPublished={email?.isPublished}
            data={email}
            showAlert={showAlert}
            alertMessage={alertMessage}
            shouldShowButton={shouldShowButton}
          />
        )}

        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton />
            ) : (
              <>
                <MainModal
                  modalTitle={t("caymland.email.send.example")}
                  isOpen={sendExampleOpen}
                  setIsOpen={setSendExampleOpen}
                  handleSubmit={handleSubmit}
                  loading={sendLoading}
                  content={<SendEmailContent sendEmailList={sendEmailList} setSendEmailList={setSendEmailList} />}
                  saveText={t("caymland.email.send")}
                />

                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <EmailStats
                      extras={extraInfo}
                      type={email?.emailType}
                      setExtras={setExtraInfo}
                      handleDateChange={handleDateChange}
                      loading={applyLoading}
                    />
                  </CardBody>
                </Card>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <Nav className="nav-primary w-full" tabs>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 1 ? "active" : ""}
                          onClick={() => changeDynamicTab(1)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.trackable.click_counts")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 2 ? "active" : ""}
                          onClick={() => changeDynamicTab(2)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.email.associated.contacts")}
                        </NavLink>
                      </NavItem>
                      {email?.translationChildren.length || email?.translationParent ? (
                        <NavItem>
                          <NavLink
                            href="#"
                            className={primarycolorTab === 3 ? "active" : ""}
                            onClick={() => changeDynamicTab(3)}
                            style={{
                              color: "#313131",
                              textTransform: "capitalize",
                            }}
                          >
                            {t("caymland.core.translations")}
                          </NavLink>
                        </NavItem>
                      ) : (
                        <></>
                      )}
                    </Nav>
                    <TabContent activeTab={primarycolorTab}>
                      <TabPane tabId={1}>
                        <ClickCounts extras={extraInfo} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <Contacts
                          setDateFrom={setDateFrom}
                          setDateTo={setDateTo}
                          dateTo={dateTo}
                          dateFrom={dateFrom}
                          extras={extraInfo}
                          setExtraInfo={setExtraInfo}
                        />
                      </TabPane>
                      <TabPane tabId={3}>
                        <Translations refetch={refetch} email={email} extras={extraInfo} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwoNoMargin"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton applyPadding />
            ) : (
              <>
                <CustomizedAccordions accordions={accordions} />
                <Sidebar email={email} extras={extraInfo} setEmail={setEmail} />
              </>
            )
          }
        />
      </>
    </Fragment>
  );
}

export default ViewEmail;
