import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { FieldsRenderSection } from "../contacts/FieldsRenderer";
import { current } from "@reduxjs/toolkit";

function DynamicTab({ currentTab, contactFields, control, errors }) {
  const [filteredFields, setFilteredFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contactFields) {
      const sortedAndFilteredFields = [...contactFields]
        .sort((a, b) => a.order - b.order)
        .filter((x) => x.isPublished === true && x.group == currentTab && x.isVisible === true);
      setFilteredFields(sortedAndFilteredFields);
    }
  }, [contactFields, currentTab]);
  return (
    <Form>
      <div>
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Loading />
          </div>
        ) : (
          <div className="gap-10" style={{ display: "grid", padding: "10px" }}>
            <FieldsRenderSection errors={errors} filteredFields={filteredFields} isW50={false} control={control} />
          </div>
        )}
      </div>
    </Form>
  );
}

export default DynamicTab;
