import { t } from "i18next";
import React from "react";

function ClickCounts({ extras }) {
  return (
    <div className="history-table no-min-height">
      <div className="history-table-header flex items-center">
        <div className="flex-1" style={{ padding: "10px 10px 10px 20px" }}>
          {t("caymland.trackable.click_url")}
        </div>
        <div
          style={{
            width: "70px",
            padding: "10px 10px 10px 20px",
          }}
        >
          {t("caymland.trackable.click_count")}
        </div>
        <div
          style={{
            width: "120px",
            padding: "10px 10px 10px 20px",
          }}
        >
          {t("caymland.trackable.click_unique_count")}
        </div>
        <div
          style={{
            flex: 1,
            maxWidth: "25%",
            padding: "10px 10px 10px 20px",
          }}
        >
          {t("caymland.trackable.click_track_id")}
        </div>
      </div>
      <div
        style={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        {extras?.trackables?.map((item, index) => (
          <div key={index} className="history-table-content">
            <div className="flex items-center">
              <div
                className="flex-1"
                style={{
                  padding: "10px 10px 10px 20px",
                  fontSize: "12px",
                }}
              >
                {item.url}
              </div>
              <div
                className="col-md-8"
                style={{
                  width: "70px",
                  padding: "10px 0",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.hits}
              </div>
              <div
                className="col-md-3"
                style={{
                  width: "120px",
                  padding: "10px 10px 10px 20px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p
                  title={t("caymland.email.stat.simple.tooltip")}
                  className="badge badge-dark tag-pills-sm-mb"
                  style={{ fontSize: "10px", fontWeight: "500", padding: "5px 7px" }}
                >
                  {item.unique_hits}
                </p>
              </div>
              <div
                className="col-md-3"
                style={{
                  flex: 1,
                  maxWidth: "25%",
                  padding: "10px 10px 10px 20px",
                  fontSize: "12px",
                }}
              >
                {item.redirect_id}
              </div>
            </div>
          </div>
        ))}

        <div className="history-table-content">
          <div className="flex items-center">
            <div
              className="flex-1"
              style={{
                padding: "10px 10px 10px 20px",
                fontSize: "12px",
              }}
            >
              {t("caymland.trackable.total_clicks")}
            </div>
            <div
              className="col-md-9"
              style={{
                width: "70px",
                padding: "10px 0",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {extras?.totalHits}
            </div>
            <div
              className="col-md-3"
              style={{
                width: "120px",
                padding: "10px 10px 10px 20px",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                title={t("caymland.email.stat.simple.tooltip")}
                className="badge badge-dark tag-pills-sm-mb"
                style={{ fontSize: "10px", fontWeight: "500", padding: "5px 7px" }}
              >
                {extras?.totalUniqueHits}
              </p>
            </div>
            <div
              className="col-md-3"
              style={{
                flex: 1,
                maxWidth: "25%",
                padding: "10px 10px 10px 20px",
                fontSize: "12px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClickCounts;
