import React from "react";
import { FaEnvelope } from "react-icons/fa";
import DateFromTo from "../../../../dashboard/defaultCompo/dateFromTo";
import LineChart from "../../../../reusableComponents/ECharts/LineChart/LineEChart";
import BarChart from "../../../../reusableComponents/ECharts/BarChart/BarChart";
import Loading from "../../../../reusableComponents/loading";
import { t } from "i18next";

function EmailStats({ extras, handleDateChange, type, loading }) {
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5px" style={{ width: "30%", color: "#8c8c8c", borderBottom: "20px" }}>
          <FaEnvelope color="#8c8c8c" size={18} />
          {t("caymland.email.stats")}
        </div>
        <div className="flex items-center gap-5px">
          <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} />
        </div>
      </div>

      {loading && (
        <div className="absolute-loading">
          <Loading />
        </div>
      )}
      <div className={`${loading ? "small-blur" : ""}`} style={{ marginTop: "20px" }}>
        {type === "list" ? (
          <BarChart
            labelData={extras?.emailStats.labels || []}
            dataSeries={
              extras?.emailStats?.datasets?.map((dataset) => ({
                name: dataset.label,
                data: dataset.data.map((value) => ({
                  value,
                  itemStyle: { color: dataset.backgroundColor },
                })),
              })) || []
            }
          />
        ) : (
          <LineChart
            labelData={extras?.emailStats.labels || []}
            dataSeries={
              extras?.emailStats?.datasets?.map((dataset, index) => ({
                name: dataset.label,
                data: extras?.emailStats.datasets?.map((value, i) => ({
                  value,
                  itemStyle: { color: dataset.backgroundColor },
                })),
              })) || []
            }
          />
        )}
      </div>
    </>
  );
}

export default EmailStats;
