import React, { useState, useEffect, Fragment, useRef } from "react";
import { FormControl } from "react-bootstrap";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Text, Select } from "../../../../reusableComponents/Inputs";
import Button from "../../../../reusableComponents/Buttons/Button";
import { FaTrashAlt } from "react-icons/fa";
import { t } from "i18next";

function GroupBy({
  modifiedData,
  setModifiedData,
  createHandleSearchChange,
  handleSelectChange,
  createHandleSelectAll,
  createHandleDeselectAll,
  sortOptionsByIndex,
  handleDelete,
  filterOptionsBySearch,
  aggregators,
  groupBy,
  id,
}) {
  const [leftGroupBySearchValue, setLeftGroupBySearchValue] = useState("");
  const [rightGroupBySearchValue, setRightGroupBySearchValue] = useState("");
  const [rightGroupBy, setRightGroupBy] = useState([]);
  const [leftGroupBy, setLeftGroupBy] = useState();
  const prevSourceRef = useRef(modifiedData.source);

  const getDataTable = () => {
    if (modifiedData?.groupBy !== undefined && Object.keys(modifiedData?.groupBy)?.length > 0) {
      const groupByValues = Object.values(modifiedData?.groupBy);
      const newRightGroupBy = groupByValues
        .map((columnValue) => groupBy.find((column) => column.value === columnValue))
        .filter(Boolean);

      const newLeftGroupBy = groupBy.filter((column) => !groupByValues.includes(column.value));

      setRightGroupBy(newRightGroupBy);
      setLeftGroupBy(newLeftGroupBy);
    }
  };
  useEffect(() => {
    const prevSource = prevSourceRef.current;
    const currentSource = modifiedData.source;

    if (prevSource !== null && prevSource !== currentSource) {
      setRightGroupBy([]);
    }
    prevSourceRef.current = currentSource;
  }, [modifiedData.source, setRightGroupBy]);
  useEffect(() => {
    setLeftGroupBy(groupBy);
    if (id) {
      getDataTable();
    }
  }, [groupBy]);

  useEffect(() => {
    if (modifiedData?.groupBy) {
      const updatedGroupBy = rightGroupBy
        ?.filter((option) => option !== undefined)
        ?.reduce((acc, option, index) => {
          acc[index] = option.value;
          return acc;
        }, {});

      setModifiedData((prevModifiedData) => ({
        ...prevModifiedData,
        groupBy: updatedGroupBy || {},
      }));
    }
  }, [rightGroupBy, setModifiedData]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedOptions = Array.from(rightGroupBy);
    const [movedOption] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedOption);

    setRightGroupBy(reorderedOptions);
  };

  const handleLeftGroupBySearchChange = createHandleSearchChange(setLeftGroupBySearchValue);
  const handleRightGroupBySearchChange = createHandleSearchChange(setRightGroupBySearchValue);

  const handleSelectAllFilters = createHandleSelectAll(leftGroupBy, rightGroupBy, setLeftGroupBy, setRightGroupBy);
  const handleDeselectAllFilters = createHandleDeselectAll(leftGroupBy, rightGroupBy, setLeftGroupBy, setRightGroupBy);

  const handleAddToAggregators = () => {
    setModifiedData((prevData) => {
      const maxKey = Math.max(0, ...Object.keys(prevData.aggregators).map(Number)) || 0;
      const newKey = maxKey + 1;

      return {
        ...prevData,
        aggregators: {
          ...prevData.aggregators,
          [newKey]: { function: null, column: null },
        },
      };
    });
  };

  const handleAggregatorChange = (key, field, value) => {
    setModifiedData((prevData) => ({
      ...prevData,
      aggregators: {
        ...prevData.aggregators,
        [key]: { ...prevData.aggregators[key], [field]: value },
      },
    }));
  };

  const functions = [
    { label: t("caymland.report.report.label.aggregators.count"), value: "COUNT" },
    { label: t("caymland.report.report.label.aggregators.avg"), value: "AVG" },
    { label: t("caymland.report.report.label.aggregators.sum"), value: "SUM" },
    { label: t("caymland.report.report.label.aggregators.min"), value: "MIN" },
    { label: t("caymland.report.report.label.aggregators.max"), value: "MAX" },
  ];

  return (
    <Fragment>
      <div className="Columns flex">
        <div className="childColumns w-50" style={{ marginRight: "30px" }}>
          <h4 className="mt-10px">
            <strong>{t("caymland.report.form.groupby")}</strong>
          </h4>
          <div className="d-flex">
            <div className="w-50">
              <div className="flex flex-col gap-10 w-100">
                <div>
                  <Text
                    label={`${t("caymland.core.select")}...`}
                    value={leftGroupBySearchValue}
                    onChange={handleLeftGroupBySearchChange}
                  />
                </div>
              </div>
              <FormControl
                as="select"
                multiple
                value={sortOptionsByIndex(filterOptionsBySearch(leftGroupBy, leftGroupBySearchValue)).map(
                  (option) => option.value
                )}
                style={{ height: "200px", fontSize: "15px" }}
              >
                {leftGroupBy?.length &&
                  sortOptionsByIndex(filterOptionsBySearch(leftGroupBy, leftGroupBySearchValue))?.map((option) => (
                    <option
                      key={option?.value}
                      onClick={() =>
                        handleSelectChange(leftGroupBy, rightGroupBy, setLeftGroupBy, setRightGroupBy)(option?.label)
                      }
                    >
                      {option?.label}
                    </option>
                  ))}
              </FormControl>
              <div className="float-right">
                <a className="cursor-pointer" onClick={handleSelectAllFilters}>
                  {t("caymland.core.select.all")}
                </a>
              </div>
            </div>
            <RiArrowLeftRightFill size={35} className="align-self-center" />
            <div className="w-50" style={{ marginLeft: "10px" }}>
              <div className="flex flex-col gap-10 w-100">
                <div>
                  <Text
                    label={`${t("caymland.core.select")}...`}
                    value={rightGroupBySearchValue}
                    onChange={handleRightGroupBySearchChange}
                  />
                </div>
              </div>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul
                      {...provided.droppableProps}
                      multiple
                      ref={provided.innerRef}
                      value={
                        rightGroupBy
                          ? filterOptionsBySearch(rightGroupBy, rightGroupBySearchValue)?.map((option) => option?.value)
                          : null
                      }
                      className="form-control"
                      style={{
                        height: "200px",
                        overflowY: "scroll",
                        textWrap: "nowrap",
                        fontSize: "15px",
                      }}
                    >
                      {filterOptionsBySearch(rightGroupBy, rightGroupBySearchValue)?.map((option, index) => (
                        <Draggable key={option?.value} draggableId={option?.value} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() =>
                                handleSelectChange(
                                  leftGroupBy,
                                  rightGroupBy,
                                  setLeftGroupBy,
                                  setRightGroupBy
                                )(option?.label)
                              }
                            >
                              {option?.label}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              <Link className="cursor-pointer" onClick={handleDeselectAllFilters}>
                {t("caymland.core.deselect.all")}
              </Link>
            </div>
          </div>
        </div>

        <div className="childColumns w-50" style={{ marginLeft: "30px" }}>
          <h4 className="mt-10px">
            <strong>{t("caymland.core.calculated.fields")}</strong>
          </h4>
          <div>
            {Object.entries(modifiedData?.aggregators ?? {}).map(([key, event], i) => (
              // Object.values(modifiedData?.aggregators ?? {})?.map((event, i) => (
              <div key={i} className="flex gap-10 w-100 align-items-center mb-20px">
                <div className="w-50">
                  <Select
                    name="function"
                    label={t("caymland.report.function")}
                    isClearable={false}
                    options={functions ?? []}
                    value={functions?.find((option) => option.value === event.function) ?? null}
                    onChange={(e) => handleAggregatorChange(key, "function", e.value)}
                    margin={true}
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <Select
                    name="column"
                    label={t("caymland.report.report.label.filtercolumn")}
                    isClearable={false}
                    options={aggregators?.column ?? []}
                    value={aggregators?.column?.find((option) => option.value === event.column) ?? null}
                    onChange={(e) => handleAggregatorChange(key, "column", e.value)}
                    margin={true}
                  />
                </div>
                <div className="flex" style={{ height: "40px", alignItems: "flex-end" }}>
                  <FaTrashAlt
                    className="cursor-pointer"
                    onClick={() => handleDelete("aggregators", key)}
                    color="#f86b4f"
                    size={18}
                  />
                </div>
              </div>
            ))}
            <Button
              buttons={[
                {
                  name: "addFunction",
                  title: t("caymland.report.report.label.function"),
                  onClick: () => handleAddToAggregators(),
                  textFormat: "capitalize",
                },
              ]}
              isDisabled={rightGroupBy?.length < 1}
              variant="contained"
              textFormat="capitalize"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GroupBy;
