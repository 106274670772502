import React from "react";
import Editor from "@monaco-editor/react";

const CustomJson = ({ modifiedData, setModifiedData }) => {
  return (
    <Editor
      height="100%"
      options={{
        unusualLineTerminators: "auto",
      }}
      defaultLanguage="json"
      value={modifiedData?.customJson || ""}
      theme="vs-dark"
      className="monacoEditor"
      onChange={(e) => setModifiedData({ ...modifiedData, customJson: e })}
    />
  );
};

export default CustomJson;
