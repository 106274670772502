import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { languages } from "../../../../reusableComponents/data";
import { useLocation } from "react-router-dom";
import Button from "../../../../reusableComponents/Buttons/Button";
import { Label, Text, Select as ReusSelect, Checkbox, DatePicker } from "../../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../../redux/api/categoriesApi";
import { formsApi } from "../../../../../redux/api/formsApi";
import { emailsApi, useGetEmailsQuery } from "../../../../../redux/api/emailsApi";
import { segmentsApi } from "../../../../../redux/api/segmentsApi";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";

function Sidebar({
  modifiedData,
  setModifiedData,
  preferenceCenterPages,
  errors,
  setErrors,
  setOriginalData,
  originalData,
  pagesOptions,
}) {
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPopUpCategory, setShowPopUpCategory] = useState(false);
  const [translations, setTranslations] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState();
  const [selectedPage, setSelectedPage] = useState();
  const [selectLists, setSelectLists] = useState([]);
  const location = useLocation();

  const newEmail = location.pathname.includes("new");

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "email",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const [q, setQ] = useState({
    pageIndex: 0,
    pageSize: 1000,
    orderByDir: "ASC",
    orderBy: "id",
    where: [
      // {
      //   expr: "isNull",
      //   col: "folderId",
      // },
    ],
  });

  const [triggerFormsQuery] = formsApi.useLazyGetFormsQuery();
  const [triggerSegmentsQuery] = segmentsApi.useLazyGetSegmentsQuery();
  const { data } = useGetEmailsQuery(q);

  useEffect(() => {
    if (data?.data) {
      retrieveEmails();
    }
  }, [data]);
  //function for getting conact segments from api
  function isArrayContainingObject(array) {
    return array.some((element) => element !== null && typeof element === "object" && !Array.isArray(element));
  }
  useEffect(() => {
    if (Array.isArray(modifiedData.lists) && isArrayContainingObject(modifiedData.lists)) {
      const formattedLists = modifiedData.lists.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setSelectLists(formattedLists);

      // Update modifiedData.lists with just the IDs
      const listIds = formattedLists.map((item) => item.value);
      if (JSON.stringify(listIds) !== JSON.stringify(modifiedData.lists)) {
        setModifiedData((prev) => ({
          ...prev,
          lists: listIds,
        }));
        setOriginalData((prev) => ({
          ...prev,
          lists: listIds,
        }));
      }
    }
  }, [modifiedData?.lists, originalData?.lists]);
  const handleSelectChangee = (selectedOptions) => {
    setSelectLists(selectedOptions);
    const updatedLists = selectedOptions.map((option) => option.value);
    setModifiedData((prev) => ({
      ...prev,
      lists: updatedLists,
    }));
  };

  useEffect(() => {}, []);

  const retrieveForms = (inputValue) =>
    new Promise(async (resolve) => {
      if (!triggerFormsQuery) resolve([]);
      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }
      const response = await triggerFormsQuery(query).unwrap();
      const data = response?.data;
      if (data) {
        let arr = Object?.entries(data)?.map((x) => x[1]);
        resolve(arr);
      }
    });

  //Segments
  const retrieveSegments = (inputValue) =>
    new Promise(async (resolve) => {
      if (!triggerSegmentsQuery) resolve([]);

      const query = {
        where: [
          {
            expr: "eq",
            col: "isPublished",
            val: 1,
          },
        ],
      };

      if (inputValue.length) {
        query.search = inputValue;
      }
      // const query = "where[0][expr]=eq&where[0][col]=isPublished&where[0][val]=1";
      const response = await triggerSegmentsQuery(query).unwrap();

      if (!response?.errors) {
        const segmentsData = response?.data;
        const segmentsArray = segmentsData?.map((field) => ({
          label: field?.name,
          value: field?.id,
        }));

        setSegments(segmentsArray);

        // const formatted = Object.entries(data).map((x) => x[1]);

        resolve(segmentsArray);
      }
    });

  //Is a translation of - making select options
  const retrieveEmails = async () => {
    setLoading(true);
    let response = data?.data;
    let groupList = [];
    // const staticOption = {
    //   options: [{ label: "Choose translated item", value: "staticOption" }],
    // };
    response
      .filter(
        (item) => !item.translationParent && item.emailType === modifiedData.emailType && item.id !== modifiedData.id
      )
      .forEach((item) => {
        const groupKey = item.language;
        if (!groupList[groupKey]) {
          groupList[groupKey] = [];
        }
        groupList[groupKey].push({
          label: item.name,
          value: item.id,
        });
      });

    const groupedOptions = Object.keys(groupList).map((key) => {
      return {
        label: key,
        options: groupList[key],
      };
    });
    // groupedOptions.unshift(staticOption);
    setTranslations(groupedOptions);

    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name" || name === "subject") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }

    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSelectChangeCategory = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  const handleSelectChange = (e, name) => {
    if (e === null) {
      setModifiedData({ ...modifiedData, [name]: "" });
    } else {
      setModifiedData({ ...modifiedData, [name]: e });
    }
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData({
      ...modifiedData,
      [name]: checked,
    });
  };

  const handleUtmChange = (e) => {
    const { name, value } = e.target;

    setModifiedData((prev) => ({
      ...prev,
      utmTags: {
        ...prev.utmTags,
        [name]: value,
      },
    }));
  };

  //Is a translation of
  const handleTranslationOfChange = (selectedOptions) => {
    if (modifiedData.emailType === "list") {
      if (selectedOptions && selectedOptions.value === "staticOption") {
        // Static option is selected
        setSelectedTranslation(selectedOptions.value);
        setModifiedData({
          ...modifiedData,
          segmentTranslationParent: null,
        });
      } else {
        const selectedValues = selectedOptions?.value;
        setSelectedTranslation(selectedValues);
        setModifiedData({
          ...modifiedData,
          segmentTranslationParent: selectedValues,
        });
      }
    } else {
      if (selectedOptions && selectedOptions.value === "staticOption") {
        setSelectedTranslation(selectedOptions.value);
        setModifiedData({
          ...modifiedData,
          segmentTranslationParent: null,
        });
      } else {
        if (newEmail) {
          const selectedValues = selectedOptions?.value;
          setSelectedTranslation(selectedValues);
          setModifiedData({
            ...modifiedData,
            templateTranslationParent: selectedValues,
          });
        } else {
          const selectedValues = selectedOptions?.value;
          setSelectedTranslation(selectedValues);
          setModifiedData({
            ...modifiedData,
            segmentTranslationParent: selectedValues,
          });
        }
      }
    }
  };

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Label
        name="chooseEmailType"
        required={true}
        label={t("caymland.email.send.emailtype")}
        tooltip={t("caymland.email.type.template.description")}
      />

      <div className="flex items-center justify-center w-full" style={{ marginBottom: "25px" }}>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "campaignEmail",
                title: `${t("caymland.campaign.campaign")} ${t("caymland.email.email")}`,
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    emailType: "template",
                    lists: null,
                    segmentTranslationParent: null,
                    templateTranslationParent: null,
                  });
                },
              },
            ]}
            variant={modifiedData?.emailType === "template" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "segmentEmail",
                title: `${t("caymland.email.report.segment")} ${t("caymland.email.email")}`,
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    emailType: "list",
                    segmentTranslationParent: null,
                    templateTranslationParent: null,
                  });
                },
              },
            ]}
            variant={modifiedData?.emailType === "list" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
      </div>
      <div className="mb-20px">
        <Text
          name="subject"
          errors={errors?.subject}
          required={true}
          value={modifiedData?.subject || ""}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.mailbox.compose.label.subject")}
        />
      </div>
      <div className="mb-20px">
        <Text
          name="name"
          errors={errors?.name}
          required={true}
          value={modifiedData?.name || ""}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.email.form.internal.name")}
        />
      </div>
      {modifiedData?.emailType === "list" &&
        !modifiedData?.templateTranslationParent &&
        !modifiedData?.segmentTranslationParent &&
        modifiedData.translationParent !== "null" && (
          <div style={{ marginBottom: "15px" }}>
            <ReusSelect
              name="lists"
              label={t("caymland.email.form.list")}
              errors={errors?.lists}
              isMulti={true}
              isClearable
              required={true}
              options={retrieveSegments}
              value={selectLists}
              onChange={handleSelectChangee}
            />
          </div>
        )}

      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="category"
          bundle="email"
          label={t("caymland.page.tracking.form.category")}
          options={retrieveCategories}
          trigger={triggerCategories}
          value={modifiedData?.category ?? null}
          onChange={(e) => handleSelectChangeCategory(e)}
          isClearable
          isCreatable
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="language"
          label={t("caymland.core.language")}
          options={languages.map((lang) => ({ label: t(lang.labelKey), value: lang.value }))}
          value={modifiedData?.language}
          onChange={(e) => handleSelectChange(e.value, "language")}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <ReusSelect
          id="language"
          tooltip={t("caymland.core.form.translation_parent.help")}
          label={t("caymland.core.form.translation_parent")}
          name="language"
          options={translations}
          value={translations
            .flatMap((group) => group.options)
            .find(
              (option) =>
                option.value ===
                (selectedTranslation !== undefined ? selectedTranslation : modifiedData?.translationParent?.id)
            )}
          placeholder={`${t("caymland.core.select")}...`}
          onChange={(selectedOptions) => handleTranslationOfChange(selectedOptions)}
        />
      </div>
      <div>
        <Checkbox
          name="isPublished"
          label={t("caymland.core.form.published")}
          value={modifiedData?.isPublished || false}
          onChange={(e) => handleToggleChange(e)}
        />
      </div>
      <div>
        <Checkbox
          name="Double-Opt-In"
          label={t("caymland.email.double_opt_in")}
          value={modifiedData?.doubleOptIn || false}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              doubleOptIn: e.target.checked,
            })
          }
          tooltip={t("caymland.email.double_opt_in.tooltip")}
        />
      </div>
      <div>
        <Checkbox
          name="`ignoreD`NC"
          label={t("caymland.email.ignore_dnc")}
          value={modifiedData?.ignoreDNC || false}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              ignoreDNC: e.target.checked,
            })
          }
          tooltip={t("caymland.email.ignore_dnc.tooltip")}
        />
      </div>
      <div>
        <Checkbox
          name="scheduleEmail"
          label={t("caymland.email.publish_command")}
          value={modifiedData?.scheduleEmail || false}
          onChange={(e) =>
            setModifiedData({
              ...modifiedData,
              scheduleEmail: e.target.checked,
            })
          }
          tooltip={t("caymland.email.publish_command.help")}
        />
        {modifiedData?.scheduleEmail && (
          <>
            <div className="warning-text p-no-margin" style={{ display: "block", position: "relative" }}>
              <p>{t("caymland.email.publish_command.help")}</p>
            </div>
            <div className=" mt-10">
              <DatePicker
                label={t("caymland.core.form.publishup")}
                name={"Publish at (date/time)"}
                value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
                onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
                maxDate={modifiedData?.publishDown}
              />
            </div>
            <div className="">
              <DatePicker
                label={t("caymland.core.form.publishdown")}
                name={"Unpublish at (date/time)"}
                value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
                onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
                minDate={modifiedData?.publishUp}
              />
            </div>
          </>
        )}
      </div>
      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="language"
          label={t("caymland.email.form.unsubscribeform")}
          options={retrieveForms}
          value={modifiedData?.unsubscribeForm}
          valueField="id"
          labelField="name"
          onChange={(e) => {
            if (e === null) {
              setModifiedData({ ...modifiedData, unsubscribeForm: null });
            } else {
              setModifiedData({ ...modifiedData, unsubscribeForm: e });
            }
          }}
          tooltip={t("caymland.email.form.unsubscribeform.tooltip")}
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="language"
          label={t("caymland.email.form.preference_center")}
          options={pagesOptions}
          value={
            modifiedData?.preferenceCenter?.id ? modifiedData?.preferenceCenter?.id : modifiedData.preferenceCenter
          }
          onChange={(e) => {
            setModifiedData((prev) => ({
              ...prev,
              preferenceCenter: e.value,
            }));
          }}
          tooltip={t("caymland.email.form.preference_center.tooltip")}
        />
      </div>
      <div
        style={{
          borderTop: "1px solid #d7d7d7",
          padding: "20px 0",
          marginTop: "10px",
        }}
      >
        <span style={{ fontSize: "20px" }}>{t("caymland.email.utm_tags")}</span>
        <div className="mb-30" style={{ marginTop: "30px" }}>
          <Text
            name="utmSource"
            value={modifiedData?.utmTags?.utmSource || ""}
            onChange={(e) => handleUtmChange(e)}
            label={t("caymland.email.campaign_source")}
          />
        </div>
        <div className="mb-30" style={{ marginTop: "30px" }}>
          <Text
            name="utmMedium"
            value={modifiedData?.utmTags?.utmMedium || ""}
            onChange={(e) => handleUtmChange(e)}
            label={t("caymland.email.campaign_medium")}
          />
        </div>
        <div className="input-container ic1 mb-30" style={{ marginTop: "30px" }}>
          <Text
            name="utmCampaign"
            value={modifiedData?.utmTags?.utmCampaign || ""}
            onChange={(e) => handleUtmChange(e)}
            label={t("caymland.email.campaign_name")}
          />
        </div>
        <div className="input-container ic1 mb-30" style={{ marginTop: "30px" }}>
          <Text
            name="utmContent"
            value={modifiedData?.utmTags?.utmContent || ""}
            onChange={(e) => handleUtmChange(e)}
            label={t("caymland.email.campaign_content")}
          />
        </div>
      </div>
    </>
  );
}

export default Sidebar;
