import {
  PieChart,
  Box,
  User,
  Filter,
  Activity,
  MessageSquare,
  Target,
  GitPullRequest,
  Tag,
  UserCheck,
  Database,
} from "react-feather";
import { HiOutlineCube } from "react-icons/hi";
import { RxComponent1 } from "react-icons/rx";
import { BiSolidComponent } from "react-icons/bi";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import StreamOutlinedIcon from "@mui/icons-material/StreamOutlined";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import { FaMobileAlt, FaRegNewspaper } from "react-icons/fa";
import { AiOutlineConsoleSql, AiOutlineFolderOpen } from "react-icons/ai";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { BiCog, BiEnvelope } from "react-icons/bi";
import { FiLifeBuoy, FiUsers, FiLock, FiList, FiFolder } from "react-icons/fi";
import { TbArrowsRightLeft, TbPlug } from "react-icons/tb";
import { BsTag } from "react-icons/bs";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";

import { FaRegBuilding, FaRegIdBadge, FaEnvelope } from "react-icons/fa";
import { GrLineChart } from "react-icons/gr";
import { t } from "i18next";

export const MENUITEMS = [
  {
    title: t("caymland.dashboard.menu.index"),
    icon: DashboardOutlinedIcon,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/dashboard",
    permissionKey: "api:access",
  },
  {
    title: "caymland.contact.segments.fields.contacts",
    icon: PeopleAltOutlinedIcon,
    type: "link",
    path: "/contacts",
    permissionKey: "lead:leads",
    active: false,
  },
  {
    title: "caymland.companies.menu.index",
    icon: BusinessOutlinedIcon,
    type: "link",
    path: "/companies",
    permissionKey: "lead:leads",
    active: false,
  },
  {
    title: "caymland.lead.preferred.segments",
    icon: FilterAltOutlinedIcon,
    type: "link",
    permissionKey: "lead:leads",
    path: "/segments",
    active: false,
  },
  {
    title: "caymland.plugin.badge.generator.index",
    icon: LocalPostOfficeOutlinedIcon,
    type: "sub",
    permissionKey: ["mailbox:template", "lead:exports"],
    active: false,
    children: [
      {
        path: "/serial-letters",
        title: "caymland.plugin.badge.generator",
        type: "link",
        permissionKey: "mailbox:template",
      },
      {
        path: "/multiple-exports",
        title: "caymland.plugin.badge.generator.segments",
        type: "link",
        permissionKey: "lead:exports",
      },
    ],
  },
  {
    title: "caymland.core.components",
    icon: ViewInArOutlinedIcon,
    type: "sub",
    permissionKey: ["asset:assets", "form:forms", "page:pages", "dynamiccontent:dynamiccontents"],
    active: false,
    children: [
      {
        path: "/assets",
        title: "caymland.asset.assets",
        type: "link",
        permissionKey: "asset:assets",
      },
      {
        path: "/forms",
        title: "caymland.form.forms",
        type: "link",
        permissionKey: "form:forms",
      },
      {
        path: "/pages",
        title: "caymland.page.pages",
        type: "link",
        permissionKey: "page:pages",
      },
      {
        path: "/dwc",
        title: "caymland.core.dynamicContent",
        type: "link",
        permissionKey: "dynamiccontent:dynamiccontents",
      },
    ],
  },
  {
    title: "caymland.campaign.campaigns",
    icon: DisplaySettingsOutlinedIcon,
    type: "link",
    path: "/campaigns",
    active: false,
    permissionKey: "campaign:campaigns",
  },
  {
    title: "caymland.core.channels",
    icon: ChatBubbleOutlineOutlinedIcon,
    type: "sub",
    permissionKey: ["email:emails", "sms:smses", "focus:items"],
    active: false,
    children: [
      {
        path: "/emails",
        title: "E-Mails",
        type: "link",
        permissionKey: "email:emails",
      },
      {
        path: "/sms",
        title: "SMS",
        type: "link",
        permissionKey: "sms:smses",
      },
      {
        path: "/focus",
        title: "caymland.focus",
        type: "link",
        permissionKey: "focus:items",
      },
    ],
  },
  {
    title: "caymland.lead.field.points",
    icon: GpsFixedOutlinedIcon,
    type: "sub",
    permissionKey: ["point:points", "point:triggers"],
    active: false,
    children: [
      {
        path: "/points",
        title: "caymland.point.menu.index",
        type: "link",
        permissionKey: "point:points",
      },
      {
        path: "/triggers",
        title: "caymland.point.trigger.menu.index",
        type: "link",
        permissionKey: "point:triggers",
      },
    ],
  },
  {
    title: "caymland.stages.menu.root",
    icon: RouteOutlinedIcon,
    type: "link",
    path: "/stages",
    active: false,
    permissionKey: "stage:stages",
  },
  {
    title: "caymland.report.reports",
    icon: AssessmentOutlinedIcon,
    type: "link",
    path: "/reports",
    active: false,
    permissionKey: "report:reports",
  },
];

export const SETTINGSMENU = [
  {
    title: "caymland.category.header.index",
    icon: <AiOutlineFolderOpen size={20} />,
    path: "/categories",
    permissionKey: "category:categories",
  },
  {
    title: "caymland.user.users",
    icon: <FiUsers size={20} />,
    path: "/users",
    permissionKey: "user:users",
  },
  {
    title: "caymland.webhook.webhooks",
    icon: <TbArrowsRightLeft size={20} />,
    path: "/webhooks",
    permissionKey: "webhook:webhooks",
  },
  {
    title: "caymland.lead.field.header.index",
    icon: <FiList size={20} />,
    path: "/contacts/fields",
    permissionKey: "lead:fields",
  },
  {
    title: "caymland.tags_editor",
    icon: <BsTag size={20} />,
    path: "/tags",
    permissionKey: "tageditor:items",
  },
];
