import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import CustomTable from "../../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function Contacts({ extras, setExtraInfo, setDateFrom, setDateTo, dateFrom, dateTo }) {
  const [order, setOrder] = useState("id");
  const [orderSort, setOrderSort] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [contactsLength, setContactsLength] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { id } = useParams();

  useEffect(() => {
    if (extras?.contacts) {
      setTableData(Object.values(extras?.contacts?.results));
    }
  }, [extras]);

  useEffect(() => {
    setDateFrom(localStorage.getItem("fromDate"));
    setDateTo(localStorage.getItem("toDate"));
  }, [localStorage]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) => row.firstname || row.ipAddresses[0].ip,
        header: t("caymland.user.account.permissions.editname"),
        enableColumnActions: false,
      },
      {
        accessorKey: "email",
        accessorFn: (row) => row.email,
        header: t("caymland.core.type.email"),
        enableColumnActions: false,
      },
      {
        accessorKey: "Location",
        accessorFn: (row) => row.location,
        header: t("caymland.lead.lead.thead.location"),
        enableColumnActions: false,
      },
    ],
    []
  );

  return (
    <div
      style={{
        borderBottom: "1px solid #ebebeb",
        minHeight: "100px",
      }}
    >
      <CustomTable
        columns={columns}
        query={query}
        data={tableData}
        setQuery={setQuery}
        viewTable={true}
        showDetailPanel
      />
    </div>
  );
}

export default Contacts;
