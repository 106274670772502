import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Details from "./addEditAction/Details";
import Sidebar from "./addEditAction/Sidebar";
import { Error, Success } from "../../../reusableComponents/Alerts";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import { useAddPointMutation, useGetPointQuery, useUpdatePointMutation } from "../../../../redux/api/pointsApi";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import Swal from "sweetalert2";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

function AddAction() {
  const navigate = useNavigate();
  const [submitBackLoading, setSubmitBackLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  let today = new Date();
  let time = today.toLocaleTimeString();

  const [originalData, setOriginalData] = useState({});
  const [modifiedData, setModifiedData] = useState({
    name: "",
    description: "",
    isPublished: true,
    timeExecution: time,
    category: null,
    repeatable: false,
    publishUp: null,
    publishDown: null,
    delta: "",
    events: [
      {
        eventType: "action",
        type: "task.add",
        name: "Add a Custom Task",
      },
    ],
    properties: {},
    type: null,
  });

  const [createPoint, { isLoading: isAddLoading }] = useAddPointMutation();
  const [updatePoint, { isLoading: isEditLoading }] = useUpdatePointMutation();
  const { data, isLoading, isFetching } = useGetPointQuery(id, { skip: !id });
  const isSaving = isEditLoading || isAddLoading;

  const navBack = () => {
    navigate("/points");
  };

  useEffect(() => {
    if (data && id) {
      let actionsData = data?.point;
      if (isClone) {
        actionsData = { ...actionsData, isPublished: false };
      }
      setModifiedData({
        ...actionsData,
        category: actionsData?.category
          ? {
              label: actionsData?.category?.title,
              value: actionsData?.category?.id,
            }
          : null,
      });
      setOriginalData(actionsData);
      if (data?.point?.type === "url.hit") {
        convertPropertiesFromSeconds(data?.point);
      }
    }
  }, [id, data]);
  const checkIfFormIsModified = () => {
    return JSON.stringify(modifiedData) !== JSON.stringify(originalData);
  };

  function convertPropertiesFromSeconds(dataReponse) {
    function convertFromSeconds(seconds, unit) {
      if (seconds && unit) {
        switch (unit) {
          case "d":
            return seconds / (24 * 60 * 60);
          case "H":
            return seconds / (60 * 60);
          case "i":
            return seconds / 60;
          default:
            return;
        }
      }
      return;
    }

    let accumulativeTime;
    let accumulativeTimeUnit;
    let accumulativeTimeConverted;

    let returnsAfter;
    let returnsAfterUnit;
    let returnsAfterConverted;

    let returnsWithin;
    let returnsWithinUnit;
    let returnsWithinConvertd;

    accumulativeTime = dataReponse?.properties?.accumulative_time;
    accumulativeTimeUnit = dataReponse?.properties?.accumulative_time_unit;
    accumulativeTimeConverted = convertFromSeconds(accumulativeTime, accumulativeTimeUnit);

    returnsAfter = dataReponse?.properties?.returns_after;
    returnsAfterUnit = dataReponse?.properties?.returns_after_unit;
    returnsAfterConverted = convertFromSeconds(returnsAfter, returnsAfterUnit);

    returnsWithin = dataReponse?.properties?.returns_within;
    returnsWithinUnit = dataReponse?.properties?.returns_within_unit;
    returnsWithinConvertd = convertFromSeconds(returnsWithin, returnsWithinUnit);

    setModifiedData((prev) => ({
      ...(prev || []),
      properties: {
        page_hits: dataReponse?.properties?.page_hits,
        page_url: dataReponse?.properties?.page_url,
        accumulative_time_unit: accumulativeTimeUnit,
        accumulative_time: accumulativeTimeConverted,
        returns_after_unit: accumulativeTimeUnit,
        returns_after: returnsAfterConverted,
        returns_within_unit: returnsWithinUnit,
        returns_within: returnsWithinConvertd,
      },
    }));
  }

  const handleAddAction = async (params) => {
    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    if (modifiedData?.delta.length < 1) {
      errors.delta = true;
      setErrors({ ...errors });
    } else {
      delete errors.delta;
      setErrors({ ...errors });
    }

    if (!modifiedData?.type) {
      errors.type = true;
      setErrors({ ...errors });
    } else {
      delete errors.type;
      setErrors({ ...errors });
    }

    const hasErrors = Object.values(errors).some((value) => value === true);
    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }
    if (Object.keys(errors).length === 0) {
      try {
        setSubmitBackLoading(true);

        if (modifiedData?.intervalExecution) {
          modifiedData.intervalExecution = modifiedData.intervalExecution.value;
        }
        if (modifiedData?.category) modifiedData.category = modifiedData.category.value;

        let response;
        if (id && !isClone) {
          response = await updatePoint({ ...modifiedData, id });
        } else {
          response = await createPoint(modifiedData);
        }
        if (!response?.error) {
          Success(
            t("caymland.core.update.step.success"),
            t("caymland.message.type.successfully", {
              type: id ? "edited" : t("caymland.dashboard.create.past.tense"),
            })
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navBack();
            } else {
              navigate(`/points/edit/${response.data.point.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
        setSubmitBackLoading(false);
      } catch (e) {
        setSubmitBackLoading(false);
        Error(t("caymland.message.error"));
      }
    }
  };
  const handleCancel = () => {
    if (checkIfFormIsModified()) {
      Swal.fire({
        title: t("caymland.badge_contact.form.confirmdelete"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/points");
        } else {
          return;
        }
      });
    } else {
      navigate("/points");
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddAction("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddAction(""),
    },
  ];

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          showAlert={showAlert}
          alertMessage={alertMessage}
          data={modifiedData}
          // showIsPublished={true}
          loading={isSaving}
          title={
            <TitleHeader
              name={t("caymland.point.actions.header.index")}
              clone={isClone}
              title={modifiedData?.name}
              loading={isLoading}
              id={id}
            />
          }
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton />
          ) : (
            <Details
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
      />
    </>
  );
}

export default AddAction;
