import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import Loading from "../../../../reusableComponents/loading";
import DropZoneMask from "../../../../reusableComponents/DropZone/DropZoneMask";
import CustomModal from "../../../../reusableComponents/CustomModal/CustomModal";
import ContentGeneratorActions from "../helpers/contentGeneratorActions";
import { MdOutlineDragIndicator } from "react-icons/md";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import SidebarSkeleton from "../../../../reusableComponents/SidebarSkeleton";
import { formsApi } from "../../../../../redux/api";
import { SlideAlert } from "../../../../reusableComponents/Alerts";
import { Text } from "../../../../reusableComponents/Inputs";
import { t } from "i18next";
import { IconButton, Tooltip, Zoom } from "@mui/material";

const Actions = ({ actions, loading, setModifiedData, modifiedData }) => {
  const [showZoneMask, setShowZoneMask] = useState(false);
  const [actionData, setActionData] = useState(null);
  const [actionFields, setActionFields] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [searchActions, setSearchActions] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "",
    loading: false,
  });

  const [triggerActions] = formsApi.useLazyGetFormsActionsQuery();

  const filteredActions = actions.filter((action) => {
    if (!searchActions) return true;
    return action.options.label.toLowerCase().includes(searchActions.toLowerCase());
  });

  const closeModal = () => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
      editData: null,
    }));
    setActionData(actionData);
  };

  const handleActionInfo = (item) => {
    return {
      value: item.value,
      label: `${item.options.label}`,
    };
  };

  const onDragStart = (event, nodeType) => {
    setShowZoneMask(true);
    event.dataTransfer.setData("application/reactflow", JSON.stringify(nodeType));
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragStop = () => {
    setShowZoneMask(false);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
  const onDrop = useCallback(async (event) => {
    event.preventDefault();

    const dragData = event.dataTransfer.getData("application/reactflow");

    if (dragData && dragData !== "undefined") {
      const action = JSON.parse(dragData);

      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
        title: action.label,
        type: action.value,
        loading: true,
      }));

      const response = await triggerActions({ type: action.value });
      let data = response.data;
      let filterData = Object.entries(data).map((x) => ({
        label: x[0],
        value: x[1],
      }));

      let finalData = filterData.filter((x) => x.label !== "type" && x.label !== "formId" && x.label !== "buttons");
      let properties = finalData.filter((x) => x.label === "properties");

      setActionFields(finalData);
      setActionData(
        properties.length > 0
          ? {
              properties: {},
            }
          : null
      );
      setModalOptions((prev) => ({
        ...prev,
        loading: false,
      }));

      setShowZoneMask(false);
    } else {
      console.error("Dragged data is undefined or invalid.");
    }
  }, []);

  const getDynamicLabels = (actionFields) => {
    let labels = [];

    actionFields.forEach((field) => {
      if (field.label === "properties" && field.value) {
        Object.keys(field.value).forEach((key) => {
          if (field.value[key].label) {
            labels.push(field.value[key].label);
          }
        });
      }
    });

    return labels;
  };

  const handleSubmit = () => {
    if (actionData) {
      const newAction = {
        tempActionId: actionData.id || Math.floor(Math.random() * 100000),
        type: modalOptions.type,
        ...actionData,
      };

      if (!newAction.name || newAction.name.trim() === "") {
        newAction.name = modalOptions.title;
      }

      const missingRequiredFields = actionFields
        .filter((field) => field.value?.required)
        .flatMap((field) => {
          if (field.label === "properties") {
            return Object.entries(field.value)
              .filter(([key, subField]) => subField.required && (!newAction.properties || !newAction.properties[key]))
              .map(([key, subField]) => ({ label: subField.label || key, value: subField }));
          }
          return !newAction[field.label] ? [field] : [];
        });

      if (missingRequiredFields.length > 0) {
        const missingFieldNames = missingRequiredFields.map((field) => field.label || field.value.label).join(", ");
        setShow(true);
        setMessage(`${t("caymland.form.tab.actions.missingfields")} ${missingFieldNames}`);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");

        }, 7000);
        return;
        return;
      }

      //Edit action
      if (actionData.id) {
        const updatedActions = modifiedData.actions.map((action) => (action.id === actionData.id ? newAction : action));
        setModifiedData((prev) => ({
          ...prev,
          actions: updatedActions,
        }));
      } else {
        //Add new action
        setModifiedData((prev) => ({
          ...prev,
          actions: [...prev.actions, newAction],
        }));
      }
      if (actionData.tempActionId) {
        const updatedActions = modifiedData.actions.map((action) =>
          action.tempActionId === actionData.tempActionId ? newAction : action
        );
        setModifiedData((prev) => ({
          ...prev,
          actions: updatedActions,
        }));
      }
    }
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setActionFields([]);
    setActionData(null);
  };

  const handleDelete = (item) => {
    setModifiedData((prev) => ({
      ...prev,
      actions: prev.actions.filter((x) => x.id !== item.id || x.tempActionId !== item.tempActionId),
    }));
  };

  const handleEdit = async (item) => {
    // Open the modal with edit mode
    setModalOptions((prev) => ({
      ...prev,
      isOpen: true,
      title: `Edit ${item.name}`,
      type: item.type,
      editData: actionData,
      loading: true,
    }));
    const response = await triggerActions({ type: item.type });
    let data = response.data;
    let filterData = Object.entries(data).map((x) => ({
      label: x[0],
      value: x[1],
    }));

    let finalData = filterData.filter((x) => x.label !== "type" && x.label !== "formId" && x.label !== "buttons");
    let properties = finalData.filter((x) => x.label === "properties");

    setActionFields(finalData);
    setActionData(item);
    setModalOptions((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} setShowAlert={setShow} />
      <CustomModal
        modalOptions={modalOptions}
        handleSubmit={handleSubmit}
        close={closeModal}
        saveText="Add"
        content={
          <ContentGeneratorActions
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            data={actionFields}
            setEntity={setActionData}
            entity={actionData}
            modalOptions={modalOptions}
            show={show}
          />
        }
      />

      {loading ? (
        <div className="flex items-center justify-center">
          <SidebarSkeleton applyPadding />
        </div>
      ) : (
        <div className="flex items-start justfiy-between gap-20" style={{ padding: "16px" }}>
          <div className="" style={{ width: "30%" }}>
            <Text
              name="search"
              value={searchActions}
              onChange={(e) => setSearchActions(e.target.value)}
              label={t("caymland.core.multiselect.search")}
            />
            <div
              className="accordion-list"
              style={{
                // maxHeight: "80vh",
                position: "relative",
                overflowY: "scroll",
              }}
            >
              {filteredActions
                .filter((item) => (modifiedData.formType === "campaign" ? item.options?.allowCampaignForm : true))
                .map((item, index) => (
                  <div
                    onDragStart={(event) => onDragStart(event, handleActionInfo(item))}
                    onDragEnd={(e) => onDragStop(e)}
                    key={index}
                    className="accordion-list-item"
                    draggable
                  >
                    <div className="flex flex-wrap items-center justify-between mid-gap-15 cursor-grab">
                      {item.options?.label}
                      <i>
                        <IconButton>
                          <DragHandleIcon style={{ color: "#757575", cursor: "grab" }} />
                        </IconButton>
                      </i>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div
            style={{ alignSelf: "stretch" }}
            className=" flex-1  relative"
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => onDragOver(e)}
          >
            {showZoneMask && <DropZoneMask title="action" />}
            {modifiedData.actions?.map((action, i) => (
              <div
                key={i}
                className="actions-cards"
                onMouseEnter={() => setHoveredItem(i)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className={hoveredItem === i ? ` action-cards-card` : ""} onDoubleClick={() => handleEdit(action)}>
                  <div className="grid">
                    <strong>{action.name}</strong>
                    <div dangerouslySetInnerHTML={{ __html: action.description }} />
                  </div>
                  {hoveredItem === i && (
                    <div className="d-flex items-center gap-5px ">
                      <div className="action-cards-button">
                        <FaEdit
                          onClick={(e) => {
                            e.stopPropagation(), handleEdit(action);
                          }}
                          color="#135f95"
                        />
                      </div>
                      <div className="action-cards-button">
                        <FaTrashAlt
                          onClick={(e) => {
                            e.stopPropagation(), handleDelete(action);
                          }}
                          color="#f86b4f"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Actions;
