import React from "react";
import { Skeleton, Box } from "@mui/material";

const DetailsSkeleton = ({ applyPadding = false, company = false }) => {
  return (
    <>
      {company ? (
        <Box sx={{ p: applyPadding ? 2 : 0, mt: 0 }}>
          <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
            <Skeleton variant="text" width="50%" height={60} />
            <Skeleton variant="text" width="50%" height={60} />
          </Box>
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Box>
      ) : (
        <>
          <Box sx={{ p: applyPadding ? 2 : 0, mt: 0 }}>
            <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
              <Skeleton variant="text" width="50%" height={60} />
              <Skeleton variant="text" width="50%" height={60} />
            </Box>
            <Skeleton variant="rectangular" width="100%" height={120} />
            <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
              <Skeleton variant="text" width="100%" height={60} />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default DetailsSkeleton;