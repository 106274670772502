import React, { Fragment, useMemo } from "react";
import moment from "moment";
import NoResultsFound from "../../../reusableComponents/noResultsFound/NoResultsFound";
import CustomTableSmallView from "../../../reusableComponents/CustomTable/CustomTableSmallView";
import { t } from "i18next";

const Logs = ({ hook }) => {
  const transformedData = hook?.logs?.map((x) => ({
    id: x.id,
    response: x.statusCode,
    note: x.note,
    requestRuntime: x.runtime,
    dateAdded: x.dateAdded,
  }));

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: t("caymland.core.id"),
        size: 50,
      },
      {
        accessorKey: "response",
        header: t("caymland.webhook.status"),
        size: 50,
      },
      {
        accessorKey: "note",
        header: t("caymland.webhook.note"),
        size: 250,
      },
      {
        accessorKey: "requestRuntime",
        header: t("caymland.webhook.runtime"),
        size: 50,
      },
      {
        accessorKey: "dateAdded",
        header: t("caymland.showare.field.date_added"),
        size: 50,
      },
    ],
    []
  );

  return (
    <Fragment>
      {hook?.logs?.length > 0 ? <CustomTableSmallView data={transformedData} columns={columns} /> : <NoResultsFound />}
    </Fragment>
  );
};

export default Logs;
