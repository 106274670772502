import moment from "moment";
import format from "date-fns/format";
import checkIfStringIsDate from "./checkIfStringIsDate";

const formatObject = (obj) => {
  let newObject = {};
  Object.entries(obj).forEach((keyAndValue) => {
    let [key, value] = keyAndValue;
    // if (keyAndValue[0] === "tags")
    //   newObject["tags"] = keyAndValue[1].map((c) => {
    //     let tempObject = {};
    //     tempObject["tag"] = c.label;
    //     return tempObject;
    //   });
    if (typeof value === "object" && checkIfStringIsDate(value)) {
      newObject[key] = format(value, "dd-MM-yyyy hh:mm:ss");
    } else if (
      value !== null &&
      typeof value === "object" &&
      value.hasOwnProperty("value")
    ) {
      if (value.value !== null) newObject[key] = value.value;
    } else if (value !== null) newObject[key] = value;
  });
  return newObject;
};

export default formatObject;
