import React, { Fragment, useEffect, useState } from "react";
import NewCategoryPopUp from "../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Controller } from "react-hook-form";
import { Checkbox, Select, DatePicker, TimePicker } from "../../reusableComponents/Inputs";
import { retrieveCategories } from "../../reusableComponents/retriveCategoriesByBundle";
import { categoriesApi, useAddCategoryMutation, useGetCategoriesOptionsQuery } from "../../../redux/api/categoriesApi";
import { t } from "i18next";
import { CategoryModalData } from "../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../reusableComponents/ModalGenerator/ModalGenerator";

function Sidebar({ control, watch, setValue }) {
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "campaign",
  });
  const [newItem, setNewItem] = useState();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const [addCategory] = useAddCategoryMutation();

  useEffect(() => {
    if (newItem) {
      setValue("category", newItem);
    }
  }, [newItem]);
  const intervals = [
    { label: "10 Minuten", value: "PT10M" },
    { label: "30 Minuten", value: "PT30M" },
    { label: "1 Stunde", value: "PT60M" },
    { label: "6 Stunden", value: "PT6H" },
    { label: "12 Stunden", value: "PT12H" },
    { label: "Täglich", value: "PT1D" },
  ];

  const handleExecution = (e) => {
    if (!e.target.checked) {
      setValue("intervalExecution", null);
      setValue("timeExecution", null);
    }

    return e;
  };

  return (
    <Fragment>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />

      <Controller
        control={control}
        name="category"
        render={({ field: { name, value, onChange } }) => (
          console.log(value),
          (
            <Select
              name={name}
              label={t("caymland.core.category")}
              options={retrieveCategories}
              trigger={triggerCategories}
              bundle="campaign"
              value={value}
              isCreatable
              onChange={(selectedOptions) => {
                onChange(selectedOptions?.value);
                if (selectedOptions?.value === "create_new") {
                  setModalOptions((prev) => ({
                    ...prev,
                    isOpen: true,
                  }));
                }
              }}
            />
          )
        )}
      />

      <Controller
        control={control}
        name="allowRestart"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            label={t("caymland.campaign.allow_restart")}
            tooltip={""}
          />
        )}
      />
      <Controller
        control={control}
        name="noLog"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            label={t("caymland.campaign.no_log")}
            tooltip={t("caymland.campaign.no_log.tooltip")}
          />
        )}
      />
      <Controller
        control={control}
        name="isPublished"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
        )}
      />
      <>
        <Controller
          control={control}
          name="publishUp"
          render={({ field: { name, value, onChange } }) => (
            <DatePicker
              label={t("caymland.core.form.publishup")}
              name={name}
              value={value}
              maxDate={watch("publishDown")}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="publishDown"
          render={({ field: { name, value, onChange } }) => (
            <DatePicker
              label={t("caymland.core.form.publishdown")}
              name={name}
              value={value}
              minDate={watch("publishUp")}
              onChange={onChange}
            />
          )}
        />
      </>
      <Controller
        control={control}
        name="execution"
        render={({ field: { name, value, onChange } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={(e) => onChange(handleExecution(e))}
            label={t("caymland.core.range.execute")}
          />
        )}
        style={{
          opacity: !!watch("execution") ? 1 : 0,
          transition: "opacity 0.6s ease-out",
        }}
      />
      <Controller
        control={control}
        name="intervalExecution"
        render={({ field: { name, value, onChange } }) => (
          <Select
            name={name}
            label={t("caymland.core.form.interval")}
            options={intervals}
            value={value}
            onChange={onChange}
            required={true}
          />
        )}
      />
      <Controller
        control={control}
        name="timeExecution"
        render={({ field: { name, value, onChange } }) => (
          <TimePicker
            label={t("caymland.core.form.time.execute")}
            name={name}
            value={value}
            onChange={onChange}
            required={true}
          />
        )}
      />
    </Fragment>
  );
}

export default Sidebar;
