import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const geographicApi = createApi({
  reducerPath: "geographicApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["geographic"],
  endpoints: (build) => ({
    getCountries: build.query({
      query: () => "api/settings/countries",
    }),
    getTimezones: build.query({
      query: () => "/api/settings/timezone",
    }),
    getRegions: build.query({
      query: () => "api/settings/regions",
    }),
    getStates: build.query({
      query: () => "api/settings/states",
    }),
    getLocales: build.query({
      query: () => "api/settings/locales",
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetTimezonesQuery,
  useGetRegionsQuery,
  useGetStatesQuery,
  useGetLocalesQuery,
} = geographicApi;
