import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../../reusableComponents/Buttons/Button";
import SingleCampaignForModalForCampaigns from "./SingleCampaignForModalForCampaigns";
import { ImCancelCircle } from "react-icons/im";
import { useTranslation } from "react-i18next";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Tooltip } from "@mui/material";
import { t } from "i18next";

function ModalForCampaigns({
  handleClose,
  show,
  firstName,
  lastName,
  campaigns,
  addContactToCampaign,
  removeContactFromCampaign,
}) {
  const { t } = useTranslation();
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: handleClose,
    },
  ];
  return (
    <Modal show={show} onHide={handleClose} style={{}}>
      <Modal.Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeButton
      >
        <Modal.Title
          style={{
            color: "#000",
            fontSize: "16px",
            fontWeight: "500",
            padding: "4px",
          }}
          className="flex w-100 justify-content-between"
        >
          {t("caymland.lead.lead.header.campaigns").replace(/%name%/, `${firstName} ${lastName}`)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ margin: "0px 0px 2em 0px", height: "70vh", overflowY: "auto" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px" }}>
          <Tooltip title={t("caymland.core.campaign.warningmessage")} arrow>
            <WarningAmberOutlinedIcon style={{ color: "orange" }} />
          </Tooltip>
        </div>

        {campaigns?.map((singlecampaign) => (
          <SingleCampaignForModalForCampaigns
            campaignName={singlecampaign.name}
            checked={singlecampaign.contactIncluded}
            id={singlecampaign.id}
            addContactToCampaign={addContactToCampaign}
            removeContactFromCampaign={removeContactFromCampaign}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalForCampaigns;
