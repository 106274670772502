import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Select } from "../../../reusableComponents/Inputs";
import { useForm, Controller } from "react-hook-form";
import { campaignsApi } from "../../../../redux/api/campaignsApi";
import { retrieveSegments } from "../../../reusableComponents/retrieveSegments/retrieveSegmentsFunc";
import { useUpdateContactSegmentsMutation } from "../../../../redux/api/contactsApi";
import { segmentsApi } from "../../../../redux/api";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SegmentBatchModal = ({ modalOptions, setModalOptions, setShow, setMessage, setBackgroundToast }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [customError, setCustomError] = useState(false);

  const closeModal = () => {
    setModalOptions(() => ({
      isOpen: false,
      title: "",
    }));
    reset();
    setIsSubmitted(false);
    setCustomError(false);
  };

  const [trigger] = segmentsApi.useLazyGetSegmentsQuery();
  const [updateSegment] = useUpdateContactSegmentsMutation();

  const handleSegments = async (data) => {
    setIsSubmitted(true);
    const addValues = data?.add?.map((item) => item.value) || [];
    const removeValues = data?.remove?.map((item) => item.value) || [];
    if (addValues.length === 0 && removeValues.length === 0) {
      setCustomError(true);
      setShow(true);
      setMessage(t("caymland.core.error.not.valid"));
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3000);
      return;
    } else {
      setCustomError(false);
    }

    const idsArray = modalOptions?.id || [];
    const idsString = JSON.stringify(idsArray);

    const lead_batch = {
      add: addValues,
      remove: removeValues,
      ids: idsString,
    };
    const response = await updateSegment({ lead_batch: lead_batch });
    if (!response.error) {
      closeModal();
      setShow(true);
      setMessage(t("caymland.message.type.successfully", { type: "edited" }));
      setBackgroundToast("1");
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
    } else {
      setShow(true);
      setMessage(response.error.data.error.message);
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3000);
    }
  };

  const validationSchema = yup.object().shape({
    add: yup.array().nullable(),
    remove: yup.array().nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleSegments),
    },
  ];

  return (
    <Modal show={modalOptions.isOpen && modalOptions.type === "segments"} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ padding: "4px", fontSize: "18px" }}>{modalOptions.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Controller
            control={control}
            name="add"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={t("caymland.contact.add.segment")}
                isClearable
                isMulti
                className="w-full"
                value={value}
                options={retrieveSegments}
                trigger={trigger}
                onChange={onChange}
                errors={isSubmitted && (errors.add || customError)}
              />
            )}
          />
          <Controller
            control={control}
            name="remove"
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={t("caymland.contact.remove.segment")}
                isClearable
                isMulti
                className="w-full"
                value={value}
                options={retrieveSegments}
                trigger={trigger}
                onChange={onChange}
                errors={isSubmitted && (errors.remove || customError)}
              />
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default SegmentBatchModal;
