import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },

  backgroundColor: "#fff",
  boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",

  width: "100%",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#313131" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .04)" : "rgba(0, 0, 0, .02)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(1),
    alignItems: "center",
    display: "flex",
  },
  "&.Mui-expanded": {
    color: "#313131",
    backgroundColor: "#e0e0e0",
  },
  "& .MuiTypography-root": {
    color: "#313131 !important",
  },
  height: "40px",
  minHeight: "40px",
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props) {
  const { accordions, isOpen } = props;

  return (
    <>
      {accordions?.map((accordion, index) => (
        <div key={`panel-container-${index}`}>
          <Accordion key={`panel-${index}`} expanded={accordion?.isOpen || isOpen} onChange={() => {}}>
            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
              <Typography
                sx={{
                  fontSize: "14px !important",
                  fontWeight: "bold",
                  fontFamily: '"Titillium Web", sans-serif !important',
                }}
              >
                {accordion.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{accordion.component}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </>
  );
}
