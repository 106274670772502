import React, { Fragment, useState, useMemo, useEffect } from "react";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { AddorEditCategoryModal } from "./AddorEditCategoryModal";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { MRT_ToggleSelect } from "../../reusableComponents/CustomTable/MRT_ToggleSelect";
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useGetCategoryTypesQuery,
  useUpdateCategoryMutation,
  categoriesApi,
} from "../../../redux/api/categoriesApi";
import { useTranslation } from "react-i18next";

function Categories(props) {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [isOpen, setIsOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const name = "Categories";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const [modifiedData, setModifiedData] = useState({
    bundle: "",
    title: "",
    description: "",
    alias: "",
    color: "#000000",
    published: false,
  });

  const { data, isFetching, refetch } = useGetCategoriesQuery(query);
  const [updateCategory] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [getCategory] = categoriesApi.useLazyGetCategoryQuery();
  const { data: categoryTypes } = useGetCategoryTypesQuery();

  useEffect(() => {
    if (categoryTypes) {
      setTypes(categoryTypes);
    }
  }, [categoryTypes]);

  const listCategory = (type) => {
    if ((type || type === 0) && type !== "category") {
      setQuery((prev) => ({
        ...prev,
        where: [
          {
            col: "c.bundle",
            expr: "eq",
            val: type,
          },
        ],
      }));
    } else if (type === "category") {
      setQuery((prev) => ({
        ...prev,
        where: [],
      }));
    } else {
      setQuery((prev) => ({
        ...prev,
        where: [],
      }));
    }
  };

  const handleTogglePublished = async (data) => {
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
      parentId: null,
    };
    await updateCategory(updatedData);
    await refetch();
  };

  const ColorComponent = (props) => {
    return props?.data?.color ? (
      <div className="flex items-center p-no-margin">
        <span
          className="p-10"
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: `${props?.data?.color.startsWith("#") ? props?.data?.color : `#${props?.data?.color}`}`,
            boxShadow: "0 0 10px rgb(0 0 0 / 20%)",
          }}
        ></span>
      </div>
    ) : (
      <></>
    );
  };

  const toggle = async (type, id) => {
    setAddOrEdit(type);
    setIsOpen(!isOpen);
    if (type === "Edit" && id) {
      const getCategoryByID = await getCategory(id).unwrap();
      setModifiedData(getCategoryByID.category);
    } else {
      setModifiedData({
        bundle: "",
        title: "",
        description: "",
        alias: "",
        color: "#000000",
        published: false,
      });
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "color",
      accessorFn: (row) => row?.color,
      size: "unset",
      header: "",
      enableColumnActions: false,
      Cell: ({ cell }) => <ColorComponent data={cell.row.original} />,
    },
    {
      accessorKey: "title",
      accessorFn: (row) => row.title,
      header: t("caymland.core.title"),
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          handleTogglePublished={handleTogglePublished}
          link="categories"
          modal={toggle}
          disableLink={true}
        />
      ),
    },
    {
      accessorKey: "type",
      accessorFn: (row) => row.bundle,
      header: t("caymland.core.type"),
      enableColumnActions: false,
      Cell: ({ cell }) => {
        return cell.row.original.bundle === "0" ? "messages" : cell.row.original.bundle;
      },
    },
  ]);

  const actionButtons = ActionButtons({
    link: "categories",
    deleteItem: deleteCategory,
    refetch: refetch,
    clone: false,
    openModal: toggle,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "categories",
    refetch: refetch,
  });

  const toolbarButtons = useMemo(
    () => [
      {
        name: "categories",
        component: MRT_ToggleSelect,
        props: {
          data: categoryTypes,
          onChange: listCategory,
          value: query?.where && query?.where[0]?.val,
          title: "categories",
        },
      },
    ],
    [query?.where, categoryTypes]
  );

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "AddNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => {
              setModifiedData({
                bundle: "",
                title: "",
                description: "",
                alias: "",
                color: "#000000",
                published: false,
              });
              toggle("Add");
            },
          },
        ]}
        title={t("caymland.category.header.index")}
        alertMessage={alertMessage}
        showAlert={showAlert}
      />
      <AddorEditCategoryModal
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        setAlertMessage={setAlertMessage}
        alertMessage={alertMessage}
        modifiedData={modifiedData}
        setModifiedData={setModifiedData}
        toggle={toggle}
        addOrEdit={addOrEdit}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        types={types}
        isFetching={isFetching}
        refetch={refetch}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        linkNavigate={"categories"}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        toolbarActions={{
          select: true,
        }}
        toolbarButtons={toolbarButtons}
        isFetching={isFetching}
        setIsOpen={setIsOpen}
        modal={toggle}
      />
    </Fragment>
  );
}

export default Categories;
