import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./addEmailContent/Sidebar";
import Advanced from "./addEmailContent/Advanced";
import CustomJson from "./addEmailContent/CustomJson";
import CustomHtml from "./addEmailContent/CustomHtml";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Error, Success } from "../../../reusableComponents/Alerts";
import BuilderButton from "../../../reusableComponents/Buttons/Builder";
import Builder from "./addEmailContent/Builder";
import ThemeCards from "./addEmailContent/ThemeCards";
import { FaCube } from "react-icons/fa";
import Button from "../../../reusableComponents/Buttons/Button";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { Checkbox } from "../../../reusableComponents/Inputs";
import Loading from "../../../reusableComponents/loading";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import {
  useAddEmailMutation,
  useGetAddEditEmailsQuery,
  useGetEmailEditorOptionQuery,
} from "../../../../redux/api/emailsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import { t } from "i18next";

function AddEmail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [themeLoading, setThemeLoading] = useState(false);
  const [submitBackLoading, setSubmitBackLoading] = useState(false);
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [builderLoading, setBuilderLoading] = useState(false);
  const [themes, setThemes] = useState([]);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [response, setResponse] = useState();

  const [modifiedData, setModifiedData] = useState({
    name: "",
    subject: "",
    fromAddress: "",
    fromName: "",
    replyToAddress: "",
    bccAddress: "",
    isPublished: true,
    publishUp: null,
    publishDown: null,
    language: "de",
    customHtml: null,
    customJson: null,
    ignoreDOI: false,
    ignoreDNC: false,
    plainText: "",
    previewText: "",
    template: "",
    emailType: "template",
    translationChildren: [],
    segmentTranslationParent: null,
    templateTranslationParent: null,
    category: null,
    unsubscribeForm: null,
    isTemplate: false,
    preferenceCenter: null,
    lists: [],
    scheduleEmail: false,
    assetAttachments: [],
    headers: [],
    utmTags: {
      utmCampaign: "",
      utmContent: "",
      utmMedium: "",
      utmSource: "",
    },
  });

  const { data, refetch, isFetching, isLoading } = useGetAddEditEmailsQuery({});

  const { data: editorData } = useGetEmailEditorOptionQuery();

  const [addEmail, { isLoading: isAddLoading }] = useAddEmailMutation();

  useEffect(() => {
    if (modifiedData.name && modifiedData.subject) {
      delete errors.subject;
      delete errors.name;
      setErrors({ ...errors });
    }
  }, [modifiedData.name, modifiedData.subject]);

  useEffect(() => {
    if (data?.themes) {
      retrieveThemes();
    }
  }, [data]);

  const retrieveThemes = async () => {
    setThemeLoading(true);
    try {
      const templatesArray = Object.entries(data.templates.emails).map((x) => x[1]);
      let arr = templatesArray;

      arr.push({
        id: -1,
        name: data?.themes?.themes?.blank?.name,
        customJson: null,
        customHtml: null,
      });

      arr.push({
        id: -2,
        name: data?.themes?.themes?.full?.name,
        customJson: null,
        customHtml: null,
      });

      setThemes(arr);
    } catch (e) {
      console.log(e);
    } finally {
      setThemeLoading(false);
    }
  };

  const retrievePreferencePage = (inputValue) => {
    return new Promise(async (resolve) => {
      const PageList = data?.pages?.pages;
      resolve(PageList);
    });
  };

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };
  const queryParams = new URLSearchParams(location.search);
  const isTemplateRoute = queryParams.has("template");

  const handleAddEmail = async (params, openBuilderAfterSave = false) => {
    if (modifiedData?.subject.length < 1) {
      errors.subject = true;
      setErrors({ ...errors });
    } else {
      delete errors.subject;
      setErrors({ ...errors });
    }

    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    if (modifiedData?.emailType === "list" && !modifiedData?.segmentTranslationParent) {
      if (!modifiedData?.lists.length && !modifiedData.segmentTranslationParent) {
        errors.lists = true;
        setErrors({ ...errors });
      } else {
        delete errors.lists;
        setErrors({ ...errors });
      }
    }
    const hasErrors = Object.values(errors).some((value) => value === true);

    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }

    if (Object.keys(errors).length === 0) {
      const headersList = Array.isArray(modifiedData?.headers)
        ? modifiedData.headers.map((item) => ({
            label: item.label,
            value: item.value,
          }))
        : [];

      try {
        if (params === "back") {
          setSubmitBackLoading(true);
        }

        if (modifiedData?.headers) {
          modifiedData.headers = {
            list: headersList,
          };
        }

        if (modifiedData?.category) {
          modifiedData.category = modifiedData.category.id;
        }

        // if (modifiedData?.lists) {
        //   modifiedData.lists = modifiedData.lists.map((x) => x.id);
        // }

        if (modifiedData.assetAttachments) {
          if (Array.isArray(modifiedData.assetAttachments)) {
            // Handle the case where it's already an array of asset IDs
            modifiedData.assetAttachments = modifiedData.assetAttachments.map((x) => x.id);
          } else if (typeof modifiedData.assetAttachments === "object") {
            // Handle the case where it's an object or single asset
            modifiedData.assetAttachments = [modifiedData.assetAttachments.id];
          }
        } else {
          // Handle the case where modifiedData.assetAttachments is undefined or falsy
          modifiedData.assetAttachments = [];
        }

        if (modifiedData.language?.value) {
          modifiedData.language = modifiedData.language?.value;
        }

        if (modifiedData?.translationChildren) {
          modifiedData.translationChildren = modifiedData.translationChildren.map((x) => x.id);
        }

        if (modifiedData?.translationParent) {
          modifiedData.translationParent = modifiedData.translationParent.id;
        }

        if (modifiedData?.unsubscribeForm) {
          modifiedData.unsubscribeForm = modifiedData.unsubscribeForm.id;
        }

        if (modifiedData?.preferenceCenter) {
          modifiedData.preferenceCenter = modifiedData.preferenceCenter.id;
        }
        let response;
        response = await addEmail(modifiedData);
        setResponse(response);

        console.log(response);
        if (!response.error) {
          refetch();
          console.log(response);
          Success(
            `${t("caymland.message.queue.report.success")}, ${t("caymland.message.type.successfully", {
              type: t("caymland.dashboard.create.past.tense"),
            })}!`
          ).then((result) => {
            if (result.isConfirmed && params === "save") {
              navigate(`/emails/view/${response?.data?.email?.id || response?.email?.id}`);
            } else if (result.isConfirmed && params === "apply") {
              navigate(`/emails/edit/${response?.data.email?.id || response?.email?.id}`);
            } else if (result.isConfirmed && params === "applyBuilder") {
              navigate(`/emails/edit/${response?.data.email?.id || response?.email?.id}?openBuilder=true`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
        setLoading(false);
        setSubmitBackLoading(false);
      } catch (e) {
        console.log(e);

        setLoading(false);
        setSubmitBackLoading(false);
        Error(t("caymland.message.error"));
      }
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handelError = () => {
    errors.subject = true;
    errors.name = true;
    setErrors({ ...errors });
  };

  const buttons = [
    {
      name: "builder",
      title: t("caymland.core.builder"),
      icon: <FaCube size={15} />,
      onClick: () => modifiedData?.name && modifiedData?.subject && setIsOpen(true),
      not_active: modifiedData?.name && modifiedData?.subject ? false : true,
    },
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/emails"),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEmail("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEmail("apply", true),
    },
  ];

  return (
    <Fragment>
      {!isOpen && (
        <Header
          showAlert={showAlert}
          alertMessage={alertMessage}
          buttons={buttons}
          loading={isAddLoading}
          title={<TitleHeader name={t("caymland.core.type.email")} title={modifiedData?.name} loading={isLoading} />}
        />
      )}

      {isOpen ? (
        <Builder
          editorData={editorData}
          setModifiedData={setModifiedData}
          modifiedData={modifiedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggle={toggle}
          response={response}
          loading={loading}
          title={<TitleHeader name={t("caymland.core.type.email")} title={modifiedData?.name} loading={isLoading} />}
          apply={handleAddEmail}
        />
      ) : (
        <>
          <Wrapper
            childrenClassOne={"w-75 json-html-style"}
            childrenOne={
              <>
                <Nav className="nav-primary w-full flex" tabs>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 1 ? "active" : ""}
                      onClick={() => changeDynamicTab(1)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.core.filter.themes")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 2 ? "active" : ""}
                      onClick={() => changeDynamicTab(2)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.core.advanced")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 3 ? "active" : ""}
                      onClick={() => changeDynamicTab(3)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.email.custom.json")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 4 ? "active" : ""}
                      onClick={() => changeDynamicTab(4)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.email.custom.html")}
                    </NavLink>
                  </NavItem>
                  <div style={{ marginLeft: "auto", paddingRight: "10px", display: "flex" }}>
                    <div class="d-flex gap-10">
                      <p style={{ marginBottom: "0", display: "flex", alignItems: "center" }}>
                        {t("caymland.email.template")}
                      </p>
                      <Checkbox
                        name="isTemplate"
                        alignSelf
                        marginBottom="mb-8px"
                        value={modifiedData?.isTemplate}
                        onChange={(e) =>
                          setModifiedData({
                            ...modifiedData,
                            isTemplate: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </Nav>
                <TabContent activeTab={primarycolorTab} style={{ display: "flex" }}>
                  <TabPane tabId={1} style={{ overflow: "auto" }}>
                    <div className="py-20 px-10">
                      <ThemeCards modifiedData={modifiedData} setModifiedData={setModifiedData} themes={themes} />
                    </div>
                  </TabPane>
                  <TabPane tabId={2} style={{ flex: "1" }}>
                    <div className="py-20 px-10">
                      <Advanced modifiedData={modifiedData} setModifiedData={setModifiedData} />
                    </div>
                  </TabPane>
                  <TabPane tabId={3} style={{ flex: "1" }}>
                    <CustomJson modifiedData={modifiedData} setModifiedData={setModifiedData} themes={themes} />
                  </TabPane>
                  <TabPane tabId={4} style={{ flex: "1" }}>
                    <CustomHtml modifiedData={modifiedData} setModifiedData={setModifiedData} />
                  </TabPane>
                </TabContent>
              </>
            }
            childrenClassTwo={"wrapper-childTwo"}
            childrenTwo={
              isLoading ? (
                <SidebarSkeleton />
              ) : (
                <Sidebar
                  modifiedData={modifiedData}
                  setModifiedData={setModifiedData}
                  preferenceCenterPages={retrievePreferencePage}
                  errors={errors}
                  setErrors={setErrors}
                />
              )
            }
          />
        </>
      )}
    </Fragment>
  );
}

export default AddEmail;
