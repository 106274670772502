import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import { FaPlus, FaRegTimesCircle } from "react-icons/fa";
import { t } from "i18next";
import { Select } from "../../reusableComponents/Inputs";
import { contactsApi } from "../../../redux/api";
import { useUpdateContactSegmentsMutation } from "../../../redux/api/contactsApi";

const ContactSegmentModal = ({ isOpen, setIsOpen, id, refetch }) => {
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();
  const [updateSegment] = useUpdateContactSegmentsMutation();

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
        };
        if (inputValue.length) {
          query.search = inputValue;
        }

        const response = await triggerContactsQuery(query);
        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData?.map((contact) => {
            const firstname = contact?.fields?.all?.firstname || "";
            const lastname = contact?.fields?.all?.lastname || "";
            const email = contact?.fields?.all?.email || "";

            const label = `${firstname} ${lastname}`.trim();

            return {
              label: label && `${label}`,
              value: contact?.id,
            };
          });

          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  const handleSelectChange = (selectedOptions) => {
    // Update selected contacts state with selected options
    setSelectedContacts(selectedOptions);
  };

  const buttons = [
    {
      name: "close",
      title: `${t("caymland.core.close")}`,
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => setIsOpen(!isOpen),
    },
    {
      name: "save",
      title: t("caymland.core.form.add"),
      icon: <FaPlus size={15} />,
      onClick: async () => {
        const idsString = JSON.stringify(selectedContacts.map((item) => item.value));
        const lead_batch = {
          add: [id],
          remove: [],
          ids: idsString,
        };
        const response = await updateSegment({ lead_batch: lead_batch });
        if (!response.error) {
          setIsOpen(!isOpen);
          setSelectedContacts([]);

          setTimeout(() => {
            refetch();
          }, 2000);
        }
        // Handle save logic with selectedContacts
        console.log("Selected Contacts:", selectedContacts);
      },
    },
  ];

  return (
    <Modal show={isOpen} onHide={() => setIsOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t("caymland.lead.lead.events.addtolists")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select options={retrieveContacts} isMulti onChange={handleSelectChange} value={selectedContacts} />
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default ContactSegmentModal;
