import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { FieldsRenderSection } from "./FiledsRenderReusable";

function TabsAddCompany({ activeTab, companyFields, control, errors }) {
  const [filteredFields, setFilteredFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyFields) {
      // Create a copy of data.data and then sort and filter it
      const sortedAndFilteredFields = [...companyFields]
        .sort((a, b) => a.order - b.order)
        .filter((x) => x.isPublished === true && x.group === activeTab && x.isVisible === true);

      // Update the state with the sorted and filtered array
      setFilteredFields(sortedAndFilteredFields);
    }
  }, [companyFields, activeTab]);

  return (
    <Form>
      <div>
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Loading />
          </div>
        ) : (
          <>
            <div className="gap-10" style={{ display: "grid", padding: "20px" }}>
              <FieldsRenderSection filteredFields={filteredFields} control={control} errors={errors} />
            </div>
          </>
        )}
      </div>
    </Form>
  );
}

export default TabsAddCompany;
