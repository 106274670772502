import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DateFromTo from "../../../dashboard/defaultCompo/dateFromTo";
import moment from "moment";
import Swal from "sweetalert2";
import { useRef } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import Details from "./Details";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import LineChart from "../../../reusableComponents/ECharts/LineChart/LineEChart";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import {
  smsApi,
  useDeleteSmsMutation,
  useGetSMSExtrasQuery,
  useGetSmsQuery,
  useSendExampleSmsMutation,
} from "../../../../redux/api/smsApi";
import { t } from "i18next";
import { FaChartLine } from "react-icons/fa";
import ClickCounts from "../emails/viewEmail/ClickCounts";
import MainModal from "../../../reusableComponents/MainModal";
import SendSmsContent from "./SendSmsContent";
import { useSelector } from "react-redux";
import { BsSend } from "react-icons/bs";
import { useSendExampleMutation } from "../../../../redux/api/emailsApi";

function ViewSMS(props) {
  const navigate = useNavigate();
  const userPhone = useSelector((state) => state.auth.user);
  const id = location?.state?.id || location?.state || useParams().id;
  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [openDetails, setOpenDetails] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [sendExampleOpen, setSendExampleOpen] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [sendSmsList, setSendSmsList] = useState([{ id: 1, sms: userPhone.phone1, position: 1 }]);
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
  });

  const [sendExample] = useSendExampleSmsMutation(id);
  const detailsRef = useRef(null);
  const [deleteSms] = useDeleteSmsMutation();
  const { data: smsData } = useGetSmsQuery(id, { skip: !id });
  const { data, isLoading, refetch } = useGetSMSExtrasQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
  });

  useEffect(() => {
    if (openDetails && detailsRef.current) {
      const height = detailsRef.current.scrollHeight + "px";
      detailsRef.current.style.maxHeight = height;
    } else if (detailsRef.current) {
      detailsRef.current.style.maxHeight = "0px";
    }
  }, [openDetails]);

  const handleDelete = async () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.message.delete", { name: smsData?.sms?.name }),
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteSms(id);
        if (!response?.error) {
          setShowAlert(true);
          setAlertMessage(t("caymland.core.alert.message.points"));
          setTimeout(() => {
            setShowAlert(false);
            setAlertMessage("");
          }, 7000);
          navigate("/sms");
        }
      }
    });
  };

  const handleDateChange = () => {
    const fromDate = localStorage.getItem("fromDate");
    const toDate = localStorage.getItem("toDate");
    refetch({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
  };

  const showSendSms = () => {
    setSendExampleOpen(true);
  };

  const buttons = useMemo(
    () => [
      {
        name: "close",
        title: t("caymland.core.form.cancel"),
        icon: <FaRegTimesCircle size={15} />,
        onClick: () => navigate("/sms"),
      },
      {
        name: "SendExample",
        title: t("caymland.infobip.viber.send.example"),
        icon: <IoIosSend size={15} />,
        onClick: () => showSendSms(),
      },
      ...(smsData?.sms?.pendingCount > 0
        ? [
            {
              name: "sendSms",
              title: t("caymland.infobip.send.sms"),
              icon: <BsSend size={15} />,
              onClick: () => {},
            },
          ]
        : []),
      {
        name: "edit",
        title: t("caymland.core.permissions.edit"),
        icon: <FaEdit size={15} />,
        onClick: () => navigate(`/sms/edit/${id}`),
        childs: [
          {
            name: "delete",
            title: t("caymland.mailbox.list.action.deleteMessage"),
            icon: <FaTrashAlt size={15} />,
            onClick: () => handleDelete(),
          },
        ],
      },
    ],
    [navigate, showSendSms, handleDelete, smsData?.sms?.pendingCount]
  );

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} sms={smsData?.sms} />,
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "ip",
        accessorFn: (row) => `${row.ipAddresses[0].ip}` || `${row.firstname} ${row.lastname}`,
        header: t("caymland.core.name"),
        enableColumnActions: false,
      },
    ],
    []
  );

  const handleSubmit = async () => {
    try {
      setSendLoading(true);
      const recipients = sendSmsList.map((item) => item.sms.replace(/\D/g, ""));
      const requestBody = {
        recipients,
      };
      const response = await sendExample({ id: id, body: requestBody }).unwrap();

      if (response?.success) {
        setShowAlert(true);
        setAlertMessage(t("caymland.sms.surge.test_sent_multiple.success"));
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 7000);
      }
    } catch (e) {
      console.error("Error submitting:", e);
      Error(t("caymland.message.error"));
    } finally {
      setSendLoading(false);
    }
    setSendExampleOpen(false);
  };

  // const focusStyles = `
  // .nav-primary .nav-link.active, .nav-primary .show > .nav-link, .nav-pills.nav-primary .nav-link.active, .nav-pills.nav-primary .show > .nav-link {
  //   background-color: ${userColor} !important;
  //   color: ${textColor} !important;
  // }
  // .nav-tabs .nav-link.active {
  //   border-color:  transparent !important;
  // }
  // `;

  return (
    <Fragment>
      {/* <style>{focusStyles}</style> */}
      <>
        {isLoading ? (
          <HeaderSkeleton isView={true} viewMediumButton={true} />
        ) : (
          <Header
            buttons={buttons}
            title={smsData?.sms?.name}
            showIsPublished={true}
            isPublished={smsData?.sms?.isPublished}
            showAlert={showAlert}
            data={smsData?.sms}
            alertMessage={alertMessage}
            setShowAlert={setShowAlert}
          />
        )}

        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton />
            ) : (
              <>
                <MainModal
                  modalTitle={t("caymland.email.send.example")}
                  isOpen={sendExampleOpen}
                  setIsOpen={setSendExampleOpen}
                  handleSubmit={handleSubmit}
                  loading={sendLoading}
                  content={<SendSmsContent sendSmsList={sendSmsList} setSendSmsList={setSendSmsList} />}
                  saveText={t("caymland.infobip.send.sms")}
                />
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-5px" style={{ width: "30%" }}>
                        <FaChartLine size={18} color={"#6c757d"} />
                        {t("caymland.sms.stats")}
                      </div>
                      <div className="flex items-center justify-center gap-5px">
                        <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} />
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <LineChart
                        labelData={data?.stats?.labels || []}
                        dataSeries={
                          data?.stats?.datasets.map((dataset, index) => ({
                            name: dataset.label,
                            data: dataset.data.map((value, i) => ({
                              value,
                              itemStyle: { color: dataset.backgroundColor },
                            })),
                          })) || []
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <Nav className="nav-primary w-full" tabs>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 1 ? "active" : ""}
                          onClick={() => setprimarycolorTab(1)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.trackable.click_counts")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 2 ? "active" : ""}
                          onClick={() => setprimarycolorTab(2)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.contact.segments.fields.contacts")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={primarycolorTab}>
                      <TabPane tabId={1}>
                        <ClickCounts extras={data || []} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <div className=" grid-col w-full">
                          <CustomTable
                            columns={columns}
                            data={data?.contacts?.results ? Object.values(data?.contacts?.results) : []}
                            query={query}
                            setQuery={setQuery}
                            viewTable={true}
                            showDetailPanel
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwoNoMargin"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton applyPadding />
            ) : (
              <>
                <CustomizedAccordions accordions={accordions} />
                <div className="px-20 mt-20px">
                  <hr />
                  <div className="card-body activity-scroll">
                    <p style={{ color: "#555", fontWeight: "bold" }}>{t("caymland.core.recent.activity")}</p>
                    <div className="activity">
                      {data && Array.isArray(data.recentActivity)
                        ? data.recentActivity.map((i, index) => {
                            return (
                              <div key={index} className="d-flex">
                                <div
                                  className="flex-shrink-0 gradient-round gradient-line-1"
                                  style={{
                                    marginRight: "15px",
                                    background: "limegreen",
                                  }}
                                >
                                  <FaCheck />
                                </div>
                                <div className="flex-grow-1">
                                  <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                                    {i?.action === "create"
                                      ? t("caymland.core.create.by.past.tense")
                                      : t("caymland.core.update.by.past.tense")}
                                    <strong style={{ marginLeft: "5px" }}>{i?.userName}</strong>
                                  </p>
                                  <small style={{ fontSize: "12px" }}>
                                    {moment(i?.dateAdded).format("DD.MM.YY HH:mm")}
                                  </small>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </>
            )
          }
        />
      </>
    </Fragment>
  );
}

export default ViewSMS;
