import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const triggersApi = createApi({
  reducerPath: "triggersApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Triggers"],
  endpoints: (build) => ({
    getTriggers: build.query({
      query: customBaseQuery((q) => `/api/points/triggers?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Triggers"],
    }),
    getTriggersEvents: build.query({
      query: customBaseQuery((q) => `/api/points/triggers/events/new?${qs.stringify(q)}`),

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Triggers"],
    }),
    updateTrigger: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/points/triggers/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Triggers", id }],
    }),
    deleteTrigger: build.mutation({
      query: (id) => ({
        url: `/api/points/triggers/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          triggersApi.util.updateQueryData("getTriggers", undefined, (draft) => {
            draft.data = draft.data.filter((Triggers) => Triggers.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Triggers", id }],
    }),
    addNewTrigger: build.mutation({
      query: (body) => ({
        url: `/api/points/triggers/new`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "Triggers", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    getTriggersAndPointTrigger: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        let trigger;
        let pointTriggerResult = await baseQuery({ url: `/api/points/triggers/events/types` });
        const pointTrigger = pointTriggerResult.data;

        if (id) {
          let triggerResult = await baseQuery({ url: `/api/points/triggers/${id}` });
          trigger = triggerResult.data;
        } else {
          trigger = null;
        }

        const data = {
          trigger,
          pointTrigger,
        };

        return { data: data };
      },
      providesTags: (result, error, id) => [{ type: "Triggers", id }],
    }),
  }),
});

export const {
  useGetTriggersQuery,
  useDeleteTriggerMutation,
  useUpdateTriggerMutation,
  useAddNewTriggerMutation,
  useGetTriggersAndPointTriggerQuery,
  useGetTriggersEventsQuery,
} = triggersApi;
