import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import {
  useAddFolderFocusItemsMutation,
  useDeleteFocusItemsMutation,
  useDeleteFolderFocusItemsMutation,
  useGetFocusItemsQuery,
  useGetFolderFocusItemsQuery,
  useRemoveFocusItemsToFoldersMutation,
  useUpdateFocusItemsMutation,
  useUpdateFocusItemsToFoldersMutation,
  useUpdateFolderFocusItemsMutation,
} from "../../../../redux/api/focusItemsApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MRT_CustomRowsActionFolder } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { t } from "i18next";

function FocusItems(props) {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const [activeRowId, setActiveRowId] = useState(null);
  const [editingFolder, setEditingFolder] = useState();
  const name = "FocusItems";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "focus",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "focus",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const { data: focusData, isFetching: focusIsFetching, refetch: refetchTableData } = useGetFocusItemsQuery(query);
  const { data: folderFocusItemsData, refetch } = useGetFolderFocusItemsQuery();
  const [updateFocusItems] = useUpdateFocusItemsMutation();
  const [deleteFocusItems] = useDeleteFocusItemsMutation();
  const [addFolder] = useAddFolderFocusItemsMutation();
  const [updateFolder] = useUpdateFolderFocusItemsMutation();
  const [deleteFolder] = useDeleteFolderFocusItemsMutation();
  const [updateFocusItemsToFolder] = useUpdateFocusItemsToFoldersMutation();
  const [removeFocusItemsFromFolder] = useRemoveFocusItemsToFoldersMutation();

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { ...data, isPublished: !data.isPublished };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateFocusItems(updatedData);
    await refetchTableData();
    setLocalLoading(-1);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string || typeof string !== "string") {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.core.name"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          handleTogglePublished={handleTogglePublished}
          localLoading={localLoading}
          link="focus"
          view={true}
        />
      ),
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.core.category"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "type",
      accessorFn: (row) => row.type,
      header: t("caymland.focus.thead.type"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => capitalizeFirstLetter(cell.row.original.type),
    },
    {
      accessorKey: "style",
      accessorFn: (row) => row.type,
      header: t("caymland.focus.thead.style"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => capitalizeFirstLetter(cell.row.original.style),
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const body = [<CampaignForm control={control} />];

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/focus/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "focus",
    removeItemFromFolder: removeFocusItemsFromFolder,
    deleteItem: deleteFocusItems,
    refetch: refetchTableData,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "focus",
    refetch: refetchTableData,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.focus")} />
      <DragAndDropTable
        data={focusData}
        folderData={folderFocusItemsData}
        columns={columns}
        isFetching={focusIsFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateFocusItemsToFolder}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
      />
    </Fragment>
  );
}

export default FocusItems;
