import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const SidebarSkeleton = ({ applyPadding = false }) => {
  return (
    <Box sx={{ width: '100%', my: 0, p: applyPadding ? 2 : 0 }}>
      <Skeleton variant="text" width="100%" height={60} />
      <Skeleton variant="text" width="100%" height={60} />

      <Skeleton variant="rectangular" width="100%" height={60} />
      <Skeleton variant="rectangular" width="100%" height={60} />

      <Skeleton variant="rectangular" width="100%" height={60} />

      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={40} />

      <Skeleton variant="rectangular" width="100%" height={60} />
      <Skeleton variant="rectangular" width="100%" height={60} />

      <Skeleton variant="rectangular" width="100%" height={60} />

      <Skeleton variant="text" width="100%" height={60} />

      <Skeleton variant="rectangular" width="100%" height={40} />

      <Skeleton variant="rectangular" width="100%" height={120} />
    </Box>
  );
};

export default SidebarSkeleton;
