import React, { useState } from "react";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import Button from "../../../../reusableComponents/Buttons/Button";
import CustomModal from "./CustomModal";
import { t } from "i18next";

function Sidebar({ extras, cachedHtml, manual, automatic }) {
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    type: "",
    data: {},
  });
  const openModal = (type, row) => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: true,
      type: type,
      data: row,
    }));
  };

  const closeModal = (type) => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
      type: type,
      data: null,
    }));
  };
  const buttons = [
    {
      name: "automatic",
      title: t("caymland.form.form.header.automaticcopy"),
      onClick: () => openModal("automatic"),
    },
    {
      name: "manual",
      title: t("caymland.form.form.header.manualcopy"),
      onClick: () => openModal("manual"),
    },
  ];
  return (
    <>
      <div
        style={{
          padding: "20px",
          background: "",
          color: "",
          boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.1)",
          margin: "20px",
        }}
      >
        <h4 style={{ fontSize: "14px" }}>{t("caymland.form.form.header.copy")}</h4>
        <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
          {t("caymland.form.form.help.landingpages")}
        </p>
        <div className="flex items-center gap-5px mt-10">
          <Button buttons={buttons} />
          <CustomModal
            show={modalOptions.isOpen}
            modalOptions={modalOptions}
            handleClose={closeModal}
            title={
              modalOptions.type === "automatic"
                ? t("caymland.form.form.header.automaticcopy")
                : t("caymland.form.form.header.manualcopy")
            }
            automatic={automatic}
            manual={manual}
            cachedHtml={cachedHtml}
          />
        </div>
      </div>

      <hr />
      <div className="card-body px-20">
        <p style={{ color: "#555", fontWeight: "bold" }}>{t("caymland.core.recent.activity")}</p>
        <div className="activity">
          {extras?.recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{
                  marginRight: "15px",
                  background: activity.action === "create" ? "limegreen" : "#e9e7ed",
                }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity.action === "create"
                    ? t("caymland.showare.field.created_by")
                    : t("caymland.core.update.by.past.tense")}{" "}
                  <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{moment(activity.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
