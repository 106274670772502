import React from "react";
import { MaterialReactTable } from "material-react-table";

const CustomTableSmallView = ({ data, columns, height }) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableGlobalFilter={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableToolbarTop={false}
      enableToolbarBottom={false}
      enableColumnActions={false}
      manualPagination
      enableTopToolbar={false}
      manualSorting
      enableDensityToggle={false}
      enableTableFooter={false}
      enableBottomToolbar={false}
      enableStickyFooter={false}
      enableSorting={false}
      enableFilters={false}
      enableFullScreenToggle={false}
      showpaginationbottom={false}
      muiTablePaperProps={{
        sx: {
          boxShadow: "none",
        },
      }}
      enableStickyHeader
      enableRowSelection={false}
      enableMultiRowSelection={false}
      displayColumnDefOptions={{
        showColumnDefHeader: false,
      }}
      muiBottomToolbarProps={{
        sx: {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        },
      }}
      muiTableContainerProps={{
        sx: { overflowY: "auto", height: height },
      }}
    />
  );
};

export default CustomTableSmallView;
