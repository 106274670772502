import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { t } from "i18next";
export default function ({ handleBack }) {
  return (
    <Button
      name="back"
      style={{ margin: "4px", border: "1px solid rgba(19, 95, 149, 0.5)" }}
      startIcon={<ArrowBackIcon size={15} />}
      onClick={handleBack}
    >
      {t("caymland.modal.back")}
    </Button>
  );
}
