import React from "react";
import Moment from "moment";
import { t } from "i18next";

function Details({ sms }) {
  return (
    sms && (
      <div className="details-container-accordion">
        {[
          { label: t("caymland.page.tracking.form.category"), value: sms?.category?.title },
          { label: t("caymland.showare.field.created_by"), value: sms?.createdByUser },
          { label: t("caymland.core.created"), value: Moment(sms?.dateAdded).format("DD.MM.YY HH:mm") },
          { label: t("caymland.core.form.publishup"), value: Moment(sms?.dateAdded).format("DD.MM.YY HH:mm") },
          {
            label: t("caymland.core.form.publishdown"),
            value: sms?.publishDown ? Moment(sms.publishDown).format("DD.MM.YY HH:mm") : t("caymland.core.never"),
          },
          { label: t("caymland.core.id"), value: sms?.id },
        ].map((item, index) => (
          <div key={index} className="details-item gap-5px">
            <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
          </div>
        ))}
      </div>
    )
  );
}

export default Details;
