import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SingleSegmentUsage from "./SingleSegmentUsage";
import { useGetUsagesQuery } from "../../../redux/api/segmentsApi";

function SegmentUsages() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state?.id || location?.state || useParams().id;
  const { data } = useGetUsagesQuery(id);


  // /emails?search=ids%3A70%2C56%2C75
  // emails?search=ids%3A70%2C56%2C75

  const showCampaignsWithIDS = (label, ids, route) => {
    console.log("label", label)
    navigate(route, {
      state: { search: `ids:${ids}` },
    });
  };
  return (
    <>
      {data?.data.map((usage, index) =>
        usage?.ids?.length ? (
          <SingleSegmentUsage key={index} showCampaignsWithIDS={showCampaignsWithIDS} usage={usage} />
        ) : (
          <React.Fragment key={index}></React.Fragment>
        )
      )}
    </>
  );
}

export default SegmentUsages;
