import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { setCredentials } from "../features/authSlice";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (build) => ({
    authLogin: build.mutation({
      query: (credentials) => ({
        url: "api/login_check",
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          console.log(data);
          dispatch(setCredentials(data.data));
        } catch (e) {
          console.log(e);
        }
      },
    }),
  }),
});

export const { useAuthLoginMutation } = authApi;
