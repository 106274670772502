import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./addPageContent/Sidebar";
import CustomJson from "./addPageContent/CustomJson";
import CustomHtml from "./addPageContent/CustomHtml";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Error, Success, handleCancel } from "../../../reusableComponents/Alerts";
import Builder from "./addPageContent/Builder";
import ThemeCards from "./addPageContent/ThemeCards";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { FaCube } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import {
  useAddLandingPageMutation,
  useGetLandingPageDataQuery,
  useGetPageQuery,
  useGetPagesEditorOptionQuery,
  useUpdatePageMutation,
} from "../../../../redux/api/pagesApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { useTranslation } from "react-i18next";
import he from "he";

function NewLandingPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [themes, setThemes] = useState([]);
  const [preferenceCenterPages, setPreferenceCenterPages] = useState([]);
  const [errors, setErrors] = useState({});
  const [pages, setPages] = useState([]);

  const [addLandingPageItem, { isLoading: isAddLoading }] = useAddLandingPageMutation();
  const [updatePages, { isLoading: isEditLoading }] = useUpdatePageMutation(id);
  const isSaving = isEditLoading || isAddLoading;

  const { data, isFetching, isLoading } = useGetLandingPageDataQuery(id);

  const { data: editorData } = useGetPagesEditorOptionQuery();
  const { data: getPage } = useGetPageQuery(id, { skip: !id });

  const [modifiedData, setModifiedData] = useState({
    title: "",
    alias: "",
    category: null,
    isPublished: true,
    language: null,
    metaDescription: "",
    redirectType: null,
    redirectUrl: "",
    customJson: null,
    customHtml: "",
    translationParent: [],
    publishUp: null,
    publishDown: null,
    isPreferenceCenter: false,
    noIndex: null,
    template: "blank",
  });
  console.log(modifiedData);
  useEffect(() => {
    if (id && getPage) {
      let pageData = getPage?.page;
      if (isClone) {
        pageData = { ...pageData, isPublished: false };
      }

      setModifiedData({
        ...pageData,
        category: pageData?.category
          ? {
              label: pageData?.category?.title,
              value: pageData?.category?.id,
            }
          : null,
      });
    }
  }, [getPage]);

  useEffect(() => {
    if (data?.featureThemesData) {
      let arr = [
        { id: -1, name: data?.featureThemesData?.blank?.name, customJson: {} },
        { id: 1, name: data?.featureThemesData?.full?.name, customJson: data?.featureThemesData?.full?.content },
      ];
      setThemes(arr);
    }

    if (data?.pagesListData?.pages) {
      setPreferenceCenterPages(data.pagesListData.pages);
    }

    if (data?.groupListData) {
      setPages(data?.groupListData);
    }
  }, [id, data]);

  const handleAddPage = async (params) => {
    if (modifiedData?.title.length < 1) {
      errors.title = true;
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      setErrors({ ...errors });
    } else {
      delete errors.title;
      setErrors({ ...errors });
    }

    if (Object.keys(errors).length === 0) {
      try {
        let response;
        const payload = {
          ...modifiedData,
          category:
            modifiedData.category && typeof modifiedData.category === "object"
              ? modifiedData.category.value
              : modifiedData.category,
          translationParent:
            modifiedData.translationParent && typeof modifiedData.translationParent === "object"
              ? modifiedData.translationParent.id
              : modifiedData.translationParent,
        };

        if (id && !isClone) {
          response = await updatePages({ ...payload, id });
        } else {
          response = await addLandingPageItem(payload);
        }

        if (!response?.error) {
          Success(
            `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
              type: id ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
            })}`
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navigate(`/pages/view/${response?.data?.page?.id}`);
            } else {
              navigate(`/pages/edit/${response?.data?.page?.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };

  const buttons = [
    {
      name: "builder",
      title: t("caymland.core.builder"),
      icon: <FaCube size={15} />,
      onClick: () => modifiedData?.title && setIsOpen(true),
      not_active: modifiedData?.title ? false : true,
      show: false,
    },
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/pages", navigate),
      show: false,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddPage("back"),
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddPage(""),
      show: false,
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        !isOpen && (
          <Header
            alertMessage={alertMessage}
            showAlert={showAlert}
            buttons={buttons}
            loading={isSaving}
            title={
              !isClone && id
                ? t("caymland.page.header.edit", { name: he.decode(modifiedData.title) })
                : t("caymland.page.header.new")
            }
          />
        )
      )}
      {isOpen ? (
        <Builder
          editorData={editorData}
          setModifiedData={setModifiedData}
          modifiedData={modifiedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggle={() => setIsOpen(!isOpen)}
          loading={isFetching}
          currentId={id}
          apply={handleAddPage}
        />
      ) : (
        <Wrapper
          childrenClassOne={"w-75 json-html-style"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton applyPadding={true} />
            ) : (
              <>
                <Nav className="nav-primary w-full flex" tabs>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 1 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(1)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.core.form.theme")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 2 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(2)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.email.custom.json")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 3 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(3)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("caymland.email.custom.html")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={primarycolorTab} style={{ display: "flex" }}>
                  <TabPane tabId={1} style={{ flex: "1" }}>
                    <ThemeCards modifiedData={modifiedData} setModifiedData={setModifiedData} themes={themes} />
                  </TabPane>
                  <TabPane tabId={2} style={{ flex: "1" }}>
                    <CustomJson modifiedData={modifiedData} setModifiedData={setModifiedData} />
                  </TabPane>
                  <TabPane tabId={3} style={{ flex: "1" }}>
                    <CustomHtml modifiedData={modifiedData} setModifiedData={setModifiedData} />
                  </TabPane>
                </TabContent>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwo"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton />
            ) : (
              <Sidebar
                modifiedData={modifiedData}
                setModifiedData={setModifiedData}
                pages={pages}
                errors={errors}
                setErrors={setErrors}
              />
            )
          }
        />
      )}
    </Fragment>
  );
}

export default NewLandingPage;
