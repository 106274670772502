import React from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "./Button";
import { AiOutlineSave } from "react-icons/ai";
import { t } from "i18next";

function SaveAndClose({ submit, loading, color }) {
  const navigate = useNavigate();

  return (
    <>
      {/* <button onClick={submit} className="btn-white flex items-center gap-5px">
        {loading ? <ClipLoader color={color ? color : "#fff"} size={15} /> : <AiOutlineSave size={15} />}
        Save & Close
      </button> */}
      <Button
        buttons={[
          {
            name: "Save&Close",
            title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
            icon: <AiOutlineSave size={15} />,
            onClick: submit,
          },
        ]}
        loading={loading}
      />
    </>
  );
}

export default SaveAndClose;
