import { configureStore } from "@reduxjs/toolkit";
import {
  authApi,
  fieldsApi,
  reportsApi,
  stagesApi,
  triggersApi,
  pointsApi,
  focusItemsApi,
  dwcApi,
  pagesApi,
  assetsApi,
  categoriesApi,
  usersApi,
  webHooksApi,
  tagsApi,
  campaignsApi,
  companiesApi,
  contactsApi,
  segmentsApi,
  formsApi,
  emailsApi,
  widgetsApi,
  geographicApi,
  smsApi,
  serialLettersApi,
  multipleExportApi,
} from "./api";
import authSlice from "./features/authSlice";
import { batchDeleteApi } from "./api/batchDeleteApi";
import settingsSlice from "./features/settingsSlice";
import { settingsApi } from "./api/settingsApi";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [fieldsApi.reducerPath]: fieldsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [stagesApi.reducerPath]: stagesApi.reducer,
    [triggersApi.reducerPath]: triggersApi.reducer,
    [pointsApi.reducerPath]: pointsApi.reducer,
    [focusItemsApi.reducerPath]: focusItemsApi.reducer,
    [dwcApi.reducerPath]: dwcApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [batchDeleteApi.reducerPath]: batchDeleteApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [webHooksApi.reducerPath]: webHooksApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [segmentsApi.reducerPath]: segmentsApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [emailsApi.reducerPath]: emailsApi.reducer,
    [widgetsApi.reducerPath]: widgetsApi.reducer,
    [geographicApi.reducerPath]: geographicApi.reducer,
    [smsApi.reducerPath]: smsApi.reducer,
    [serialLettersApi.reducerPath]: serialLettersApi.reducer,
    [multipleExportApi.reducerPath]: multipleExportApi.reducer,
    auth: authSlice,
    settings: settingsSlice,
  },
  devTools: process.env.REACT_APP_NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      settingsApi.middleware,
      fieldsApi.middleware,
      reportsApi.middleware,
      stagesApi.middleware,
      triggersApi.middleware,
      pointsApi.middleware,
      focusItemsApi.middleware,
      dwcApi.middleware,
      pagesApi.middleware,
      assetsApi.middleware,
      categoriesApi.middleware,
      batchDeleteApi.middleware,
      usersApi.middleware,
      webHooksApi.middleware,
      tagsApi.middleware,
      campaignsApi.middleware,
      contactsApi.middleware,
      companiesApi.middleware,
      segmentsApi.middleware,
      formsApi.middleware,
      emailsApi.middleware,
      widgetsApi.middleware,
      geographicApi.middleware,
      smsApi.middleware,
      serialLettersApi.middleware,
      multipleExportApi.middleware,
    ]),
});
