import React from 'react';
import {Outlet} from 'react-router-dom';
import SideNavBar from '../Sidebar/Sidebar';
import Footer from '../footer';
import Header from '../../reusableComponents/Header/Header';
import {PrivateOutlet} from '../../utils/PrivateOutlet';

const MainLayout = () => {
    return (
        <div className='layout'>
            <div className='sidebar'>
                <SideNavBar/>
            </div>
            <div className='content'>
                <PrivateOutlet/>
                {/*<Footer/>*/}
            </div>
        </div>
    )
}

export default MainLayout;