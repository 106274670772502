import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import moment from "moment";

export const formsApi = createApi({
  reducerPath: "formsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Forms"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    //FORMS
    getForms: build.query({
      query: (q) =>
        `/api/forms?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Forms"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getForm: build.query({
      query: (id) => `/api/forms/${id}`,
      providesTags: (id) => [{ type: "Forms", id }],
      keepUnusedDataFor: false,
    }),
    getFormsFields: build.query({
      query: (q) => `/api/forms/fieldinfo?${qs.stringify(q)}`,
      keepUnusedDataFor: false,
      providesTags: ["Forms"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getFormsActions: build.query({
      query: (q) => `/api/forms/actioninfo?${qs.stringify(q)}`,
      keepUnusedDataFor: false,
      providesTags: ["Forms"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getResults: build.query({
      query: ({ id, q }) =>
        `/api/forms/results/${id}?${qs.stringify({
          ...q,
          ...{ page: q.pageIndex + 1, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Forms"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    updateForms: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/forms/${id}/edit`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["Forms"],
    }),
    updateFormsPatch: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/forms/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Forms"],
    }),
    deleteForm: build.mutation({
      query: (id) => ({
        url: `/api/forms/${id}/delete`,
        method: "DELETE",
      }),
    }),
    deleteResult: build.mutation({
      query: (id) => ({
        url: `/api/forms/results/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //ADD/EDIT/CLONE/VIEW
    addForms: build.mutation({
      query: (body) => ({
        url: `/api/forms/new`,
        method: "POST",
        body,
      }),
    }),
    getAddEditForms: build.query({
      queryFn: async ({ id, query }, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        if (id) {
          let formsById = await baseQuery({ url: `/api/forms/${id}` });
          let filedsActions = await baseQuery({
            url: `/api/forms/fieldsactions`,
          });
          let getResults = await baseQuery({
            url: `/api/forms/results/${id}?${qs.stringify({
              ...query,
              ...{ page: query.pageIndex + 1, limit: query.pageSize },
            })}`,
          });

          const forms = formsById?.data;
          const filedsAndActions = filedsActions?.data;
          const results = getResults?.data;

          const data = {
            forms,
            filedsAndActions,
            results,
          };

          return { data: data };
        } else {
          let filedsActions = await baseQuery({
            url: `/api/forms/fieldsactions`,
          });
          const filedsAndActions = filedsActions?.data;
          const data = {
            filedsAndActions,
          };
          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Forms", id }],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
    }),
    downloadForm: build.mutation({
      query: ({ id, format, filter, ...patch }) => ({
        url: `api/forms/results/${id}/export?format=${format}&${filter}`,
        method: "get",
        responseHandler: (response) => (format === "csv" ? response.text() : response.blob()),
      }),
    }),

    //VIEW
    getViewForm: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        if (id) {
          let formsById = await baseQuery({ url: `/api/forms/${id}` });
          let manualById = await baseQuery({
            url: `/api/forms/${id}/scripts/manual`,
          });
          let automaticById = await baseQuery({
            url: `/api/forms/${id}/scripts/automatic`,
          });

          const forms = formsById?.data;
          const manuals = manualById?.data;
          const automatics = automaticById?.data;

          const data = {
            forms,
            manuals,
            automatics,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Forms", id }],
    }),
    getFormExtras: build.query({
      query: ({ id, dateFrom, dateTo, query }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
          ...query,
          ...{ page: query.pageIndex + 1, limit: query.pageSize },
        });
        return `api/forms/${id}/extras?${params}`;
      },
      // providesTags: (result, error, { id }) => [{ type: 'Segments', id }],
    }),

    //FOLDERS
    //GET FOLDERS
    getFolderForms: build.query({
      query: (q) => `/api/folders?limit=300&where[0][col]=source&where[0][expr]=eq&where[0][val]=forms`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderForms: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderForms: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Forms"],
    }),
    //DELETE FOLDER
    deleteFolderForms: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateFormsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/forms/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Forms"],
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeFormsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/forms/${itemId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Forms"],
    }),
    //Get Segments for Select
    getSelectForms: build.query({
      query: (type) => `/api/forms?where[0][expr]=eq&where[0][col]=isPublished&where[0][val]=1`,
      providesTags: ["Forms"],
    }),
  }),
});

export const {
  useGetFormsQuery,
  useGetFormQuery,
  useUpdateFormsMutation,
  useUpdateFormsPatchMutation,
  useDeleteFormMutation,
  useDeleteResultMutation,
  useAddFormsMutation,
  useGetFormExtrasQuery,
  //GET SELECT SEGMENTS
  useGetSelectFormsQuery,
  //CRUD
  useGetAddEditFormsQuery,
  //VIEW
  useGetViewFormQuery,
  //FOLDERS
  useGetFolderFormsQuery,
  useUpdateFolderFormsMutation,
  useDeleteFolderFormsMutation,
  useAddFolderFormsMutation,
  useUpdateFormsToFoldersMutation,
  useRemoveFormsToFoldersMutation,
  useDownloadFormMutation,
  useGetFormsFieldsQuery,
} = formsApi;
