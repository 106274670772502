import React from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import { FaTrashAlt, FaRegClone } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { BsFillCameraFill } from "react-icons/bs";
import { GoDatabase } from "react-icons/go";
import Button from "./Button";
import { t } from "i18next";

function Cancel(props) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="flex items-center gap-5px">
      {props.loading && <ClipLoader size={14} />}
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <Button
          buttons={[
            {
              name: "close",
              title: !props.noText ? t("caymland.core.form.close") : undefined,
              icon: <FaRegTimesCircle size={15} />,
              onClick: () => {
                if (props.navigate) {
                  props.navigate();
                }
                if (props.submit) {
                  props.submit();
                }
              },
            },
          ]}
          loading={props.loading}
        />

        {props.showMore ? (
          <>
            <DropdownToggle className="no-padding-caret" caret color="white" style={{ padding: "0px 9px" }} />
            <DropdownMenu>
              {props.formsCancelButton && (
                <>
                  <Link to={`/form/preview/${props?.modifiedData?.id}`} target="_blank">
                    <DropdownItem className="d-flex items-center gap-5px">
                      <BsFillCameraFill size={13} />
                      <p style={{ fontSize: "12px", marginBottom: "0" }}>Preview</p>
                    </DropdownItem>
                  </Link>
                  <Link to={`/forms/results/${props?.modifiedData?.id}`}>
                    <DropdownItem className="d-flex items-center gap-5px">
                      <GoDatabase size={13} />
                      <p style={{ fontSize: "12px", marginBottom: "0" }}>Results</p>
                    </DropdownItem>
                  </Link>
                </>
              )}
              {props.reportDropDown && (
                <>
                  <DropdownItem
                    className="d-flex items-center gap-5px"
                    onClick={() => props.handleDownloadClick("csv")}
                  >
                    <i className="fa fa-file-text-o"></i>
                    <p style={{ fontSize: "12px", marginBottom: "0" }}>Export to CSV</p>
                  </DropdownItem>
                  <DropdownItem
                    className="d-flex items-center gap-5px"
                    onClick={() => props.handleDownloadClick("xlsx")}
                  >
                    <i className="fa fa-file-excel-o"></i>
                    {/* <FaTrashAlt size={13} color="#f86b4f" /> */}
                    <p style={{ fontSize: "12px", marginBottom: "0" }}>Export to Excel</p>
                  </DropdownItem>
                  <DropdownItem className="d-flex items-center gap-5px" onClick={props.navigateHTMLView}>
                    {/* <FaRegFileText  size={13} color="#f86b4f" /> */}
                    <i className="fa fa-file-code-o"></i>
                    <p style={{ fontSize: "12px", marginBottom: "0" }}>Export to HTML</p>
                  </DropdownItem>
                </>
              )}
              <DropdownItem className="d-flex items-center gap-5px" onClick={props.handleDelete}>
                <FaTrashAlt size={13} color="#f86b4f" />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>Delete</p>
              </DropdownItem>
            </DropdownMenu>
          </>
        ) : (
          <></>
        )}
      </ButtonDropdown>
    </div>
  );
}

export default Cancel;
