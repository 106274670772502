import React from 'react';
import { Checkbox, DatePicker, Select, Text, TextArea } from './Inputs';

const GenerateDynamicInputs = ({ filterKey, type, value, onChange, options, extraStyle, ...otherProps }) => {


    const optionsArray = Array.isArray(options) ? options : Object.entries(options || {}).map(([key, value]) => {
        return { value: key, label: value };
    });

    const getSelectedOptions = (type, optionsArray, value) => {
        if (type === 'multiselect') {
            const valueArray = Array.isArray(value) ? value : [];
            return optionsArray.filter(option => valueArray.includes(option.value));
        } else {
            return optionsArray.find(option => option.value === value);
        }
    };
    
    const handleSelectChange = (type, onChange, filterKey, selectedOptions) => {
        const newValue = type === 'multiselect'
            ? selectedOptions?.map(option => option.value)
            : selectedOptions.value;
        onChange(filterKey, 'value', newValue);
    };
    

    switch (type) {
        case 'select':
        case 'timezone':
        case 'multiselect':
            const selectedOptions = getSelectedOptions(type, optionsArray || [], value);
            return (
                <Select
                    options={optionsArray || []}
                    value={selectedOptions}
                    onChange={(selectedOptions) => handleSelectChange(type, onChange, filterKey, selectedOptions)}
                    isMulti={type === 'multiselect'}
                    extraStyle={{ margin: true }}
                    isClearable={false}
                    {...otherProps}
                />
            );
        case 'boolean':
        case 'bool':
        case 'checkbox':
            return (
                <div style={{alignSelf: extraStyle?.alignSelf}} onClick={() => onChange(filterKey, 'value', !value)}>
                <Checkbox
                    value={value}
                    // onChange={onChange(filterKey, 'value', !value)}
                    {...otherProps}
                />
                </div>
            );
        case 'date':
        case 'datetime':
            return (
                <DatePicker
                    value={value}
                    onChange={(date) => onChange(filterKey, 'value', date)}
                    extraStyle={{className:'p-0 smallDatePicker w-full px-10'}}
                    marginBottom={false}
                    {...otherProps}
                />
            );
        default:
            return (
                <Text
                    type={type === 'float' ? 'number' : type}
                    value={value}
                    onChange={(e) => onChange(filterKey, 'value', e.target.value)}
                    marginBottom={'m-0'}
                    {...otherProps}
                />
            );
    }
};

export default GenerateDynamicInputs;
