import { FaDownload } from "react-icons/fa";
import { Error } from "../../Alerts";
import { useExportDownloadMutation } from "../../../../redux/api/contactsApi";

const sourceFile = (props) => {
  const { file, dir, status, id } = props;

  const [exportDownload] = useExportDownloadMutation();

  const handleDownloadClick = async () => {
    if (status === 3 && file) {
      try {
        // Make the API call to get the file as a Blob
        const response = await exportDownload(id).unwrap();
        console.log(response);

        // Create a URL for the Blob object
        const blobUrl = window.URL.createObjectURL(new Blob([response]));

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", `${file}`); // Set the file name and extension

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Download failed:", error);
      }
    }
  };

  return (
    <div className="d-flex items-center justify-start">
      {status === 3 && dir && (
        <FaDownload onClick={handleDownloadClick} style={{ cursor: "pointer", marginRight: "5px" }} />
      )}
      <div className="table-links ml-2" onClick={handleDownloadClick}>
        {file}
      </div>
    </div>
  );
};

export default sourceFile;
