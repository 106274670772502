import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import Loading from "../loading";
import Button from "../Buttons/Button";
import { Modal } from "react-bootstrap";
import SidebarSkeleton from "../SidebarSkeleton";
import DetailsSkeleton from "../DetailsSkeleton";
import { Title } from "chart.js";
import { t } from "i18next";

const CustomModal = ({ modalOptions, handleSubmit, saveText, close, disabled, content, closeModal }) => {
  const { isOpen, title, icon, editData, loading } = modalOptions;

  useEffect(() => {
    // Lock body scroll and touch action when modal is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.touchAction = "auto";
    }

    // Clean up
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.touchAction = "auto";
    };
  }, [isOpen]);

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: () => close(),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <TbDeviceFloppy size={15} />,
      onClick: () => handleSubmit(editData ? "edit" : "add"),
    },
  ];

  return (
    <>
      <Modal show={modalOptions.isOpen} onHide={close}>
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              padding: "4px",
              fontSize: "18px",
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "16px" }}>
          {loading ? (
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            content
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
    </>
   
  );
};

export default CustomModal;
