import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";
import moment from "moment";

export const widgetsApi = createApi({
  reducerPath: "widgetsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Widgets"],
  endpoints: (build) => ({
    getListTemplates: build.query({
      query: () => `/api/widgets/template`,
      providesTags: [{ type: "Widgets" }],
    }),
    getTemplateData: build.query({
      query: ({ template, dateFrom, dateTo }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        });
        if (template) return `api/widgets/template/data/${template}?${params}`;
        else if (params) return `api/widgets/template/data?${params}`;
      },
      providesTags: [{ type: "Widgets" }],
    }),
    addNewWidgets: build.mutation({
      query: (body) => ({
        url: `/api/widgets/new`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "Widgets", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    editWidgets: build.mutation({
      query: ({ currentWidgetId, ...widget }) => ({
        url: `api/widgets/${currentWidgetId}/edit`,
        method: "PATCH",
        body: widget,
      }),
      invalidatesTags: ({ currentWidgetId }) => [{ type: "Widgets", id: currentWidgetId }],
    }),
    getWidgetByType: build.query({
      query: ({ type, params = {} }) => {
        const queryString = qs.stringify(params);
        return `api/widgets/data/${type}?${queryString}`;
      },
      providesTags: [{ type: "Widgets" }],
    }),
    getAllWidgets: build.query({
      query: () => `api/widgets/data`,
      providesTags: [{ type: "Widgets" }],
    }),
    getWidgetsOptions: build.query({
      query: (type) => `api/widgets/options/${type}`,
      providesTags: [{ type: "Widgets" }],
    }),
    saveWidgetTemplate: build.mutation({
      query: (template) => ({
        url: `api/widgets/template/edit`,
        method: "PUT",
        body: template,
      }),
      invalidatesTags: [{ type: "Widgets", id: "LIST" }],
    }),
    getWidgetById: build.query({
      query: (widgetId) => `api/widgets/${widgetId}`,
      providesTags: (result, error, widgetId) => [{ type: "Widgets", id: widgetId }],
    }),
    deleteTemplate: build.mutation({
      query: (id) => ({
        url: `api/widgets/template/delete?id=${id}`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Widgets", id }],
    }),
  }),
});

export const {
  useGetListTemplatesQuery,
  useGetTemplateDataQuery,
  useAddNewWidgetsMutation,
  useGetWidgetByTypeQuery,
  useEditWidgetsMutation,
  useGetAllWidgetsQuery,
  useGetWidgetsOptionsQuery,
  useSaveWidgetTemplateMutation,
  useGetWidgetByIdQuery,
  useDeleteTemplateMutation,
} = widgetsApi;
