import moment from "moment";
import React, { Fragment, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { useTogglePublicPreviewMutation } from "../../../../../redux/api/emailsApi";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

function Sidebar({ email, extras, setEmail }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [togglePublicPreview] = useTogglePublicPreviewMutation();
  const handlePublicToggle = async () => {
    try {
      setLoading(true);
      const response = await togglePublicPreview(email?.id);
      if (!response.error) {
        // Create a new object with the updated publicPreview property
        const updatedEmail = { ...email, publicPreview: !email.publicPreview };
        setEmail(updatedEmail);
        // Use the updatedEmail object instead of modifying the original email
        // You can also update the state or Redux store with this updated email object
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            padding: "20px 19px",
            fontSize: "15px",
            fontWeight: "600",
            width: "100%",
          }}
        >
          {t("caymland.email.preview.url")}
        </div>
        <div className="input-container ic1 flex px-20">
          {/* <div className="publish-button">
            {loading ? (
              <ClipLoader size={12} color="#939393" />
            ) : (
              <label className="switch-published" style={{ marginBottom: "0" }}>
                <input type="checkbox" checked={email?.publicPreview} onChange={handlePublicToggle} />
                <span className="slider-published round-published"></span>
              </label>
            )}
          </div> */}
          <input
            id="download-url"
            style={{
              borderRadius: "6px 0 0 6px",
              background: "#f4f4f4",
              padding: "0 10px",
            }}
            className="jump-input cursor-disabled"
            type="text"
            disabled
            value={`/email/preview/${email?.id}`}
            placeholder=" "
          />
          <a rel="noreferrer" href={`/email/preview/${email?.id}`} target="_blank" className="download-button">
            <BiLinkExternal color="#333" />
          </a>
        </div>
        <div
          style={{
            padding: "20px 19px",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          {t("caymland.email.testing.url")}
        </div>
        <div className="input-container ic1 flex px-20">
          <input
            id="download-url"
            style={{
              borderRadius: "6px 0 0 6px",
              background: "#f4f4f4",
              padding: "0 10px",
            }}
            className="jump-input cursor-disabled"
            type="text"
            disabled
            value={`/email/testing/${email?.id}`}
            placeholder=" "
          />
          <div className="download-button">
            <BiLinkExternal
              color="#333"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/email/testing/${email?.id}`);
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "0px 19px 15px 19px",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {t("caymland.core.recent.activity")}
      </div>
      <div className="card-body px-20">
        <div className="activity">
          {extras?.recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{
                  marginRight: "15px",
                  background: activity.action === "create" ? "limegreen" : "#e9e7ed",
                }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity.action === "create"
                    ? t("caymland.core.create.by.past.tense")
                    : t("caymland.core.update.by.past.tense")}{" "}
                  <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{moment(activity.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Sidebar;
