export const retrieveUsers = (inputValue, bundle, trigger = null, includeGlobal = true) =>
  new Promise(async (resolve, reject) => {
    if (typeof trigger !== "function" || trigger === null) {
      resolve([]);
      return;
    }

    const val = [];

    if (bundle) {
      val.push({ expr: "eq", col: "bundle", val: bundle });
    }

    if (includeGlobal) {
      val.push({ expr: "eq", col: "bundle", val: "global" });
    }
    const query = {};

    if (inputValue.length) {
      query.search = inputValue;
    }
    const response = await trigger(query);
    if (!response.error) {
      const ownersData = response.data?.data?.filter((owner) => owner?.isPublished);

      const ownersArray = ownersData?.map((owners) => ({
        label: owners?.firstName + " " + owners?.lastName,
        value: owners?.id,
      }));
      resolve(ownersArray);
    }
  });
