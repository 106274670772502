import React, { Fragment, useEffect, useState } from 'react'
import Details from './DetailsAndFilters/Details'
import Filters from './DetailsAndFilters/Filters'
import CustomizedAccordions from '../../../reusableComponents/Accordion/Accordion';
import Description from '../../../reusableComponents/Description/Description'
import { t } from 'i18next';

const parseResponseData = (response) => {
  const filterDefinitions = response.data.filters.filterDefinitions;
  const parseData = (data) => {
    const options = [];
    Object.entries(data).forEach((label) => {
      options.push({
        options: label[1],
      });
    });
    return options;
  }
  return {
    filterDefinitionsOptions: parseData(filterDefinitions),
  }
};

function DetailsAndFilters({ report, updateFilters, handleDateChange }) {

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details report={report} />
    },
  ];
  const accordionFilters = [
    {
      name: t("caymland.core.filters"),
      component: <Filters updateFilters={updateFilters} handleDateChange={handleDateChange} report={report} 
      parseResponseData={parseResponseData} />,
      isOpen: true
    }
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={report} />,
    },
  ];

  return (

    report && (
      <Fragment>
        <CustomizedAccordions accordions={accordions} />
                {report && report?.description ? (
                  <CustomizedAccordions accordions={accordionsDesc} isOpen={true} />
                ) : null}

                <CustomizedAccordions accordions={accordionFilters}   isOpen={true}/>
      </Fragment>
    )

  )
}

export default DetailsAndFilters