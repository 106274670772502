import React from "react";

const Wrapper = ({ childrenOne, childrenTwo, childrenClassOne, childrenClassTwo, style }) => {
  return (
    <div className="wrapper-container flex items-start w-full">
      {childrenOne && (
        <div className={`wrapper-child ${childrenClassOne}`} style={style}>
          {childrenOne}
        </div>
      )}
      {childrenTwo && <div className={`${childrenClassTwo} wrapper-child`}>{childrenTwo}</div>}
    </div>
  );
};

export default Wrapper;
