import React from "react";
import { FaRegTimesCircle, FaUpload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Text } from "../../reusableComponents/Inputs";
import Button from "../Buttons/Button";
import Header from "../../reusableComponents/Header/Header";
import { useTranslation } from "react-i18next";

const Import = ({
  handelUpload,
  handleFileInputChange,
  selectedFile,
  modifiedData,
  setModifiedData,
  loading,
  isContactsView,
  isCompaniesView,
}) => {
  const { t } = useTranslation();
  const handleDelimiterInputChange = (event) => {
    const value = event.target.value;

    setModifiedData((prevData) => ({
      ...prevData,
      properties: {
        ...prevData.properties,
        parser: {
          ...prevData.properties.parser,
          delimiter: value,
        },
      },
    }));
  };

  const navigate = useNavigate();

  const ImportHistorytLink = () => {
    if (isContactsView) {
      navigate("/contacts/import");
    } else if (isCompaniesView) {
      navigate("/companies/import/history");
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => ImportHistorytLink(),
    },
  ];

  return (
    <>
      <Header
        buttons={buttons}
        title={`${t("caymland.lead.lead.import")} ${
          isContactsView ? t("caymland.lead.leads") : t("caymland.hubspot.object.company")
        }`}
        loading={loading}
      />

      <div className="d-flex justify-center">
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="panel-title">{t("caymland.lead.import.start.instructions")}</div>
          </div>
          <div className="panel-body">
            <form>
              <div className="input-group well mt-5">
                <input
                  type="file"
                  id="lead_import_file"
                  name="lead_import[file]"
                  required
                  accept=".csv"
                  className="form-control"
                  autoComplete="off"
                  onChange={handleFileInputChange}
                />

                <span className="input-group-btn">
                  <Button
                    buttons={[
                      {
                        name: "upload",
                        title: t("caymland.lead.import.upload"),
                        icon: <FaUpload size={15} />,
                        onClick: () => handelUpload(selectedFile),
                      },
                    ]}
                    loading={loading}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-12">
                  <Text
                    name="Delimiter"
                    value={modifiedData?.properties?.parser?.delimiter}
                    onChange={handleDelimiterInputChange}
                    label={t("caymland.lead.import.delimiter")}
                    required={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Import;
