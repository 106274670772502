import { SelectField } from "./contentFields/selectField";
import { TextEditor } from "./contentFields/textEditor";
import { TextField } from "./contentFields/textField";
import { ToggleField } from "./contentFields/toggleField";

export const ContentGenerator = ({ data, setEntity, entity, modalOptions }) => {
  const choiceGenerator = (data) => {
    if (data?.choices[0]?.label?.toLowerCase() === "no") {
      return (
        <ToggleField
          label={data.label}
          name={data.name}
          setEntity={setEntity}
          entity={entity}
          required={data.required}
        />
      );
    } else {
      return (
        <SelectField
          options={data.choices}
          label={data.label}
          name={data.name}
          value=""
          setEntity={setEntity}
          entity={entity}
          modalOptions={modalOptions}
          multiple={data.multiple}
          required={data.required}
          tooltip={data.attr.tooltip}
        />
      );
    }
  };

  const generateProperties = (data) => {
    let newData = Object.entries(data).map((x) => ({
      label: x[0],
      value: x[1],
    }));

    return newData.map((x) => {
      if (x.label !== "label" && x.label !== "name" && x.label !== "required" && x.label !== "attr") {
        let { required, label, name, choices } = x.value;
        if (x.label === "message") {
          return (
            <div className="w-full small-editor" style={{ marginBottom: "20px" }}>
              <TextEditor
                fullEditor={true}
                required={required}
                label={label}
                name={name}
                setEntity={setEntity}
                entity={entity}
              />
            </div>
          );
        }
        if (choices) {
          return choiceGenerator(x.value);
        } else {
          return (
            <TextField
              required={required}
              label={label}
              name={name}
              value=""
              setEntity={setEntity}
              entity={entity}
              property={true}
            />
          );
        }
      }
      return null;
    });
  };

  const generator = (data) => {
    const { label, name, required } = data.value;
    switch (data.value.childType) {
      case "textarea":
        return (
          <div className="w-full small-editor" style={{ marginBottom: "20px" }}>
            <TextEditor required={required} label={label} name={name} setEntity={setEntity} entity={entity} />
          </div>
        );
      case "choice":
      case "yesno_button_group":
      case "emailsend_list":
        return generateProperties(data);
      default:
        return (
          <div style={{ marginTop: "10px" }}>
            {label !== "" &&
              name !== "previewEmailButton" &&
              name !== "editEmailButton" &&
              name !== "newEmailButton" && (
                <TextField required={required} label={label} name={name} setEntity={setEntity} entity={entity} />
              )}
          </div>
        );
    }
  };
  return data.map((x, i) => <div key={i}>{generator(x)}</div>);
};
