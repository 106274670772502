import React, { Fragment, useEffect, useState } from "react";
import { Checkbox, Text, Select, TimePicker } from "../../../reusableComponents/Inputs";
import EmailIcon from "@mui/icons-material/Email";
import { useGetAllScheduleQuery, useGetScheduleQuery } from "../../../../redux/api/reportsApi";
import { t } from "i18next";

function Schedule({ modifiedData, setModifiedData }) {
  const [scheduleUnit, setScheduleUnit] = useState([]);
  const [scheduleDay, setScheduleDay] = useState([]);
  const [scheduleMonthFrequency, setScheduleMonthFrequency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduleQueryArgs, setScheduleQueryArgs] = useState(null);

  const { data: datesReport, isLoading } = useGetScheduleQuery(scheduleQueryArgs, { skip: !scheduleQueryArgs });
  const { data: allSchedule } = useGetAllScheduleQuery();

  useEffect(() => {
    if (allSchedule) {
      handleSchedule();
    }
  }, [allSchedule]);

  const handleSchedule = async () => {
    setLoading(true);

    try {
      function mapDataToOptions(data) {
        return Object.entries(data).map(([key, label]) => ({
          label: label,
          value: key,
        }));
      }

      if (!allSchedule?.error) {
        const scheduleDay = allSchedule ? mapDataToOptions(allSchedule?.scheduleDay) : [];
        setScheduleDay(scheduleDay);

        const scheduleUnit = allSchedule ? mapDataToOptions(allSchedule?.scheduleUnit) : [];
        setScheduleUnit(scheduleUnit);

        const scheduleMonthFrequency = allSchedule ? mapDataToOptions(allSchedule?.scheduleMonthFrequency) : [];
        setScheduleMonthFrequency(scheduleMonthFrequency);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const listDatesReport = async () => {
    try {
      const argumentsArray = [
        modifiedData?.isScheduled ? "1" : "0",
        modifiedData?.scheduleUnit?.value || modifiedData?.scheduleUnit,
        modifiedData?.scheduleDay?.value || modifiedData?.scheduleDay,
        modifiedData?.scheduleMonthFrequency?.value || modifiedData?.scheduleMonthFrequency,
        modifiedData?.scheduleTime,
      ].filter((arg) => arg !== undefined && arg !== null);
      setScheduleQueryArgs(argumentsArray.join("/"));

      // const response = useGetScheduleQuery(argumentsArray.join("/"))
      // setDatesReport(response);
    } catch (e) {}
  };

  useEffect(() => {
    listDatesReport();
  }, [modifiedData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    let updatedValue = checked;

    if (name === "scheduleFormat") {
      updatedValue = checked ? "csv" : "xls";

      setModifiedData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
      }));
    } else if (type === "text")
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedTime = dateTime.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  // const defaultTime = {
  //   scheduleTime: new Date().setHours(8, 0, 0) // Set default time to 08:00
  // };

  return (
    <Fragment>
      <div className="flex gap-20">
        <Checkbox
          label={t("caymland.report.schedule.isScheduled")}
          name="isScheduled"
          value={modifiedData?.isScheduled}
          onChange={(e) => handleInputChange(e)}
        />
        {modifiedData?.isScheduled && (
          <Checkbox
            label={t("caymland.report.schedule.format")}
            name="scheduleFormat"
            value={modifiedData?.scheduleFormat === "csv"}
            onChange={(e) => handleInputChange(e)}
            toggleType="switch-toggleExport"
            innerLabel={{ trueLabel: t("caymland.core.export.csv"), falseLabel: t("caymland.core.export.xls") }}
          />
        )}
      </div>
      {modifiedData?.isScheduled && !isLoading && (
        <div className="flex mt-10">
          <div className="w-50" style={{ marginRight: "30px" }}>
            <div className="input-group flex" style={{ flexWrap: "nowrap" }}>
              <div className="w-100">
                <Text
                  label={`${t("caymland.report.schedule.toAddress.label")} ${t("caymland.lead.field.address")}`}
                  name={"toAddress"}
                  placeholder={t("caymland.lead.field.address")}
                  value={modifiedData?.toAddress}
                  onChange={(e) => handleInputChange(e)}
                  tooltip={t("caymland.mailbox.message.emails.tooltip")}
                  iconStart={<EmailIcon sx={{ fontSize: 18 }} />}
                />
              </div>
            </div>
            <Select
              name="scheduleUnit"
              label={t("caymland.report.schedule.every")}
              options={scheduleUnit}
              value={modifiedData?.scheduleUnit}
              // onChange={(e) => setModifiedData({ ...modifiedData, scheduleUnit: e?.value ?? null })}
              onChange={(e) => {
                setModifiedData({ ...modifiedData, scheduleUnit: e?.value ?? null });
              }}
            />
            {(modifiedData?.scheduleUnit === "MONTHLY" || modifiedData?.scheduleUnit?.value === "MONTHLY") && (
              <Select
                name="scheduleUnit"
                label={t("caymland.report.schedule.every")}
                options={scheduleUnit}
                value={modifiedData?.scheduleUnit}
                onChange={(e) => {
                  setModifiedData({ ...modifiedData, scheduleUnit: e?.value ?? null });
                }}
              />
            )}
            {(modifiedData?.scheduleUnit === "MONTHLY" || modifiedData?.scheduleUnit?.value === "MONTHLY") && (
              <Select
                name="scheduleMonthFrequency"
                label={t("caymland.report.schedule.month_frequency")}
                options={scheduleMonthFrequency}
                value={modifiedData?.scheduleMonthFrequency ?? null}
                onChange={(e) => setModifiedData({ ...modifiedData, scheduleMonthFrequency: e ?? null })}
              />
            )}
            {(modifiedData?.scheduleUnit?.value === "WEEKLY" ||
              modifiedData?.scheduleUnit?.value === "MONTHLY" ||
              modifiedData?.scheduleUnit === "WEEKLY" ||
              modifiedData?.scheduleUnit === "MONTHLY") && (
              <Select
                name="scheduleDay"
                label={t("caymland.report.schedule.day")}
                options={scheduleDay ?? []}
                value={modifiedData?.scheduleDay ?? null}
                onChange={(e) => setModifiedData({ ...modifiedData, scheduleDay: e ?? null })}
              />
            )}

            <>
              <div className="w-full"></div>
              <div>
                <TimePicker
                  label={t("caymland.report.schedule.time")}
                  name="reportTime"
                  tooltip={t("caymland.report.schedule.time.tooltip")}
                  value={modifiedData?.scheduleTime}
                  onChange={(timeString) => {
                    setModifiedData({ ...modifiedData, scheduleTime: timeString });
                  }}
                />
              </div>
            </>
          </div>
          <div
            className="w-50 p-10"
            style={{
              fontSize: "12px",
              marginLeft: "30px",
              backgroundColor: "rgba(222, 222, 222, 0.2)",
              border: "1px solid #ccc",
              borderRadius: "3px",
              color: "darkgray",
            }}
          >
            <strong>{datesReport?.datesMessage}</strong>
            <div style={{ marginTop: "30px" }}>
              {datesReport?.dates.map((item, i) => (
                <p className="mb-0" key={i} style={{ fontSize: "12px", lineHeight: "1.3" }}>
                  {formatDateTime(item).replace(/\//g, ".")}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Schedule;
