import React, { useEffect, useRef, useState } from "react";
import { Select } from "../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../reusableComponents/retriveCategoriesByBundle";
import { categoriesApi } from "../../../../redux/api";
import { useAddContactCategoryMutation } from "../../../../redux/api/contactsApi";
import { useParams } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ModalGenerator from "../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import { useAddCategoryMutation } from "../../../../redux/api/categoriesApi";
import { FaRegTimesCircle } from "react-icons/fa";

const Categories = ({ replaceCategories, categoriesFields, setShow, setMessage, setBackgroundToast }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState();
  const [editCategories, setEditCategories] = useState([]);
  const [hover, setHover] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "global",
  });
  const [newItem, setNewItem] = useState();
  const [addCategory] = useAddCategoryMutation();
  const editRef = useRef(null);

  const { id } = useParams();

  const [triggerCategories] = categoriesApi.useLazyGetCategoriesQuery();
  const [updateContact] = useAddContactCategoryMutation();

  useEffect(() => {
    if (Array.isArray(categoriesFields) && categoriesFields.length > 0) {
      const formattedCategories = categoriesFields?.map((category) => ({
        title: category.title,
        id: parseInt(category.id),
        color: category.color,
      }));
      setEditCategories(formattedCategories);
    }
  }, [categoriesFields]);

  useEffect(() => {
    if (newItem) {
      // Assume newItem has the same structure as items in editCategories
      // Create a new array that includes all items from editCategories plus the new item
      const updatedCategories = [...editCategories, newItem];
      setEditCategories(updatedCategories);
    }
  }, [newItem]);
  const handleChange = (selectedOptions) => {
    // Check if 'create_new' option is selected in the array of selected options.
    if (selectedOptions.some((option) => option.id === "create_new")) {
      // Open the modal by updating modalOptions state
      setModalOptions({
        ...modalOptions,
        isOpen: true,
        // Add more modal options as needed
      });
    } else {
      // Update categories for other selections
      setEditCategories(selectedOptions);
    }
  };

  const updateCategory = async () => {
    const categories = editCategories?.map((category) => category.id) || [];
    try {
      const payload = { categories };
      const response = await updateContact({ id, body: payload });
      if (!response.errors) {
        replaceCategories(editCategories);
        setEdit(false);
        setShow(true);
        setMessage(t("caymland.core.setmessage.categories"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      console.error("Error updating stage:", error);
    }
  };
  const isWhite = (str) => {
    const whiteLimit = 200;
    let r = parseInt("0x" + str?.substring(1, 3));
    let g = parseInt("0x" + str?.substring(3, 5));
    let b = parseInt("0x" + str?.substring(5, 7));
    if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
      return false;
    }
    return true;
  };

  return (
    <div
      ref={editRef}
      className={`cursor-pointer flex items-center mt-10 gap-5px w-50 pr-10 `}
      onClick={() => setEdit(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
        type="contacts"
      />
      <div style={{ padding: "0", display: "block" }}>
        <span style={{ marginBottom: "0" }}>{t("caymland.lead.preferred.categories")}: </span>
      </div>
      {edit ? (
        <div className="d-flex  gap-5px w-full ">
          <Select
            isMulti
            isSearchable
            margin
            className="w-full"
            bundle="contacts"
            options={retrieveCategories}
            value={editCategories}
            onChange={handleChange}
            trigger={triggerCategories}
            labelField="title"
            valueField="id"
            isCreatable
          />
          <group className="flex gap-5px">
            {" "}
            <button
              className="normal-btn"
              onClick={(e) => {
                e.stopPropagation(), setEdit(false), setEditCategories(categoriesFields);
              }}
            >
              <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
            </button>
            <button className="normal-btn" onClick={updateCategory}>
              <AiOutlineSave color="#135F95" size={20} title="Save" />
            </button>
          </group>
        </div>
      ) : (
        <div className="flex items-center  w-full" style={{ display: "flex", flexWrap: "wrap" }}>
          <>
            {categoriesFields?.length > 0 &&
              categoriesFields?.map((category) => (
                <>
                  <p
                    className="badge badge-dark tag-pills-sm-mb gap-5px"
                    style={{
                      margin: "2px",
                      marginLeft: "5px",
                      fontSize: "0.75",
                      fontWeight: "400",
                      backgroundColor: isWhite(category?.color) ? "black" : `${category?.color}`,
                    }}
                  >
                    {category?.title}
                  </p>
                </>
              ))}
            <div style={{ transform: "translateX(10px)" }}></div>
          </>
          {hover && !edit && <Edit fontSize="small" style={{ marginLeft: "auto" }} />} {/* Render edit icon on hover */}
        </div>
      )}
    </div>
  );
};

export default Categories;
