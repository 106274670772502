import { t } from "i18next";

export const CategoryComponent = ({ data }) => {
  return data.category ? (
    <div className="flex items-center gap-5px p-no-margin">
      <span
        className="category-box-shadow"
        style={{
          width: "10px",
          height: "10px",
          background: `${data?.category?.color}`,
        }}
      ></span>
      <p>{data?.category?.title}</p>
    </div>
  ) : (
    t("caymland.core.form.uncategorized")
  );
};
