import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Pagination from "../../reusableComponents/Pagination";
import NoResultsFound from "../noResultsFound/NoResultsFound";
import { t } from "i18next";

export default function AgGridTable(props) {
  const gridRef = useRef();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsFrom, setRowsFrom] = useState(1);
  const [rowsTo, setRowsTo] = useState(10);
  const [gridApi, setGridApi] = useState(null);

  const location = useLocation();
  const isAssetsView = location.pathname.includes("/assets/view");
  const isSegmentView = location.pathname.includes("/segments/view");
  const isDWCView = location.pathname.includes("/dwc/view");
  const isFormsView = location.pathname.includes("/forms/view");
  const isCampaignsView = location.pathname.includes("/campaigns/view");
  const isEmailView = location.pathname.includes("/emails/view");
  const isReportView = location.pathname.includes("/reports/view");
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  useEffect(() => {
    setTotalPageCount(Math.ceil(props.totalLength / rowsPerPage));
  }, [props?.loading, props?.propsLoading, props?.data?.length]);

  const firstPage = () => {
    props.handlePageChange(1);
    setRowsFrom(1);
    setRowsTo(rowsPerPage);
    setPageCount(1);
  };

  const lastPage = () => {
    if (rowsPerPage >= props.totalLength) {
      return;
    } else {
      setRowsFrom(props.totalLength - rowsPerPage / rowsPerPage - 7);
      let rowsToValue = props.totalLength - rowsPerPage / rowsPerPage - 7 + rowsPerPage;
      setRowsTo(rowsToValue);
      setPageCount(totalPageCount);
      props.handlePageChange(totalPageCount);
    }
  };

  const previousPage = () => {
    let newPage = props.page - 1;
    if (props.page <= 1) {
    } else {
      props.handlePageChange(newPage);
      setRowsFrom((rowsFrom) => rowsFrom - rowsPerPage);
      setRowsTo((rowsTo) => rowsTo - rowsPerPage);
      setPageCount((pageCount) => pageCount - 1);
    }
  };

  const nextPage = () => {
    if (pageCount < totalPageCount) {
      let newPage = props.page + 1;
      setRowsFrom((rowsFrom) => rowsFrom + rowsPerPage);
      setRowsTo((rowsTo) => rowsTo + rowsPerPage);
      setPageCount((pageCount) => pageCount + 1);
      props.handlePageChange(newPage);
    }
  };

  const onPageSizeChanged = useCallback(() => {
    var value = Number(document.getElementById("page-size").value);
    setRowsFrom(1);
    setRowsPerPage(value);
    setRowsTo(value);
    setPageCount(1);
    props.handlePerRowsChange(value, 1);
    props.handlePageChange(1);
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const resizeGrid = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  useEffect(() => {
    console.log(gridRef?.current?.columnApi?.columnModel?.bodyWidth);
    resizeGrid();
    window.addEventListener("resize", resizeGrid);
    return () => {
      window.removeEventListener("resize", resizeGrid);
    };
  }, [resizeGrid]);

  useEffect(() => {
    if (gridApi) {
      props.loading || props.propsLoading ? gridApi.showLoadingOverlay() : gridApi.hideOverlay();
    }
  }, [gridApi, props.loading, props.propsLoading]);

  const getRowStyle = (params) => {
    const doNotContact = params.data[1]?.doNotContact?.length;
    if (doNotContact === 0) {
      return null;
    } else if (doNotContact > 0) {
      return { background: "#f8d7da" };
    }
  };

  return (
    <div style={containerStyle}>
      <div className="outer-div">
        <div className="grid-wrapper">
          <div
            className="ag-theme-alpine"
            style={{
              height: props.heightTable,
              // props.autoHeight && !props.customHeight
              //   ? ""
              //   : props.autoHeight && props.customHeight && !props.noPercentage
              //   ? "80%"
              //   : props.autoHeight && props.customHeight && props.noPercentage
              //   ? "420px"
              //   : "calc(100vh - 390px)",
              minHeight: props.heightTable,
            }}
          >
            {!isAssetsView && !isSegmentView && !isDWCView && !isFormsView && !isCampaignsView && !isEmailView ? (
              <>
                <AgGridReact
                  onGridReady={onGridReady}
                  ref={gridRef}
                  rowData={props.data}
                  columnDefs={props.columnDefs}
                  defaultColDef={props.defaultColDef}
                  animateRows={true}
                  rowDragManaged={true}
                  rowSelection="multiple"
                  className={props.cls}
                  overlayLoadingTemplate={`<span className="ag-overlay-loading-center">Loading...</span>`}
                  suppressRowClickSelection={true}
                  domLayout={props.autoHeight && !props.customHeight ? "autoHeight" : ""}
                  // groupDefaultExpanded={1}
                  groupSelectsChildren={true}
                  rowHeight={props.rowHeight}
                  onSelectionChanged={props.onSelectedChanged}
                  getRowStyle={getRowStyle}
                  onFirstDataRendered={resizeGrid}
                />
                <Pagination
                  totalPageCount={totalPageCount}
                  onPageSizeChanged={onPageSizeChanged}
                  rowsFrom={rowsFrom}
                  rowsTo={rowsTo}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  firstPage={firstPage}
                  lastPage={lastPage}
                  pageCount={pageCount}
                  handlePageChange={props.handlePageChange}
                  handlhandlePerRowsChange={props.handlePerRowsChange}
                  page={props.page}
                  rowsPerPage={props.rowsPerPage}
                  totalLength={props.totalLength}
                  paginationClass={props.paginationClass}
                />
              </>
            ) : props.data.length > 0 &&
              (isAssetsView || isSegmentView || isDWCView || isFormsView || isCampaignsView || isEmailView) ? (
              <>
                <AgGridReact
                  onGridReady={onGridReady}
                  ref={gridRef}
                  rowData={props.data}
                  columnDefs={props.columnDefs}
                  defaultColDef={props.defaultColDef}
                  animateRows={true}
                  rowDragManaged={true}
                  rowSelection="multiple"
                  className={props.cls}
                  overlayLoadingTemplate={`<span className="ag-overlay-loading-center">Loading...</span>`}
                  suppressRowClickSelection={true}
                  domLayout={props.autoHeight && !props.customHeight ? "autoHeight" : ""}
                  groupDefaultExpanded={1}
                  groupSelectsChildren={true}
                  rowHeight={props.rowHeight}
                  onSelectionChanged={props.onSelectedChanged}
                  getRowStyle={getRowStyle}
                />
                <Pagination
                  totalPageCount={totalPageCount}
                  onPageSizeChanged={onPageSizeChanged}
                  rowsFrom={rowsFrom}
                  rowsTo={rowsTo}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  firstPage={firstPage}
                  lastPage={lastPage}
                  pageCount={pageCount}
                  handlePageChange={props.handlePageChange}
                  handlhandlePerRowsChange={props.handlePerRowsChange}
                  page={props.page}
                  rowsPerPage={props.rowsPerPage}
                  totalLength={props.totalLength}
                />
              </>
            ) : props.data.length <= 0 &&
              (isAssetsView || isSegmentView || isDWCView || isFormsView || isCampaignsView || isEmailView) ? (
              <div className="wrapper-contact">
                <div className="no-contact">
                  <h3>{t("caymland.lead.grid.noresults.header")}</h3>
                  <span>{t("caymland.lead.grid.noresults.message")}</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
