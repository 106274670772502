import React, { useState, useEffect } from "react";
import Import from "./Import";
import { SlideAlert } from "../Alerts";
import ImportedRender from "./ImportedRender";
import { useLocation } from "react-router-dom";
import { useImportCompanyMutation } from "../../../redux/api/companiesApi";
import { useGetCompanyFieldsQuery, useGetContactFieldsQuery } from "../../../redux/api/fieldsApi";
import { t } from "i18next";

const Main = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [headers, setHeaders] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [show, setShow] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");
  const [message, setMessage] = useState("");
  const [fields, setFields] = useState();
  const [modifiedData, setModifiedData] = useState({
    object: null,
    dir: null,
    file: null,
    originalFile: null,
    properties: {
      parser: {
        delimiter: ";",
        enclosure: '"',
        escape: "\\",
        batchlimit: 100,
      },
      headers: [],
      defaults: {
        owner: null,
        list: null,
        tags: [],
        categories: [],
      },
      lines: null,
      fields: {},
    },
  });
  console.log(modifiedData);
  const isContactsView = location.pathname.includes("/contacts/import");
  const isCompaniesView = location.pathname.includes("/companies/import");

  const [importCompany] = useImportCompanyMutation();

  const { data: contactFields } = useGetContactFieldsQuery();

  const { data: companyFields } = useGetCompanyFieldsQuery();

  useEffect(() => {
    if (isContactsView) {
      setRoute("lead");
      // setModifiedData((prevData) => ({
      //   ...prevData,
      //   object: 'lead'
      // }));
    } else if (isCompaniesView) {
      setRoute("company");
      // setModifiedData((prevData) => ({
      //   ...prevData,
      //   object: 'company'
      // }));
    }
  }, []);

  useEffect(() => {
    if (isCompaniesView && companyFields?.data) {
      let filteredResponse = Object.entries(companyFields.data).map((x) => x[1]);
      setFields(filteredResponse);
    } else if (isContactsView) {
      let combinedData = [];

      if (companyFields?.data) {
        combinedData = combinedData.concat(Object.entries(companyFields.data).map((x) => x[1]));
      }

      if (contactFields?.data) {
        combinedData = combinedData.concat(Object.entries(contactFields.data).map((x) => x[1]));
      }

      setFields(combinedData);
    }
  }, [companyFields, contactFields]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const contents = e.target.result;
        const lines = contents.split("\n");

        let numberOfRows = lines.length - 1;
        if (lines[lines.length - 1].trim() === "") {
          numberOfRows -= 1;
        }

        if (numberOfRows > 0) {
          const headers = lines[0].split(";").map((header) => header.replace(/"/g, "").replace(/_/g, " ").trim());
          const headersString = headers.join(",");

          setModifiedData((prevData) => ({
            ...prevData,
            object: route,
            properties: {
              ...prevData.properties,
              headers: headers,
              lines: numberOfRows,
            },
          }));
        }
      };

      reader.readAsText(file);
    }
  };

  const handelUpload = async (file) => {
    setLoading(true);

    if (!file || file.size === 0) {
      setShow(true);
      setMessage(t("caymland.core.slidealert.nonempty.file"));
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 7000);
      setLoading(false);
      return;
    }

    if (!file) {
      setShow(true);
      setMessage(t("caymland.core.slidealert.file"));
      setBackgroundToast("2");

      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 7000);
      setLoading(false);
      return;
    }

    if (file.type !== "text/csv") {
      setShow(true);
      setMessage(t("caymland.core.slidealert.csv.format"));
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 7000);
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await importCompany(formData);
      console.log(response);
      if (!response?.error) {
        setShow(true);
        setMessage(t("caymland.core.slidealert.uploaded.successfully"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 7000);
        setUploaded(true);
        setModifiedData((prev) => ({
          ...prev,
          originalFile: file.name,
          file: response.data.file,
          dir: response.data.dir,
        }));

        const filename = response?.data?.file?.split("/").pop();
        setModifiedData((prev) => ({
          ...prev,
          file: filename,
        }));

        setLoading(false);
      } else {
        console.log(response);
        setShow(true);
        setMessage(response?.error?.data?.message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 7000);
        setUploaded(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <SlideAlert background={backgorundToast} show={show} message={message} setShowAlert={setShow} />
      {uploaded === false ? (
        <Import
          handelUpload={handelUpload}
          handleFileInputChange={handleFileInputChange}
          selectedFile={selectedFile}
          modifiedData={modifiedData}
          setModifiedData={setModifiedData}
          loading={loading}
          isContactsView={isContactsView}
          isCompaniesView={isCompaniesView}
        />
      ) : (
        <ImportedRender
          setUploaded={setUploaded}
          modifiedData={modifiedData}
          setModifiedData={setModifiedData}
          setSelectedFile={setSelectedFile}
          fields={fields}
        />
      )}
    </>
  );
};

export default Main;
