import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Tags"],
  endpoints: (build) => ({
    getTags: build.query({
      query: customBaseQuery((q) => `/api/tageditor?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 60,
      providesTags: ["Tags"],
    }),
    getTagsOptions: build.query({
      query: (q) => `/api/tags/select`,
      keepUnusedDataFor: false,
      providesTags: ["Tags"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getTag: build.query({
      query: (id) => `/api/tags/${id}`,
      providesTags: (result, error, id) => [{ type: "Tags", id }],
      lazy: true,
    }),
    addTag: build.mutation({
      query: (body) => ({
        url: `api/tags/new`,
        method: "POST",
        body,
      }),
    }),
    updateTag: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/tags/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Tags", id }],
    }),
    deleteTag: build.mutation({
      query: (id) => ({
        url: `/api/tags/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          tagsApi.util.updateQueryData("getTags", undefined, (draft) => {
            draft.data = draft.data.filter((tags) => tags.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Tags", id }],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useGetTagQuery,
  useDeleteTagMutation,
  useGetTagsOptionsQuery,
  useUpdateTagMutation,
  useAddTagMutation,
} = tagsApi;
