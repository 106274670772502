import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import Button from "../../reusableComponents/Buttons/Button";
import { Text } from "../../reusableComponents/Inputs";
import { useGetTagQuery } from "../../../redux/api/tagsApi";
import { Success } from "../../reusableComponents/Alerts";
import { useEffect, useState } from "react";
import { t } from "i18next";

const TagsModal = ({
  modalOptions,
  setModalOptions,
  updateTag,
  addTag,
  tagName,
  setTagName,
  refetch,
  setShowAlert,
  showAlert,
  setAlertMessage,
  alertMessage,
}) => {
  console.log(modalOptions);

  const [errors, setErrors] = useState({});
  const { data, isFetching } = useGetTagQuery(modalOptions.id, {
    skip: !modalOptions.id,
  });
  console.log(data);

  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen });
  };

  useEffect(() => {
    if (data?.tag && modalOptions.id) {
      setTagName(data.tag.tag);
    }
  }, [data, modalOptions.id]);
  console.log(tagName);

  const validateFields = () => {
    const newErrors = {};

    if (!tagName) newErrors.tagname = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  //EDIT
  const handleEditTag = async () => {
    if (!validateFields()) return;

    const response = await updateTag({ id: modalOptions.id, tag: tagName });
    if (!response.errors) {
      setModalOptions({ isOpen: false });
      setTagName("");
      refetch();
      Success(
        `${t("caymland.core.update.step.success")}`,
        t("caymland.message.type.successfully", { type: t("caymland.core.type.edited") })
      );
    }
  };

  //ADD
  const handleAddTag = async () => {
    if (!validateFields()) return;
    const response = await addTag({ tag: tagName });

    if (!response.errors) {
      setModalOptions({ isOpen: false });
      setTagName("");
      refetch();
      Success(
        `${t("caymland.core.update.step.success")}`,
        t("caymland.message.type.successfully", { type: t("caymland.dashboard.create.past.tense") })
      );
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setTagName(e.target.value); // Update the tagName state with the new value
  };

  return (
    <Modal backdrop="static" keyboard={false} isOpen={modalOptions.isOpen}>
      <form>
        <ModalHeader toggle={toggle}>
          <div className="d-flex items-center gap-5px">
            {modalOptions.type === "add"
              ? t("caymland.tageditor.plugin.new")
              : t("caymland.tageditor.plugin.edit", { name: tagName })}
          </div>
        </ModalHeader>
        <ModalBody>
          <Text
            name="tagname"
            value={tagName}
            onChange={handleInputChange}
            label={t("caymland.tageditor.name")}
            required={true}
            errors={errors.tagname}
          />
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              buttons={[
                {
                  name: "close",
                  title: t("caymland.core.close"),
                  icon: <ImCancelCircle size={15} />,
                  onClick: () => {
                    setModalOptions({
                      isOpen: false,
                    }),
                      setTagName("");
                  },
                },
              ]}
            />

            <Button
              buttons={[
                {
                  name: "save",
                  title: t("caymland.core.form.save"),
                  icon: <TbDeviceFloppy size={15} />,
                  onClick: modalOptions.type === "edit" ? handleEditTag : handleAddTag,
                },
              ]}
            />
          </>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default TagsModal;
