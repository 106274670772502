import { Fragment, useEffect, useMemo, useState } from "react";
import "reactflow/dist/style.css";
import { Styles } from "../components/utils";
import Builder from "../Builder";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../components/utils/Schema";
import EdgeLabel from "../components/utils/EdgeLabel";

const Preview = ({ data }) => {
  const {
    reset,
    control,
    formState: { errors, isLoading },
  } = useForm({
    defaultValues: { events: [], lists: [], forms: [], canvasSettings: { nodes: [], connections: [] } },
    resolver: yupResolver(schema),
  });

  const {
    fields: listsFields,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "lists", // unique name for your Field Array
  });

  const {
    fields: eventsFields,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "events", // unique name for your Field Array
  });

  const {
    fields: formsFields,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "forms", // unique name for your Field Array
  });

  const {
    fields: nodesFields,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "canvasSettings.nodes", // unique name for your Field Array
  });

  const {
    fields: connectionsFields,
  } = useFieldArray({
    keyName: "formId",
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "canvasSettings.connections", // unique name for your Field Array
  });

  const getCampaign = () =>
    new Promise(async (resolve, reject) => {
      if (data) {
        resolve(data);
      } else {
        reject(data?.error?.message);
      }
    });

  useEffect(() => {
    if (data) {
      getCampaign()
        .then((response) => {
          reset(response);
        })
        .catch((errors) => console.error(errors));
    }
  }, [data]);

  const [nodes, setNodes] = useState({});
  useEffect(() => {
    const initialNodes = {
      nodes: [],
      edges: [],
    };

    let listNode = nodesFields.find((nf) => nf.id === "lists");
    let formNode = nodesFields.find((nf) => nf.id === "forms");
    const listsList = listsFields
      .map((lf) => {
        return { name: lf.name, id: lf.id };
      })
      .filter(Boolean);
    const formsList = formsFields
      .map((lf) => {
        return { name: lf.name, id: lf.id };
      })
      .filter(Boolean);
    if (listNode) {
      initialNodes.nodes.push({
        id: "lists",
        type: "source",
        data: { params: { id: "lists", type: "lists", eventType: "leadSource", name: "lists", lists: listsList } },
        position: { x: parseInt(listNode?.positionX), y: parseInt(listNode?.positionY) },
      });
    }

    if (formNode) {
      initialNodes.nodes.push({
        id: "forms",
        type: "source",
        data: { params: { id: "forms", type: "forms", eventType: "leadSource", name: "forms", forms: formsList } },
        position: { x: parseInt(formNode?.positionX), y: parseInt(formNode?.positionY) },
      });
    }

    if (eventsFields?.length) {
      eventsFields?.map((ef) => {
        let eventNode = nodesFields.find((nf) => nf.id == ef.id);
        if (!_.isEmpty(eventNode)) {
          const { positionX = false, positionY = false } = eventNode;
          const { id, eventType } = ef;

          initialNodes.nodes.push({
            id: id.toString(),
            type: eventType,
            data: { params: ef },
            position: { x: parseInt(positionX), y: parseInt(positionY) },
          });
        }
      });
    }

    if (connectionsFields?.length) {
      initialNodes.edges = connectionsFields?.map((cf) => {
        const {
          sourceId,
          targetId,
          anchors: { source, target },
        } = cf;

        const correspondingEvent = eventsFields.find((event) => event.id == targetId);
        let label = "";
        if (correspondingEvent) {
          label = EdgeLabel(correspondingEvent);
        }

        return {
          id: `${_.uniqueId("new")}`,
          source: sourceId,
          target: targetId,
          sourceHandle: source,
          animated: false,
          type: "step",
          style: { stroke: Styles.edgesColor[source] },
          label: label,
        };
      });
    }
    setNodes(initialNodes);
  }, [listsFields, formsFields, eventsFields, nodesFields, connectionsFields]);

  const forms = useMemo(
    () => ({
      events: {},
      nodes: {
        fields: nodesFields,
      },
      edges: {
        fields: connectionsFields,
      },
    }),
    [connectionsFields]
  );

  const [preview, setPreview] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPreview(true)
    }, 200);
    return () => clearTimeout(timer);
  }, []);

 
  return (
    <Fragment>
      <Builder nodesData={nodes} forms={forms} preview={preview} interactive={false} />
    </Fragment>
  );
};

export default Preview;
