import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Reports"],
  endpoints: (build) => ({
    getReports: build.query({
      query: customBaseQuery((q) => `/api/reports?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Reports"],
    }),
    getReport: build.query({
      query: (id) => `/api/reports/${id}`,
      providesTags: (result, error, id) => [{ type: "Reports", id }],
      keepUnusedDataFor: false,
    }),
    getReportView: build.query({
      queryFn: async (args, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        let modifiedArgs = {};
        if ("pageIndex" in args && "pageSize" in args) {
          const { pageIndex, pageSize } = args;
          modifiedArgs = {
            ...args,
            page: pageIndex + 1,
            limit: pageSize,
          };
        }
        console.log(modifiedArgs);
        if (modifiedArgs.id) {
          let _report = await baseQuery({
            url: `/api/reports/${modifiedArgs.id}/data`,
            method: "POST",
            body: modifiedArgs,
          });

          const data = _report.data;
          return { data: data };
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Reports"],
    }),
    addReport: build.mutation({
      query: (body) => ({
        url: `/api/reports/new`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Reports", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    updateReport: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/reports/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Reports", id }],
    }),
    deleteReport: build.mutation({
      query: (id) => ({
        url: `/api/reports/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          reportsApi.util.updateQueryData("getReports", undefined, (draft) => {
            draft.data = draft.data.filter((report) => report.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    getDataTab: build.query({
      query: (reportName) => `/api/reports/datatab?${qs.stringify(reportName)}`,
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    getSources: build.query({
      query: () => `/api/reports/sources`,
      providesTags: (result, error, id) => [{ type: "Reports", id }],
      transformResponse: (response, meta, arg) => {
        return Object.keys(response).map((key) => {
          const options = Object.entries(response[key]).map(([value, label]) => ({
            value,
            label,
          }));
          return { label: key, options };
        });
      },
    }),
    getAllSchedule: build.query({
      query: () => `/api/reports/schedule/preview`,
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    getSchedule: build.query({
      query: (q) => `/api/reports/schedule/preview/${q}`,
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderReports: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=reports`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderReports: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderReports: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Reports", id }],
    }),
    //DELETE FOLDER
    deleteFolderReports: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateReportsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/reports/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Reports", id }],
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeReportsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/reports/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetReportQuery,
  useGetReportViewQuery,
  useAddReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useGetDataTabQuery,
  useGetSourcesQuery,
  useGetScheduleQuery,
  useGetAllScheduleQuery,
  //FOLDERS
  useGetFolderReportsQuery,
  useUpdateFolderReportsMutation,
  useDeleteFolderReportsMutation,
  useAddFolderReportsMutation,
  useUpdateReportsToFoldersMutation,
  useRemoveReportsToFoldersMutation,
} = reportsApi;
