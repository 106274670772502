import { useEffect, useState } from "react";
import { checkConnectionRestrictions } from "../utils/CheckConnectionRestrictions";
import _ from "lodash";
import { Text } from "../../../../reusableComponents/Inputs";
import { t } from "i18next";

export default function (props) {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const { type, step, actions, parent } = props;
    if (actions && typeof actions?.getEventsBySource === "function") {
      actions.getEventsBySource({ source: type, step }).then((result) => {
        const events = result?.filter((event) => {
          return checkConnectionRestrictions({ event, parent: parent.params });
        });

        setEvents(events);
      });
    }
  }, [props]);

  useEffect(() => {
    const { type, step, actions } = props;
    if (actions && actions?.getEventsBySource) {
      actions
        .getEventsBySource({ source: type, step })
        .then((result) => {
          const filteredEvents = result.filter((event) => {
            return checkConnectionRestrictions({ event, parent: props.parent.params });
          });
          setEvents(filteredEvents);
        })
        .catch((error) => console.error(error));
    }
  }, [props]);

  const handleSearchOnChange = (data) => {
    setSearch(data.target.value);
  };
  return (
    <>
      {props.step > 0 && <Text label={"Search"} value={search} onChange={handleSearchOnChange} autoFocus={true} />}
      {events
        .filter((ee) => {
          if (search.length) {
            const regex = new RegExp(search, "i");
            return regex.test(ee.label);
          }

          return true;
        })
        .map((event, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 10,
              width: "100%",
              border: `1px solid ${event.background || "#555"}`,
              borderRadius: ".25rem",
              color: "#495057",
              backgroundColor: `#fff`,
              transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
              appearance: "none",
              margin: "10px 0",
              cursor: "pointer",
            }}
            onClick={() => props.handleOnSelectEvent(event.alias, event.eventType || null)}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  fontSize: 16,
                }}
              >
                {event.icon}
                {event.label}
              </span>
              <span style={{ fontSize: 10 }} dangerouslySetInnerHTML={{ __html: event.description }}></span>
            </div>
          </div>
        ))}
    </>
  );
}
