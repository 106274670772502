import React, { useState } from "react";
import { MdRefresh } from "react-icons/md";
import Cancel from "../../reusableComponents/Buttons/cancel";
import SaveAndClose from "../../reusableComponents/Buttons/saveAndClose";
import Apply from "../../reusableComponents/Buttons/apply";
import { useNavigate } from "react-router-dom";

import "./AddSQLcondition.css";
import QueryEditor from "../../reusableComponents/queyEditor/QueryEditor";
import NewCategoryPopUp from "./../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { t } from "i18next";
// import { SqlEditor } from "react-sql-editor";
function AddSQLCondition() {
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);
  const [valueOfSelect, setValueOfSelect] = useState("Uncategorized");
  const navBack = () => {
    navigate("/sqlConditions");
  };

  const handleSelectChange = (e) => {
    if (e.target.value === "create_new_category") {
      setValueOfSelect("Uncategorized");
      setShowPopUp(true);
    } else {
      setValueOfSelect(e.target.value);
    }
  };
  return (
    <>
      {/* POPUP */}
      <NewCategoryPopUp show={showPopUp} setShow={setShowPopUp} />

      <div
        className="container-fluid container-fluid-margin pt-80"
        // style={{ backgroundColor: "red" }}
      >
        <div className="page-header heading-padding">
          <div
            className="flex flex-wrap items-center justify-between mid-gap-15"
            style={{
              borderBottom: "1px solid #dddddd",
              paddingBottom: "10px",
            }}
          >
            <div className="col">
              <div className="page-header-left d-flex items-center gap-5px">
                <h3 style={{ fontWeight: "400", textTransform: "capitalize" }}>{t("caymland.sqlConditions.new")}</h3>
                {/* {customFields.length > 0 ? (
                <></>
              ) : (
                <>
                  <MdRefresh
                    className="cursor-pointer"
                    title="Refresh fields"
                    onClick={() => retrieveFields()}
                    color="#135f95"
                    size={18}
                  />
                </>
              )} */}
              </div>
            </div>

            <div className="d-flex items-center gap-10 mid-full-width mid-justify-center">
              <Cancel navigate={navBack} />
              <SaveAndClose />
              <Apply />
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "3fr 3fr 1fr",
              columnGap: "1.5em",
              marginTop: "2em",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="name" className="required">
                {t("caymland.core.name")}
              </label>
              <input type="text" name="name" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="category">{t("caymland.core.category")}</label>
              <select name="category" id="" onChange={handleSelectChange} value={valueOfSelect}>
                <option value="uncategorized">{t("caymland.core.form.uncategorized")}</option>
                <option value="test">{t("caymland.tomas.custom.fields.test")}</option>
                <option value="test_ernes">{t("caymland.core.test.ernes")}</option>
                <option value="create_new_category">{t("caymland.core.create.new.category")}...</option>
              </select>
            </div>
            <div>
              <label htmlFor="">{t("caymland.core.form.published")}</label>
              <div class="switch-toggle">
                <input type="checkbox" id="visible-on-forms-addSQLcondition" />
                <label className="toggle-label" for="visible-on-forms-addSQLcondition"></label>
              </div>
            </div>
          </div>

          {/* <div className="sqlEditor">
            <SqlEditor
              title="Sql Editor"
              width="auto"
              height="300px"
              isShowHeader={true}
              onChange={(data) => {
              }}
            />
          </div> */}
          <div className="sqlEditor">
            <QueryEditor />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSQLCondition;
