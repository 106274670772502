import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const stagesApi = createApi({
  reducerPath: "stagesApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Stages"],
  endpoints: (build) => ({
    getStages: build.query({
      query: customBaseQuery((q) => `/api/stages?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Stages"],
    }),
    getStagesOptions: build.query({
      query: (q) => `/api/stages/select`,
      keepUnusedDataFor: false,
      providesTags: ["Stages"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getStage: build.query({
      query: (id) => `/api/stages/${id}`,
      providesTags: (result, error, id) => [{ type: "Stages", id }],
    }),
    updateStage: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/stages/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Stages", id }],
    }),
    deleteStage: build.mutation({
      query: (id) => ({
        url: `/api/stages/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          stagesApi.util.updateQueryData("getStages", undefined, (draft) => {
            draft.data = draft.data.filter((stages) => stages.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Stages", id }],
    }),
    addStage: build.mutation({
      query: (body) => ({
        url: `/api/stages/new`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Stages", id: "stage" }],
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderStages: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=stages`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderStages: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderStages: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Stages", id }],
    }),
    //DELETE FOLDER
    deleteFolderStages: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateStagesToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/stages/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Stages", id }],
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeStagesToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/stages/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetStagesQuery,
  useDeleteStageMutation,
  useUpdateStageMutation,
  useAddStageMutation,
  useGetStageQuery,
  useGetStagesOptionsQuery,
  //FOLDERS
  useGetFolderStagesQuery,
  useUpdateFolderStagesMutation,
  useDeleteFolderStagesMutation,
  useAddFolderStagesMutation,
  useUpdateStagesToFoldersMutation,
  useRemoveStagesToFoldersMutation,
} = stagesApi;
