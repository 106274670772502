import React, { useEffect, useRef, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import Label from "./Label";

const CustomCreatableSelectInput = ({
  name,
  value,
  onChange,
  bundle,
  options,
  label,
  placeholder = "Select...",
  isClearable = true,
  isSearchable = true,
  errors = false,
  isMulti = false,
  labelField = "label",
  valueField = "value",
  required = false,
  tooltip,
  isDisabled = false,
  trigger = null,
  margin,
  viewEdit = false,
  onBlur,
  document = false,
  extraStyle,
  onCreateOption,
}) => {
  const [defaultValue, setDefaultValue] = useState();
  const selectRef = useRef(null);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    delay(50).then(() => {
      if (!selectRef.current?.props) return;

      let options = selectRef.current.props.options;
      if (_.has(options[0], "options")) {
        options = options
          .map((val) => val.options)
          .reduce((pre, cur) => pre.concat(cur), [])
          .map((e) => ({ label: e[labelField], value: e[valueField] }));
      }

      if (value) {
        if (Array.isArray(value)) {
          if (value.some((v) => typeof v === "object")) {
            setDefaultValue(value);
          } else {
            let values = options.map((o) => (value.includes(parseInt(o[valueField])) ? o : undefined)).filter(Boolean);
            setDefaultValue(values);
          }
        } else if (typeof value === "object") {
          setDefaultValue(value);
        } else {
          setDefaultValue(
            options.find((o) => {
              const optionValue = o[valueField];
              const stringValue = value?.toString();
              return optionValue !== undefined && optionValue.toString() === stringValue;
            })
          );
        }
      } else {
        setDefaultValue(null);
      }
    });
  }, [value, options, labelField, valueField]);

  const handleOnChange = (data) => {
    if (!data) {
      onChange(null);
      return;
    }

    if (Array.isArray(data)) {
      let choices = data.map((ff) => ({
        [labelField]: ff[labelField],
        [valueField]: ff[valueField],
      }));
      onChange(choices);
      return;
    }

    if (_.has(data, [valueField])) {
      onChange(data);
      return;
    }

    onChange(null);
  };

  const getOptions = async (inputValue) => {
    if (typeof options === "function") {
      const results = await options(inputValue, bundle, trigger);
      return results; // This should return an array of options
    }
    return options; // If `options` is an array, return it directly
  };

  const handleCreateNewOption = (inputValue) => {
    if (onCreateOption) {
      onCreateOption(inputValue);
    }
  };

  return (
    <div
      className={`w-full ${extraStyle?.margin ? "" : !margin && "mb-20px"}`}
      style={{ minWidth: !extraStyle?.minWidth ? "" : "150px" }}
    >
      {label && label?.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />
      )}

      <AsyncCreatableSelect
        id={name}
        key={JSON.stringify(value)}
        ref={selectRef}
        onBlur={onBlur}
        name={name}
        value={defaultValue}
        required={required}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        defaultOptions
        menuPlacement="auto"
        menuPortalTarget={document ? document.body : null}
        maxMenuHeight={300}
        loadOptions={getOptions}
        getOptionLabel={(data) => data[labelField]}
        getOptionValue={(data) => data[valueField]}
        isMulti={isMulti}
        onChange={handleOnChange}
        onCreateOption={handleCreateNewOption}
        aria-errormessage={errors}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "#135f95",
            primary: "#135f95",
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            outline: "none !important",
            minHeight: "40px",
            fontSize: "15px",
            backgroundColor: viewEdit ? "inherit" : "white",
            border: viewEdit ? "0" : "",
            marginBottom: viewEdit ? "3px" : "0",
            boxShadow: viewEdit ? "none" : "",
            borderColor: errors ? "#d32f2f" : base.borderColor,
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: viewEdit ? "none" : "scale(1.003)",
              boxShadow: viewEdit ? "none" : "0 6px 20px rgba(0, 0, 0, 0.1)",
              borderColor: errors ? "#d32f2f" : base["&:hover"].borderColor,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#347AA6" : "white",
            color: state.isSelected ? "white" : "#333",
            padding: "10px 2px",
            fontSize: "15px",
            transition: "background-color 0.2s ease",
            paddingLeft: "20px",
            "&:active": {
              color: "white",
              backgroundColor: "#135f95",
            },
            "&:hover": {
              color: "white",
              backgroundColor: "#135f95",
            },
            "&:focus": {
              color: "white",
            },
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: "#606060",
            ":hover": {
              backgroundColor: "#135f95",
              color: "white",
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            fontSize: "15px",
            backgroundColor: " #e7e7e7",
            borderRadius: "5px",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            fontSize: "15px",
            color: "#606060",
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "#6C757D",
            fontSize: viewEdit ? "13px" : "15px",
          }),
          group: (base, state) => ({
            ...base,
            // backgroundColor: "#f6f6f6",
            padding: "10px 2px",
          }),

          groupHeading: (base, state) => ({
            ...base,
            fontWeight: "bold",
            paddingLeft: "5px",
            color: "#333",
          }),
          menu: (provided) => ({ ...provided, zIndex: 99999999 }),
        }}
      />
    </div>
  );
};

export default CustomCreatableSelectInput;
