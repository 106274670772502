import _ from "lodash";

export const Event = ({id, type, eventType, name, properties, triggerMode, ...data }) => {

   const event= {
        id,
        type, 
        eventType,
        name,
        properties,
        triggerMode,
        ...data
    }

    return event;
}

