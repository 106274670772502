import React, { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../../reusableComponents/loading";
import NoResultsFound from "../../../reusableComponents/noResultsFound/NoResultsFound";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { useDeletePointMutation, useGetPointsQuery, useUpdatePointMutation } from "../../../../redux/api/pointsApi";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import { t } from "i18next";

function ManageActions(props) {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const name = "ManageActions";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });

  const { data, isFetching, refetch } = useGetPointsQuery(query);
  const [updatePoint] = useUpdatePointMutation();
  const [deletePoint] = useDeletePointMutation();

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { id: data.id, isPublished: !data.isPublished };
    await updatePoint(updatedData);
    await refetch();
    setLocalLoading(-1);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.dashboard.widget.form.name"),
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="points"
        />
      ),
    },
    {
      accessorKey: "category",
      accessorFn: (row) => row.category,
      header: t("caymland.page.tracking.form.category"),
      enableColumnActions: false,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "delta",
      accessorFn: (row) => row.points,
      header: t("caymland.point.thead.delta"),
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.delta,
    },
    {
      accessorKey: "action",
      accessorFn: (row) => row.points,
      header: t("caymland.stage.thead.action"),
      enableColumnActions: false,
      Cell: ({ cell }) => <div style={{ cursor: "default !important" }}>{cell.row.original.type}</div>,
    },
  ]);

  const actionButtons = ActionButtons({
    link: "points",
    deleteItem: deletePoint,
    refetch: refetch,
  });

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/points/new"),
    },
  ];

  const actionBatchButtons = ActionBatchButtons({
    link: "points",
    refetch: refetch,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.point.menu.index")} />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate="edit"
        isFetching={isFetching}
      />
    </Fragment>
  );
}

export default ManageActions;
