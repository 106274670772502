import React, { useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import Button from "./Button";
import ModalForSegments from "./ModalForSegments";
import { Tooltip } from "@mui/material";
import { t } from "i18next";

export default function Segments(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const showSegments = () => {};

  return (
    <>
      <ModalForSegments
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        showSegments={showSegments}
        firstName={props.firstName}
        lastName={props.lastName}
        segments={props.segments}
        updateSegments={props.updateSegments}
        id={props.id}
        retrieveSegmentsForID={props?.retrieveSegmentsForID}
      />
      <Button
        buttons={[
          {
            name: !props.noText && "Segments",
            title: !props.noText && t("caymland.campaign.lead.segments"),
            icon: (
              <Tooltip title={t("caymland.campaign.lead.segments")} arrow>
                <FilterAltOutlinedIcon size={15} />
              </Tooltip>
            ),
            onClick: () => handleShow(),
          },
        ]}
      />
    </>
  );
}
