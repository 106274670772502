import { t } from "i18next";
import SweetAlert from "sweetalert2";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";

export function Success(title, text, closeButtonLabel = t("caymland.core.close")) {
  return SweetAlert.fire({
    title: title,
    html: text,
    text: text,
    icon: "success",
    confirmButtonColor: "#135f95",
    confirmButtonText: t("caymland.core.close"),
    customClass: {
      confirmButton: "result-component swal-custom",
    },
  });
}

export function Error(title, text, closeButtonLabel) {
  return SweetAlert.fire({
    title: title,
    text: text,
    icon: "error",
    confirmButtonColor: "#135f95",
    confirmButtonText: t("caymland.core.close"),
    customClass: {
      confirmButton: "result-component swal-custom",
    },
  });
}

export function Alert(title, text) {
  return SweetAlert.fire({
    title: title,
    text: text,
    icon: "question",
    confirmButtonColor: "#e62b25",
    confirmButtonText: t("caymland.core.form.yes"),
    cancelButtonText: t("caymland.core.form.cancel"),
    customClass: {
      confirmButton: "confirm-component swal-custom",
      cancelButton: "result-component swal-custom",
    },
    cancelButtonColor: "#135f95",
  });
}

export const SlideAlert = ({ message, show, setShowAlert, background = "1" }) => {
  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div
      className={`transition-in-out`}
      style={{
        position: "fixed",
        zIndex: "9999",
        bottom: 1,
        right: show ? 1 : "-10000px",
        background: background === "1" ? "#039477" : "#d11a2a",
        padding: "8px 7px 8px 12px",
        color: "white",
        fontSize: "16px",
        borderRadius: "8px",
        border: "1px solid #b8daf2",
      }}
    >
      {message}
      <button
        onClick={closeAlert}
        style={{
          marginLeft: "5px",
          background: "transparent",
          border: "none",
          color: "white",
          cursor: "pointer",
          fontWeight: "bold",
        }}
      >
        X
      </button>
    </div>
  );
};

export const handleCancel = (link, navigate) => {
  Swal.fire({
    title: t("caymland.mailbox.message.delete.title"),
    text: t("caymland.validation.message.changes"),
    icon: "question",
    customClass: {
      confirmButton: "confirm-component swal-custom",
      cancelButton: "result-component swal-custom",
    },
    confirmButtonText: t("caymland.core.form.yes"),
    cancelButtonText: t("caymland.core.form.cancel"),
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      navigate(link);
    } else {
      return;
    }
  });
};

export const handleCancelBuilder = (setIsOpen, navigate, location) => {
  Swal.fire({
    title: t("caymland.mailbox.message.delete.title"),
    text: t("caymland.validation.message.changes"),
    icon: "question",
    customClass: {
      confirmButton: "confirm-component swal-custom",
      cancelButton: "result-component swal-custom",
    },
    confirmButtonText: t("caymland.core.form.yes"),
    cancelButtonText: t("caymland.core.form.cancel"),
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      setIsOpen(false);
      if (navigate) {
        navigate(location?.pathname, { replace: true });
      }
    } else {
      return;
    }
  });
};
