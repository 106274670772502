import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { TextArea } from "../../../reusableComponents/Inputs";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useUpdateContactDNCMutation } from "../../../../redux/api/contactsApi";
import { t } from "i18next";

const DNCBatchModal = ({ modalOptions, setModalOptions, setShow, setMessage, refetch, setBackgroundToast }) => {
  const closeModal = () => {
    setModalOptions(() => ({
      isOpen: false,
      title: "",
    }));
    reset();
  };

  const [updateDNC] = useUpdateContactDNCMutation();

  const handleCategories = async (data) => {
    console.log(data);

    const idsArray = modalOptions?.id || [];
    const idsString = JSON.stringify(idsArray);

    const lead_batch = {
      reason: watch("add"),
      ids: idsString,
    };
    const response = await updateDNC({ lead_batch_dnc: lead_batch });
    console.log(response);
    if (!response.error) {
      closeModal();
      refetch();
      setShow(true);
      setMessage(t("caymland.message.type.successfully", { type: "edited" }));
      setBackgroundToast("1");
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
    } else {
      setShow(true);
      setMessage(response.error.data?.message);
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3000);
    }
  };

  const { control, handleSubmit, watch, reset } = useForm();

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleCategories),
    },
  ];
  return (
    <Modal show={modalOptions.isOpen && modalOptions.type === "dnc"} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            padding: "4px",
            fontSize: "18px",
          }}
        >
          {modalOptions.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Controller
            control={control}
            name="add"
            render={({ field: { name, onChange, value } }) => (
              <TextArea
                name={name}
                label={t("caymland.lead.batch.dnc")}
                isClearable
                className="w-full"
                value={value ?? null}
                onChange={onChange}
              />
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default DNCBatchModal;
