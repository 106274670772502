import React from "react";
import Moment from "moment";
import { t } from "i18next";
 
function Details({ campaign }) {
  const details = [
    { label: t("caymland.lead.lead.category"), value: campaign?.category?.title },
    { label: t("caymland.showare.field.created_by"), value: campaign?.createdByUser },
    {
      label: t("caymland.core.created"),
      value: campaign?.dateAdded
        ? Moment(campaign?.dateAdded).format("DD.MM.YY hh:mm")
        : "Invalid Date",
    },
    { label: t("caymland.core.modifiedby"), value: campaign?.createdByUser },
    {
      label: t("caymland.core.modified"),
      value: campaign?.dateModified
        ? Moment(campaign?.dateModified).format("DD.MM.YY hh:mm")
        : "Invalid Date",
    },
    {
      label: t("caymland.page.publish.up"),
      value: campaign?.publishUp
        ? Moment(campaign?.publishUp).format("DD.MM.YY hh:mm")
        : "Invalid Date",
    },
    {
      label: t("caymland.page.publish.down"),
      value: campaign?.publishDown
        ? Moment(campaign?.publishDown).format("DD.MM.YY hh:mm")
        : "Invalid Date",
    },
    { label: t("caymland.points.table.id"), value: campaign?.id },
    { 
      label: t("caymland.lead.lead.events.segments"), value: `${
      campaign?.lists?.map( e=> e?.name).join(', ') || ''}` 
    },
    { 
      label: t("caymland.form.type.campaign"), value: `${
      campaign?.forms?.map( e=> e?.name).join(', ') || ''}` 
    },
  ];
 
  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px">
          <span className="details-label">{item.label}</span>:
          <span className="details-value">{item.value}</span>
        </div>
      ))}
    </div>
  );
}
 
export default Details;
 