import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ({ buttons, name, value, onChange, margin, viewEdit = false, onBlur, defaultValue }) {
  const initialValue = value === undefined ? defaultValue : value;

  return (
    <div className={` ${!margin && "mb-20px"}`}>
      <ToggleButtonGroup
        onBlur={onBlur}
        color="primary"
        value={initialValue}
        exclusive
        onChange={onChange}
        aria-label="text"
      >
        {buttons.map((button, index) => (
          <ToggleButton
            className={`${index !== 0 ? "group-btn-border-left" : ""}`}
            value={button.value}
            key={index}
            aria-label="left aligned"
            sx={{
              backgroundColor: viewEdit ? "inherit" : "#fff",
              height: viewEdit ? "24px" : "40px",
              minWidth: "45px",
              fontSize: viewEdit ? "13px" : "15px",
              textTransform: "capitalize",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "scale(1.01)",
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                borderColor: "#5A5A5A",
              },
              "&.Mui-selected": {
                backgroundColor: "#347AA6",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#135f95",
                  color: "#fff",
                },
              },
            }}
          >
            {button.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
