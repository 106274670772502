import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { t } from "i18next";

const SortableItem = SortableElement(({ value, index, onChange, onRemove, rowIndex }) => (
  <div index={rowIndex} className="d-flex items-center" style={{ marginBottom: "5px", visibility: "visible" }}>
    <button
      type="button"
      onClick={() => onRemove(value.id)}
      className="dataType-options-btn button-with-x-background bgd1"
      style={{
        borderRadius: "4px 0 0 4px",
        backgroundColor: "#d1d1d1!important",
      }}
    ></button>
    <div className="input-container ic1" style={{ height: "28px" }}>
      <input
        style={{
          borderRadius: "0",
          fontSize: "14px",
          paddingTop: 0,
        }}
        name={`email-${value.id}`}
        id="email"
        value={value.email}
        className="jump-input"
        type="text"
        placeholder={t("caymland.email.builder.tag.email")}
        onChange={(e) => onChange(e, rowIndex)}
      />
    </div>
    <button type="button" className="dataType-options-btn" style={{ borderRadius: "0 4px 4px 0" }}>
      <span>
        <BiDotsVertical size={12} />
      </span>
    </button>
  </div>
));

const SortableList = SortableContainer(({ items, onChange, onRemove }) => {
  return (
    <div>
      {items
        .sort((a, b) => a.position - b.position)
        .map((value, index) => (
          <SortableItem
            hideSortableGhost={false}
            value={value}
            rowIndex={index}
            index={index}
            key={value.id}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
});

function SendEmailContent({ sendEmailList, setSendEmailList }) {
  const handleOptionsRemove = (id) => {
    let list = sendEmailList.filter((x) => x.id !== id);
    setSendEmailList(list);
  };

  const handleOptionsAdd = () => {
    let id = parseInt(Date.now() * Math.random() * 10);

    setSendEmailList([...sendEmailList, { id: id, email: "", position: sendEmailList.length + 1 }]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let arr = arrayMoveImmutable(sendEmailList, oldIndex, newIndex);

    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }
    setSendEmailList(arr);
  };

  const handleOptionsChange = (e, index, type) => {
    const { name, value } = e.target;
    const list = [...sendEmailList];

    list[index]["email"] = value;
    setSendEmailList(list);
  };

  return (
    <div className="w-full">
      <p>
        {t("caymland.lead.field.form.properties.select")} <span style={{ color: "red" }}>*</span>
      </p>
      <button type="button" onClick={handleOptionsAdd} className="addOptions-btn">
        <span>{t("caymland.form.button.add.option")}</span>
      </button>
      <SortableList
        helper
        className="z-9999"
        items={sendEmailList}
        onSortEnd={onSortEnd}
        onChange={handleOptionsChange}
        onRemove={handleOptionsRemove}
        axis={"y"}
      />
    </div>
  );
}

export default SendEmailContent;
