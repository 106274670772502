import React from "react";
import { Skeleton, Box } from "@mui/material";

const HeaderSkeleton = ({
  hasBuilderButton = false,
  viewBigButton = false,
  viewMediumButton = false,
  isView = false,
  isContactView = false,
}) => {
  return isView ? (
    <div className="flex items-center" style={{ height: "65px" }}>
      <Box sx={{ width: "100%", my: 0 }}>
        <Skeleton variant="text" width="40%" height={50} />
      </Box>
      {!isContactView ? (
        <Box sx={{ width: "100%", my: 0, p: "0 10", display: "flex", justifyContent: "flex-end" }}>
          {viewBigButton && <Skeleton variant="rectangular" width="158px" height={40} sx={{ mr: "10px" }} />}
          <Skeleton variant="rectangular" width="66.6px" height={40} sx={{ mr: "10px" }} />
          {viewMediumButton && <Skeleton variant="rectangular" width="90.4px" height={40} sx={{ mr: "10px" }} />}
          <Skeleton variant="rectangular" width="124px" height={40} />
        </Box>
      ) : (
        <Box sx={{ width: "100%", my: 0, p: "0 10", display: "flex", justifyContent: "flex-end" }}>
          <Skeleton variant="rectangular" width="40px" height={40} sx={{ mr: "10px" }} />
          <Skeleton variant="rectangular" width="40px" height={40} sx={{ mr: "10px" }} />
          <Skeleton variant="rectangular" width="40px" height={40} sx={{ mr: "10px" }} />
          <Skeleton variant="rectangular" width="40px" height={40} sx={{ mr: "10px" }} />
          <Skeleton variant="rectangular" width="40px" height={40} sx={{ mr: "10px" }} />
          <Skeleton variant="rectangular" width="40px" height={40} sx={{ mr: "10px" }} />
          <Skeleton variant="rectangular" width="40px" height={40} />
        </Box>
      )}
    </div>
  ) : (
    <div className="flex items-center" style={{ height: "65px" }}>
      <Box sx={{ width: "100%", my: 0 }}>
        <Skeleton variant="text" width="40%" height={50} />
      </Box>
      <Box sx={{ width: "100%", my: 0, p: "0 10", display: "flex", justifyContent: "flex-end" }}>
        {hasBuilderButton && <Skeleton variant="rectangular" width="140px" height={40} sx={{ mr: "10px" }} />}
        <Skeleton variant="rectangular" width="76px" height={40} sx={{ mr: "10px" }} />
        <Skeleton variant="rectangular" width="120px" height={40} sx={{ mr: "10px" }} />
        <Skeleton variant="rectangular" width="76px" height={40} />
      </Box>
    </div>
  );
};

export default HeaderSkeleton;
