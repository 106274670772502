import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../reusableComponents/Header/Header";
import { AiOutlineFileText, AiOutlineUser } from "react-icons/ai";
import { FaRegFileExcel, FaTimes } from "react-icons/fa";
import {
  useDownloadFormMutation,
  useGetAddEditFormsQuery,
  useDeleteResultMutation,
} from "../../../../redux/api/formsApi";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import he from "he";

function FormResults() {
  const navigate = useNavigate();
  const [modifiedData, setModifiedData] = useState({
    name: "",
    alias: "",
    description: "",
    category: null,
    isPublished: false,
    publishUp: null,
    publishDown: null,
    noIndex: false,
    inKioskMode: false,
    renderStyle: false,
    theme: null,
    formType: null,
  });
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "dateSubmitted",
    filterBy: "", // Column to filter by
    value: "", // Value to filter with
  });

  const { id } = useParams();

  const { data, error, isFetching, refetch } = useGetAddEditFormsQuery({ id, query });
  const [downloadForm] = useDownloadFormMutation();
  const [deleteResult] = useDeleteResultMutation();
  const [columnFilters, setColumnFilters] = useState([]);

  if (error) console.log(error);

  const navBack = () => {
    navigate("/forms");
  };

  useEffect(() => {
    if (data?.forms && id) {
      setModifiedData(data.forms.form);
    }
  }, [data, id]);

  const [loading, setLoading] = useState(false);

  const exportCSV = async () => {
    setLoading(true);

    // Prepare the filters to be sent
    const filters = columnFilters.map((filter, index) => {
      let columnName;

      // Use switch statement to check and assign the column name
      switch (filter.id) {
        case "id":
          columnName = "s.id";
          break;
        case "leadId":
          columnName = "s.lead_id";
          break;
        case "referer":
          columnName = "s.referer";
          break;
        case "dateSubmitted":
          columnName = "s.date_submitted";
          break;
        case "ipAddress":
          columnName = "i.ip_address";
          break;
        default:
          columnName = `r.${filter.id}`; // Use r.filter.id if no match is found
          break;
      }

      return {
        [`filters[${index}][column]`]: columnName,
        [`filters[${index}][expr]`]: "like",
        [`filters[${index}][value]`]: `%${filter.value}%`,
      };
    });

    // Convert filters to query string
    const filterQueryString = filters
      .map((f) =>
        Object.entries(f)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join("&")
      )
      .join("&");

    // Send request with filters
    const response = await downloadForm({
      id: modifiedData.id,
      format: "csv",
      filter: filterQueryString, // Send filters as query string
    });

    const csvData = new Blob([response.data], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.setAttribute("download", "results.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };
  const exportExcel = async () => {
    setLoading(true);
    const filters = columnFilters.map((filter, index) => {
      let columnName;

      // Use switch statement to check and assign the column name
      switch (filter.id) {
        case "id":
          columnName = "s.id";
          break;
        case "leadId":
          columnName = "s.lead_id";
          break;
        case "dateSubmitted":
          columnName = "s.date_submitted";
          break;
        case "referer":
          columnName = "s.referer";
          break;
        case "ipAddress":
          columnName = "i.ip_address";
          break;
        default:
          columnName = `r.${filter.id}`; // Use r.filter.id if no match is found
          break;
      }

      return {
        [`filters[${index}][column]`]: columnName,
        [`filters[${index}][expr]`]: "like",
        [`filters[${index}][value]`]: `%${filter.value}%`,
      };
    });
    // Convert filters to query string
    const filterQueryString = filters
      .map((f) =>
        Object.entries(f)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join("&")
      )
      .join("&");
    const response = await downloadForm({
      id: modifiedData.id,
      format: "xlsx",
      filter: filterQueryString, // Send filters as query string
    });
    const excelData = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const excelUrl = URL.createObjectURL(excelData);
    const link = document.createElement("a");
    link.href = excelUrl;
    link.setAttribute("download", "results.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  // const exportHTML = () => {
  //     navigate(`/forms/results/${modifiedData.id}/export/html`, { state: data })
  // }

  const viewContacts = () => {
    navigate("/contacts", { state: { search: `form:${modifiedData.alias}` } });
  };
  const buttons = [
    {
      name: "exportToCSV",
      title: t("caymland.form.result.export.csv"),
      icon: <AiOutlineFileText size={15} />,
      onClick: () => exportCSV(),
    },

    {
      name: "exportToExcel",
      title: t("caymland.form.result.export.xlsx"),
      icon: <FaRegFileExcel size={15} />,
      onClick: () => exportExcel(),
    },
    {
      name: "viewContacts",
      title: t("caymland.lead.list.view_contacts"),
      icon: <AiOutlineUser size={15} />,
      onClick: () => viewContacts(),
      childs: [
        {
          name: "Close",
          title: t("caymland.core.close"),
          icon: <FaTimes size={13} />,
          onClick: () => navBack(),
        },
      ],
    },
  ];

  const columns = useMemo(() => {
    const staticColumn = [
      {
        accessorKey: "leadId",
        accessorFn: (row) => row.leadId,
        header: "Lead ID",
        enableSorting: false,
        muiTableBodyCellProps: {},
        Cell: ({ cell }) => (
          <a
            href={`/contacts/view/${cell.getValue()}`}
            target="_blank"
            rel="noopener noreferrer"
            className="table-links"
          >
            {cell.getValue()}
          </a>
        ),
      },
      {
        accessorKey: "dateSubmitted",
        accessorFn: (row) => row.dateSubmitted,
        header: "Date Submitted",
        enableSorting: false,
      },
      {
        accessorKey: "ipAddress",
        accessorFn: (row) => row.ipAddress,
        header: "IP Address",
        enableSorting: false,
      },
      {
        accessorKey: "referer",
        accessorFn: (row) => row.referer,
        header: "Referer",
        enableSorting: false,
        muiTableBodyCellProps: {
          sx: {
            paddingRight: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "400px",
          },
        },
      },
    ];

    if (data?.results && data.results.results?.length > 0 && data.results.results[0]?.results) {
      const dynamicColumns = Object.keys(data.results.results[0].results || {})
        .filter((key) => typeof data.results.results[0][key] !== "object")
        .map((key) => ({
          accessorKey: key,
          accessorFn: (row) => row[key],
          header: data.results.results[0]?.results[key]?.label,
          Cell: ({ cell }) => <>{he.decode(cell.row.original[key])}</>,
          muiTableBodyCellProps: {
            sx: {
              paddingRight: 2,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "300px",
            },
          },
        }));

      return [...dynamicColumns, ...staticColumn];
    } else {
      console.warn("Data or results structure is invalid:", data);
      return staticColumn; // Return static columns if dynamic columns can't be generated
    }
  }, [data]);

  const actionBatchButtons = ActionBatchButtons({
    link: `forms/results/${id}`,
    refetch: refetch,
  });
  const actionButtons = ActionButtons({
    link: "forms/results",
    deleteItem: deleteResult,
    refetch: refetch,
    clone: false,
    edit: false,
  });
  return (
    <Fragment>
      <Header
        buttons={buttons}
        title={t("caymland.form.result.header.index", { name: modifiedData?.name })}
        loading={loading}
        isFetching={isFetching}
      />
      <CustomTable
        data={data?.results}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        isFetching={isFetching}
        searchFilter={false}
        linkNavigate={null}
        enableColumnFilters
        enableColumnActions
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        filterTable
        enableSorting={false}
      />
    </Fragment>
  );
}

export default FormResults;
