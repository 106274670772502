import { t } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";

function Sidebar({ hook, extras }) {
  return (
    <div className="px-20 mt-20px">
      <div
        style={{
          paddingBottom: "20px",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        {t("caymland.page.point.action.form.page.url")}
      </div>
      <div class="input-container ic1 flex">
        <input
          id="download-url"
          style={{
            borderRadius: "6px 0 0 6px",
            background: "#f4f4f4",
          }}
          className="jump-input cursor-disabled"
          type="text"
          disabled
          value={`${hook.webhookUrl}`}
          placeholder=""
        />
        <a rel="noreferrer" href={`${hook.webhookUrl}`} target="_blank" className="download-button">
          <BiLinkExternal color="#333" />
        </a>
      </div>
      <hr />
      <div className="card-body activity-scroll">
        <div className="activity" style={{ overflow: "hidden" }}>
          {extras?.recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{
                  marginRight: "15px",
                  background: activity.action === "create" ? "limegreen" : "#e9e7ed",
                }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity?.action === "create" ? "Created By" : "Updated By"} <strong>{activity?.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{moment(activity?.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
