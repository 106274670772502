import { t } from "i18next";
import React from "react";
import { FaAsterisk, FaLock, FaGlobeEurope, FaEyeSlash, FaPlus } from "react-icons/fa";
function MailboxAdmin() {
  return (
    <div className="container-fluid container-fluid-margin pt-80">
      <div className="page-header">
        <div
          className="flex flex-wrap items-center justify-between px-30px"
          style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px" }}
        >
          <div className="col">
            <div className="page-header-left">
              <h3 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                {t("caymland.mailbox.menu.admin.index")}
              </h3>
            </div>
          </div>
          <div className="d-flex items-center gap-10">{/* <Button /> */}</div>
        </div>
      </div>{" "}
    </div>
  );
}

export default MailboxAdmin;
