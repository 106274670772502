import DatePicker from "react-datepicker";
import React, { useState, useEffect } from "react";
import { Label } from "./index";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "react-datepicker/dist/react-datepicker.css"; // Ensure you have this CSS import

const CustomDatePicker = ({
  name,
  value,
  onChange,
  label,
  tooltip,
  required = false,
  minDate,
  maxDate,
  showTimeSelect = true,
  isClearable = false,
  isLoading = false,
  disabled = false,
  placeholderText,
  myBorder,
  noBorderRadius,
  marginBottom = true,
  viewEdit = false,
  extraStyle,
  timeFormat = "HH:mm",
  errors,
  datestring = false,
}) => {
  const dateFormat = showTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (date) => {
    if (date) {
      setInternalValue(date);
      onChange(date.toISOString());
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (isValidDate(inputValue)) {
      const formattedDate = formatDateString(inputValue);
      setInternalValue(formattedDate);
      onChange(new Date(inputValue).toISOString());
    } else {
      setInternalValue(inputValue);
      onChange(inputValue);
    }
  };

  const isValidDate = (date) => {
    return !isNaN(Date.parse(date));
  };

  const formatDateString = (isoString) => {
    if (showTimeSelect) {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    } else {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }
  };

  return datestring ? (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && label.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />
      )}
      <div style={{ position: "relative" }}>
        <input
          type="text"
          name={name}
          value={isValidDate(internalValue) ? formatDateString(internalValue) : internalValue}
          onChange={handleInputChange}
          placeholder={placeholderText}
          disabled={disabled}
          className={`form-control datepicker-placeholder hover-effect ${
            viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
          } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
          style={{ paddingLeft: "40px" }} // Ensure the input text starts after the icon
        />
      </div>
      <DatePicker
        name={name}
        selected={isValidDate(internalValue) ? new Date(internalValue) : null}
        onChange={handleChange}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown
        showMonthDropdown
        isClearable={isClearable}
        customInput={
          <CalendarTodayIcon
            style={{
              position: "absolute",
              left: "10px",
              top: "-35px",
              transform: "translateY(-50%)",
              color: "gray",
              fontSize: "22px",
            }}
          />
        }
        disabled={disabled}
        required={required}
        placeholderText={placeholderText}
      />
    </div>
  ) : (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && label.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />
      )}
      <DatePicker
        name={name}
        placeholderText={placeholderText}
        isLoading={isLoading}
        disabled={disabled}
        className={`form-control   datepicker-placeholder hover-effect ${
          viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
        } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown
        showMonthDropdown
        isClearable={isClearable}
        required={required}
        selected={value ? new Date(value) : null}
        onChange={(e) => onChange(e ? new Date(e) : null)}
      />
    </div>
  );
};

export default CustomDatePicker;
