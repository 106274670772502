import React, { useEffect, Fragment, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import Button from "../../../../reusableComponents/Buttons/Button";
import { Select, Text, Checkbox } from "../../../../reusableComponents/Inputs";
import DeleteIcon from "@mui/icons-material/Delete";
import GenerateDynamicInputs from "../../../../reusableComponents/GenerateDynamicInputs";
import { t } from "i18next";

function Filters({ modifiedData, setModifiedData, handleDelete, filters }) {
  const [filteredState, setFilteredState] = useState({
    filters: [],
    filteredCondition: [],
    filteredTypes: [],
  });

  const glue = [
    { label: t("caymland.report.report.glue.choice.and"), value: "and" },
    { label: t("caymland.report.report.glue.choice.or"), value: "or" },
  ];

  const orderOptions = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = glue.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
      resolve(filteredOptions);
    });
  };

  const handleAddToFilters = () => {
    setModifiedData((prevData) => {
      const maxKey = Math.max(0, ...Object.keys(prevData.filters).map(Number)) || 0;
      const newKey = maxKey + 1;

      return {
        ...prevData,
        filters: {
          ...prevData.filters,
          [newKey]: { column: null, glue: "and", value: null, dynamic: 0, condition: null },
        },
      };
    });
  };

  const handleFilterChange = (key, field, newValue) => {
    setModifiedData((prevData) => {
        const currentFilter = prevData.filters[key];

        if (field === "column" && currentFilter.column === newValue) {
            return prevData;
        }

        const updatedFilters = {
            ...prevData.filters,
            [key]: { ...currentFilter, [field]: newValue }
        };

        if (field === "column") {
            updatedFilters[key].value = null;
        }

        return {
            ...prevData,
            filters: updatedFilters,
        };
    });
};


  const handleToggleChange = (key) => {
    setModifiedData((prevData) => {
      const updatedFilters = { ...prevData.filters };
      if (updatedFilters[key]) {
        updatedFilters[key] = {
          ...updatedFilters[key],
          dynamic: !updatedFilters[key].dynamic,
        };
      }

      return {
        ...prevData,
        filters: updatedFilters,
      };
    });
  };

  useEffect(() => {
    if (modifiedData?.filters) {
      let newFilteredConditions = Object.values(modifiedData.filters).map((filter, index) => {
        const filteredOptions = filters?.filterOperatorList?.find((option) => option.value === filter?.column)
          ? Object.entries(filters?.filterOperatorList?.find((option) => option.value === filter?.column).label).map(
              ([name, label]) => ({
                value: name,
                label: label,
              })
            )
          : [];

        return { options: filteredOptions };
      });

      let newFilteredTypes = Object.values(modifiedData.filters).map((filter) => {
        const selectedColumn = filter?.column;
        const filterDefinition = filters?.filterDefinitions?.find((option) => option.label === selectedColumn);
        const type = filterDefinition ? filterDefinition?.type : null;
        let options = null;

        if ((type === "select" || type === "multiselect") && filterDefinition.options) {
          options = filterDefinition.options
            .map((option) => {
              if (Array.isArray(option.label)) {
                return option.label.map((label) => ({ label: label.label, value: label.value }));
              } else {
                return option;
              }
            })
            .flat();
        }

        return { type, options };
      });

      setFilteredState((prevData) => ({
        ...prevData,
        filteredCondition: newFilteredConditions,
        filteredTypes: newFilteredTypes,
      }));
    }
  }, [modifiedData, filters]);

  return (
    <Fragment>
      <div className="Filters">
        <h4 className="mt">
          <strong>{t("caymland.core.filters")}</strong>
        </h4>
        {Object.entries(modifiedData?.filters ?? {}).map(([key, e], i) => (
          <div
            key={i}
            style={{
              marginLeft: e.glue === "and" && i !== 0 && "20px",
              marginTop: e.glue === "or" && "20px",
              border: "1px solid #ebedf0",
              borderRadius: 0,
              boxShadow: "0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62,57,107,0.06)",
              padding: "15px 15px 45px 15px",
            }}
          >
            {i === 0 ? (
              <></>
            ) : (
              <>
                <div style={{ width: "18%" }}>
                  <Select
                    name="glue"
                    id="glue"
                    placeholder={`${t("caymland.core.select")}...`}
                    isClearable={false}
                    options={orderOptions}
                    value={glue?.find((option) => option.value === e.glue) ?? null}
                    onChange={(e) => handleFilterChange(key, "glue", e.value)}
                    extraStyle={{ minWidth: false, margin: false }}
                  />
                </div>
              </>
            )}
            <div className="flex gap-10 w-100 align-items-end flex-column1024">
              {filteredState?.filteredCondition && (
                <>
                  <div className="flex w-50 gap-10 childColumns">
                    <div style={{ width: "50%" }}>
                      <Select
                        name="column"
                        label={t("caymland.report.report.label.filtercolumn")}
                        placeholder={`${t("caymland.core.select")}...`}
                        isClearable={false}
                        options={filters?.filterList ?? []}
                        value={filters?.filterList?.find((option) => option.value === e.column) ?? null}
                        onChange={(e) => handleFilterChange(key, "column", e.value)}
                        extraStyle={{ minWidth: false, margin: true }}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <Select
                        name="condition"
                        label={t("caymland.report.report.label.filtercondition")}
                        placeholder={`${t("caymland.core.select")}...`}
                        isClearable={false}
                        options={filteredState?.filteredCondition[i]?.options ?? null}
                        value={filteredState?.filteredCondition[i]?.options?.find(
                          (option) => option.value === e.condition
                        )}
                        onChange={(e) => handleFilterChange(key, "condition", e.value)}
                        extraStyle={{ minWidth: false, margin: true }}
                      />
                    </div>
                  </div>
                  <div className="flex w-50 gap-10 childColumns">
                    <div className="flex media50" style={{ width: "65%", alignItems: "flex-end" }}>
                      <GenerateDynamicInputs
                       key={key} 
                       filterKey={key}
                        type={filteredState?.filteredTypes[i]?.type}
                        value={e.value}
                        onChange={handleFilterChange}
                        options={filteredState?.filteredTypes[i]?.options}
                        wFull="w-full"
                      />
                    </div>
                    <div>
                      <Checkbox
                        label={t("caymland.report.report.label.filterdynamic")}
                        name={`dynamic-${i}`}
                        id={`dynamic-${i}`}
                        value={modifiedData?.filters[key]?.dynamic ?? null}
                        onChange={(e) => handleToggleChange(key, e)}
                        tooltip={t("caymland.report.report.label.filterdynamic_tooltip")}
                        extraStyle={{ marginRight: 0, color: "red" }}
                        alignSelf={true}
                      />
                    </div>
                    <div className="flex" style={{ marginTop: '12px'}}>
                      <div className="flex items-center">
                        <FaTrashAlt
                        className="cursor-pointer"
                        onClick={() => handleDelete("filters", key)}
                        color= "#f86b4f"
                        size={18}
                      />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        <div className="mb-20px" style={{marginTop: Object.entries(modifiedData?.filters)?.length ? '20px' : '0.5rem'}}>
          <Button
            buttons={[
              {
                name: "addFilter",
                title: t("caymland.report.report.label.addfilter"),
                onClick: () => handleAddToFilters(),
              },
            ]}
            variant="contained"
            textFormat="capitalize"
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Filters;
