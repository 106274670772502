import React from "react";
import Moment from "moment";
import { DragHandle } from "../../widgetGenerator/index";
import { Label } from "../../../../reusableComponents/Inputs";
import EditDeleteMenu from "../../EditDeleteMenu";
import { t } from "i18next";

export default function Activities({ name, logs, width, height, handleEdit, handleDelete, id }) {
  return (
    <div>
      <div className="chart-header">
        <DragHandle />
        <div style={{ marginTop: "8px", marginLeft: "10px" }}>
          <Label name={name} label={name} />
        </div>
        <EditDeleteMenu handleEdit={handleEdit} handleDelete={handleDelete} id={id} />
      </div>
      <div style={{ height: height, minHeight: height }}>
        {!Array.isArray(logs)
          ? [logs]?.map((x) => (
              <div className="d-flex">
                <div
                  className="flex-shrink-0 gradient-round  gradient-line-1"
                  style={{ marginRight: "10px", background: "#d8d8d8" }}
                ></div>
                <div className="flex-grow-1">
                  <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                    <strong>{x.userName}</strong>{" "}
                    {x.action === "update" ? t("caymland.dashboard.update.past.tense") : x.action}{" "}
                    <strong>{x.objectName}</strong> {x.object}
                  </p>
                  {x.dateModified ? (
                    <small style={{ fontSize: "12px" }}>
                      {Moment(x.details.dateModified[x.dateModified?.length - 1]).format("DD.MM.YY hh:mm")}
                    </small>
                  ) : (
                    <small style={{ fontSize: "12px" }}>{Moment(x.dateAdded).format("DD.MM.YY hh:mm")}</small>
                  )}
                </div>
              </div>
            ))
          : logs?.map((x) => (
              <div className="d-flex " style={{ alignItems: "center" }}>
                <div
                  className="flex-shrink-0 gradient-round  gradient-line-1"
                  style={{ marginRight: "10px", background: "#d8d8d8" }}
                ></div>
                <div className="flex-grow-1 d-flex">
                  <p className="mb-0 pb-0" style={{ fontSize: "12px", marginRight: "10px" }}>
                    <strong>{x.userName}</strong>{" "}
                    {x.action === "update" ? t("caymland.dashboard.update.past.tense") : x.action}{" "}
                    <strong>{x.objectName}</strong> {x.object}
                  </p>
                  {x.dateModified ? (
                    <p style={{ fontSize: "12px" }} className="mb-0">
                      {Moment(x.details.dateModified[x.dateModified?.length - 1]).format("DD.MM.YY hh:mm")}
                    </p>
                  ) : (
                    <p style={{ fontSize: "12px" }} className="mb-0">
                      {Moment(x.dateAdded).format("DD.MM.YY hh:mm")}
                    </p>
                  )}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
