import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { ExpandMore } from "@mui/icons-material";

export const MRT_CustomExpandButton = ({ row, table, history }) => {
  const {
    getState,
    options: {
      icons: { ExpandMoreIcon },
      localization,
      muiExpandButtonProps,
      renderDetailPanel,
    },
  } = table;
  const canExpand = row.getCanExpand();
  const isExpanded = row.getIsExpanded();
  const { density } = getState();
  const disabled = (!canExpand && !renderDetailPanel) || !row?.original?.extra;

  const iconButtonProps =
    typeof muiExpandButtonProps === "function" ? muiExpandButtonProps({ table, row }) : muiExpandButtonProps;

  const handleToggleExpand = (event) => {
    event.stopPropagation();
    row.toggleExpanded();
    if (iconButtonProps && iconButtonProps.onClick) {
      iconButtonProps.onClick(event);
    }
  };
  const iconClass = row?.original?.icon || null; // Fallback to a default icon

  return (
    <Tooltip
      arrow
      disableHoverListener={!canExpand && !renderDetailPanel}
      enterDelay={1000}
      enterNextDelay={1000}
      title={
        iconButtonProps && iconButtonProps.title
          ? iconButtonProps.title
          : isExpanded
          ? localization.collapse
          : localization.expand
      }
    >
      <span>
        <IconButton
          aria-label={localization.expand}
          disabled={history ? disabled : !canExpand && !renderDetailPanel}
          {...iconButtonProps}
          onClick={handleToggleExpand}
          sx={(theme) => ({
            height: density === "compact" ? "1.75rem" : "2.25rem",
            width: density === "compact" ? "1.75rem" : "2.25rem",
            ...(typeof iconButtonProps?.sx === "function" ? iconButtonProps.sx(theme) : iconButtonProps?.sx),
          })}
          title={undefined}
        >
          {iconButtonProps && iconButtonProps.children ? (
            iconButtonProps.children
          ) : iconClass ? (
            <i
              className={`fa ${iconClass}`}
              style={{
                fontSize: "16px",
                color: "gray",
                transform: `scale(${!canExpand && !renderDetailPanel ? "1.01" : isExpanded ? "1.2" : "1"})`,
                opacity: disabled ? "0.5" : "1",
              }}
            ></i>
          ) : (
            <ExpandMore />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};
