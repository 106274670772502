import React from 'react'
import { RiLineChartLine } from 'react-icons/ri'
import DateFromTo from "../../../dashboard/defaultCompo/dateFromTo"
import LineChart from '../../../reusableComponents/ECharts/LineChart/LineEChart'
import { t } from 'i18next'

function CampaignStats({ campaignStats, handleDateChange }) {

  return (
    <>
      <div className="flex items-center justify-between">
        <div
          className="flex items-center gap-5px"
          style={{ width: "30%", color: "#8c8c8c", borderBottom: "20px" }}
        >
          <RiLineChartLine color="#8c8c8c" size={18} />
          {t("caymland.campaign.statistics")}
        </div>
        <div className='flex items-center gap-5px'>
          <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} />

        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <LineChart
          labelData={campaignStats?.labels || []}
          dataSeries={
            campaignStats?.datasets?.map((dataset, index) => ({
              name: dataset.label,
              data: dataset.data.map((value, i) => ({
                value,
                itemStyle: { color: dataset.backgroundColor },
              })),
            })) || []
          }
        />
      </div>
    </>
  )
}

export default CampaignStats