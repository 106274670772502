import { useTranslation } from "react-i18next";

const DateAdded = (props) => {
  const { t } = useTranslation();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours());
    const now = new Date();

    const diffMilliseconds = now - date;
    const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      return `${t("caymland.core.date.today", { time: formattedTime })}`;
    } else if (diffDays === 1) {
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      return `${t("caymland.lead.list.yesterday")}, ${formattedTime}`;
    } else {
      return ` ${t("caymland.core.time.days_numbered", { days: diffDays })}`;
    }
  };

  return (
    <span
      style={{
        display: "flex",
        color: "black",
        alignItems: "center",
        justifyContent: "start",
        width: "100%",
        paddingLeft: "2.5em",
      }}
    >
      {formatDate(props?.dateAdded)}
    </span>
  );
};

export default DateAdded;
