import React, { useState } from "react";
import { FaRegTimesCircle, FaUpload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "../../../reusableComponents/Inputs";
import Button from "../../../reusableComponents/Buttons/Button";
import Header from "../../../reusableComponents/Header/Header";
import { useTranslation } from "react-i18next";
import { BsSend } from "react-icons/bs";

const SendSms = () => {
  const [number, setNumber] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = location?.state?.id || location?.state || useParams().id;

  return (
    <>
      <Header buttons={[]} title={`${t("caymland.infobip.viber.send")}`} />

      <div className="d-flex justify-center">
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="panel-title">{t("caymland.sms.send.instructions")}</div>
          </div>
          <div className="panel-body">
            <form>
              <div className="input-group well mt-5">
                <input
                  type="text"
                  id="number"
                  name="number"
                  value={number}
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => {
                    setNumber(e);
                  }}
                />

                <span className="input-group-btn">
                  <Button
                    buttons={[
                      {
                        name: "send",
                        title: t("caymland.infobip.viber.send"),
                        icon: <BsSend size={15} />,
                        onClick: () => {},
                      },
                    ]}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-12">
                  <Button
                    buttons={[
                      {
                        name: "cancel",
                        title: t("caymland.core.form.cancel"),
                        onClick: () => navigate(`/sms/view/${id}`),
                      },
                    ]}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendSms;
