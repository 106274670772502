import { useSelector } from "react-redux";

const usePermissions = () => {
  const { rawPermissions: permissions = {}, isAdmin } = useSelector((state) => state?.auth?.user?.role || {});

  const hasPermission = (permissionKey, permissionValue) => {
    // Render button by default if permissionKey or permissionValue are not provided
    if (!permissionKey || !permissionValue) {
      return true;
    }
    if (isAdmin) {
      return true;
    }
    if (permissionKey in permissions) {
      if (!permissionValue || permissions[permissionKey].includes("full")) {
        return true;
      }
      return permissions[permissionKey].includes(permissionValue);
    }
    return false;
  };

  return { hasPermission };
};

export default usePermissions;
