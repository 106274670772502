import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaRegFileExcel } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DateFromTo from "../../../../dashboard/defaultCompo/dateFromTo";
import moment from "moment";
import Links from "./advancedReport/Links";
import EmailStats from "./advancedReport/EmailStats";
import { AiOutlineFileText } from "react-icons/ai";
import * as XLSX from "xlsx";
import { FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../../reusableComponents/Header/Header";
import {
  useDownloadReportMutation,
  useGetAddEditEmailsQuery,
  usePostAdvancedReportsMutation,
} from "../../../../../redux/api/emailsApi";
import Wrapper from "../../../../reusableComponents/Wrapper/Wrapper";
import SidebarSkeleton from "../../../../reusableComponents/SidebarSkeleton";
import cleanHtmlContent from "../../../../reusableComponents/cleanHtmlContent";
import { t } from "i18next";

function AdvancedReportView() {
  const [modifiedData, setModifiedData] = useState([]);
  const [extras, setExtras] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [trackables, setTrackables] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [highlightedLink, setHighlightedLink] = useState(null);
  const [highlightedLinkTable, setHighlightedLinkTable] = useState(null);

  const fromDate = moment(localStorage.getItem("fromDate")).format("DD-MM-YYYY");
  const toDate = moment(localStorage.getItem("toDate")).format("DD-MM-YYYY");

  // Construct the payload
  const payload = {
    date_from: fromDate,
    date_to: toDate,
  };

  const { data } = useGetAddEditEmailsQuery({ id });
  const [downloadReport] = useDownloadReportMutation();
  const [postAdvancedReports] = usePostAdvancedReportsMutation();

  useEffect(() => {
    if (data && id) {
      retrieveData();
    }
  }, [data, id]);

  useLayoutEffect(() => {
    if (trackables?.length > 0 && totalClicks > 0) {
      modifyHtmlWithTooltip(trackables);
    }
  }, [trackables, totalClicks]);

  const retrieveData = async () => {
    setLoading(true);
    try {
      const extras = await postAdvancedReports({ id, body: payload });

      const trackablesWithIndex = extras?.data?.trackables?.map((e, index) => ({
        ...e,
        index: index + 1,
      }));

      setTrackables(trackablesWithIndex);
      setModifiedData(data.email.email);
      setExtras(extras.data);
      modifyHtmlWithTooltip(trackables);

      let totalClicks = 0;
      trackablesWithIndex?.map((e) => {
        totalClicks += parseInt(e.hits, 10);
      });
      setTotalClicks(totalClicks);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const modifiedDataRef = useRef();

  const modifyHtmlWithTooltip = (extrasData) => {
    if (modifiedDataRef.current && extrasData && totalClicks > 0) {
      let container = modifiedDataRef.current;
      extrasData.forEach((trackable) => {
        const total = ((trackable?.hits * 100) / totalClicks).toFixed(2);
        let links = container.querySelectorAll(`a[href="${trackable.url}"]`);
        links.forEach((link) => {
          const tooltip = document.createElement("div");
          tooltip.setAttribute("id", `${trackable.redirect_id}`);
          tooltip.classList.add("tooltip");

          tooltip.addEventListener("click", (evt) => {
            handleClick(evt, trackable.redirect_id);
          });
          tooltip.innerText = `${trackable.index}: ${total}% Clicks: ${trackable.hits}`;
          link.style.position = "relative";
          link.appendChild(tooltip);
        });
      });
      addStyleTooltip();
    }
  };

  const addStyleTooltip = () => {
    const tooltipElements = document.querySelectorAll(".tooltip");
    tooltipElements.forEach((tooltip) => {
      const redirectId = tooltip.getAttribute("id");
      if (highlightedLink === redirectId || highlightedLinkTable === redirectId) {
        tooltip.classList.add("highlighted");
        tooltip.classList.remove("default");
      } else {
        tooltip.classList.add("default");
        tooltip.classList.remove("highlighted");
      }
    });
  };
  useEffect(() => {
    addStyleTooltip();
    setPrimaryColorTab(2);
  }, [highlightedLink, highlightedLinkTable]);

  const handleClick = (evt, trackingId) => {
    evt.preventDefault();
    setHighlightedLinkTable(trackingId);
    setHighlightedLink(null);
  };

  const navBack = () => {
    if (id !== undefined) navigate(`/emails/view/${id}`);
    else navigate(`/emails`);
  };

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };

  const handleDateChange = () => {
    retrieveData(true);
  };

  const exportPDF = async () => {
    try {
      const response = await downloadReport({ id: modifiedData.id });
      console.log(response);

      const blob = new Blob([response.data], { type: "application/pdf" });

      const pdfUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", `emailReport-${modifiedData.id}.pdf`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Failed to download PDF:", error);
    }
  };
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    const statsData = extras?.stats?.map((stat) => ({
      Label: stat?.label,
      Percentage: stat?.percentage,
      Value: stat?.value,
    }));
    console.log(statsData);
    const statsWs = XLSX?.utils?.json_to_sheet(statsData);
    XLSX.utils.book_append_sheet(wb, statsWs, "Stats");

    const trackablesData = extras?.trackables?.map((trackable) => ({
      Hits: trackable?.hits,
      ID: trackable?.id,
      Redirect_ID: trackable?.redirect_id,
      Unique_Hits: trackable?.unique_hits,
      URL: trackable?.url,
    }));
    const trackablesWs = XLSX?.utils?.json_to_sheet(trackablesData);
    XLSX?.utils?.book_append_sheet(wb, trackablesWs, "Trackables");

    XLSX?.writeFile(wb, "export.xlsx");
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navBack(),
    },
    {
      name: "exportToPDF",
      title: t("caymland.form.result.export.pdf"),
      icon: <AiOutlineFileText size={15} />,
      onClick: () => exportPDF(),
    },
    {
      name: "ExportToExcel",
      title: t("caymland.form.result.export.xlsx"),
      icon: <FaRegFileExcel size={15} />,
      onClick: () => exportToExcel(),
    },
  ];
  const childOne = <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} />;
  return (
    <Fragment>
      {!data ? (
        <div className="flex items-center justify-center">
          <SidebarSkeleton />
        </div>
      ) : (
        <>
          <Header
            title={t("caymland.email.report.header")}
            buttons={buttons}
            childOne={modifiedData?.emailType === "template" && childOne}
          />
          <Wrapper
            childrenClassOne={"w-60 border-radius-5px h-92 overflow-auto mb-0"}
            childrenOne={
              <div className="flex items-center w-full gap-10">
                <div className="input-container ic1 " style={{ height: "100%" }}>
                  {modifiedData && (
                    <div
                      className="historyDetails"
                      style={{ position: "relative", height: "auto", overflowX: "auto", borderRadius: "5px" }}
                      ref={modifiedDataRef}
                      dangerouslySetInnerHTML={{
                        __html: modifiedData?.customHtml && cleanHtmlContent(modifiedData?.customHtml),
                      }}
                    ></div>
                  )}
                </div>
              </div>
            }
            childrenClassTwo={"w-40 border-radius-5px mb-0 h-92"}
            childrenTwo={
              <Card style={{ boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)" }}>
                <CardBody style={{ padding: 0 }}>
                  <Nav className="nav-primary flex col-md-12 col-lg-12" tabs style={{ borderRadius: "5px !important" }}>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 1 ? "active" : ""}
                        onClick={() => changeDynamicTab(1)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.email.report.tab1")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 2 ? "active" : ""}
                        onClick={() => changeDynamicTab(2)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.email.report.tab2")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={primarycolorTab}>
                    <TabPane tabId={1}>
                      <EmailStats extras={extras} id={id} />
                    </TabPane>
                    <TabPane tabId={2}>
                      <Links
                        extras={extras}
                        highlightedLink={highlightedLink}
                        setHighlightedLink={setHighlightedLink}
                        highlightedLinkTable={highlightedLinkTable}
                        setHighlightedLinkTable={setHighlightedLinkTable}
                        id={id}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            }
          />
        </>
      )}
    </Fragment>
  );
}

export default AdvancedReportView;
