import React from 'react';
import { Text } from "../../../../../reusableComponents/Inputs";
import { BiDotsVertical } from "react-icons/bi";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { t } from 'i18next';

const SortableItem = React.memo(SortableElement(({ value, index, onItemChange, onRemove, rowIndex }) => {
    const handleItemChange = (e, type) => {
      const updatedValue = { ...value, [type]: e.target.value };
      onItemChange(rowIndex, updatedValue);
    };
    return (
      <div index={rowIndex} key={index} className="d-flex" style={{ marginBottom: "5px" }}>
        <button
          type="button"
          onClick={() => onRemove(value)}
          className="result-component"
          style={{ height: "40px", width: "80px" }}
        >
          X
        </button>
        <div className="mb-1" style={{ height: "28px", width: "100%" }}>
        <Text
          name={`label-${index}`}
          value={value.label || ""}
          onChange={(e) => handleItemChange(e, "label")}
          label={t("caymland.core.label")}
        />
        </div>
        <div className="mb-1" style={{ height: "28px", width: "100%" }}>
        <Text
          name={`value-${index}`}
          value={value.value || ""}
          onChange={(e) => handleItemChange(e, "value")}
          label={t("caymland.core.value")}
        />
        </div>
    
        <button type="button" className="result-component" style={{ height: "40px", width: "80px" }}>
          <BiDotsVertical size={12} />
        </button>
      </div>
    );
  }));

const SortableList = SortableContainer(({ items, onItemChange, onRemove }) => {
    return (
      <div>
        {Array.isArray(items) &&
          items.map((value, index) => (
            <SortableItem
              value={value}
              rowIndex={index}
              index={index}
              key={value.id}
              onItemChange={onItemChange}
              onRemove={onRemove}
            />
          ))}
      </div>
    );
  });

  export default SortableList;