import React, { useState, useEffect } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { Text, TextArea, Select, Label } from "../../../../reusableComponents/Inputs";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import { assetsApi, useGetAssetsQuery } from "../../../../../redux/api/assetsApi";
import { t } from "i18next";

function Advanced({ modifiedData, setModifiedData, setOriginalData, originalData }) {
  useEffect(() => {
    // Check if headers is present and is an object
    if (modifiedData.headers && typeof modifiedData.headers === "object" && !Array.isArray(modifiedData.headers.list)) {
      // Create a new array from the headers object
      const headersList = Object.entries(modifiedData.headers).map(([key, value]) => ({
        label: key,
        value: value,
      }));

      // Update the headers in modifiedData
      setModifiedData((prevData) => ({
        ...prevData,
        headers: {
          list: headersList,
        },
      }));
      setOriginalData((prevData) => ({
        ...prevData,
        headers: {
          list: headersList,
        },
      }));
    }
  }, [modifiedData, setModifiedData, originalData, setOriginalData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModifiedData({ ...modifiedData, [name]: value });
  };
  const [triggerAssetsQuery] = assetsApi.useLazyGetAssetsQuery();

  const retrieveAssets = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerAssetsQuery) resolve([]);

      const query = {};

      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerAssetsQuery(query).unwrap();
      const data = response?.data;
      resolve(data);
    });

  function handleOptionsAdd() {
    const newItem = { label: "", value: "" };

    // Check if 'list' exists and is an array, otherwise use an empty array
    const currentList = Array.isArray(modifiedData?.headers?.list) ? modifiedData?.headers?.list : [];

    const newList = [...currentList, newItem];

    setModifiedData({
      ...modifiedData,
      headers: {
        ...modifiedData.headers,
        list: newList,
      },
    });
  }

  return (
    <div className="grid" style={{ gap: "50px" }}>
      <div className="flex gap-30 mt-10 items-start">
        <div className="grid " style={{ flex: 1 }}>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="fromName"
              value={modifiedData?.fromName || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.from_name")}
              tooltip={t("caymland.email.from_name.tooltip")}
              iconStart={<PersonIcon sx={{ fontSize: 22 }} />}
            />
          </div>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="fromAddress"
              value={modifiedData?.fromAddress || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.from_email")}
              tooltip={t("caymland.email.from_email.tooltip")}
              iconStart={<MailIcon sx={{ fontSize: 22 }} />}
            />
          </div>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="replyToAddress"
              value={modifiedData?.replyToAddress || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.reply_to_email")}
              tooltip={t("caymland.email.reply_to_email.tooltip")}
              iconStart={<MailIcon sx={{ fontSize: 22 }} />}
            />
          </div>
          <div className="w-100" style={{ flexWrap: "nowrap" }}>
            <Text
              name="bccAddress"
              value={modifiedData?.bccAddress || ""}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.email.bcc")}
              tooltip={t("caymland.email.bcc.tooltip")}
              iconStart={<MailIcon sx={{ fontSize: 22 }} />}
            />
          </div>

          <div className="small-editor" style={{ maxWidth: "600px" }}>
            <TextArea
              label={t("caymland.email.form.previewtext")}
              tooltip={t("caymland.email.bcc.tooltip")}
              value={modifiedData?.previewText ?? ""}
              onChange={(data) => {
                setModifiedData((prev) => ({
                  ...prev,
                  previewText: data ?? "",
                }));
              }}
            />
          </div>

          <div style={{ marginBottom: "15px", marginTop: "-10px" }}>
            <Select
              name="assets"
              label={t("caymland.mailbox.compose.label.attachments")}
              options={retrieveAssets}
              labelField="title"
              valueField="id"
              value={modifiedData?.assetAttachments || ""}
              onChange={(e) => {
                if (e === null) {
                  setModifiedData({ ...modifiedData, assetAttachments: [] });
                } else {
                  setModifiedData({
                    ...modifiedData,
                    assetAttachments: e,
                  });
                }
              }}
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className="flex items-center p-no-margin gap-5px">
            <Label
              name="customHeaders"
              label={t("caymland.email.custom_headers")}
              tooltip={t("caymland.email.custom_headers.tooltip")}
            />
          </div>
          <button type="button" onClick={handleOptionsAdd} className="addOptions-btn">
            <span>{`${t("caymland.core.form.add")} ${t("caymland.core.value")}`}</span>
          </button>
          <div style={{ flex: 1 }}>
            {modifiedData?.headers?.list?.map((item, index) => {
              const handleRemoveClick = () => {
                const newList = [...modifiedData.headers.list];
                newList.splice(index, 1);
                setModifiedData({
                  ...modifiedData,
                  headers: {
                    ...modifiedData.headers,
                    list: newList,
                  },
                });
              };

              const handleInputChange = (e, field) => {
                const newList = [...modifiedData.headers.list];
                newList[index][field] = e.target.value;

                setModifiedData({
                  ...modifiedData,
                  headers: {
                    ...modifiedData.headers,
                    list: newList,
                  },
                });
              };

              return (
                <div key={index} className="d-flex" style={{ marginBottom: "5px" }}>
                  <button
                    type="button"
                    onClick={handleRemoveClick}
                    className="result-component"
                    style={{
                      height: "40px",
                      width: "80px",
                    }}
                  >
                    X
                  </button>
                  <div className="mb-1" style={{ height: "28px", width: "100%" }}>
                    <Text
                      name="label"
                      value={item?.label || ""}
                      onChange={(e) => handleInputChange(e, "label")}
                      label={t("caymland.form.field.form.label")}
                    />
                  </div>
                  <div className="mb-1" style={{ height: "28px", width: "100%" }}>
                    <Text
                      name="value"
                      value={item?.value || ""}
                      onChange={(e) => handleInputChange(e, "value")}
                      label={t("caymland.caymlandb2b.opportunity.form.value")}
                    />
                  </div>
                  <button type="button" className="result-component" style={{ height: "40px", width: "80px" }}>
                    <span>
                      <BiDotsVertical size={12} />
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advanced;
