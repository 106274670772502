const Styles = {
    handleStyle: {
        width: 15,
        height: 15,
        borderColor: '#555',
        background: '#555',
    },
    handleStyleLeft: {
        width: 15,
        height: 15,
        borderColor: 'transparent',
        left: 30,
        background: '#04B76B',
    },
    handleStyleCenter: {
        width: 15,
        height: 15,
        borderColor: '#555',
        top: -6,
        background: '#555',
    },
    handleStyleRight: {
        width: 15,
        height: 15,
        borderColor: 'transparent',
        left: 138,
        background: '#e56249',
    },

    handleStyleTop: {
        width: 15,
        height: 15,
        top: -6,
        borderColor: '#555',
        background: '#555',
    },
    handleStyleBottom: {
        width: 15,
        height: 15,
        borderColor: '#555',
        background: '#555',
    },
    buttonEditStyle: {
        border: "1px dashed #555",
        marginRight: 4,
        background: '#fff'
    },

    buttonDeleteStyle: {
        border: "1px dashed #e56249",
        background: '#fff'
    },
    edgesColor: {
        yes: '#04B76B',
        no: '#e56249',
        leadsourceright: '#555',
        leadsourceleft: '#555',
        leadsource: '#555',
        bottom: '#555',
    },
    edgesType: {
        yes: 'step',
        no: 'step',
        leadsourceright: 'default',
        leadsourceleft: 'default',
        leadsource: 'default',
        bottom: 'step',
    },
    handleStyleJump: {
        width: 15,
        height: 15,
        position: 'absolute',
        bottom: '-7px',
        fill: 'black'
    },
}


export default Styles
