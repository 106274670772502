import React, { Fragment, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { TextArea, Text, Checkbox } from "../../../reusableComponents/Inputs";
import Button from "../../../reusableComponents/Buttons/Button";
import { useSendTestHookMutation } from "../../../../redux/api/webHooksApi";
import SendIcon from "@mui/icons-material/Send";
import { t } from "i18next";

function Details({ errors, setErrors, modifiedData, setModifiedData, triggers }) {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [sendTestHook] = useSendTestHookMutation();

  const handleSendTestPayload = async () => {
    setLoading(true);
    setResponseMessage("");

    try {
      const response = await sendTestHook({
        url: modifiedData?.webhookUrl,
        types: modifiedData?.triggers,
      }).unwrap();
      setResponseMessage(response.html);
      console.log("response", response);
    } catch (error) {}
    setLoading(false);
  };
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    let newErrors = { ...errors };

    if (["name", "webhookUrl"].includes(name)) {
      if (value.length > 0) {
        delete newErrors[name];
      } else {
        newErrors[name] = true;
      }
      setErrors(newErrors);
    }

    if (type === "text") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleToggleChange = (triggerName) => {
    setModifiedData((prevData) => {
      let newTriggers;
      if (prevData.triggers.includes(triggerName)) {
        newTriggers = prevData.triggers.filter((t) => t !== triggerName);
      } else {
        newTriggers = [...prevData.triggers, triggerName];
      }
      return { ...prevData, triggers: newTriggers };
    });
  };

  return (
    <Fragment>
      <div className="flex flex-row w-full height120">
        <div className="flex flex-col gap-10 mt-20 w-50" style={{ paddingRight: 30 }}>
          <div class="input-container ic1 mb-20px">
            <Text
              name="name"
              value={modifiedData?.name}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.name")}
              errors={errors.name}
              required={true}
            />
          </div>
          <TextArea
            name="description"
            label={t("caymland.webhook.form.description")}
            value={modifiedData?.description ?? ""}
            onChange={(data) => {
              setModifiedData((prev) => ({
                ...prev,
                description: data,
              }));
            }}
          />
          <Text
            name="webhookUrl"
            value={modifiedData?.webhookUrl}
            onChange={(e) => handleInputChange(e)}
            required={true}
            errors={errors?.webhookUrl}
            placeholder="http://exemple.com"
            label={t("caymland.webhook.form.webhook_url")}
            tooltip={t("caymland.webhook.form.message.url")}
          />
          <div className="w-100 d-flex justify justify-between">
            <Button
              loading={loading}
              buttons={[
                {
                  name: "sendTestPayload",
                  title: t("caymland.webhook.send.test.payload"),
                  icon: <SendIcon />,
                  onClick: handleSendTestPayload,
                },
              ]}
            />
            {responseMessage && <div dangerouslySetInnerHTML={{ __html: responseMessage }} />}
          </div>
        </div>
        <div className="flex flex-col gap-10 mt-20 w-50" style={{ paddingRight: 30 }}>
          <h4 className="mandatory-field">{t("caymland.webhook.form.webhook.events")}</h4>
          <>
            {triggers?.map((trigger) => (
              <div className="checkbox-wrapper-46" key={trigger[0]} onClick={() => handleToggleChange(trigger[0])}>
                <input
                  className="inp-cbx"
                  htmlFor={trigger[0]}
                  type="checkbox"
                  checked={modifiedData?.triggers?.includes(trigger[0])}
                />
                <label className="cbx" htmlFor={trigger[0]}>
                  <span>
                    <svg width="12px" height="10px" viewbox="0 0 12 10">
                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </svg>
                  </span>
                  <span> {trigger[1].label}</span>
                </label>
              </div>
            ))}
          </>
        </div>
      </div>
    </Fragment>
  );
}

export default Details;
