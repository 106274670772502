import React, { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import { useDeleteHookMutation, useGetHooksQuery, useUpdateHookMutation } from "../../../redux/api/webHooksApi";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function WebHooks(props) {
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(-1);
  const name = "WebHooks";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });

  const { data, isFetching, refetch } = useGetHooksQuery(query);
  const [updateHook] = useUpdateHookMutation();
  const [deleteHook] = useDeleteHookMutation();

  const handleTogglePublished = async (data) => {
    const updatedData = {
      ...data,
      isPublished: !data.isPublished,
    };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateHook(updatedData);
    await refetch();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.core.name"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="webhooks"
          view={true}
        />
      ),
    },
    {
      accessorKey: "webhookUrl",
      accessorFn: (row) => row.webhookUrl,
      header: t("caymland.webhook.webhook_url"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.webhookUrl,
    },
  ]);

  const actionButtons = ActionButtons({
    link: "webhooks",
    deleteItem: deleteHook,
    refetch: refetch,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "hooks",
    refetch: refetch,
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => navigate("/webhooks/new"),
          },
        ]}
        title={t("caymland.webhook.webhooks")}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        isFetching={isFetching}
      />
    </Fragment>
  );
}

export default WebHooks;
