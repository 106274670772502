import React, { useState } from "react";
import Button from "../../../reusableComponents/Buttons/Button";
import { MdOutlineBolt } from "react-icons/md";
import QuickAddModal from "./QuickAddModal";
import { FaPlus } from "react-icons/fa";
import { Fade, IconButton, Tooltip, Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";

const QuickAdd = ({ setShowAlert, setAlertMessage, plusIcon = false, id }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <div>
      <QuickAddModal
        id={id}
        show={show}
        setShow={setShow}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
      />
      {plusIcon ? (
        <div style={{ marginBottom: "6px" }}>
          <Tooltip
            title={`${t("caymland.core.form.add")} ${t("caymland.lead.contact")}`}
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            PopperProps={{
              disablePortal: true,
            }}
          >
            <IconButton style={{ cursor: "help", padding: 0 }}>
              <FaPlus color="#686868" size={17} style={{ marginRight: "4px" }} onClick={() => setShow(true)} />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <Button
          buttons={[
            {
              name: "Quick Add",
              title: t("caymland.lead.lead.menu.quickadd"),
              icon: <MdOutlineBolt size={15} />,
              onClick: () => setShow(true),
            },
          ]}
        />
      )}
    </div>
  );
};

export default QuickAdd;
