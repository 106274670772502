import React, { useState } from "react";
import Button from "../../reusableComponents/Buttons/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { t } from "i18next";

const DateFromTo = ({ cls, saveFromTo, dateChange, setDateChange, showApplyButton = true, loading, customStyle }) => {
  const [stateChange, setStateChange] = useState(false);
  const dateFormat = "DD.MM.YYYY";

  const handleChange = (newDate) => {
    const date = dayjs(newDate);
    if (date.isValid()) {
      localStorage.setItem("fromDate", date.toISOString());
    }
    setStateChange(!stateChange);
  };
  
  const handleEndDate = (newDate) => {
    const date = dayjs(newDate);
    if (date.isValid()) {
      localStorage.setItem("toDate", date.toISOString());
    }
    setStateChange(!stateChange);
  };
  
  const safeNewDate = (dateString) => {
    if (dateString) {
      const parsedDate = dayjs(dateString);
      if (parsedDate.isValid()) {
        return parsedDate.toDate();
      }
    }
    return dayjs().toDate();
  };
  
  

  return (
    <>
      <div className={`flex gap-5px ${customStyle?.className || ''}`}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ width: customStyle?.width || "200px", maxHeight: "40px" }}>
            <DatePicker
              label={t("caymland.dashboard.label.appointments.start")}
              value={dayjs(safeNewDate(localStorage.getItem("fromDate")))}
              onChange={handleChange}
              maxDate={dayjs(safeNewDate(localStorage.getItem("toDate")))}
              slotProps={{ textField: { size: "small" } }}
              format={dateFormat}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  color: "rgba(0, 0, 0, 0.54)",
                },
                "& .MuiSvgIcon-root": {
                  color: "rgba(0, 0, 0, 0.48)",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.54)",
                },
                "& .MuiInputBase-root": {
                  height: "40px",
                },
                "&:hover": {
                  transform: "scale(1.003)",
                  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.4s ease, box-shadow 0.4s ease",
                },
              }}
            />
          </div>
          <RemoveIcon color="action" fontSize="small" style={{ alignSelf: "center" }} />
          <div style={{ width: customStyle?.width || "200px", maxHeight: "40px" }}>
            <DatePicker
              label={t("caymland.dashboard.label.appointments.end")}
              value={dayjs(safeNewDate(localStorage.getItem("toDate")))}
              onChange={handleEndDate}
              minDate={dayjs(safeNewDate(localStorage.getItem("fromDate")))}
              slotProps={{ textField: { size: "small" } }}
              format={dateFormat}
              sx={{
                backgroundColor: "white",
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  color: "rgba(0, 0, 0, 0.54)",
                },
                "& .MuiSvgIcon-root": {
                  color: "rgba(0, 0, 0, 0.48)",
                },
                "& .MuiInputBase-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.54)",
                },
                "&:hover": {
                  transform: "scale(1.003)",
                  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.4s ease, box-shadow 0.4s ease",
                },
              }}
            />
          </div>
        </LocalizationProvider>
        {showApplyButton && (
          <div>
            <Button
              color="secondary"
              loading={loading}
              buttons={[
                {
                  name: t("caymland.core.form.apply"),
                  icon:   <ArrowForwardOutlinedIcon style={{ color: "rgba(0, 0, 0, 0.48)" }} />,
                  onClick: () => saveFromTo(),
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DateFromTo;
