import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetViewFormQuery } from "../../../../../redux/api/formsApi";

const PreviewForm = () => {
  const { id } = useParams();
  const { data } = useGetViewFormQuery(id);
  const [iframe, setIframe] = useState("");

  const iframeString = data?.automatics?.automatic?.viaIframe;

  const decodeHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.documentElement.textContent;
  };

  useEffect(() => {
    if (iframeString) {
      const decodedIframe = decodeHtml(iframeString);
      const parser = new DOMParser();
      const doc = parser.parseFromString(decodedIframe, "text/html");
      const iframeElement = doc.querySelector("iframe");

      if (iframeElement) {
        // Get the current src value
        let src = iframeElement.getAttribute("src");

        // Check if src already has query parameters
        if (src) {
          const hasParams = src.includes("?");
          src += hasParams ? "&isApi=1" : "?isApi=1";

          // Update the src attribute with the new URL
          iframeElement.setAttribute("src", src);
        }

        // Modify the style attribute
        iframeElement.style.width = "100vw";
        iframeElement.style.height = "100vh";

        // Serialize the document back to HTML
        const updatedIframe = doc.body.innerHTML;
        setIframe(updatedIframe);
      }
    }
  }, [iframeString]);

  return <div dangerouslySetInnerHTML={{ __html: iframe }} />;
};

export default PreviewForm;
