import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckDouble } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { t } from "i18next";

function Apply({ submit, loading, color }) {
  const navigate = useNavigate();

  return (
    <button type="submit" onClick={submit} className="btn-white flex items-center gap-5px">
      {loading ? <ClipLoader color={color ? color : "#fff"} size={13} /> : <FaCheckDouble size={13} />}
      {t("caymland.core.form.apply")}
    </button>
  );
}

export default Apply;
