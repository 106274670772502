import React, { Fragment, useEffect, useState } from "react";
import NewCategoryPopUp from "../../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Select, Checkbox } from "../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../reusableComponents/retriveCategoriesByBundle";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../redux/api/categoriesApi";
import { t } from "i18next";
import ModalGenerator from "../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../reusableComponents/ModalGenerator/ModalGeneratorData";

function Sidebar({ modifiedData, setModifiedData }) {
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "global",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const events = [
    { title: t("caymland.core.form.default"), value: "" },
    { title: t("caymland.webhook.config.event.orderby.chronological"), value: "ASC" },
    { title: t("caymland.webhook.config.event.orderby.reverse.chronological"), value: "DESC" },
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "text")
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  return (
    <Fragment>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Select
        name="category"
        label={t("caymland.core.category")}
        options={retrieveCategories}
        trigger={triggerCategories}
        value={modifiedData?.category}
        bundle="global"
        placeholder={t("caymland.query.builder.select.placeholder")}
        onChange={handleSelectChange}
        isCreatable
        isClearable
      />
      <Select
        name="eventsOrderbyDir"
        label={t("caymland.webhook.config.event.orderby")}
        options={events}
        value={
          events.find((e) => e.value === (modifiedData?.eventsOrderbyDir?.value || modifiedData?.eventsOrderbyDir)) ||
          events[0]
        }
        placeholder={t("caymland.query.builder.select.placeholder")}
        valueField="value"
        tooltip={t("caymland.webhook.config.event.orderby.tooltip")}
        labelField="title"
        onChange={(e) =>
          setModifiedData((prev) => ({
            ...prev,
            eventsOrderbyDir: e,
          }))
        }
      />
      <Checkbox
        name={"isPublished"}
        value={modifiedData?.isPublished}
        onChange={(e) => handleInputChange(e)}
        label={t("caymland.core.form.published")}
      />
    </Fragment>
  );
}

export default Sidebar;
