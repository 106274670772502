import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const serialLettersApi = createApi({
  reducerPath: "serialLettersApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["SerialLetters"],
  endpoints: (build) => ({
    getSerialLetters: build.query({
      query: customBaseQuery((q) => `/api/serialletters?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["SerialLetters"],
    }),
    // getStagesOptions: build.query({
    //   query: (q) => `/api/stages/select`,
    //   keepUnusedDataFor: false,
    //   providesTags: ["Stages"],
    //   forceRefetch({ currentArg, previousArg }) {
    //     return currentArg !== previousArg;
    //   },
    // }),

    getSerialLettersOptions: build.query({
      query: (q) => `/api/serialletters/select`,
      keepUnusedDataFor: 30,
      providesTags: ["SerialLetters"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getSerialLetterById: build.query({
      query: (id) => `/api/serialletters/${id}`,
      providesTags: (result, error, id) => [{ type: "SerialLetters", id }],
    }),
    updateSerialLetter: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/serialletters/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "SerialLetters", id }],
    }),
    deleteSerialLetter: build.mutation({
      query: (id) => ({
        url: `/api/serialletters/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          serialLettersApi.util.updateQueryData("getSerialLetters", undefined, (draft) => {
            draft.data = draft.data.filter((stages) => stages.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "SerialLetters", id }],
    }),
    addSerialLetter: build.mutation({
      query: (body) => ({
        url: `/api/serialletters/new`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "SerialLetters" }],
    }),
    uploadSerialLetter: build.mutation({
      query: (body) => ({
        url: `/api/serialletters/upload`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "SerialLetters" }],
    }),
    // //FOLDERS
    // //GET FOLDERS
    getFolderSerialLetters: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=serialletters`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderSerialLetters: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    // //EDIT FOLDER
    updateFolderStages: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "SerialLetters", id }],
    }),
    // //DELETE FOLDER
    deleteFolderStages: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    // //MOVE CAMPAIGN TO FOLDER
    updateSerialLettersToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/serialletters/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Stages", id }],
    }),
    // //REMOVE CAMPAIGN TO FOLDER
    removeSerialLettersToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/serialletters/${itemId}`,
        method: "DELETE",
      }),
    }),

    downloadLeadLetter: build.mutation({
      query: ({ objectId, leadId }) => ({
        url: `/api/serialletters/${objectId}/lead/${leadId}/download`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
      invalidatesTags: [{ type: "SerialLetters" }],
    }),
  }),
});

export const {
  useGetSerialLettersQuery,
  useGetSerialLetterByIdQuery,
  useUpdateSerialLetterMutation,
  useDeleteSerialLetterMutation,
  useAddSerialLetterMutation,
  useGetFolderSerialLettersQuery,
  useUploadSerialLetterMutation,
  useAddFolderSerialLettersMutation,
  useUpdateFolderStagesMutation,
  useDeleteFolderStagesMutation,
  useUpdateSerialLettersToFoldersMutation,
  useGetSerialLettersOptionsQuery,
  useDownloadLeadLetterMutation,
} = serialLettersApi;
