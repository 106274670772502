import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cleanHtmlContent from "../../../../../reusableComponents/cleanHtmlContent";
import { useEmailTestingQuery } from "../../../../../../redux/api/emailsApi";
import Wrapper from "../../../../../reusableComponents/Wrapper/Wrapper";
import { Select } from "../../../../../reusableComponents/Inputs";
import { contactsApi } from "../../../../../../redux/api/contactsApi";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Tooltip } from "@mui/material";
import DetailsSkeleton from "../../../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../../../reusableComponents/SidebarSkeleton";
import Button from "../../../../../reusableComponents/Buttons/Button";
import { t } from "i18next";
import { FaRegTimesCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

function EmailTesting(props) {
  const { id } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [contactId, setContactId] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  const navigate = useNavigate();

  //   const { data, isLoading } = useGetEmailPreviewQuery(id, { skip: !id });
  const { data, refetch, isFetching, isLoading } = useEmailTestingQuery({ id, contactId });
  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();

  useEffect(() => {
    if (id && data) {
      let html = cleanHtmlContent(data?.content);
      setHtmlContent(data?.content);
    }
  }, [id, data]);

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise((resolve, reject) => {
      // Clear the previous timeout if the user types again before the timeout is completed
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      // Set a new timeout
      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
          search: inputValue,
        };

        const response = await triggerContactsQuery(query);

        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData.map((contact) => ({
            label: contact?.fields?.all?.firstname + " " + contact?.fields?.all?.lastname,
            value: contact?.id,
          }));

          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500); // Adjust the delay as needed

      setDebounceTimeout(newTimeout);
    });
  const handleChange = (option) => {
    setSelectedOption(option);
    setContactId(option?.value);
  };
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate(-1),
    },
  ];
  return (
    <Fragment>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <Button buttons={buttons} />
      </div>
      <div style={{ padding: "8px", height: "100vh" }}>
        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          style={{ minHeight: "97vh", maxHeight: "97vh", overflowY: "auto" }}
          childrenOne={isLoading ? <DetailsSkeleton /> : <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
          childrenClassTwo={"wrapper-childTwo"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton />
            ) : (
              <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Select
                  label="Choose contact for a personalized template..."
                  options={retrieveContacts}
                  onChange={handleChange}
                  value={selectedOption}
                  margin
                />
                {selectedOption?.label && (
                  <>
                    <div
                      style={{
                        borderTop: "1px solid gainsboro",
                        paddingTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "4px",
                        // justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "48%" }}>
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>Email Template rendered for:</span>
                      </div>
                      <div style={{ width: "50%" }} className="d-flex items-center gap-5px mr-5">
                        <span className="result-component" style={{ minWidth: "90%" }}>
                          <a rel="noreferrer" href={`/contacts/view/${selectedOption?.value}`} target="_blank">
                            {selectedOption?.label}
                          </a>
                        </span>
                        <Tooltip title="Refresh template" arrow>
                          <i style={{ margin: 0, padding: 0 }} onClick={() => refetch()}>
                            <AutorenewIcon
                              style={{ fontSize: "20px", color: "#135f95", cursor: "pointer" }}
                              className="selectIcon"
                            />
                          </i>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                )}
                {selectedOption?.label && data && (
                  <div
                    style={{
                      borderTop: "1px solid gainsboro",
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: "600" }}>Sender info</span>

                    <span>Subject:</span>
                    <span>
                      <b>{data?.subject}</b>
                    </span>

                    <span>From Name:</span>
                    <span>
                      <b>{data?.fromName}</b>
                    </span>

                    <span>From Address:</span>
                    <span>
                      <b>{data?.fromAddress}</b>
                    </span>
                  </div>
                )}
              </div>
            )
          }
        />
      </div>
    </Fragment>
  );
}

export default EmailTesting;
