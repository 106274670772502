import {NodeToolbar, Position} from "reactflow";
import {Button} from "@mui/material";
import {Styles} from "../utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function ({isVisible, handleEdit, handleDelete}) {
    return (
        <NodeToolbar isVisible={isVisible} position={Position.Right} style={{left: -8}}>
            <Button style={Styles.buttonEditStyle} onClick={handleEdit}>
                <EditIcon
                    sx={{color: '#555', fontSize: 25}}/>
            </Button>
            <Button style={Styles.buttonDeleteStyle} onClick={handleDelete}>
                <DeleteForeverIcon sx={{color: 'red', fontSize: 25}}/></Button>
        </NodeToolbar>
    )
}
