import React, { useEffect, useState } from "react";
import EChart from "../EChart";
import * as echarts from "echarts";
import { worldMapData } from "./WorldMapData";
import BarChartIcon from "@mui/icons-material/BarChart";
import PublicIcon from "@mui/icons-material/Public";
import { Fade, IconButton, Tooltip, Zoom } from "@mui/material";
import { t } from "i18next";

function GeoEChart({ height, mapData, maxValue }) {
  const [option, setOption] = useState({});
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    echarts.registerMap("world", worldMapData);

    const getOption = (isMap) => {
      if (isMap) {
        return {
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              if (isNaN(params.value)) {
                return params.name;
              }
              return params.name + ": " + params.value;
            },
          },

          visualMap: {
            left: "right",
            min: 0,
            max: maxValue,
            inRange: {
              color: [
                "#313695",
                "#4575b4",
                "#74add1",
                "#abd9e9",
                "#e0f3f8",
                "#ffffbf",
                "#fee090",
                "#fdae61",
                "#f46d43",
                "#d73027",
                "#a50026",
              ],
            },
            text: ["High", "Low"],
            calculable: true,
          },
          series: [
            {
              id: "population",
              type: "map",
              roam: true,
              zoom: 1.2,
              map: "world",
              animationDurationUpdate: 1000,
              universalTransition: true,
              data: mapData,
            },
          ],
          xAxis: {
            show: !toggle,
          },
          yAxis: {
            show: !toggle,
          },
        };
      } else {
        return {
          xAxis: {
            show: !toggle,
            type: "value",
          },
          yAxis: {
            show: !toggle,
            type: "category",
            axisLabel: {
              rotate: 30,
              show: true,
            },
            data: mapData.map((item) => item.name),
          },
          animationDurationUpdate: 1000,
          series: [
            {
              type: "bar",
              id: "population",
              data: mapData.map((item) => item.value),
              universalTransition: true,
              label: {
                show: true,
                position: "inside",
                formatter: "{c}",
                color: "white",
                textStyle: {
                  fontSize: 10,
                },
              },
            },
          ],
        };
      }
    };

    setOption(getOption(toggle));
  }, [toggle, mapData]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div
      style={{
        height: height,
        minHeight: height,
        maxHeight: height,
      }}
    >
      <EChart option={option} />

      <Tooltip
        title={t("caymland.echart.toggle.view")}
        arrow
        followCursor
        placement="top"
        TransitionComponent={Zoom}
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton style={{ margin: 0 }} onClick={handleToggle}>
          {toggle ? <BarChartIcon size={16} /> : <PublicIcon size={16} />}
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default GeoEChart;
