import React from "react";
import { useGetAssetQuery } from "../../../../../redux/api/assetsApi";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

// Updated getExtensionFromUrl function
export const getExtensionFromUrl = (url) => {
  if (url?.includes(".pdf")) {
    return "pdf";
  }
  const extension = url?.split(".").pop();
  return extension || null;
};

export const PreviewModal = ({ id, modalIsOpen, closeModal }) => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetAssetQuery(id);
  console.log("data", data);

  const extension =
    data?.asset?.storageLocation === "local" ? data?.asset?.extension : getExtensionFromUrl(data?.asset?.remotePath);

  return (
    <>
      <Modal show={modalIsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("caymland.core.preview")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 d-flex justify-content-center">
            {/* Conditional rendering based on extension */}
            {extension === "pdf" ? (
              // Render PDF
              <object
                data={`${data?.asset?.downloadUrl}?stream=1&api=1`}
                type="application/pdf"
                width="100%"
                height="500px"
              >
                {/* Fallback content */}
                <p>
                  Unable to display the PDF file.{" "}
                  <a href={`${data?.asset?.downloadUrl}?stream=1&api=1`} target="_blank" rel="noopener noreferrer">
                    Download
                  </a>{" "}
                  instead.
                </p>
              </object>
            ) : ["png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(extension) ? (
              // Render image
              <img src={`${data?.asset?.downloadUrl}?stream=1&api=1`} alt="Asset Preview" style={{ maxWidth: "60%" }} />
            ) : (
              // Render icon for unsupported formats
              <i style={{ fontSize: "100px" }} className={`${data?.asset?.iconClass}`}></i>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
