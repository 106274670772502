import React from "react";
import { Fade, IconButton, Tooltip, Zoom } from "@mui/material";
import { t } from "i18next";

function EventContent({ data, type }) {
  return data?.map((singleData, index) => (
    <div
      key={index}
      className="flex items-center justify-between w-full mt-10"
      style={{ padding: "10px 15px", background: "#fbfbfb", border: "1px solid #f0f0f0" }}
    >
      <div className="flex items-center" style={{ gap: "100px" }}>
        <div className="flex items-center gap-5px">
          {type === "action" && (singleData?.decisionPath === "yes" || !singleData?.decisionPath) && (
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.report.campaign.yes.percent")}
              style={{ minWidth: "50px", alignSelf: "flex-start" }}
            >
              <div className="square-button" style={{ background: "#04B76B" }}>
                <span style={{ color: "#fff" }}>{singleData?.yesPercent ?? "0"}&#37;</span>
              </div>
            </Tooltip>
          )}
          {type === "action" && singleData?.decisionPath === "no" && (
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.report.campaign.no.percent")}
              style={{ minWidth: "50px", alignSelf: "flex-start" }}
            >
              <div className="square-button" style={{ background: "#FF5E6D" }}>
                <span style={{ color: "#fff" }}>{singleData?.noPercent ?? "0"}%</span>
              </div>
            </Tooltip>
          )}
          {type !== "action" && (
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.report.campaign.yes.percent")}
              style={{ minWidth: "50px", alignSelf: "flex-start" }}
            >
              <div className="square-button" style={{ background: "#04B76B" }}>
                <span style={{ color: "#fff" }}>{singleData?.yesPercent ?? "0"}&#37;</span>
              </div>
            </Tooltip>
          )}
          {type !== "action" && (
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.report.campaign.no.percent")}
              style={{ minWidth: "50px", alignSelf: "flex-start" }}
            >
              <div className="square-button" style={{ background: "#FF5E6D" }}>
                <span style={{ color: "#fff" }}>{singleData?.noPercent ?? "0"}%</span>
              </div>
            </Tooltip>
          )}
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.report.campaign.completed.actions")}
            style={{ minWidth: "50px", alignSelf: "flex-start" }}
          >
            <div className="square-button" style={{ background: "#fb3" }}>
              <span style={{ color: "#fff" }}>{singleData?.logCount ?? "0"}</span>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.report.campaign.pending.actions")}
            style={{ minWidth: "50px", alignSelf: "flex-start" }}
          >
            <div className="square-button" style={{ background: "#051E34" }}>
              <span style={{ color: "#fff" }}>{singleData?.leadCount ?? "0"}</span>
            </div>
          </Tooltip>
        </div>
        <p style={{ marginBottom: 0, color: "#135f95", fontSize: "15px", fontWeight: "600" }}>
          {singleData?.name}{" "}
          <small style={{ color: "#8393a2", fontSize: "10.5px" }}>{singleData?.percent ?? "0"}&#37;</small>
        </p>
      </div>
      <div>
        <em style={{ color: "#b3b3b3" }}>{singleData?.type}</em>
      </div>
    </div>
  ));
}

export default EventContent;
