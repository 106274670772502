export const retrieveStages = (inputValue, bundle, trigger = null, includeGlobal = true) =>
  new Promise(async (resolve, reject) => {
    if (typeof trigger !== "function" || trigger === null) {
      resolve([]);
      return;
    }

    const val = [];

    if (bundle) {
      val.push({ expr: "eq", col: "bundle", val: bundle });
    }

    if (includeGlobal) {
      val.push({ expr: "eq", col: "bundle", val: "global" });
    }
    const query = {};

    if (inputValue.length) {
      query.search = inputValue;
    }
    const response = await trigger(query);
    if (!response.error) {
      const stagesData = response?.data?.data?.filter((stage) => stage?.isPublished);

      const stagesArray = stagesData?.map((stages) => ({
        label: stages?.name,
        value: stages?.id,
      }));
      resolve(stagesArray);
    }
  });
