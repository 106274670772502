import React, { useEffect, useState } from "react";
import DateFromTo from "../../../../dashboard/defaultCompo/dateFromTo";
import GenerateDynamicInputs from "../../../../reusableComponents/GenerateDynamicInputs";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "../../../../reusableComponents/Buttons/Button";
import { t } from "i18next";

function Filters({ report, updateFilters, handleDateChange, }) {
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    console.log(report?.filters)
    const hasDynamic = Object.entries(report.filters).some(([key, e]) => 
      e.dynamic === true || e.dynamic === '1' || e.dynamic === 1
    );
  
    setShowFilter(hasDynamic);
  }, [report]);

  const buttons = [
    {
      name: "filter",
      title: t("caymland.core.filter.header"),
      icon: <FilterListIcon size={17} />,
      onClick: () => handleDateChange(),
    },
  ];



  return (
    (showFilter || !report?.settings?.hideDateRangeFilter) &&(
      <div className="d-flex w-full flex-wrap" style={{justifyContent: 'center', padding: '10px 0'}} >
        {!report?.settings?.hideDateRangeFilter && (
          <div className="flex items-center" style={{ width: "fit-content", padding: '0.5rem' }}>
            <DateFromTo cls="mid-full-width" showApplyButton={false} saveFromTo={handleDateChange} customStyle={{className: "smallDateFromTo", width: 'auto'}}/>
          </div>
        )}
        <div className="flex w-full items-center" style={{flexFlow: 'column', padding: '0.5rem'}}>
          {report &&
            report.filters &&
            Object.entries(report.filters).map(([key, e], i) =>
              e?.dynamic ? (
                <GenerateDynamicInputs
                key={key} 
                filterKey={key}
                type={e?.label?.type ?? ""}
                label={e?.label?.label}
                value={e.value}
                 onChange={updateFilters}
                 options={e?.label?.list || e?.label?.list?.list}
                 wFull="w-full"
                 marginBottom={'mb-20px' || true}
                 extraStyle={{ margin: false, alignSelf: 'flex-start' }}
               />
              ) : (
                ""
              )
            )}
          <Button buttons={buttons} />
        </div>
      </div>
    )
  );
}

export default Filters;
