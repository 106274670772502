import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import Downloads from "./Downloads";
import { useGetReportViewQuery } from "../../../../redux/api/reportsApi";
import Header from "../../../reusableComponents/Header/Header";

function ViewHTMLReports() {
  const [report, setReport] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [dateFrom, setDateFrom] = useState(moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
  const [filters, setFilters] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    dateFrom,
    dateTo,
    filters: {},
  });

  const { id } = useParams();

  const { data, isLoading, isFetching } = useGetReportViewQuery({ id, ...filters });

  useEffect(() => {
    if (data) {
      if (initialLoad) {
        setReport(data?.report);
      }
      setInitialLoad(false);
      setExtraInfo(data?.graphs);
      setDataTable(data?.table);
    }
  }, [id, data]);

  const fromDate = moment(localStorage.getItem("fromDate")).format("DD.MM.YY");
  const toDate = moment(localStorage.getItem("toDate")).format("DD.MM.YY");

  return (
    <Fragment>
      <>
        <div className="d-flex  gap-10 items-center">
          <Header title={report?.name} />
          <span>
            {" "}
            {fromDate} - {toDate}
          </span>
        </div>

        <div className="w-100">
          <Card>
            <CardBody style={{ padding: "0" }}>
              <Downloads
                extras={extraInfo}
                dataTable={dataTable}
                setFilters={setFilters}
                filters={filters}
                report={report}
                isFetching={isFetching}
              />
            </CardBody>
          </Card>
        </div>
      </>
    </Fragment>
  );
}

export default ViewHTMLReports;
