export default function cleanHtmlContent(html) {
  if (!html) return null
  let cleanHtml = html.replace(/<!--\[if\s+.*?\]>[\s\S]*?<!\[endif\]-->/gi, '');
  cleanHtml = cleanHtml.replace(/<mcomment\s*\/?>/gi, '');
  cleanHtml = cleanHtml.replace(/<\/mcomment>/gi, '');
  cleanHtml = cleanHtml.replace(/<mif[^>]*>.*?<\/mif>/gi, '');
  cleanHtml = cleanHtml.replace(/\[if\s+\(mso\)\|\(IE\)\].*?\[endif\]/gi, '');
  cleanHtml = cleanHtml.replace(/\[if.*?\[endif\]/gi, '');
  cleanHtml = cleanHtml.replace(/<mconditionnonoutlook>.*?<mif>/gi, '');
  cleanHtml = cleanHtml.replace(/<mif.*?>/gi, '');
  cleanHtml = cleanHtml.replace(/<\/mif>/gi, '');
  cleanHtml = cleanHtml.replace(/<\/mconditionnonoutlook>/gi, '');
  cleanHtml = cleanHtml.replace(/-->/gi, '');
  return cleanHtml;
}
