import React from 'react'
import {Controller} from "react-hook-form";
import {Text, TextArea} from '../../reusableComponents/Inputs';
import { t } from 'i18next';

function Details({errors, control}) {
    
    return (
        <div className="flex flex-col gap-10" style={{paddingRight: 30}}>
            <Controller
                control={control}
                name={'name'}
                render={({field: {name, onChange, value}}) => (
                    <Text
                        name={name}
                        value={value ?? ""}
                        onChange={onChange}
                        label={t("caymland.core.name")}
                        required={true}
                        errors={errors && errors[name]}                  
                    />
                )}>

            </Controller>
            <Controller
                control={control}
                name="description"
                render={({field: {name, onChange, value}}) => (
                    <TextArea
                        name={name}
                        value={value ?? ""}
                        onChange={onChange}
                        label={t("caymland.lead.field.companydescription")}
                    />
                )}/>
        </div>
    )
}

export default Details
