import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { RiQuestionFill } from "react-icons/ri";
import { SketchPicker } from "react-color";
import Label from "./Label";
import { t } from "i18next";

export default function ({
  name,
  value,
  onChange,
  onBlur,
  label,
  tooltip,
  required = false,
  type = "text",
  errors = false,
  iconStart,
  placeholder,
  disabled,
  defaultValue,
  accept,
  extraText,
  wFull,
  marginBottom = false,
  labelHidden = false,
  colorPickerRef,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setDisplayColorPicker(false);
      }
    }

    if (displayColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displayColorPicker, colorPickerRef]);

  return (
    <>
      <div className={`${marginBottom ? marginBottom : "mb-20px"}  ${wFull ? wFull : ""}`}>
        {labelHidden && <Label name={name} label={"."} labelHidden={labelHidden} />}
        <div className="position-relative">
          <TextField
            id={name}
            name={name}
            value={value?.startsWith("#") ? value : `#${value}` || ""}
            defaultValue={defaultValue}
            placeholder={placeholder}
            size="small"
            variant="outlined"
            accept={accept}
            label={label}
            className="custom-textfield"
            required={required}
            type={type}
            helperText={errors ? t("caymland.core.fieldrequired") : ""}
            error={errors}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            fullWidth
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%",
                margin: 0,
                color: "rgba(255, 102, 102, 0.5)",
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
                alignItems: "center",
                backgroundColor: "#fff",
              },
              "& .MuiInputBase-input": {
                fontSize: "15px",
                height: "40px",
                padding: "0 14px 0 40px",
                boxSizing: "border-box",
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "15px",
              },
              "& .MuiInputLabel-root": {
                color: "#6C757D",
                fontSize: "15px",
                transform: "translate(50px, 10px) scale(1)",
              },
              "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: "translate(50px, -6px) scale(0.75)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                padding: "0 42px",
              },
              "& .MuiFormHelperText-root": {
                position: "absolute",
                bottom: "100%",
                left: "0",
                fontSize: "11px",
                color: "rgba(255, 0, 0, 0.7)",
              },
              "& .Mui-focused .MuiInputLabel-root": {
                color: "#6C757D",
              },
              "&:hover": {
                transform: "scale(1.003)",
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.4s ease, box-shadow 0.4s ease",
              },
            }}
            InputProps={{
              startAdornment: (
                <>
                  {iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null}
                  <div
                    className="color-input-background"
                    style={{
                      backgroundColor: value?.startsWith("#") ? value : `#${value}` || "",
                      pointerEvents: "none",
                      width: 40,
                      height: "36px",
                      position: "absolute",
                      left: 2,
                      top: 2,
                      zIndex: 1,
                      borderBottomLeftRadius: "2px",
                      borderTopLeftRadius: "2px",
                      cursor: "pointer",
                    }}
                  ></div>
                </>
              ),
              endAdornment:
                tooltip && tooltip.length ? (
                  <Tooltip
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        fontSize: "15px",
                      },
                    }}
                    title={tooltip}
                    arrow
                    followCursor
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <IconButton style={{ cursor: "help", padding: 4 }}>
                      <RiQuestionFill size={16} />
                    </IconButton>
                  </Tooltip>
                ) : extraText && extraText.length ? (
                  <span style={{ fontSize: "15px", color: "#6C757D" }}>{extraText}</span>
                ) : null,
            }}
            onClick={handleColorClick}
          />
          {displayColorPicker && (
            <div className="position-absolute z-10">
              <div className="position-fixed top-0 right-0 bottom-0 left-0" onClick={handleColorClose} />
              <SketchPicker
                color={value}
                onChange={onChange}
                presetColors={[
                  "#D0021B",
                  "#F5A623",
                  "#F8E71C",
                  "#8B572A",
                  "#7ED321",
                  "#417505",
                  "#BD10E0",
                  "#9013FE",
                  "#4A90E2",
                  "#50E3C2",
                  "#B8E986",
                  "#000000",
                  "#4A4A4A",
                  "#9B9B9B",
                  "#FFFFFF",
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
