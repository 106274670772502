import * as yup from 'yup';


// const propertiesSchema = yup.mixed().shape({
//     id: yup.mixed(),
//     name: yup.string(),
//     description: yup.string().nullable(),
//     type: yup.string(),
//     eventType: yup.string(),
//     order: yup.number(),
//     properties: yup.mixed().nullable(),
//     triggerDate: yup.date().nullable(),
//     triggerInterval: yup.string().nullable(),
//     triggerIntervalUnit: yup.string().nullable(),
//     triggerHour: yup.string().nullable(),
//     triggerRestrictedStartHour: yup.string().nullable(),
//     triggerRestrictedStopHour: yup.string().nullable(),
//     triggerRestrictedDaysOfWeek: yup.array().of(yup.string()).nullable(),
//     triggerMode: yup.string().nullable(),
//     anchor: yup.string().nullable(),
//     anchorEventType: yup.string().nullable(),
//     // canvasSettings: yup.object().shape({
//     //     droppedX: yup.string().nullable(),
//     //     droppedY: yup.string().nullable(),
//     // }),
//     buttons: yup.object().notRequired(),
//     _token: yup.string().notRequired(),
//     campaignId: yup.string().notRequired(),
//     tempId: yup.string().notRequired(),
//     changes: yup.object().notRequired(),
//     decisionPath: yup.string().notRequired(),
// });


const eventSchema = yup.object().shape({
    id: yup.string().nullable(),  
    name: yup.string().nullable(),
    description: yup.string().nullable(),
    type: yup.string().nullable(),
    eventType: yup.string().nullable(),
    anchor: yup.string().nullable(),
    anchorEventType: yup.string().nullable(),
    triggerMode: yup.string().nullable(),
    triggerDate: yup.date().nullable(),
    triggerInterval: yup.string().nullable(),
    triggerIntervalUnit: yup.string().nullable(),
    triggerHour: yup.string().nullable(),
    triggerRestrictedStartHour: yup.string().nullable(),
    triggerRestrictedStopHour: yup.string().nullable(),
    triggerRestrictedDaysOfWeek: yup.array().of(yup.string()).nullable(),
    properties: yup.mixed().default([]).nullable(),
    decisionPath: yup.string().notRequired(),
    children: yup.array().of(yup.object().notRequired()).notRequired(),
});

const formSchema = yup.object().shape({
    createdByUser: yup.string().nullable(),
    modifiedByUser: yup.string().nullable(),
    id: yup.number(),
    name: yup.string().nullable(),
    alias: yup.string().nullable(),
});

const listSchema = yup.object().shape({
    createdByUser: yup.string().nullable(),
    modifiedByUser: yup.string().nullable(),
    id: yup.number(),
    name: yup.string().nullable(),
    alias: yup.string().nullable(),
    description: yup.string().nullable(),
});

const canvasNodeSchema = yup.object().shape({
    id: yup.string(),
    positionX: yup.string(),
    positionY: yup.string(),
});

const canvasConnectionSchema = yup.object().shape({
    sourceId: yup.string(),
    targetId: yup.string(),
    anchors: yup.object().shape({
        source: yup.string(),
        target: yup.string(),
    }),
});

const canvasSettingsSchema = yup.object().shape({
    nodes: yup.array().of(canvasNodeSchema),
    connections: yup.array().of(canvasConnectionSchema),
});

const schema = yup.object().shape({
    isPublished: yup.boolean().nullable(),
    dateAdded: yup.date().nullable(),
    dateModified: yup.date().nullable(),
    createdBy: yup.number().nullable(),
    createdByUser: yup.string().nullable(),
    modifiedBy: yup.number().nullable(),
    modifiedByUser: yup.string().nullable(),
    id: yup.number().nullable(),
    name: yup.string().required(),
    category: yup.mixed().nullable(),
    description: yup.string().nullable(),
    allowRestart: yup.boolean().nullable(),
    noLog: yup.boolean().nullable(),
    execution: yup.boolean().nullable(),
    publishUp: yup.date().nullable(),
    publishDown: yup.date().nullable(),
    events: yup.array().of(eventSchema).min(1),
    forms: yup.array().of(formSchema).nullable(),
    lists: yup.array().of(listSchema).nullable(),
    canvasSettings: canvasSettingsSchema.nullable(),
});

export default schema;
