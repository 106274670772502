import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Label } from "../../../../reusableComponents/Inputs";
import { CardSkeleton } from "../../../Components/landingPages/addPageContent/ThemeCards";
import Button from "../../../../reusableComponents/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import LaunchIcon from "@mui/icons-material/Launch";
import { Tooltip } from "@mui/material";

function ThemeCards({ themes, handleOnClick, modifiedData, setModifiedData }) {
  const [selectedTheme, setSelectedTheme] = useState(-1);
  const navigate = useNavigate();

  const handleThemeChange = (theme) => {
    setSelectedTheme(theme.id);
    setModifiedData((prev) => ({
      ...prev,
      customJson: theme.customJson,
      customHtml: theme.customHtml,
    }));
  };

  return (
    <div className="flex flex-wrap justify-center items-start gap-4 w-full">
      {themes?.length > 0
        ? themes.map((theme, index) => (
            <Card
              className="flex flex-col items-center justify-between gap-4 cardShadow"
              key={index}
              style={{ width: "30%", padding: "15px" }}
            >
              <CardHeader
                style={{
                  borderBottom: 0,
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  flexWrap: "wrap",
                  height: "30px",
                }}
              >
                <span>{theme.name}</span>

                {theme.id > 0 && (
                  <Button
                    buttons={[
                      {
                        name: false && "title",
                        icon: (
                          <Tooltip title="View Template" arrow>
                            {" "}
                            <LaunchIcon style={{ fontSize: "18px", marginTop: "10px" }} />
                          </Tooltip>
                        ),
                        onClick: () => navigate(`/emails/edit/${theme.id}`),
                      },
                    ]}
                  />
                )}
              </CardHeader>
              <CardBody className="w-full p-0">
                {theme.id === -1 ? (
                  <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                    <img
                      title={t("caymland.core.preview")}
                      class="frame"
                      frameborder="0"
                      style={{
                        pointerEvents: "none",
                        overflow: "hidden",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                      }}
                      src={`${process.env.REACT_APP_PROD_URL}/themes/blank/thumbnail.png`}
                    ></img>
                  </div>
                ) : theme.id === -2 ? (
                  <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                    <img
                      title={t("caymland.core.preview")}
                      class="frame"
                      frameborder="0"
                      style={{
                        pointerEvents: "none",
                        overflow: "hidden",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                      }}
                      src={`${process.env.REACT_APP_PROD_URL}/themes/full/thumbnail.png`}
                    ></img>
                  </div>
                ) : (
                  <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                    <iframe
                      title={t("caymland.core.preview")}
                      class="frame"
                      frameborder="0"
                      style={{
                        pointerEvents: "none",
                        overflow: "hidden",
                        transform: "scale(0.47)",
                        width: "35vw",
                        height: "65vh",
                      }}
                      src={`/email/preview/${theme.id}`}
                    ></iframe>
                  </div>
                )}
              </CardBody>
              <CardFooter style={{ borderBottom: 0, padding: 0, height: "40px" }}>
                <Button
                  buttons={[
                    {
                      name: "Selecte",
                      title:
                        selectedTheme === theme.id
                          ? t("caymland.core.form.filters.selected")
                          : t("caymland.core.select"),
                      onClick: () => handleThemeChange(theme),
                    },
                  ]}
                  textFormat="capitalize"
                />
              </CardFooter>
            </Card>
          ))
        : Array.from({ length: 3 }).map((_, index) => <CardSkeleton key={index} />)}
    </div>
  );
}

export default ThemeCards;

// import React, { useState, useEffect } from "react";
// import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
// import { Label } from "../../../../reusableComponents/Inputs";
// import { CardSkeleton } from "../../../Components/landingPages/addPageContent/ThemeCards";
// import Button from "../../../../reusableComponents/Buttons/Button";
// import { useNavigate } from "react-router-dom";
// import { t } from "i18next";
// import LaunchIcon from "@mui/icons-material/Launch";
// import { Tooltip } from "@mui/material";
// import { Link } from "react-router-dom";

// function ThemeCards({ themes, handleOnClick, modifiedData, setModifiedData }) {
//   const [selectedTheme, setSelectedTheme] = useState(-2);
//   const navigate = useNavigate();

//   const handleThemeChange = (theme) => {
//     if (theme.id !== -1 && theme.id !== -2) {
//       const id = theme.id;
//       navigate(`/emails/clone`, { state: { id } });
//     }
//     if (theme?.isTemplate) {
//       setSelectedTheme(theme.id);
//     } else {
//       setSelectedTheme(theme.id);
//       setModifiedData((prev) => ({
//         ...prev,
//         customJson: theme.customJson,
//         customHtml: theme.customHtml,
//       }));
//     }
//   };

//   return (
//     <div className="flex flex-wrap justify-center items-start gap-4 w-full">
//       {themes?.length > 0
//         ? themes.map((theme, index) => (
//             <Card
//               className="flex flex-col items-center justify-between gap-4 cardShadow"
//               key={index}
//               style={{ width: "30%", padding: "15px" }}
//             >
//               <CardHeader
//                 style={{
//                   borderBottom: 0,
//                   padding: 0,
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   width: "100%",
//                   flexWrap: "wrap",
//                   height: "30px",
//                 }}
//               >
//                 <span>{theme.name}</span>

//                 {theme.id > 0 && (
//                   <Button
//                     buttons={[
//                       {
//                         name: false && "title",
//                         icon: (
//                           <Tooltip title="View Template" arrow>
//                             {" "}
//                             <LaunchIcon style={{ fontSize: "18px", marginTop: "10px" }} />
//                           </Tooltip>
//                         ),
//                         onClick: () => navigate(`/emails/edit/${theme.id}`),
//                       },
//                     ]}
//                   />
//                 )}
//               </CardHeader>
//               <CardBody className="w-full p-0">
//                 {theme.name === "blank" ? (
//                   <div style={{ overflow: "hidden", maxHeight: "350px" }}>
//                     <iframe
//                       title={t("caymland.core.preview")}
//                       class="frame"
//                       frameborder="0"
//                       style={{ pointerEvents: "none", overflow: "hidden", transform: "scale(0.47)" }}
//                       src={`${process.env.REACT_APP_PROD_URL}/themes/popup/blank/thumbnail.png`}
//                     ></iframe>
//                   </div>
//                 ) : theme.name === "full" ? (
//                   <div style={{ overflow: "hidden", maxHeight: "350px" }}>
//                     <iframe
//                       title={t("caymland.core.preview")}
//                       class="frame"
//                       frameborder="0"
//                       style={{ pointerEvents: "none", overflow: "hidden", transform: "scale(0.47)" }}
//                       src={`${process.env.REACT_APP_PROD_URL}/themes/popup/full/thumbnail.png`}
//                     ></iframe>
//                   </div>
//                 ) : (
//                   <div style={{ overflow: "hidden", maxHeight: "350px" }}>
//                     <iframe
//                       title={t("caymland.core.preview")}
//                       class="frame"
//                       frameborder="0"
//                       style={{
//                         pointerEvents: "none",
//                         overflow: "hidden",
//                         transform: "scale(0.47)",
//                         width: "37vw",
//                         height: "65vh",
//                       }}
//                       src={`/email/preview/${theme.id}`}
//                     ></iframe>
//                   </div>
//                 )}
//               </CardBody>
//               <CardFooter style={{ borderBottom: 0, padding: 0, height: "40px" }}>
//                 <Button
//                   buttons={[
//                     {
//                       name: "Selecte",
//                       title:
//                         selectedTheme === theme.id
//                           ? t("caymland.core.form.filters.selected")
//                           : t("caymland.core.select"),
//                       onClick: () => handleThemeChange(theme),
//                     },
//                   ]}
//                   textFormat="capitalize"
//                 />
//               </CardFooter>
//             </Card>
//           ))
//         : Array.from({ length: 3 }).map((_, index) => <CardSkeleton key={index} />)}
//     </div>
//   );
// }

// export default ThemeCards;
