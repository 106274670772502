import { useTranslation } from "react-i18next";

const Runtime = (props) => {
  const status = props?.status;

  const getDuration = () => {
    const { t } = useTranslation();
    const dateStarted = new Date(props?.dateStarted);
    const dateEnded = new Date(props?.dateEnded);
    const diffMilliseconds = dateEnded - dateStarted;

    if (diffMilliseconds < 1000) {
      return "in" + " " + t("caymland.core.date.less.than.second");
    }

    const minutes = Math.floor(diffMilliseconds / (1000 * 60));
    const seconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

    if (minutes === 0) {
      return "in" + " " + `${seconds} ${t("caymland.core.time.seconds")}`;
    }

    return "in" + " " + `${minutes} ${t("caymland.core.time.minutes")} ${seconds} ${t("caymland.core.time.seconds")}`;
  };

  if (status === 3) {
    return (
      <p style={{ fontSize: "12px", color: "gray", marginTop: "10px", alignSelf: "center" }}>
        <i>{getDuration()}</i>
      </p>
    );
  }

  return null;
};

export default Runtime;
