import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaTrashAlt } from "react-icons/fa";
import { t } from "i18next";

export default function EditDeleteMenu({ handleEdit, handleDelete, id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    console.log("option", option)
    if (option === t("caymland.core.permissions.edit")) {
      handleEdit(id);
    } else if (option === t("caymland.core.permissions.delete")) {
      handleDelete(id);
    }
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper ul": {
            padding: 0,
          },
        }}
      >
        {[t("caymland.core.permissions.edit"), t("caymland.core.permissions.delete")].map((option) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(option)} style={{ color: "rgb(117, 117, 117)" }}>
            {option === t("caymland.core.permissions.edit") ? (
              <EditIcon style={{ color: "rgb(117, 117, 117)", marginRight: "5px", fontSize: "17px" }} />
            ) : (
              <FaTrashAlt style={{ color: "rgba(255, 0, 0, 0.7)", marginRight: "5px", fontSize: "17px" }} />
            )}
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
