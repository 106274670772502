import React, { useRef } from "react";
import { Checkbox, Text, TextArea } from "../Inputs";
import ColorPicker from "../Inputs/ColorPicker";
import { Controller } from "react-hook-form";

const FieldGenerator = ({ fields, errors, control }) => {
  // Function to generate input based on field type

  const colorPickerRef = useRef();
  const generateField = (field, index) => {
    switch (field.type) {
      case "text":
        return (
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                type={field.type}
                value={value}
                onChange={onChange}
                label={field.label}
                required={field.isRequired}
                labelHidden={true}
                errors={errors && errors[name]}
              />
            )}
          />
        );
      case "textarea":
        console.log(field);
        return (
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TextArea
                name={name}
                errors={errors && errors[name]}
                label={field.label}
                required={field.isRequired}
                data={value ?? ""}
                onChange={onChange}
              />
            )}
          />
        );
      case "color":
        return (
          <Controller
            ref={colorPickerRef}
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <ColorPicker
                name={name}
                value={value || "#000000"}
                colorPickerRef={colorPickerRef}
                onChange={(so) => onChange(so.hex)}
                label={field.label}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        );
      case "boolean":
        return (
          <div className="mt-20">
            <Controller
              control={control}
              name={field.alias}
              render={({ field: { name, onChange, value } }) => (
                <Checkbox
                  name={name}
                  value={value}
                  onChange={onChange}
                  label={field.label}
                  required={field.isRequired}
                  errors={errors && errors[name]}
                />
              )}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return <>{fields.map((field, index) => generateField(field, index))}</>;
};

export default FieldGenerator;
