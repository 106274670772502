export const retrieveTags = (inputValue, bundle, trigger = null, includeGlobal = true) =>
  new Promise(async (resolve, reject) => {
    if (typeof trigger !== "function" || trigger === null) {
      resolve([]);
      return;
    }

    const val = [];

    if (bundle) {
      val.push({ expr: "eq", col: "bundle", val: bundle });
    }

    if (includeGlobal) {
      val.push({ expr: "eq", col: "bundle", val: "global" });
    }

    const query = {};

    if (inputValue.length) {
      query.search = inputValue;
    } 

    const response = await trigger(query)
    if (!response.error) {
      const tagsData = response.data?.data;

      const tagsArray = tagsData?.map((tags) => ({
        label: tags?.tag,
        value: tags?.tag,
      }));
      resolve(tagsArray);
    }
  });
