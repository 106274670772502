import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Categories"],
  endpoints: (build) => ({
    getCategories: build.query({
      query: customBaseQuery((q) => `/api/categories?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Categories"],
    }),
    getParentCategories: build.query({
      query: customBaseQuery(() => `/config/contact/points`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Categories"],
    }),
    getCategoriesOptions: build.query({
      query: (q) => `/api/categories/select?${qs.stringify(q)}`,
      keepUnusedDataFor: false,
      providesTags: ["Categories"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addCategory: build.mutation({
      query: (body) => ({
        url: `api/categories/new`,
        method: "POST",
        body,
      }),
    }),
    updateCategory: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/categories/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      invalidatesTags: (result, error, id) => [{ type: "Categories", id }],
    }),
    deleteCategory: build.mutation({
      query: (id) => ({
        url: `/api/categories/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          categoriesApi.util.updateQueryData("getCategories", undefined, (draft) => {
            draft.data = draft.data.filter((categories) => categories.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Categories", id }],
    }),
    getCategoryTypes: build.query({
      query: (type) => `/api/categorytypes`,
      providesTags: ["Categories"],
    }),
    getCategory: build.query({
      query: (id) => `/api/categories/${id}`,
      providesTags: (result, error, id) => [{ type: "Categories", id }],
    }),
    createCategory: build.mutation({
      query: (category) => {
        delete category.id;
        return {
          url: "/api/categories/new",
          method: "POST",
          body: category,
        };
      },
      invalidatesTags: [{ type: "Categories" }],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryTypesQuery,
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useAddCategoryMutation,
  useGetParentCategoriesQuery,
  useGetCategoriesOptionsQuery,
} = categoriesApi;
