import React from "react";

function Title(props) {
  return (
    <div className="under-title">
      <h3>{props.title}</h3>
      <div className="items-center flex-nowrap" style={{ width: "100%" }}>
        <span className="titledot"></span>
        <span className="titleline"></span>
      </div>
    </div>
  );
}

export default Title;
