import React from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";

const Actions = ({ extras }) => {
  const sortedFields = [...extras?.actions].sort((a, b) => a.order - b.order);
  return sortedFields.map((x) => (
    <div>
      <div className="form-actions-card mt-5">
        <div className="flex items-center gap-20">
          <FaCloudDownloadAlt color="#d9d9d9" size={22} />
          <div className="p-no-margin">
            <p
              style={{
                color: "#4285f4",
                fontWeight: "bold",
                fontSize: "14px",
              }}
              className="pb-0 mb-0"
            >
              {x.name}
            </p>
            <p
              style={{
                color: "#ccc",
              }}
              className="pb-0 mb-0"
            >
              {x.description}
            </p>
          </div>
        </div>
        <em
          style={{
            color: "#b3b3b3",
          }}
          className="mb-0 pb-0"
        >
          {x.typeName}
        </em>
      </div>
    </div>
  ));
};

export default Actions;
