import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../loading";

function SingleCampaignForModalForCampaigns({
  campaignName,
  checked,
  id,
  addContactToCampaign,
  removeContactFromCampaign,
}) {
  const userid = useParams().id;
  const [loading, setLoading] = useState(false);
  const handleCheck = async (e) => {
    if (e.target.checked === true) {
      addContactToCampaign(userid, id);
    } else {
      removeContactFromCampaign(userid, id);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        border: "0.5px solid lightgrey",
        display: "flex",
        padding: "1em 0 1em 1em",
        alignItems: "center",
        justifyContent: "start",
        gap: "1em",
        borderCollapse: "collapse",
      }}
    >
      {!loading ? (
        <div
          class="switch-toggle"
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            type="checkbox"
            id={id}
            defaultChecked={checked}
            onChange={handleCheck}
          />
          <label className="toggle-label" for={id}></label>
        </div>
      ) : (
        <Loading />
      )}

      <span>{campaignName}</span>
    </div>
  );
}

export default SingleCampaignForModalForCampaigns;
