import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { Checkbox, Select, Text } from "../../../reusableComponents/Inputs";
import ColorPicker from "../../../reusableComponents/Inputs/ColorPicker";
import { t } from "i18next";
import { useGetSegmentsOptionsQuery } from "../../../../redux/api/segmentsApi";
import { useGetSerialLettersOptionsQuery } from "../../../../redux/api/serialLettersApi";

const MainForm = ({ control, watch, errors, segmentsData, serialLettersData }) => {
  return (
    <div>
      <div className="w-full" style={{ marginTop: "20px" }}>
        <Controller
          control={control}
          name="name"
          render={({ field: { name, onChange, value } }) => (
            <Text
              name={name}
              value={value}
              onChange={onChange}
              label="Name"
              required={true}
              errors={errors && errors[name]}
            />
          )}
        />
        <Controller
          control={control}
          name="list"
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              value={value}
              onChange={onChange}
              options={segmentsData}
              label={t("caymland.plugin.badge.generator.form.mapping.segment")}
              required={true}
              errors={errors && errors[name]}
            />
          )}
        />
        <Controller
          control={control}
          name="badge"
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              value={value}
              onChange={onChange}
              options={serialLettersData}
              label={t("caymland.plugin.badge.generator.badge")}
              required={true}
              errors={errors && errors[name]}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MainForm;
