import { t } from "i18next";
import React from "react";

const DropZoneMask = ({ title }) => {
  return (
    <div class="dropzone-mask flex items-center justify-center w-full h-full">
      <div className="dropzone-mask-message">{t("caymland.core.drag.here", { title: title })}</div>
    </div>
  );
};

export default DropZoneMask;
