import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const pointsApi = createApi({
  reducerPath: "pointsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Points"],
  endpoints: (build) => ({
    getPoints: build.query({
      query: customBaseQuery((q) => `/api/points?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Points"],
    }),
    getPoint: build.query({
      query: (id) => `/api/points/${id}`,
      providesTags: (result, error, id) => [{ type: "Points", id }],
    }),
    getPointActionType: build.query({
      query: () => `/api/points/actions/types`,
      transformResponse: (response, meta, arg) => {
        const groupedOptions = Object.entries(response?.pointActionTypes).map(([category, actions]) => ({
          label: category,
          options: Object.entries(actions).map(([key, value]) => ({
            value: key,
            label: value,
          })),
        }));
        return groupedOptions;
      },
    }),
    updatePoint: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/points/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Points", id }],
    }),
    deletePoint: build.mutation({
      query: (id) => ({
        url: `/api/points/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          pointsApi.util.updateQueryData("getPoints", undefined, (draft) => {
            draft.data = draft.data.filter((Points) => Points.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Points", id }],
    }),
    addPoint: build.mutation({
      query: (body) => ({
        url: `/api/points/new`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Points", id: "point" }],
    }),
    getPointActionEvents: build.query({
      query: customBaseQuery((q) => `/api/points/actions/events/new?${qs.stringify(q)}`),
      providesTags: ["Points"],
    }),
  }),
});

export const {
  useGetPointsQuery,
  useDeletePointMutation,
  useUpdatePointMutation,
  useGetPointQuery,
  useAddPointMutation,
  useGetPointActionTypeQuery,
  useGetPointActionEventsQuery,
} = pointsApi;
