const Updated = (props) => {
    return (
      <div>
        <span
          style={{
            display: "flex",
            color: "black",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "2.5em",
          }}
        >
          {props?.updatedCount}
        </span>
      </div>
    );
  };


  export default Updated