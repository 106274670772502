import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Label } from "../../../../reusableComponents/Inputs";
import { CardSkeleton } from "../../../Components/landingPages/addPageContent/ThemeCards";
import Button from "../../../../reusableComponents/Buttons/Button";
import { t } from "i18next";
import Swal from "sweetalert2";

function ThemeCards({ themes, handleOnClick, modifiedData, setModifiedData }) {
  const [selectedTheme, setSelectedTheme] = useState(-3);

  const handleThemeChange = (theme) => {
    if (theme.id !== selectedTheme) {
      Swal.fire({
        title: t("caymland.mailbox.message.delete.title"),
        text: t("caymland.core.builder.theme_change_warning"),
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "confirm-component swal-custom",
          cancelButton: "result-component swal-custom",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedTheme(theme?.id);
          setModifiedData((prev) => ({
            ...prev,
            customJson: theme?.content ? theme?.content : theme?.customJson,
          }));
        }
      });
    } else {
      setSelectedTheme(theme?.id);
      setModifiedData((prev) => ({
        ...prev,
        customJson: theme?.content ? theme?.content : theme?.customJson,
      }));
    }
  };

  useEffect(() => {
    themes.map((theme) => {
      if (theme?.customJson === modifiedData?.customJson) {
      }
    });
  }, [modifiedData]);

  return (
    <div className="flex flex-wrap justify-center items-start gap-4 w-full">
      {themes?.length > 0
        ? themes.map((theme, index) => (
            <Card
              className="flex flex-col items-center justify-between gap-20 cardShadow"
              key={index}
              style={{ width: "30%", padding: "20px" }}
            >
              <CardHeader style={{ borderBottom: 0, padding: 0 }}>{theme.name}</CardHeader>
              <CardBody className="w-full p-0">
                {theme.id === -1 ? (
                  <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                    <img
                      title={t("caymland.core.preview")}
                      className="frame"
                      frameBorder="0"
                      style={{
                        pointerEvents: "none",
                        overflow: "hidden",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                      }}
                      src={`${process.env.REACT_APP_PROD_URL}/themes/blank/thumbnail.png`}
                    />
                  </div>
                ) : theme.id === -2 ? (
                  <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                    <img
                      title={t("caymland.core.preview")}
                      className="frame"
                      frameBorder="0"
                      style={{
                        pointerEvents: "none",
                        overflow: "hidden",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                      }}
                      src={`${process.env.REACT_APP_PROD_URL}/themes/full/thumbnail.png`}
                    />
                  </div>
                ) : (
                  <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                    <iframe
                      title={t("caymland.core.preview")}
                      className="frame"
                      frameBorder="0"
                      style={{
                        pointerEvents: "none",
                        overflow: "hidden",
                        transform: "scale(0.47)",
                        width: "35vw",
                        height: "65vh",
                      }}
                      src={`/email/preview/${theme.id}`}
                    ></iframe>
                  </div>
                )}
              </CardBody>
              <CardFooter style={{ borderBottom: 0, padding: 0 }}>
                <Button
                  buttons={[
                    {
                      name: "Selecte",
                      title:
                        selectedTheme === theme.id
                          ? t("caymland.core.form.filters.selected")
                          : t("caymland.core.select"),
                      onClick: () => handleThemeChange(theme),
                    },
                  ]}
                  textFormat="capitalize"
                />
              </CardFooter>
            </Card>
          ))
        : Array.from({ length: 3 }).map((_, index) => <CardSkeleton key={index} />)}
    </div>
  );
}

export default ThemeCards;
