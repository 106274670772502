import React, { Fragment, useState, useEffect, forwardRef, useCallback, useMemo } from "react";
import AddNew from "../../reusableComponents/Buttons/addNew";
import SearchHeader from "../../common/header-component/searchHeader";
import SearchHelper from "../../reusableComponents/searchHelper/searchHelper";
import { Link } from "react-router-dom";
import { MdOutlineBolt } from "react-icons/md";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AgGridTable from "../../reusableComponents/DataTables/AgGridTable";
import DataTableCheckbox from "../../reusableComponents/Buttons/dataTableCheckbox";
import Moment from "moment";
import { BsSortUpAlt, BsSortDownAlt } from "react-icons/bs";
import { FaTelegram } from "react-icons/fa";
import { TbBan } from "react-icons/tb";
import { TiArrowForward } from "react-icons/ti";
import calculateTableHeight from "../../reusableComponents/calculateTableHeight";
import { t } from "i18next";

function SQLcondition(props) {
  const [sqlConditions, setsqlConditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [own, setOwn] = useState(false);
  const [sqlConditionsLength, setsqlConditionsLength] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [start, setStart] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [countSelected, setCountSelected] = useState(0);
  const [anonymous, setAnnonymoys] = useState(false);
  const [order, setOrder] = useState("companyname");
  const [orderSort, setOrderSort] = useState(false);

  const LinkComponent = (props) => {
    return (
      <div className="flex items-center justify-between">
        <div>
          <Link className="table-links" to={`/sqlConditions/view/${props.data[1].fields.all.id}`}>
            {props.data[1].fields.all.companyname}
          </Link>
        </div>
      </div>
    );
  };

  const EmailComponent = (props) => {
    return props.data[1].fields.all.companyemail && props.data[1].fields.all.companyemail;
  };

  const WebsiteComponent = (props) => {
    return props.data[1].fields.all.companywebsite && props.data[1].fields.all.companywebsite;
  };

  const ScoreComponent = (props) => {
    return <span className="badge badge-light">{props.data[1].score}</span>;
  };

  const contacts = (props) => {
    return <></>;
  };

  const IdComponent = (props) => {
    return props.value;
  };

  const categoryComponent = (props) => {
    return props?.data[1]?.category;
  };

  const SelectDropdown = (p) => {
    return <DataTableCheckbox transformX={true} link={`/sqlConditions/view/${p.data[1].id}`} />;
  };
  const SelectDropdownHeader = (p) => {
    return (
      <div>
        <DataTableCheckbox headerCF={true} cls="header-checkbox" />
      </div>
    );
  };

  const isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  useEffect(() => {}, [order, orderSort]);

  const sortRows = (params) => {
    setOrder(params);
    setOrderSort((orderSort) => !orderSort);
  };

  const sortingHeader = (params) => {
    return (
      <div>
        <button
          className="flex items-center justify-between normal-btn w-full"
          onClick={() => sortRows(params.column.userProvidedColDef.field)}
        >
          <b>{params.column.userProvidedColDef.headerName}</b>
          <BsSortDownAlt size={16} />
        </button>
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      cellRenderer: SelectDropdown,
      headerComponent: SelectDropdownHeader,
      cellClass: "checkboxes-table",
      width: 100,
      sortable: true,
      lockPosition: "left",
    },
    {
      headerComponent: sortingHeader,
      headerName: t("caymland.core.name"),
      field: "name",
      flex: 1,
      minWidth: 150,
      cellRenderer: LinkComponent,
      cellClass: "overflow-hidden",
    },
    {
      headerComponent: sortingHeader,
      headerName: t("caymland.core.category"),
      field: "category",
      minWidth: 80,
      flex: 1,
      valueGetter(params) {
        return params.data[1].category;
      },
      cellRenderer: categoryComponent,
    },
    {
      headerComponent: sortingHeader,
      headerName: t("caymland.core.id"),
      field: "id",
      width: 80,
      valueGetter(params) {
        return params.data[1].id;
      },
      cellRenderer: IdComponent,
    },
  ]);

  const defaultColDef = useMemo(() => ({
    filter: true,
    headerCheckboxSelection: isFirstColumn,
    checkboxSelection: isFirstColumn,
    sortable: true,
    // rowDrag: isFirstColumn,
  }));

  const navigate = useNavigate();

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows.map((x) => x[1]));
    setCountSelected(state.selectedCount);
  }, []);

  let filteredRowsID = selectedRows.map((x) => x.id);

  useEffect(() => {
    loadAllsqlConditions(page);
  }, [rowsPerPage, page, orderSort, order]);

  async function fetchsqlConditions(url, page = 1) {
    setLoading(true);
    let start = page === 1 ? 0 : (page - 1) * rowsPerPage;
    let query = `limit=${rowsPerPage}&start=${start}&orderByDir=${orderSort ? "ASC" : "DESC"}`;

    if (url) {
      query += "&" + url;
    }

    setsqlConditions(Object.entries(response.data.companies) || Object.entries(response.data.company));
    setsqlConditionsLength(response.data.total);
    setLoading(false);
  }

  async function loadAllsqlConditions(page = 1) {
    setLoading(true);
    let url = "";
    if (search.length) {
      url += "&search=" + search;
    }
    await fetchsqlConditions(url, page);
    setOwn(false);
    setLoading(false);
  }

  async function handlePageChange(page) {
    setPage(page);
  }

  async function handlePerRowsChange(newPerPage, page) {
    setRowsPerPage(newPerPage);
    setPage(page);
  }

  async function searchContact(e) {
    e.preventDefault();
    setSearch(e.target.value);

    if (e.target.value.length > 3 || e.target.value.length < 1) {
      await loadAllsqlConditions(1);
    }
  }

  const clearSearch = async (e) => {
    setSearch("");
    await loadAllsqlConditions(1);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Zeilen pro Seite",
    rangeSeparatorText: "von",
  };

  const Checkbox = forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <Button
          id="caret"
          color="white"
          className="d-flex items-center gap-5px data-table-checkbox"
          style={{
            padding: "6px",
          }}
        >
          <input
            type="checkbox"
            className="d-flex items-center gap-5px data-table-checkbox"
            ref={ref}
            onClick={onClick}
            {...rest}
          />
        </Button>
      </>
    );
  });

  return (
    <Fragment>
      <div className="container-fluid container-fluid-margin pt-80">
        <div className="page-header">
          <div className="heading-padding">
            <div
              className="flex flex-wrap items-center justify-between"
              style={{
                borderBottom: "1px solid #dddddd",
                paddingBottom: "10px",
              }}
            >
              <div className="col">
                <div className="page-header-left">
                  <h3 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                    {t("caymland.sqlConditions.manage")}
                  </h3>
                </div>
              </div>
              <div className="d-flex items-center gap-10">
                <AddNew link={"/sqlConditions/new"} showMore={true} />
              </div>
            </div>
            <div
              className="d-flex flex-wrap items-center justify-between relative z-10"
              style={{ paddingBottom: "10px", paddingTop: "20px" }}
            >
              <div className="col">
                <div className="page-header-left filter d-flex items-center">
                  <SearchHeader
                    onChange={searchContact}
                    searchLength={search.length}
                    clear={clearSearch}
                    value={search}
                  />
                  <SearchHelper />
                </div>
              </div>
            </div>
          </div>
          <div style={{ borderBottom: "1px solid #ebebeb", marginBottom: "25px" }} className={"mt-30"}>
            <AgGridTable
              data={sqlConditions}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              rowsPerPage={rowsPerPage}
              page={page}
              totalContacts={sqlConditionsLength}
              loading={loading}
              cls="no-drag-table"
              heightTable={calculateTableHeight(page, rowsPerPage, sqlConditionsLength, 42)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SQLcondition;
