import React, { Fragment, useCallback } from "react";
import DoughnutChart from "./pieChart";
import MyLineChart from "./lineChart";

function Charts({ type, name, width, height, chartData, handleEdit, handleDelete, id }) {
  const renderChart = useCallback(() => {
    switch (type) {
      case "pie":
        return (
          <DoughnutChart
            height={height}
            value={"test"}
            chartData={chartData}
            name={name}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            id={id}
          />
        );
      case "line":
        return (
          <MyLineChart
            name={name}
            extraInfo={true}
            height={height}
            chartData={chartData}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            id={id}
          />
        );
      default:
        return null;
    }
  }, [type, name, height, chartData, handleEdit, handleDelete, id]);

  return (
    <Fragment>{renderChart()}</Fragment>
  );
}

export default Charts;
