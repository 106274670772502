import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Text } from "../reusableComponents/Inputs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SlideAlert } from "../reusableComponents/Alerts";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");

  const sendResetPasswordEmail = async () => {
    try {
      const endpoint = "https://dev.m-4.ch/passwordresetV2";
      const response = await axios.post(endpoint, { identifier: username });
      if (response.status === 200) {
        setShow(true);
        setMessage(t("A new reset password link has been sent to you. Please check your email."));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 6000);
      }
    } catch (error) {
      console.error("Failed to send email", error);
    }
  };

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} />
      <div className="page-wrapper">
        <div className="auth-bg">
          <div className="authentication-box">
            <div className="text-center">
              <img src="https://dev.m-4.ch/media/images/sidebar_logo.png" alt="" />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h4>{t("Reset Password")}</h4>
                </div>
                <Text
                  name="_username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  label={t("Username or Email")}
                />
                <div className="forgotPassword">
                  <Link to="/login">{t("Back to Login")}</Link>
                </div>
                <div className="form-group form-row mt-3 mb-0 d-grid">
                  <button className="btn active-btn" onClick={sendResetPasswordEmail}>
                    {t("Reset Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
