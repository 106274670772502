import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from "reactstrap";
import { FaBan, FaCogs, FaEdit, FaRegClone, FaRss, FaTrashAlt } from "react-icons/fa";
import { BiRefresh } from "react-icons/bi";
import { RiDatabase2Line } from "react-icons/ri";
import { VscOpenPreview } from "react-icons/vsc";
import { TiUserOutline } from "react-icons/ti";
import { TbSend } from "react-icons/tb";
import { IoMdPaperPlane } from "react-icons/io";
import { AiOutlineClockCircle, AiOutlineDashboard } from "react-icons/ai";
import { GrPieChart } from "react-icons/gr";
import { Link } from "react-router-dom";
import SweetAlert from "sweetalert2";
import Loading from "../loading";
import { Success } from "../Alerts";
import { t } from "i18next";

function DataTableCheckbox(props) {
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const location = useLocation();
  const cloneViewCompanies = location.pathname.includes("/companies");
  const cloneViewSegments = location.pathname.includes("/segments");
  const cloneViewForms = location.pathname.includes("/forms");
  const assetsPath = location.pathname.includes("/assets");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const deleteField = async () => {};

  return (
    <>
      {loading ? (
        <div className="center-loader">
          <Loading />
        </div>
      ) : (
        <ButtonDropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className={`${props.cls} ${props.transformX ? "translate-55px" : ""}`}
          style={{
            overflow: "visible",
          }}
        >
          <DropdownToggle
            disabled={
              (props.headerContacts ||
                props.headerCampaigns ||
                props.headerCompanies ||
                props.headerEmail ||
                props.headerLandingPages ||
                props.headerSegments ||
                props.headerForms ||
                props.headerDWC ||
                props.headerSegments ||
                props.pointActionsDropdown ||
                props.focusItemsDropDown ||
                props.pointTriggersDropDown ||
                props.reportDropDown ||
                props.assetsDropdown ||
                props.userDropDown) &&
              props.disabled
                ? true
                : false
            }
            onClick={props.clickE}
            className={`data-table-checkbox ${props.cls} ${props.transformX ? "checkbox-small" : "check-boxnormal"}`}
            caret
            color="white"
          />
          {props.headerCF ||
          props.headerForms ||
          props.headerDWC ||
          props.headerSegments ||
          props.headerPointActions ||
          props.headerFocusItems ||
          props.headerPointTriggers ||
          props.headerReports ||
          props.headerAssets ? (
            <DropdownMenu>
              <DropdownItem onClick={props.handleBatchDelete} className="d-flex items-center gap-5px">
                <FaTrashAlt color="#f86b4f" size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.core.form.delete_selected")}</p>
              </DropdownItem>
              <DropdownItem onClick={props.handleBatchRebuild} className="d-flex items-center gap-5px">
                <BiRefresh size={15} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.form.rebuild")}</p>
              </DropdownItem>
            </DropdownMenu>
          ) : props.contacts ? (
            <DropdownMenu>
              <Link to={`${props.link}`}>
                <DropdownItem className="d-flex items-center gap-5px">
                  <FaEdit size={13} />
                  <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.core.form.edit")}</p>
                </DropdownItem>
              </Link>
              <DropdownItem onClick={props.clickOnEmailFunction} className="d-flex items-center gap-5px">
                <TbSend size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.module.account.sent")}</p>
              </DropdownItem>
              {/* <Link to={`${props.link}`}> */}
              <DropdownItem className="d-flex items-center gap-5px" onClick={props?.onClickIDFunction}>
                <FaBan size={13} color="#f86b4f" />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  {props.doNotContact ? "Remove Do Not Contact" : "Set Do Not Contact"}
                </p>
              </DropdownItem>
              {/* </Link> */}
              <DropdownItem className="d-flex items-center gap-5px" onClick={props.handleDelete ?? deleteField}>
                <FaTrashAlt size={13} color="#f86b4f" />
                <p style={{ fontSize: "12px", marginBottom: "0", marginLeft: "3px" }}>
                  {t("caymland.core.form.delete")}
                </p>
              </DropdownItem>
            </DropdownMenu>
          ) : props.headerContacts ? (
            <DropdownMenu>
              <DropdownItem
                onClick={() => props.openCampaignModal("Campaigns")}
                className="d-flex items-center gap-5px"
              >
                <AiOutlineClockCircle size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.lead.batch.campaigns")}</p>
              </DropdownItem>
              <DropdownItem
                onClick={() => props.openCampaignModal("Categories")}
                className="d-flex items-center gap-5px"
              >
                <FaCogs size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.lead.batch.categorie")}</p>
              </DropdownItem>
              <DropdownItem className="d-flex items-center gap-5px" onClick={() => props.openCampaignModal("Channels")}>
                <FaRss size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.lead.batch.channels")}</p>
              </DropdownItem>
              <DropdownItem className="d-flex items-center gap-5px" onClick={() => props.openCampaignModal("Owner")}>
                <TiUserOutline size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.lead.batch.owner")}</p>
              </DropdownItem>
              <DropdownItem className="d-flex items-center gap-5px" onClick={() => props.openCampaignModal("Segments")}>
                <GrPieChart size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.lead.batch.lists")}</p>
              </DropdownItem>
              <DropdownItem className="d-flex items-center gap-5px" onClick={() => props.openCampaignModal("Stages")}>
                <AiOutlineDashboard size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.lead.batch.stages")}</p>
              </DropdownItem>
              <DropdownItem
                className="d-flex items-center gap-5px"
                onClick={() => {
                  props.openCampaignModal("Set Do Not Contact");
                }}
              >
                <FaBan size={13} color="#f86b4f" />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>
                  {props.doNotContact ? "Remove Do Not Contact" : "Set Do Not Contact"}
                </p>
              </DropdownItem>
              <DropdownItem onClick={props.handleDelete ?? deleteField} className="d-flex items-center gap-5px">
                <FaTrashAlt color="#f86b4f" size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.core.form.delete_selected")}</p>
              </DropdownItem>
            </DropdownMenu>
          ) : props.categories || props.userDropDown ? (
            <DropdownMenu>
              <DropdownItem onClick={props.editFunc} className="d-flex items-center gap-5px">
                <FaEdit size={13} />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.core.form.edit")}</p>
              </DropdownItem>

              <DropdownItem className="d-flex items-center gap-5px" onClick={props.handleDelete ?? deleteField}>
                <FaTrashAlt size={13} color="#f86b4f" />
                <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.core.form.delete")}</p>
              </DropdownItem>
            </DropdownMenu>
          ) : props.emailDropdown ||
            props.campaignsDropdown ||
            props.pagesDropdown ||
            props.dynamicContentDropdown ||
            props.assetsDropdown ||
            props.formsDropdown ||
            props.segmentDropdown ||
            props.companyDropdown ||
            props.cfDropdown ||
            props.pointActionsDropdown ||
            props.focusItemsDropDown ||
            props.resultsDropdown ||
            props.pointTriggersDropDown ||
            props.reportDropDown ||
            props.webHooks ? (
            <DropdownMenu>
              {!props.resultsDropdown && (
                <Link to={`${props.link}`} state={{ data: props.data, type: "edit" }}>
                  <DropdownItem className="d-flex items-center gap-5px">
                    <FaEdit size={13} />
                    <p style={{ fontSize: "12px", marginBottom: "0", marginLeft: "3px" }}>
                      {t("caymland.core.form.edit")}
                    </p>
                  </DropdownItem>
                </Link>
              )}
              {!props.resultsDropdown && !assetsPath ? (
                <Link
                  to={props?.cloneLink}
                  state={
                    cloneViewForms || cloneViewCompanies || cloneViewSegments
                      ? { data: props?.id, type: "clone" }
                      : props?.data
                  }
                >
                  <DropdownItem className="d-flex items-center gap-5px">
                    <FaRegClone size={13} />
                    <p style={{ fontSize: "12px", marginBottom: "0", marginLeft: "3px" }}>
                      {t("caymland.core.form.clone")}
                    </p>
                  </DropdownItem>
                </Link>
              ) : null}
              {props.sendSegmentEmail && !props.resultsDropdown && (
                <DropdownItem onClick={props.handleSendSegmentEmail} className="d-flex items-center gap-5px">
                  <IoMdPaperPlane size={17} />
                  <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.email.send")}</p>
                </DropdownItem>
              )}

              {props.formsDropdown && (
                <>
                  <Link to={props.previewLink} target="_blank">
                    <DropdownItem className="d-flex items-center gap-5px">
                      <VscOpenPreview size={13} />
                      <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.form.form.preview")}</p>
                    </DropdownItem>
                  </Link>
                  <Link to={props.resultsLink}>
                    <DropdownItem className="d-flex items-center gap-5px">
                      <RiDatabase2Line size={13} />
                      <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.form.form.results")}</p>
                    </DropdownItem>
                  </Link>
                </>
              )}

              {props.assetsDropdown && (
                <DropdownItem className="d-flex items-center gap-5px" onClick={() => props.openModal(props.data.id)}>
                  <VscOpenPreview size={13} />
                  <p style={{ fontSize: "12px", marginBottom: "0" }}>{t("caymland.form.form.preview")}</p>
                </DropdownItem>
              )}

              <DropdownItem className="d-flex items-center gap-5px" onClick={props.handleDelete ?? deleteField}>
                <FaTrashAlt size={13} color="#f86b4f" />
                <p style={{ fontSize: "12px", marginBottom: "0", marginLeft: "3px" }}>
                  {t("caymland.core.form.delete")}
                </p>
              </DropdownItem>
            </DropdownMenu>
          ) : (
            <></>
          )}
        </ButtonDropdown>
      )}
    </>
  );
}

export default DataTableCheckbox;
