import { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import { Styles } from "../utils";
import NodeToolbar from "./NodeToolbar";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { t } from "i18next";

const Source = ({
  data,
  isConnectable,
  setParams,
  handleAdd,
  handleEdit,
  actions,
  id,
  hasJumpNodeActive,
  jumpNode,
}) => {
  const [isVisible, setVisible] = useState(false);
  const isActive = hasJumpNodeActive && !(`${id}` === jumpNode.first || id === jumpNode.second);

  return (
    <div
      className="SourceSelect"
      onDoubleClick={() => !isActive && handleEdit(setParams, "leadSource", "edit", 2, actions, data)}
      onMouseEnter={() => !isActive && setVisible(true)}
      onMouseLeave={() => !isActive && setVisible(false)}
      style={{ opacity: isActive ? 0.5 : 1 }}
    >
      <NodeToolbar
        isVisible={isVisible}
        handleEdit={() => handleEdit(setParams, "leadSource", "edit", 2, actions, data)}
        handleDelete={() => actions.deleteNode(data.params)}
      />
      <div className="text-updater-node d-flex flex-column align-items-center sourceSelectBuilder">
        <div className="list-campaign-leadsource d-flex w-full justify-between gap-10">
          <div className="d-flex align-center place-self-center">
            {id === "lists" ? <FormatListBulletedOutlinedIcon /> : <ReceiptIcon />}
          </div>
          <div className="place-self-center" style={{ maxHeight: "50px", width: "105px", overflow: "hidden" }}>
            <p
              className="mb-0 w-100"
              style={{
                fontSize: "11px",
                width: "max-content",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {data?.params[id]?.map((e) => e.name).join(", ")}
            </p>
            {/* <p className='mb-0' style={{fontSize: "11px"}}>{t("caymland.campaign.source.total_count")}</p> */}
          </div>
        </div>
        <Handle
          type="source"
          id="leadsourceright"
          position={Position.Right}
          style={Styles.handleStyle}
          isConnectable={isConnectable}
          className="handleToggle"
          onClick={() => !isActive && handleAdd(setParams, "leadSource", "add", 0, "leadsourceright", actions, data)}
        ></Handle>
        <Handle
          type="target"
          id="leadsourceleft"
          position={Position.Left}
          style={Styles.handleStyle}
          className="handleToggle"
          isConnectable={isConnectable}
          onClick={() => !isActive && handleAdd(setParams, "leadSource", "add", 0, "leadsourceright", actions, data)}
        ></Handle>
        <Handle
          type="source"
          id="leadsource"
          position={Position.Bottom}
          style={Styles.handleStyle}
          className="handleToggle"
          isConnectable={isConnectable}
          onClick={() => !isActive && handleAdd(setParams, "events", "add", 0, "leadsource", actions, data)}
        ></Handle>
      </div>
    </div>
  );
};

export default memo(Source);
