import { Text } from "../../../../../reusableComponents/Inputs";

export const TextField = ({ label, name, setEntity, required, property, entity }) => {
  return (
    <>
      <Text
        name={name}
        value={(entity && entity[name]) || ""}
        onChange={(e) => {
          if (!property) {
            setEntity((prev) => ({ ...prev, [name]: e.target.value }));
          } else {
            setEntity((prev) => ({
              ...prev,
              properties: {
                ...prev.properties,
                [name]: e.target.value,
              },
            }));
          }
        }}
        label={label}
        required={required}
      />
      {/* <div class="input-container ic1 mb-20px">
            <input
                name={name}
                class={`jump-input`}
                type="text"
                placeholder=" " 
                onChange={(e) => {
                    if (!property) {
                        setEntity((prev) =>
                            ({ ...prev, [name]: e.target.value })
                        )
                    } else {
                        setEntity((prev) =>
                        ({
                            ...prev,
                            properties: {
                                ...prev.properties,
                                [name]: e.target.value
                            }
                        })
                        )
                    }
                }
            }
            value={(entity && entity[name]) || ""}
            />

            <label style={{ textTransform: "capitalize" }} for={name} class={`jump-placeholder ${required && "mandatory-field"}`}>
                {label}
            </label>
        </div> */}
    </>
  );
};
