import React, { useState } from "react";
import { Text } from "../../../reusableComponents/Inputs";
import { Controller } from "react-hook-form";
import Dropzone from "react-dropzone-uploader";
import { t } from "i18next";
import { useUploadSerialLetterMutation } from "../../../../redux/api/serialLettersApi";
import { BiLinkExternal } from "react-icons/bi";
import { useLocation } from "react-router-dom";

const MainForm = ({ control, setValue, errors, watch }) => {
  const [uploadSerialLetter] = useUploadSerialLetterMutation();

  const location = useLocation();

  const isEdit = location.pathname.includes("edit");
  const isClone = location.pathname.includes("clone");

  const getUploadParams = async ({ file }) => {
    const formData = new FormData();
    formData.append("files[]", file);

    try {
      const response = await uploadSerialLetter(formData).unwrap();
      if (response[0].name) {
        setValue("source", response[0].name);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    return { url: "", body: formData };
  };

  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === "removed") {
      setValue("source", "");
    }
  };

  // Extract the correct URL from the source field
  const extractFileUrl = (source) => {
    if (source) {
      const parts = source.split("https://");
      if (parts.length > 2) {
        return `https://${parts[2]}`; // Get the second 'https' part
      }
      return source; // If there's only one 'https', return the source as is
    }
    return "";
  };
  console.log(watch("source"));
  console.log(extractFileUrl(watch("source")).substring(extractFileUrl(watch("source")).lastIndexOf("/") + 1));
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "40px" }}>
      <div className="w-full" style={{ marginTop: "20px" }}>
        <Controller
          control={control}
          name="source"
          render={({ field: { onChange } }) => (
            <div>
              {watch("source") && (isEdit || isClone) && (
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                  <div class="input-container ic1 flex" style={{ width: "40%", marginBottom: "10px" }}>
                    <input
                      id="download-url"
                      style={{
                        borderRadius: "6px 0 0 6px",
                        background: "#f4f4f4",
                      }}
                      className="jump-input cursor-disabled"
                      type="text"
                      disabled
                      value={
                        extractFileUrl(watch("source")).substring(
                          extractFileUrl(watch("source")).lastIndexOf("/") + 1
                        ) || ""
                      }
                      placeholder=""
                    />
                    <a
                      rel="noreferrer"
                      href={extractFileUrl(watch("source"))}
                      target="_blank"
                      className="download-button"
                    >
                      <BiLinkExternal color="#333" />
                    </a>
                  </div>
                </div>
              )}
              {errors && errors["source"] && (
                <span style={{ color: "#d32f2f", fontSize: "11px" }}>{t("caymland.core.fieldrequired")}</span>
              )}

              <div className="dz-message needsclick h-200-dropzone">
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                  accept=".docx"
                  inputContent={t("caymland.asset.drop.file.here")}
                  styles={{
                    dropzoneActive: { borderColor: "green" },
                  }}
                />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default MainForm;
