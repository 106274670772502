import React, { useState, useEffect, Fragment } from "react";
import { DragHandle } from "../../widgetGenerator/index";
import { Label } from "../../../../reusableComponents/Inputs";
import EditDeleteMenu from "../../EditDeleteMenu";
import GeoEChart from "../../../../reusableComponents/ECharts/WorldChart/GeoEChart";
import Chart from "react-google-charts";

function GeoChart({ name, data, width, height, handleEdit, handleDelete, id }) {
  const [mapData, setMapData] = useState([["Country", "Popularity"]]);

  const options = {
    colorAxis: { colors: ["#d9eaf7", "#135f95"] },
    datalessRegionColor: "#f8f8f8",
    defaultColor: "#f8f8f8",
  };

  useEffect(() => {
    let dataValues = Object.entries(data).map((x) => [x[0], parseInt(x[1])]);
    let newArr = mapData.concat(dataValues);
    setMapData(newArr);
  }, [data]);

  return (
    <Fragment>
      <div className="chart-header">
        <DragHandle />
        <div style={{ marginTop: "8px", marginLeft: "10px" }}>
          <Label name={name} label={name} />
        </div>
        <EditDeleteMenu handleEdit={() => handleEdit(id)} handleDelete={() => handleDelete(id)} />
      </div>
      <div  style={{ minHeight: height}}>
        <Chart chartType="GeoChart"  height={height} data={mapData} options={options} width="100%" />
      </div>
    </Fragment>
  );
}

export default GeoChart;
