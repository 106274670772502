import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { t } from "i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";

const SortableItem = SortableElement(({ value, index, onChange, onRemove, rowIndex }) => {
  const userPhone = useSelector((state) => state.auth.user);
  console.log(userPhone);
  return (
    <div
      index={rowIndex}
      className="d-flex items-center w-full"
      style={{ marginBottom: "10px", visibility: "visible", position: "relative", zIndex: 2222, maxWidth: "770px" }}
    >
      <button
        type="button"
        onClick={() => onRemove(value.id)}
        className="dataType-options-btn button-with-x-background bgd1"
        style={{
          borderRadius: "4px 0 0 4px",
          backgroundColor: "#d1d1d1!important",
          height: "39px",
        }}
      ></button>
      <PhoneInput
        value={value.sms}
        onChange={(value) => onChange(value, rowIndex)}
        placeholder={userPhone.phone1 || userPhone.phone2}
      />
      <button type="button" className="dataType-options-btn" style={{ borderRadius: "0 4px 4px 0", height: "39px" }}>
        <span>
          <BiDotsVertical size={12} />
        </span>
      </button>
    </div>
  );
});

const SortableList = SortableContainer(({ items, onChange, onRemove }) => {
  return (
    <div>
      {items
        .sort((a, b) => a.position - b.position)
        .map((value, index) => (
          <SortableItem
            hideSortableGhost={false}
            value={value}
            rowIndex={index}
            index={index}
            key={value.id}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
});

function SendEmailContent({ sendSmsList, setSendSmsList }) {
  const handleOptionsRemove = (id) => {
    let list = sendSmsList.filter((x) => x.id !== id);
    setSendSmsList(list);
  };

  const handleOptionsAdd = () => {
    let id = parseInt(Date.now() * Math.random() * 10);

    setSendSmsList([...sendSmsList, { id: id, sms: "", position: sendSmsList.length + 1 }]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let arr = arrayMoveImmutable(sendSmsList, oldIndex, newIndex);

    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i;
    }
    setSendSmsList(arr);
  };

  const handleOptionsChange = (value, index) => {
    console.log("Updating index", index, "with value", value);
    const list = [...sendSmsList]; // Create a shallow copy of the list

    if (list[index]) {
      // Check if the element exists
      list[index].sms = value; // Update the sms property
      setSendSmsList(list); // Set the updated list to state
    } else {
      console.error("Error: No element at index", index); // Error handling
    }
  };

  return (
    <div className="w-full">
      <p>
        {t("caymland.infobip.example_recipients")} <span style={{ color: "red" }}>*</span>
      </p>
      <button type="button" onClick={handleOptionsAdd} className="addOptions-btn">
        <span>{t("caymland.infobip.add_recipient")}</span>
      </button>
      <SortableList
        helper
        className="z-9999"
        items={sendSmsList}
        onSortEnd={onSortEnd}
        onChange={handleOptionsChange}
        onRemove={handleOptionsRemove}
        axis={"y"}
      />
    </div>
  );
}

export default SendEmailContent;
