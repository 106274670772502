import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { t } from "i18next";
import {
  useAddSerialLetterMutation,
  useGetSerialLetterByIdQuery,
  useUpdateSerialLetterMutation,
} from "../../../redux/api/serialLettersApi";
import MainForm from "./SerialForms/MainForm";
import SidebarForm from "./SerialForms/SidebarForm";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineSave } from "react-icons/ai";
import { Success } from "../../reusableComponents/Alerts";

const SerialLetterForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");

  const isNew = location.pathname.includes("new");
  const ClonePath = location.pathname.includes("clone");

  const id = location?.state?.id || location?.state || useParams().id;

  const { data } = useGetSerialLetterByIdQuery(id, { skip: !id });
  const [addSerialLetter, { isLoading: isAddLoading }] = useAddSerialLetterMutation();
  const [updateSerialLetter, { isLoading: isEditLoading }] = useUpdateSerialLetterMutation();
  const isSaving = isEditLoading || isAddLoading;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("caymland.core.fieldrequired")),
    source: yup.string().required(t("caymland.core.fieldrequired")),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      console.error(errors);
      if (errors) {
        setShow(true);
        setMessage(t("caymland.core.error.not.valid"));
        setBackgroundToast("2");
        setTimeout(() => {
          setMessage("");
          setShow(false);
          setBackgroundToast("1");
        }, 3000);
      }
    }
  }, [errors]);

  const handleSerialLetter = async (formData, close = false) => {
    console.log(formData);
    const formattedData = {
      name: formData.name,
      source: formData.source.substring(formData.source.lastIndexOf("/") + 1) || formData.source,
      ...(formData.qrcode && {
        properties: {
          qrcode: {
            qrcode: formData.qrcode ? 1 : 0,
            qrcode_url: formData.qrcode_url,
            size: parseInt(formData.size, 10),
            margin: parseInt(formData.margin, 10),
            fgcolor: formData?.fgcolor?.replace("#", "") || "#000000",
            bgcolor: formData?.bgcolor?.replace("#", "") || "ffffff",
          },
        },
      }),
    };

    console.log(formattedData);

    try {
      let response;
      if (id && !ClonePath) {
        response = await updateSerialLetter({ id, ...formattedData }).unwrap();
        console.log(response);
      } else {
        response = await addSerialLetter(formattedData).unwrap();
        console.log(response);
      }
      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed) {
            navigate(close ? "/serial-letters" : `/serial-letters/edit/${response.serialletter.id}`);
          }
        });
      }
    } catch (error) {
      console.error(`Error ${id ? "updating" : "adding"} serial letter:`, error);
    }
  };
  console.log(data);

  useEffect(() => {
    if (data) {
      reset({
        name: data.serialletter.name || "",
        source: data.serialletter.source || "",
        qrcode: data.serialletter.properties?.qrcode.qrcode ? 1 : false,
        qrcode_url: data.serialletter.properties?.qrcode.qrcode_url || "",
        size: data.serialletter.properties?.qrcode.size || "",
        margin: data.serialletter.properties?.qrcode.margin || "",
        fgcolor: `#${data.serialletter.properties?.qrcode.fgcolor}` || "",
        bgcolor: `#${data.serialletter.properties?.qrcode.bgcolor}` || "",
      });
    }
  }, [data, reset]);

  console.log(watch("qrcode"));

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/serial-letters"),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleSerialLetter(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleSerialLetter(data)),
    },
  ];

  return (
    <div>
      <Header
        buttons={buttons}
        background={backgorundToast}
        title={t("caymland.plugin.badge.generator.index")}
        alertMessage={message}
        showAlert={show}
        loading={isSaving}
      />
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={<MainForm control={control} setValue={setValue} errors={errors} watch={watch} />}
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={<SidebarForm control={control} watch={watch} errors={errors} />}
      />
    </div>
  );
};

export default SerialLetterForm;
