import React, { Fragment, useRef } from "react";
import { Text, TextArea, Checkbox } from "../../../../reusableComponents/Inputs";
import ColorPicker from "../../../../reusableComponents/Inputs/ColorPicker";
import { t } from "i18next";

function Details({ errors, setErrors, modifiedData, setModifiedData }) {
  const colorPickerRef = useRef();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name);
    if (name === "name" ) {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }
    if (name === "points" ) {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }
    if (type === "text")
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    if (type === "number")
      setModifiedData((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  const handleColorChange = (color) => {
    setModifiedData((prev) => ({
      ...prev,
      color: color.hex,
    }));
  };

  return (
    <Fragment>
      <div className="flex flex-row w-full manageActions gap-10">
        <div className="flex flex-col w-50">
          <Text
            name={"name"}
            value={modifiedData?.name}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.lottery.column.name.name")}
            errors={errors?.name}
            required={true}
          />
          <div className="w-full small-editor">
            <TextArea
              label={t("caymland.lottery.column.name.description")}
              name="Description"
              value={modifiedData?.description}
              onChange={(data) => {
                setModifiedData((prev) => ({
                  ...prev,
                  description: data,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-50">
          <Text
            name="points"
            type="number"
            errors={errors.points}
            required={true}
            value={modifiedData?.points}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.lead.lead.event.pointtrigger")}
            tooltip={t("caymland.point.trigger.form.points_descr")}
          />
          <div className="w-full" ref={colorPickerRef}>
            <ColorPicker
              type="text"
              id="colorInput"
              name="colorInput"
              value={modifiedData?.color}
              onChange={handleColorChange}
              colorPickerRef={colorPickerRef}
              tooltip={t("caymland.point.trigger.form.color_descr")}
              label={t("caymland.point.trigger.form.color")}
            />
          </div>
          <Checkbox
            name="triggerExistingLeads"
            value={modifiedData?.triggerExistingLeads}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.point.trigger.form.existingleads")}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Details;
