const customBaseQuery = (query) => {
  return (args) => {
    if ("pageIndex" in args && "pageSize" in args) {
      const { pageIndex, pageSize } = args;
      args = {
        ...args,
        start: pageIndex * pageSize,
        limit: pageSize,
      };
    }
    return query(args);
  };
};

export default customBaseQuery;
