import {memo} from "react";
import {IconButton} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const Add = ({data, isConnectable, setParams, handleAdd, handleEdit, actions}) => {

    return (
        <IconButton
            variant="contained"
            area-label="Add source"
            size='large'
            onClick={() => handleAdd(setParams, 'leadSource', 'add', 0, 'leadsourceright', actions, data)}
        >
            <AddIcon color='white'/>
        </IconButton>
    )

}

export default memo(Add)