import { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Styles } from "../utils";
import NodeToolbar from "./NodeToolbar";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { t } from "i18next";

const Decision = ({ data, actions, setParams, handleAdd, handleEdit, isConnectable, hasJumpNodeActive, jumpNode }) => {
    const [isVisible, setVisible] = useState(false);
    const id = `${data?.params?.id}`
    const isActive = hasJumpNodeActive && !(id === jumpNode.first || id === jumpNode.second);

    return (
        <>
            <div className="eventSelect"
                onDoubleClick={() => !isActive && handleEdit(setParams, 'decision', 'edit', 2, actions, data)}
                onMouseEnter={() => !isActive && setVisible(true)}
                onMouseLeave={() => !isActive && setVisible(false)}
                style={{ opacity: isActive ? 0.5 : 1 }}
            >
                <NodeToolbar
                    isVisible={isVisible}
                    handleEdit={() => handleEdit(setParams, 'decision', 'edit', 2, actions, data)}
                    handleDelete={() => actions.deleteNode(data.params)}
                />
                <div
                    className="text-updater-node d-flex flex-column align-items-center sourceSelectBuilder"
                    style={{ border: "1px solid #04B76B" }}
                >
                    <Handle
                        type='target'
                        position={Position.Top}
                        style={Styles.handleStyleCenter}
                        isConnectable={isConnectable}
                        className="handleToggle"
                    />
                    <div className="d-flex w-full justify-between gap-10" style={{ height: '38px' }}>
                        <div className="d-flex align-center place-self-center">
                            <ShuffleIcon />
                        </div>
                        <div className="place-self-center" style={{ maxHeight: '50px', width: '105px', overflow: 'hidden' }}>
                        <p className="mb-0 text-ellipsis" style={{fontSize: "11px", lineHeight: '1.2'}}>
                                {data?.params?.name || t("caymland.campaign.event.decision.header")}
                            </p>
                        </div>
                    </div>
                    <Handle
                        id='yes'
                        type='source'
                        position={Position.Bottom}
                        style={Styles.handleStyleLeft}
                        isConnectable={isConnectable}
                        className="handleToggle"
                        onClick={() => !isActive && handleAdd(setParams, 'decision', 'add', 0, 'yes', actions, data)}
                    />
                    <Handle
                        id='no'
                        type='source'
                        position={Position.Bottom}
                        style={Styles.handleStyleRight}
                        isConnectable={isConnectable}
                        className="handleToggle"
                        onClick={() => !isActive && handleAdd(setParams, 'decision', 'add', 0, 'no', actions, data)}
                    />
                </div>
            </div>
        </>
    )
}

export default memo(Decision);
