export  const translationMap = {
    rTicketValueTotal: "caymland.showare.field.r_ticket_value_total",
    rSubscriptionValueTotal: "caymland.showare.field.r_subscription_value_total",
    rProductValueTotal: "caymland.showare.field.r_product_value_total",
    rPackageValueTotal: "caymland.showare.field.r_package_value_total",
    rGiftCertificateValueTotal: "caymland.showare.field.r_gift_certificate_value_total",
    rForeignSystemTicketValueTotal: "caymland.showare.field.r_foreign_system_ticket_value_total",
    rMembershipValueTotal: "caymland.showare.field.r_membership_value_total",
    rOrderTotal: "caymland.showare.field.r_order_total",
    nOrderID: "caymland.showare.field.n_order_id",
    orderID: "caymland.showare.field.order_id",
    nOrderStatusID: "caymland.showare.field.n_order_status_id",
    statusID: "caymland.showare.field.n_order_status_id",
    cOrderStatusName: "caymland.showare.field.c_order_status_name",
    statusName: "caymland.showare.field.c_order_status_name",
    dOrderDate: "caymland.showare.field.d_order_date",
    orderDate: "caymland.showare.field.d_order_date",
    rOrderTotal: "caymland.showare.field.r_order_total",
    orderPrice: "caymland.showare.field.r_order_total",
    rOrderTotalItem: "caymland.showare.field.r_order_total_item",
    totalItem: "caymland.showare.field.r_order_total_item",
    nDeliveryMethodID: "caymland.showare.field.n_delivery_method_id",
    deliveryMethodID: "caymland.showare.field.n_delivery_method_id",
    cDeliveryMethodName: "caymland.showare.field.c_delivery_method_name",
    deliveryMethodName: "caymland.showare.field.c_delivery_method_name",
    cCurrencyCode: "caymland.showare.field.c_currency_code",
    currencyCode: "caymland.showare.field.c_currency_code",
    cCollectedPaymentMethodNames: "caymland.showare.field.c_collected_payment_method_names",
    collectedPaymentMethodNames: "caymland.showare.field.c_collected_payment_method_names",
    cOrderDeliveryCompanyName: "caymland.showare.field.c_order_delivery_company_name",
    orderDeliveryCompanyName: "caymland.showare.field.c_order_delivery_company_name",
    cOrderDeliveryCompanyName2: "caymland.showare.field.c_order_delivery_company_name2",
    orderDeliveryCompanyName2: "caymland.showare.field.c_order_delivery_company_name2",
    cOrderDeliverySalutation: "caymland.showare.field.c_order_delivery_salutation",
    orderDeliverySalutation: "caymland.showare.field.c_order_delivery_salutation",
    cOrderDeliveryFirstName: "caymland.showare.field.c_order_delivery_first_name",
    orderDeliveryFirstName: "caymland.showare.field.c_order_delivery_first_name",
    cOrderDeliveryName: "caymland.showare.field.c_order_delivery_name",
    orderDeliveryName: "caymland.showare.field.c_order_delivery_name",
    cOrderDeliveryAddress1: "caymland.showare.field.c_order_delivery_address1",
    orderDeliveryAddress1: "caymland.showare.field.c_order_delivery_address1",
    cOrderDeliveryZIP: "caymland.showare.field.c_order_delivery_zip",
    orderDeliveryZIP: "caymland.showare.field.c_order_delivery_zip",
    cOrderDeliveryCity: "caymland.showare.field.c_order_delivery_city",
    orderDeliveryCity: "caymland.showare.field.c_order_delivery_city",
    cOrderDeliveryCountryName: "caymland.showare.field.c_order_delivery_country_name",
    orderDeliveryCountryName: "caymland.showare.field.c_order_delivery_country_name",
    cOrderDeliveryEmail: "caymland.showare.field.c_order_delivery_email",
    orderDeliveryEmail: "caymland.showare.field.c_order_delivery_email",
    cOrderDeliveryPhone: "caymland.showare.field.c_order_delivery_phone",
    orderDeliveryPhone: "caymland.showare.field.c_order_delivery_phone",
    cOrderBillingCompanyName: "caymland.showare.field.c_order_billing_company_name",
    orderBillingCompanyName: "caymland.showare.field.c_order_billing_company_name",
    cOrderBillingCompanyName2: "caymland.showare.field.c_order_billing_company_name2",
    orderBillingCompanyName2: "caymland.showare.field.c_order_billing_company_name2",
    cOrderBillingSalutation: "caymland.showare.field.c_order_billing_salutation",
    orderBillingSalutation: "caymland.showare.field.c_order_billing_salutation",
    cOrderBillingFirstName: "caymland.showare.field.c_order_billing_first_name",
    orderBillingFirstName: "caymland.showare.field.c_order_billing_first_name",
    cOrderBillingName: "caymland.showare.field.c_order_billing_name",
    orderBillingName: "caymland.showare.field.c_order_billing_name",
    cOrderBillingAddress1: "caymland.showare.field.c_order_billing_address1",
    orderBillingAddress1: "caymland.showare.field.c_order_billing_address1",
    cOrderBillingAddress2: "caymland.showare.field.c_order_billing_address2",
    orderBillingAddress2: "caymland.showare.field.c_order_billing_address2",
    cOrderBillingZIP: "caymland.showare.field.c_order_billing_zip",
    orderBillingZIP: "caymland.showare.field.c_order_billing_zip",
    cOrderBillingCity: "caymland.showare.field.c_order_billing_city",
    orderBillingCity: "caymland.showare.field.c_order_billing_city",
    nCountryIDBilling: "caymland.showare.field.n_country_id_billing",
    countryIDBilling: "caymland.showare.field.n_country_id_billing",
    cOrderBillingCountryCode: "caymland.showare.field.c_order_billing_country_code",
    orderBillingCountryCode: "caymland.showare.field.c_order_billing_country_code",
    cOrderBillingCountryName: "caymland.showare.field.c_order_billing_country_name",
    orderBillingCountryName: "caymland.showare.field.c_order_billing_country_name",
    cOrderBillingEmail: "caymland.showare.field.c_order_billing_email",
    orderBillingEmail: "caymland.showare.field.c_order_billing_email",
    cOrderBillingPhone: "caymland.showare.field.c_order_billing_phone",
    orderBillingPhone: "caymland.showare.field.c_order_billing_phone",
    cSalesChannelCompanyName: "caymland.showare.field.c_sales_channel_company_name",
    salesChannelCompanyName: "caymland.showare.field.c_sales_channel_company_name",
    cSaleschannelTypeName: "caymland.showare.field.c_saleschannel_type_name",
    saleschannelTypeName: "caymland.showare.field.c_saleschannel_type_name",
    cSaleschannelUser: "caymland.showare.field.c_sales_channel_user",
    saleschannelUser: "caymland.showare.field.c_sales_channel_user",
    cSalesChannelType: "caymland.showare.field.c_sales_channel_type",
    salesChannelType: "caymland.showare.field.c_sales_channel_type",
    rOrderDeliveryFeeTotal: "caymland.showare.field.r_order_delivery_fee_total",
    rOrderDiscountTotal: "caymland.showare.field.r_order_discount_total",
    rOrderFeeTotal: "caymland.showare.field.r_order_fee_total",
    rOrderItemTotal: "caymland.showare.field.r_order_item_total",
    rOrderPaymentMethodFeeTotal: "caymland.showare.field.r_order_payment_method_fee_total",

    bEventEnabled: "caymland.showare.field.item.b_event_enabled",
    cAllProductAttributes: "caymland.showare.field.item.c_all_product_attributes",
    cDiscountTypeName: "caymland.showare.field.item.c_discount_type_name",
    cEventCategoryList: "caymland.showare.field.item.c_event_category_list",
    cEventName: "caymland.showare.field.item.c_event_name",
    cEventSEOKeywords: "caymland.showare.field.item.c_event_seo_keywords",
    cGiftCertificateDefinitionName: "caymland.showare.field.item.c_gift_certificate_definition_name",
    cGiftCertificateMessage: "caymland.showare.field.item.c_gift_certificate_message",
    cItemTypeName: "caymland.showare.field.item.c_item_type_name",
    cLocationName: "caymland.showare.field.item.c_location_name",
    cMembershipDefinitionName: "caymland.showare.field.item.c_membership_definition_name",
    cOrderItemStatusName: "caymland.showare.field.item.c_order_item_status_name",
    cPackageDefinitionName: "caymland.showare.field.item.c_package_definition_name",
    cPerformanceName: "caymland.showare.field.item.c_performance_name",
    cPerformanceSEOKeywords: "caymland.showare.field.item.c_performance_seo_keywords",
    cPlaceType: "caymland.showare.field.item.c_place_type",
    cPriceCategoryName: "caymland.showare.field.item.c_price_category_name",
    cProductCategoryName: "caymland.showare.field.item.c_product_category_name",
    cProductDefinitionName: "caymland.showare.field.item.c_product_definition_name",
    cProductDescriptionNls: "caymland.showare.field.item.c_product_description_nls",
    cProductSEOKeywords: "caymland.showare.field.item.c_product_seo_keywords",
    cPromoterName: "caymland.showare.field.item.c_promoter_name",
    cPromotionName: "caymland.showare.field.item.c_promotion_name",
    cSeatRowTitle: "caymland.showare.field.item.c_seat_row_title",
    cSectionCode: "caymland.showare.field.item.c_section_code",
    cSectionName: "caymland.showare.field.item.c_section_name",
    cSubscriptionDefinitionName: "caymland.showare.field.item.c_subscription_definition_name",
    cSystemIdentifier: "caymland.showare.field.item.c_system_identifier",
    dMembershipExpirationDate: "caymland.showare.field.item.d_membership_expiration_date",
    dOrderItemCancellationDate: "caymland.showare.field.item.d_order_item_cancellation_date",
    dPerformanceStartDate: "caymland.showare.field.item.d_performance_start_date",
    dPerformanceStartTime: "caymland.showare.field.item.d_performance_start_time",
    nEntranceID: "caymland.showare.field.item.n_entrance_id",
    nEventID: "caymland.showare.field.item.n_event_id",
    nLocationID: "caymland.showare.field.item.n_location_id",
    nOrderID: "caymland.showare.field.item.n_order_id",
    nOrderItemID: "caymland.showare.field.item.n_order_item_id",
    nOrderStatusID: "caymland.showare.field.item.n_order_item_status_id",
    nPerformanceID: "caymland.showare.field.item.n_performance_id",
    nPriceCategoryID: "caymland.showare.field.item.n_price_category_id",
    nSubscriptionID: "caymland.showare.field.item.n_subscription_id",
    rGiftCertificatePrice: "caymland.showare.field.item.r_gift_certificate_price",
    rOrderItemDiscountTotal: "caymland.showare.field.item.r_order_item_discount_total",
    rOrderItemFeeExclTotal: "caymland.showare.field.item.r_order_item_fee_excl_total",
    rOrderItemFeeInclTotal: "caymland.showare.field.item.r_order_item_fee_incl_total",
    rOrderItemFeeTotal: "caymland.showare.field.item.r_order_item_fee_total",
    rOrderItemPrice: "caymland.showare.field.item.r_order_item_price",
    rOrderItemRefundTotal: "caymland.showare.field.item.r_order_item_refund_total",
    rOrderItemTotal: "caymland.showare.field.item.r_order_item_total",
    nCurrencyID: "caymland.showare.field.item.n_currency_id",
    cCurrencyCode: "caymland.showare.field.item.c_currency_code",
    nTicketMediumTypeID: "caymland.showare.field.item.n_ticket_medium_type_id",
    cTicketMediumTypeName: "caymland.showare.field.item.c_ticket_medium_type_name",
    cTicketMediumReferencePrintText1: "caymland.showare.field.item.c_ticket_medium_reference_print_text1",
    nPromotionID: "caymland.showare.field.item.n_promotion_id",
    nPromotionTypeID: "caymland.showare.field.item.n_promotion_type_id",
    cPromotionName: "caymland.showare.field.item.c_promotion_name",

    // Add more translations here...
  };