const Progress = (props) => {
  const insertedCount = props?.insertedCount || 0;
  const ignoredCount = props?.ignoredCount || 0;
  const updatedCount = props?.updatedCount || 0;
  const lineCount = props?.lineCount || 0;

  const totalProcessed = insertedCount + updatedCount + ignoredCount;
  const progressPercentage = lineCount > 0 ? (totalProcessed / lineCount) * 100 : 0;

  let progressDisplay;

  if (isNaN(progressPercentage) || progressPercentage === 0) {
    progressDisplay = "0";
  } else if (Math.floor(progressPercentage) === progressPercentage) {
    progressDisplay = `${progressPercentage}`;
  } else {
    progressDisplay = progressPercentage.toFixed(2);
  }

  return (
    <span
      style={{
        display: "flex",
        color: "black",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "2.5em",
      }}
    >
      {progressDisplay}%
    </span>
  );
};

export default Progress;
