import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { t } from "i18next";
import {
  useAddSerialLetterMutation,
  useGetSerialLetterByIdQuery,
  useGetSerialLettersOptionsQuery,
  useUpdateSerialLetterMutation,
} from "../../../redux/api/serialLettersApi";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MainForm from "./MultupleExportForm/MainForm";
import {
  useAddMultipleExportMutation,
  useGetMultipleExportByIdQuery,
  useUpdateMultipleExportMutation,
} from "../../../redux/api/multipleExportApi";
import { useGetSegmentsOptionsQuery } from "../../../redux/api/segmentsApi";
import { AiOutlineSave } from "react-icons/ai";
import { Success } from "../../reusableComponents/Alerts";

const MultipleExportForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");

  const { data: segmentsData } = useGetSegmentsOptionsQuery();
  const { data: serialLettersData } = useGetSerialLettersOptionsQuery();

  const isNew = location.pathname.includes("new");
  const ClonePath = location.pathname.includes("clone");

  const id = location?.state?.id || location?.state || useParams().id;

  const { data } = useGetMultipleExportByIdQuery(id, { skip: !id });
  const [addSerialLetter, { isLoading: isAddLoading }] = useAddMultipleExportMutation();
  const [updateSerialLetter, { isLoading: isEditLoading }] = useUpdateMultipleExportMutation();
  const isSaving = isEditLoading || isAddLoading;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("caymland.core.fieldrequired")),
    list: yup
      .mixed()
      .test(
        "is-valid-type",
        t("caymland.core.fieldrequired"),
        (value) => typeof value === "object" || typeof value === "number" || typeof value === "string"
      )
      .required(t("caymland.core.fieldrequired")),
    badge: yup
      .mixed()
      .test(
        "is-valid-type",
        t("caymland.core.fieldrequired"),
        (value) => typeof value === "object" || typeof value === "number" || typeof value === "string"
      )
      .required(t("caymland.core.fieldrequired")),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      console.error(errors);
      if (errors) {
        setShow(true);
        setMessage(t("caymland.core.error.not.valid"));
        setBackgroundToast("2");
        setTimeout(() => {
          setMessage("");
          setShow(false);
          setBackgroundToast("1");
        }, 3000);
      }
    }
  }, [errors]);

  const handleSerialLetter = async (formData, close = false) => {
    console.log(formData);
    const formattedData = {
      name: formData.name,
      list: formData.list.value,
      badge: formData.badge.value,
    };

    console.log(formattedData);
    try {
      let response;
      if (id && !ClonePath) {
        response = await updateSerialLetter({ id, ...formattedData }).unwrap();
        console.log(response);
      } else {
        response = await addSerialLetter(formattedData).unwrap();
        console.log(response);
      }
      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed) {
            navigate(close ? "/multiple-exports" : `/multiple-exports/edit/${response.multipleexport.id}`);
          }
        });
      }
    } catch (error) {
      console.error(`Error ${id ? "updating" : "adding"} serial letter:`, error);
    }
  };
  console.log(data);

  useEffect(() => {
    if (data && segmentsData && serialLettersData) {
      reset({
        name: data.multipleexport.name,
        list: { value: data.multipleexport.list.id, label: data.multipleexport.list.name },
        badge: { value: data.multipleexport.badge.id, label: data.multipleexport.badge.name },
      });
    }
  }, [data, reset, segmentsData, serialLettersData]);

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/multiple-exports"),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit((data) => handleSerialLetter(data, true)),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: handleSubmit((data) => handleSerialLetter(data)),
    },
  ];

  return (
    <div>
      <Header
        buttons={buttons}
        background={backgorundToast}
        title={t("caymland.plugin.badge.generator.segments")}
        alertMessage={message}
        showAlert={show}
        loading={isSaving}
      />
      <Wrapper
        childrenClassOne={"wrapper-childOne w-full"}
        childrenOne={
          <MainForm
            segmentsData={segmentsData}
            serialLettersData={serialLettersData}
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
          />
        }
      />
    </div>
  );
};

export default MultipleExportForm;
