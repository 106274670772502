import React, { useMemo, useState, useEffect } from "react";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import { MRT_ToggleSelect } from "../../../../reusableComponents/CustomTable/MRT_ToggleSelect";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import he from "he";

const History = ({ reload, setReload, data, isFetching, refetch, setQuery, query }) => {
  const [activeRowId, setActiveRowId] = useState();
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (reload) {
      refetch(); // Trigger the API refetch
      setReload(false); // Reset the reload state to false
    }
  }, [reload, refetch, setReload]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "eventLabel",
        accessorFn: (row) => {
          const label = row?.eventLabel?.label ? he.decode(row.eventLabel.label) : row.eventType;
          return label.length > 70 ? label.substring(0, 70) + "..." : label;
        },
        Cell: ({ cell }) => {
          const row = cell.row.original;
          const href = row?.eventLabel?.href;
          const hrefRedirect = row?.eventLabel?.href?.includes("email");
          const hitPageRedirect = row?.event === "page.hit";
          const handleClick = () => {
            if (hitPageRedirect || hrefRedirect) {
              window.open(href, "_blank");
            } else if (href) {
              navigate(href);
            }
          };

          return (
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              {cell.getValue()}
            </div>
          );
        },
        header: t("caymland.lead.timeline.event_name"),
        enableColumnActions: false,
      },

      {
        // Update this column definition
        accessorKey: "eventType",
        accessorFn: (row) => row.eventType,
        header: t("caymland.lead.timeline.event_type"),
        enableColumnActions: false,
      },
      {
        accessorKey: "timestamp",
        accessorFn: (row) => {
          // Use Moment.js to format the timestamp
          return moment(row?.timestamp).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.lead.timeline.event_timestamp"),
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );
  let options = [];

  // Check if types is defined before trying to create the options array
  if (data && data?.types) {
    options = Object.entries(data.types).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }

  const handleEventTypeChange = (selectedOptions) => {
    // Assuming selectedOptions is an array of selected event types
    setSelectedEventTypes(selectedOptions);
  };
  const toolbarButtons = useMemo(() => [
    {
      name: "history",
      component: MRT_ToggleSelect,
      props: {
        data: options,
        onChange: handleEventTypeChange,
        value: selectedEventTypes,
        title: t("caymland.lead.lead.tab.history"),
        isMulti: true,
      },
    },
  ]);
  return (
    <div className="h-full">
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        showDetailPanel
        footer
        mediumHeight
        actions={false}
        toolbarButtons={toolbarButtons}
        isFetching={isFetching}
        linkNavigate={null}
        header
        history
        setActiveRowId={setActiveRowId}
      />
    </div>
  );
};

export default History;
