import _ from "lodash";

export const checkConnectionRestrictions = ({event, parent}) => {
    if (!_.has(event, 'connectionRestrictions') || !_.has(event, 'connectionRestrictions.source')) return true;
    const {connectionRestrictions} = event;
    const {type, eventType} = parent;

    return !!connectionRestrictions['source'][eventType]?.some(cr => cr === type || cr === eventType);
}

