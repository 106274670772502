export const ToggleField = ({ label, name, entity, setEntity, required }) => {
  return (
    <div class="switch-holder pb-20">
      <label
        className={`toggle-label ${required ? "required" : ""}`}
        htmlFor={name} 
        style={{ fontSize: "13px" }}
      >
        {label}
      </label>

      <div class="switch-toggle">
        <input
          type="checkbox"
          name={name}
          id={name}
          onChange={(e) =>
            setEntity((prev) => ({
              ...prev,
              properties: {
                ...prev.properties,
                [name]: e.target.checked ? "1" : "0",
              },
            }))
          }
        />
        <label for={name}></label>
      </div>
    </div>
  );
};
