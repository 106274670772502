import { useEffect, useRef } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

const DataTableToHTMLTable = () => {
    const tableRef = useRef();
    const location = useLocation()
    const navigate = useNavigate()

    const data = location?.state

    useEffect(() => {
        if (location?.state) {
            const htmlTable = document.createElement('table');
            htmlTable.border = '1';

            // Add table header
            const thead = document.createElement('thead');
            const headerRow = document.createElement('tr');
            const resultKeys = [];

            Object.keys(data[0]).forEach(key => {
                Object.keys(data[0].results).forEach(resultKey => {
                    resultKeys.push(resultKey);
                });
                if (key === 'results') {
                    // If the key is "results", loop through the keys of the "results" object and create new "th" elements
                    Object.keys(data[0][key]).forEach(resultKey => {
                        const resultTh = document.createElement('th');
                        resultTh.textContent = resultKey;
                        headerRow.appendChild(resultTh);
                        resultKeys.push(resultKey);
                    });
                } else if (!resultKeys.includes(key)) {
                    // If the key is not "results" and doesn't exist in the "results" object, create a new "th" element
                    const th = document.createElement('th');
                    th.textContent = key;
                    headerRow.appendChild(th);
                } else {
                    return
                }
            });
            thead.appendChild(headerRow);
            htmlTable.appendChild(thead);

            // Add table body
            const tbody = document.createElement('tbody');
            data.forEach(row => {
                const tr = document.createElement('tr');
                Object.entries(row).forEach(([key, value]) => {
                    if (key === 'results') {
                        // If the key is "results", loop through the keys and values of the "results" object and add cells for each
                        const results = value;
                        Object.values(results).forEach(resultValue => {
                            const td = document.createElement('td');
                            td.textContent = resultValue.value ?? "";
                            tr.appendChild(td);
                        });
                    } else if (!resultKeys.includes(key)) {
                        // If the key is not "results" and doesn't exist in the "results" object, create a new "td" element
                        const td = document.createElement('td');
                        td.textContent = value;
                        tr.appendChild(td);
                    } else {
                        return
                    }
                });
                tbody.appendChild(tr);
            });
            htmlTable.appendChild(tbody);

            // Add the new HTML table to the DOM
            tableRef.current.innerHTML = '';
            tableRef.current.appendChild(htmlTable);


        } else {
            navigate("/forms")
        }
    })

    return (
        <div className="w-screen h-screen flex flex-col items-center gap-20" style={{ paddingRight: "70px", paddingTop: "100px" }}>
            <div className="flex items-center gap-5px cursor-pointer w-full" onClick={() => navigate(-1)}><HiChevronLeft size={22} />Back</div>
            <div className="w-full" ref={tableRef}></div>
        </div>
    )
}

export default DataTableToHTMLTable