import React, { useEffect, useRef, useState } from "react";
import FocusEditor from "caymland-react-builder";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa";
import Header from "../../../../reusableComponents/Header/Header";
import Wrapper from "../../../../reusableComponents/Wrapper/Wrapper";
import { Success } from "../../../../reusableComponents/Alerts";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { AiOutlineSave } from "react-icons/ai";
import { handleCancelBuilder } from "../../../../reusableComponents/Alerts";
import { useNavigate, useNavigation } from "react-router-dom";

const Builder = ({
  editorData: { projectId, options, customTools },
  isOpen,
  loading,
  setModifiedData,
  modifiedData,
  setIsOpen,
  title,
  apply,
}) => {
  const focusEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);

  const applyAndCloseTemplate = (params) => {
    focusEditorRef.current.editor.exportHtml((data) => {
      console.log(data);
      const { design, html, chunks } = data;

      // Extract CSS from chunks.css and convert it to a string
      let css = typeof chunks.css === "string" ? chunks.css : JSON.stringify(chunks.css);

      // Replace the existing <style> tags with the new CSS
      var regex = /<style\b[^>]*>(.*?)<\/style>/gs;
      const updatedHtml = html.replace(regex, `<style type='text/css'>${css}</style>`);

      // Encode the modified HTML and design JSON
      const encodedHtml = Buffer.from(updatedHtml).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      // Update the state with the modified data
      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Determine if changes should be applied or saved
      setShouldApply(params === "applybuilder");
      if (params === "savebuilder") {
        Success(t("caymland.core.success.template")).then((result) => {
          if (result.isConfirmed) {
            setIsOpen(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (shouldApply) {
      apply();
      setShouldApply(false);
    }
  }, [modifiedData, shouldApply]);

  const onLoad = () => {
    console.log(focusEditorRef);
  };
  const [initialLoad, setInitialLoad] = useState(true);
  const onReady = () => {
    if (modifiedData?.json && initialLoad) {
      try {
        focusEditorRef?.current?.editor.loadDesign(JSON.parse(modifiedData?.json));
        setInitialLoad(false);
      } catch (error) {
        console.error("Error parsing JSON: ", error);
      }
    } else {
      console.error("JSON is empty");
    }
  };
  useEffect(() => {
    onReady();
  }, []);
  const navigate = useNavigate();
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close.builder"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancelBuilder(setIsOpen, navigate),
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => applyAndCloseTemplate("applybuilder"),
      show: true,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => applyAndCloseTemplate("savebuilder"),
      show: false,
    },
  ];

  const locale = useSelector((state) => state?.auth?.user?.locale);
  console.log(projectId);
  return (
    <div className="wrapper-container" style={{ display: isOpen ? "block" : "none" }}>
      <Header buttons={buttons} title={title} loading={loading} />
      <Wrapper
        childrenClassOne={"w-100"}
        childrenOne={
          <FocusEditor
            ref={focusEditorRef}
            displayMode="popup"
            onLoad={onLoad}
            onReady={onReady}
            minHeight="100%"
            projectId={projectId}
            options={{ ...options }}
            customTools={customTools}
            locale={locale === "en_US" ? "en" : locale}
          />
        }
      />
    </div>
  );
};

export default Builder;
