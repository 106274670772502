import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Companies"],
  endpoints: (build) => ({
    getCompanies: build.query({
      query: (q) =>
        `/api/companies?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Companies"],
    }),
    getImportsReq: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        console.log(id);
        if (id) {
          let importsById = await baseQuery({ url: `/api/imports/${id}` });
          let chartsById = await baseQuery({
            url: `/api/imports/${id}/extras`,
          });

          const imports = importsById?.data;
          const charts = chartsById?.data;

          const data = {
            imports,
            charts,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Companies", id }],
    }),

    getCompaniesReq: build.query({
      queryFn: async ({ id, query }, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        if (id) {
          let companyById = await baseQuery({ url: `/api/companies/${id}` });
          let fieldsFilter = await baseQuery({
            url: `/api/fields/company?limit=200'`,
          });
          let companyLeads = await baseQuery({
            url: `/api/companies/${id}/leads?${qs.stringify({
              ...query,
              ...{ start: query.pageIndex * query.pageSize, limit: query.pageSize },
            })}`,
          });

          const company = companyById?.data;
          const fields = fieldsFilter?.data;
          const leads = companyLeads?.data;

          const data = {
            company,
            fields,
            leads,
          };

          return { data: data };
        } else {
          let segmentFilters = await baseQuery({
            url: `/api/segments/filters`,
          });
          const segmentFilter = segmentFilters.data;

          const data = {
            segmentFilter,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Companies", id }],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
    }),
    getFilterOptions: build.query({
      query: (q) => `/api/segments/filters`,
      transformResponse: customTransformResponse,
    }),
    getCompaniesOptions: build.query({
      query: (q) => `/api/companies/select?${q}`,
      keepUnusedDataFor: false,
      providesTags: ["Companies"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getCompanyById: build.query({
      query: (id) => `/api/companies/${id}`,
      providesTags: ["Companies"],
      transformResponse: customTransformResponse,
    }),
    addCompany: build.mutation({
      query: (body) => ({
        url: `api/companies/new`,
        method: "POST",
        body,
      }),
    }),
    importCompany: build.mutation({
      query: (body) => ({
        url: `api/imports/upload`,
        method: "POST",
        body,
      }),
    }),
    importBackgroundCompany: build.mutation({
      query: (body) => ({
        url: `api/imports/new`,
        method: "POST",
        body,
      }),
    }),
    mergeCompany: build.mutation({
      query: ({ mainId, secondId }) => (
        console.log(mainId),
        console.log(secondId),
        {
          url: `api/companies/merge/${mainId}/${secondId}`,
          method: "POST",
        }
      ),
    }),
    updateCompany: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/companies/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Companies"],
    }),
    //Contacts in Companies
    getCompaniesLeads: build.query({
      query: ({ query, id }) =>
        `/api/companies/${id}/leads?${qs.stringify({
          ...query,
          ...{ start: query.pageIndex * query.pageSize, limit: query.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Companies"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    deleteCompanies: build.mutation({
      query: (id) => ({
        url: `/api/companies/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          companiesApi.util.updateQueryData("getCompanies", undefined, (draft) => {
            draft.data = draft.data.filter((companies) => companies.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Companies", id }],
    }),
    getImports: build.query({
      query: (q) =>
        `/api/imports?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Imports"],
    }),
    updateImport: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/imports/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Imports", id }],
    }),
    getExports: build.query({
      query: (q) =>
        `/api/exports?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Exports"],
    }),
  }),
});

export const {
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useGetCompaniesQuery,
  useGetImportsQuery,
  useDeleteCompaniesMutation,
  useUpdateImportMutation,
  useGetExportsQuery,
  useGetCompaniesLeadsQuery,
  useGetCompanyByIdQuery,
  useGetCompaniesReqQuery,
  useMergeCompanyMutation,
  useImportCompanyMutation,
  useImportBackgroundCompanyMutation,
  useGetImportsReqQuery,
  useGetCompaniesOptionsQuery,
} = companiesApi;
