import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SingleSegmentForModalForSegments from "./SingleSegmentForModalForSegments";
import { SlideAlert } from "../Alerts";
import { useAddSegmentsToContactMutation, useRemoveSegmentsToContactMutation } from "../../../redux/api/contactsApi";
import Button from "../../reusableComponents/Buttons/Button";
import { ImCancelCircle } from "react-icons/im";
import { useTranslation } from "react-i18next";

function ModalForSegments({
  handleShow,
  handleClose,
  show,
  showSegments,
  firstName,
  lastName,
  segments,
  id,
  retrieveSegmentsForID,
  updateSegments,
}) {
  const { t } = useTranslation();
  const [contactsOn, setContactsOn] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [addSegment] = useAddSegmentsToContactMutation();
  const [removeSegment] = useRemoveSegmentsToContactMutation();

  useEffect(() => {
    const tempSegmentsToSend = segments.filter((s) => s.contactIncluded === true).map((s) => s.id);

    setContactsOn(tempSegmentsToSend);
  }, [segments]);

  const addContactToSegment = async (segmentsId) => {
    console.log(segmentsId);
    try {
      await addSegment({ segmentsId: segmentsId, contactId: id });

      const segmentIndex = segments.findIndex((segment) => segment.id === segmentsId);
      if (segmentIndex !== -1) {
        updateSegments(segmentIndex, { ...segments[segmentIndex], contactIncluded: true });
      }
    } catch (err) {
      console.log("error", err);
    }
    updateCampaign();
  };
  const removeContactFromSegment = async (segmentsId) => {
    console.log(segmentsId);
    try {
      await removeSegment({ segmentsId: segmentsId, contactId: id });

      const segmentIndex = segments.findIndex((segment) => segment.id === segmentsId);
      if (segmentIndex !== -1) {
        updateSegments(segmentIndex, { ...segments[segmentIndex], contactIncluded: false });
      }
    } catch (err) {
      console.log("error", err);
    }
    updateCampaign();
  };

  const updateCampaign = () => {
    setAlertMessage(`The segment has been updated successfully!`);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: handleClose,
    },
  ];
  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              padding: "4px",
            }}
            className="flex w-100 justify-content-between"
          >
            {t("caymland.lead.lead.header.lists").replace(/%name%/g, `${firstName} ${lastName}`)}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ marginTop: "2em 0", height: "70vh", overflowY: "auto" }}>
          {segments.map((singlesegment) => (
            <SingleSegmentForModalForSegments
              segmentName={singlesegment.name}
              id={singlesegment.id}
              checked={singlesegment?.contactIncluded}
              addContactToSegment={addContactToSegment}
              removeContactFromSegment={removeContactFromSegment}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalForSegments;
