import React, { useRef, useState, useEffect } from "react";
import PagesEditor from "caymland-react-builder";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../../reusableComponents/Header/Header";
import Wrapper from "../../../../reusableComponents/Wrapper/Wrapper";
import { Success, handleCancelBuilder } from "../../../../reusableComponents/Alerts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AiOutlineSave } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Builder = ({
  editorData: { projectId, options, mergeTags, customTools },
  isOpen,
  loading,
  setModifiedData,
  modifiedData,
  setIsOpen,
  currentId,
  apply,
}) => {
  const { t } = useTranslation();
  const pagesEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const navigate = useNavigate();

  const applyAndCloseTemplate = (params) => {
    pagesEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      // Ensure line breaks are properly encoded
      const encodedHtml = Buffer.from(html).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Directly update the editor with the latest design
      pagesEditorRef.current.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setShouldApply(true);
        setIsOpen(false);
        navigate(location.pathname, { replace: true }); // Remove query parameters
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    if (shouldApply) {
      apply();
      setShouldApply(false);
    }
  }, [modifiedData, shouldApply]);

  const onReady = () => {
    if (modifiedData?.customJson) {
      try {
        const design = JSON.parse(modifiedData?.customJson);
        pagesEditorRef?.current?.editor?.loadDesign(design);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("No custom JSON data to load.");
    }
    pagesEditorRef?.current?.setMergeTags(mergeTags);
  };

  const builderButtons = [
    {
      name: "close",
      title: t("caymland.core.close.builder"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancelBuilder(setIsOpen, navigate),
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => applyAndCloseTemplate("apply"),
      show: true,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => applyAndCloseTemplate("savebuilder"),
      show: false,
    },
  ];

  const locale = useSelector((state) => state?.auth?.user?.locale);

  return (
    <div className="wrapper-container" style={{ display: isOpen ? "block" : "none" }}>
      <Header
        buttons={builderButtons}
        title={currentId ? t("caymland.page.header.edit", { name: modifiedData.title }) : t("caymland.page.header.new")}
        loading={loading}
      />
      <Wrapper
        childrenClassOne={"w-100"}
        childrenOne={
          <PagesEditor
            ref={pagesEditorRef}
            displayMode="web"
            onReady={onReady}
            minHeight="100%"
            projectId={projectId}
            options={{ ...options }}
            customTools={customTools}
            locale={locale === "en_US" ? "en" : locale}
          />
        }
      />
    </div>
  );
};

export default Builder;
