import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "caymland-react-builder";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DynamicTabsContainer from "./DynamicTabsContainer";
import { handleCancelBuilder } from "../../../../reusableComponents/Alerts";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Button from "../../../../reusableComponents/Buttons/Button";
import Header from "../../../../reusableComponents/Header/Header";
import Wrapper from "../../../../reusableComponents/Wrapper/Wrapper";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DisplayConditionModal from "../DisplayConditionModal";
import { useGetSegmentsOptionsQuery } from "../../../../../redux/api/segmentsApi";

const Builder = ({
  editorData: { projectId, options, mergeTags, customTools },
  isOpen,
  loading,
  setModifiedData,
  modifiedData,
  setIsOpen,
  title,
  apply,
}) => {
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const emailEditorRef = useRef(null);
  const [shouldApply, setShouldApply] = useState(false);
  const [applyBuilder, setApplyBuilder] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [initalDisplayContent, setInitalDisplayContent] = useState([]);
  const [rightColumnValue, setRightColumnValue] = useState([]);

  const [applyDCond, setApplyDCond] = useState(false);
  const { data: segmentOptions } = useGetSegmentsOptionsQuery("limit=-1");

  const location = useLocation();
  const navigate = useNavigate();
  const isEdit = location.pathname.includes("edit");

  const [mainTabs, setMainTabs] = useState([
    {
      name: `${t("caymland.core.dynamicContent.new", { number: 1 })}`,
      subtabs: [
        {
          name: t("caymland.mailbox.list.action.new"),
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
        {
          name: t("caymland.core.form.default"),
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
        {
          name: `${t("caymland.core.dynamicContent.tab", { number: 1 })} `,
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
      ],
    },
  ]);

  function generateId() {
    return "r-" + Math.random().toFixed(15).substring(2);
  }

  useEffect(() => {
    const dynamicContentMergeTags = transformMainTabsToMergeTags(mainTabs);
    const newMergeTags = {
      ...mergeTags,
      dynamicContent: {
        name: "Dynamic Content",
        mergeTags: dynamicContentMergeTags,
      },
    };

    if (emailEditorRef.current) {
      emailEditorRef.current.setMergeTags(newMergeTags);
    }
  }, [mainTabs, mergeTags]);

  const callbackRef = useRef(null);

  useEffect(() => {
    if (emailEditorRef.current) {
      emailEditorRef.current.registerCallback("displayCondition", (data, done) => {
        setInitalDisplayContent(data.value);
        setOpenModal(true);

        // Store the callback reference for later manual triggering
        callbackRef.current = done;

        if (applyDCond) {
          handleConfirm(data, done);
          setApplyDCond(false);
        }
      });
    }
  }, [mainTabs, mergeTags, applyDCond, emailEditorRef]);

  const handleConfirm = (data, done) => {
    // Get the selected segments
    const selectedSegments = rightColumnValue.map((item) => item.value);

    // Generate a random type string if none exists in the data
    const type = data?.type?.length > 0 ? data.type : Math.random().toString(36).substring(7);

    // Call the done function with the appropriate data
    done({
      type: type,
      label: `${t("caymland.core.segment.filter.builder.label", {
        ids: selectedSegments.join(", "),
      })}`,
      value: selectedSegments,
      description: `${t("caymland.core.segment.filter.builder.desc", { ids: selectedSegments.join(", ") })}`,
      before: `{blockfilter segment="${selectedSegments.join(", ")}"}`,
      after: `{/blockfilter}`,
    });
  };

  useEffect(() => {
    if (applyDCond && callbackRef.current) {
      const data = {
        type: "",
        value: initalDisplayContent, // Preserve the initial display content
      };

      handleConfirm(data, callbackRef.current);
    }
  }, [applyDCond]);

  useEffect(() => {
    // Initialize rightColumnValue with the matching segments
    if (initalDisplayContent && segmentOptions) {
      const initialSelectedSegments = segmentOptions?.filter((segment) =>
        initalDisplayContent.includes(segment.value)
      );
      setRightColumnValue(initialSelectedSegments);
    }
  }, [initalDisplayContent]);

  const transformMainTabsToMergeTags = (mainTabs) => {
    return mainTabs.map((tab) => ({
      name: tab.name,
      value: `{dynamiccontent="${tab.name}"}`,
    }));
  };

  useEffect(() => {
    const newData = modifiedData?.dynamicContent?.map((item, itemIndex) => {
      const defaultContent = item.content ? item.content : "";
      let subtabs = [
        {
          name: t("caymland.mailbox.list.action.new"),
          inputValue: "",
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
        {
          name: t("caymland.core.form.default"),
          inputValue: defaultContent,
          selectValues: {
            combinator: "and",
            rules: [],
          },
        },
      ];

      const variationSubtabs = item.filters.map((filter, filterIndex) => {
        return {
          name: `${t("caymland.core.dynamicContent.tab")} ${filterIndex + 1}`,
          inputValue: filter.content,
          selectValues: {
            combinator: "and",
            rules: filter?.filters?.map((subFilter) => {
              return {
                field: {
                  field: subFilter?.field || null,
                  id: subFilter?.field || null,
                  input: subFilter?.type || null,
                  object: subFilter?.object || null,
                  operators: subFilter?.operator || null,
                  type: subFilter?.type || null,
                },
                id: generateId(),
                operator: subFilter?.operator || null,
                value: String(subFilter?.filter) || null,
                valueSource: "value",
              };
            }),
          },
        };
      });

      subtabs = [...subtabs, ...variationSubtabs];

      return {
        name: item.tokenName,
        subtabs,
      };
    });

    if (newData && newData.length > 0) {
      setMainTabs(newData);
    }
  }, [modifiedData?.id]);

  const updateDynamicContentMergeTags = () => {
    const dynamicContentMergeTags = transformMainTabsToMergeTags(mainTabs);
    const newMergeTags = {
      ...mergeTags,
      dynamicContent: {
        name: "Dynamic Content",
        mergeTags: dynamicContentMergeTags,
      },
    };
    if (emailEditorRef.current) {
      emailEditorRef.current.setMergeTags(newMergeTags);
    }
  };

  useEffect(() => {
    if (shouldApply) {
      apply(applyBuilder);
      setShouldApply(false);
    }
  }, [modifiedData, shouldApply]);

  const onDesignLoad = (data) => {};
  const onLoad = () => {};
  const [initialLoad, setInitialLoad] = useState(true);

  const onReady = () => {
    // Always set merge tags and add the event listener
    emailEditorRef?.current?.editor?.addEventListener("design:loaded", onDesignLoad);
    emailEditorRef?.current?.setMergeTags(mergeTags); // Set initial merge tags
    updateDynamicContentMergeTags(); // Update with dynamic content merge tags

    if (initialLoad && modifiedData.customJson) {
      try {
        emailEditorRef?.current?.editor.loadDesign(JSON.parse(modifiedData.customJson));
        setInitialLoad(false); // Ensure this runs only once

        // Check if there are display conditions to load
        if (modifiedData.dynamicContent?.length > 0) {
          modifiedData.dynamicContent.forEach((item) => {
            if (item.filters?.length > 0) {
              setInitalDisplayContent(item.filters.map(filter => filter.content));
              setApplyDCond(true); // Trigger the application of display conditions
            }
          });
        }

      } catch (error) {
        console.error("Error parsing JSON: ", error);
      }
    }
  };

  const applyAndCloseTemplate = (params) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;

      // Ensure line breaks are properly encoded
      const encodedHtml = Buffer.from(html).toString("base64");
      const encodedJson = Buffer.from(JSON.stringify(design, undefined)).toString("base64");

      setModifiedData((prev) => ({
        ...prev,
        customHtml: encodedHtml,
        customJson: encodedJson,
      }));

      // Directly update the editor with the latest design
      emailEditorRef?.current?.editor.loadDesign(design);

      if (params === "applyBuilder") {
        setApplyBuilder("applyBuilder");
      }

      if (params === "savebuilder") {
        setShouldApply(true);
        setIsOpen(false);
        navigate(location.pathname, { replace: true }); // Remove query parameters
      }
      setShouldApply(true);
    });
  };

  useEffect(() => {
    onReady();
  }, []); // Trigger onReady on component mount

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };

  function transformData(mainTabs) {
    const transformedData = mainTabs?.map((tab) => {
      const defaultSubtab = tab?.subtabs?.find((subtab) => subtab.name === "Default");
      const content = defaultSubtab ? defaultSubtab?.inputValue : "";

      const filters = tab.subtabs
        .map((subtab, index) => {
          if (index < 2) return null;

          const subFilters = subtab?.selectValues?.rules?.map((rule) => {
            return {
              glue: subtab.selectValues.combinator,
              operator: rule.operator,
              filter: rule.value,
              display: null,
              field: rule.field.field,
              type: rule.field.type,
              object: rule.field.object,
            };
          });

          return {
            content: subtab.inputValue,
            filters: subFilters,
          };
        })
        .filter(Boolean);

      return {
        tokenName: tab.name,
        content: content,
        filters: filters,
      };
    });

    setModifiedData((prevState) => ({
      ...prevState,
      dynamicContent: transformedData,
    }));
  }

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close.builder"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: async () => {
        await transformData(mainTabs);
        handleCancelBuilder(setIsOpen, navigate, location);
      },
      show: false,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: async () => {
        await transformData(mainTabs);
        applyAndCloseTemplate("applyBuilder", true);
      },
      show: true,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: async () => {
        await transformData(mainTabs);
        applyAndCloseTemplate("savebuilder");
      },
      show: false,
    },
  ];

  const previewButton = [
    {
      name: "Preview",
      title: t("caymland.core.preview") + " " + "E-Mail",
      icon: <VisibilityIcon size={15} />,
      onClick: () => {
        window.open(`/email/preview/${modifiedData?.id}`, "_blank");
      },
      show: false,
    },
  ];

  const locale = useSelector((state) => state?.auth?.user?.locale);

  return (
    <div style={{ display: isOpen ? "block" : "none" }}>
      <Header buttons={buttons} title={title} loading={loading} />

      <Wrapper
        childrenClassOne={"w-full"}
        childrenOne={
          <>
            <DisplayConditionModal
              segmentOptions={segmentOptions}
              setOpenModal={setOpenModal}
              openModal={openModal}
              rightColumnValue={rightColumnValue}
              setRightColumnValue={setRightColumnValue}
              emailEditorRef={emailEditorRef}
              setApplyDCond={setApplyDCond}
            />
            <Nav className="nav-primary w-full nav-builder " tabs>
              <div className="d-flex">
                <NavItem>
                  <NavLink
                    href="#"
                    className={primarycolorTab === 1 ? "active" : ""}
                    onClick={() => changeDynamicTab(1)}
                    style={{
                      color: "#313131",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("caymland.email.header.new")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={primarycolorTab === 2 ? "active" : ""}
                    onClick={() => changeDynamicTab(2)}
                    style={{
                      color: "#313131",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("caymland.core.dynamicContent")}
                  </NavLink>
                </NavItem>
              </div>
            </Nav>
            <TabContent activeTab={primarycolorTab}>
              <TabPane tabId={1}>
                <div className="py-20 px-10">
                  <EmailEditor
                    ref={emailEditorRef}
                    displayMode="email"
                    onLoad={onLoad}
                    onReady={onReady}
                    minHeight="80vh"
                    projectId={projectId}
                    options={{ ...options }}
                    designMode="edit"
                    customTools={customTools}
                    locale={locale === "en_US" ? "en" : locale}
                  />
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <DynamicTabsContainer mainTabs={mainTabs} setMainTabs={setMainTabs} />
              </TabPane>
              <div style={{ display: "flex", justifyContent: "end", marginRight: "12px" }}>
                {isEdit && <Button buttons={previewButton} />}
              </div>
            </TabContent>
          </>
        }
      />
    </div>
  );
};

export default Builder;
