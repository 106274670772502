import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { BiLinkExternal, BiDownload } from "react-icons/bi";
import DateFromTo from "../../../dashboard/defaultCompo/dateFromTo";
import moment from "moment";
import Swal from "sweetalert2";
import { useRef } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import Details from "./Details";
import { useDeleteAssetsMutation, useGetAssetQuery, useGetAssetsExtrasQuery } from "../../../../redux/api/assetsApi";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import LineChart from "../../../reusableComponents/ECharts/LineChart/LineEChart";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import Description from "../../../reusableComponents/Description/Description";
import { useTranslation } from "react-i18next";

function ViewAsset(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = location?.state?.id || location?.state || useParams().id;
  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [openDetails, setOpenDetails] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
  });

  const detailsRef = useRef(null);

  const [deleteAsset] = useDeleteAssetsMutation();
  const { data, refetch } = useGetAssetsExtrasQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
  });
  const { data: assetData, isFetching, isLoading } = useGetAssetQuery(id, { skip: !id });
  useEffect(() => {
    if (openDetails && detailsRef.current) {
      const height = detailsRef.current.scrollHeight + "px";
      detailsRef.current.style.maxHeight = height;
    } else if (detailsRef.current) {
      detailsRef.current.style.maxHeight = "0px";
    }
  }, [openDetails]);

  const handleDelete = async () => {
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.message.delete", { name: assetData?.asset?.title }),
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteAsset(id);
        if (!response?.error) {
          setShowAlert(true);
          setAlertMessage(t("caymland.core.alert.message.points"));
          setTimeout(() => {
            setShowAlert(false);
            setAlertMessage("");
          }, 7000);
          navigate("/assets");
        }
      }
    });
  };

  const handleDateChange = () => {
    const fromDate = localStorage.getItem("fromDate");
    const toDate = localStorage.getItem("toDate");
    refetch({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/assets"),
    },
    {
      name: "edit",
      title: t("caymland.core.permissions.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/assets/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.mailbox.list.action.deleteMessage"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];
  console.log(assetData);

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: (
        <Details
          setOpenDetails={setOpenDetails}
          openDetails={openDetails}
          asset={assetData?.asset}
          contacts={data?.contacts?.results ? Object.values(data?.contacts?.results) : []}
        />
      ),
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={assetData?.asset} />,
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "ip",
        accessorFn: (row) => `${row.ipAddresses[0].ip}` || `${row.firstname} ${row.lastname}`,
        header: t("caymland.core.name"),
        enableColumnActions: false,
      },
    ],
    []
  );

  // Determine the extension based on remotePath or local extension
  const extension = useMemo(() => {
    if (assetData?.asset?.remotePath && assetData.asset.remotePath.includes(".pdf")) {
      // If remotePath exists and includes '.pdf', set extension to 'pdf'
      return "pdf";
    } else if (assetData?.asset?.storageLocation === "local") {
      // For local storage, use the extension provided by the asset data
      return assetData.asset.extension;
    } else {
      // If remotePath is present but doesn't include '.pdf', infer the extension from the URL
      return assetData?.asset?.remotePath?.split(".").pop() || null;
    }
  }, [assetData?.asset?.remotePath, assetData?.asset?.storageLocation, assetData?.asset?.extension]);

  return (
    <Fragment>
      <>
        {isLoading ? (
          <HeaderSkeleton isView={true} viewMediumButton={true} />
        ) : (
          <Header
            buttons={buttons}
            title={assetData?.asset?.title}
            showIsPublished={true}
            isPublished={assetData?.asset?.isPublished}
            showAlert={showAlert}
            data={assetData?.asset}
            alertMessage={alertMessage}
            setShowAlert={setShowAlert}
          />
        )}

        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton />
            ) : (
              <>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-5px" style={{ width: "30%" }}>
                        <BiDownload size={18} />
                        {t("caymland.asset.graph.line.downloads")}
                      </div>
                      <div className="flex items-center gap-5px p-no-margin" style={{ width: "30%" }}>
                        <p>
                          {t("caymland.core.count")}: {data?.graphStats?.downloads.total}
                        </p>{" "}
                        <p>
                          {t("caymland.asset.unique")}: {data?.graphStats?.downloads.unique}
                        </p>
                      </div>
                      <div className="flex items-center gap-5px">
                        <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} />
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <LineChart
                        labelData={data?.graphStats?.downloads?.timeStats?.labels || []}
                        dataSeries={
                          data?.graphStats?.downloads?.timeStats?.datasets?.map((dataset, index) => ({
                            name: dataset.label,
                            data: dataset.data.map((value, i) => ({
                              value,
                              itemStyle: { color: dataset.backgroundColor },
                            })),
                          })) || []
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <Nav className="nav-primary w-full" tabs>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 1 ? "active" : ""}
                          onClick={() => setprimarycolorTab(1)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.asset.asset.preview")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 2 ? "active" : ""}
                          onClick={() => setprimarycolorTab(2)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.contact.segments.fields.contacts")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={primarycolorTab}>
                      <TabPane tabId={1}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "20px",
                          }}
                        >
                          {extension === "pdf" && assetData.asset.remotePath ? (
                            <>
                              <p
                                style={{
                                  marginTop: "80px",
                                  textAlign: "center",
                                  fontSize: "15px",
                                  color: "#4a4a4a",
                                  padding: "20px",
                                  borderRadius: "5px",
                                  backgroundColor: "#f8f9fa",
                                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                  border: "1px solid #ddd",
                                  width: "80%",
                                  height: "fit-content",
                                }}
                              >
                                Unable to display the PDF file.{" "}
                                <a
                                  href={`${assetData?.asset?.downloadUrl}?stream=1&api=1`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color: "#0056b3",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                    boxShadow: "0 1px 0 rgba(0,0,0,0.2)",
                                    backgroundColor: "#e7f1ff",
                                    borderRadius: "3px",
                                    padding: "5px 10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Download
                                </a>{" "}
                                instead.
                              </p>
                            </>
                          ) : extension === "pdf" && !assetData.asset.remotePath ? (
                            <>
                              <object
                                data={`${assetData?.asset?.downloadUrl}?stream=1&api=1`}
                                type="application/pdf"
                                width="100%"
                                height="500px"
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <p
                                  style={{
                                    marginTop: "80px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    color: "#4a4a4a",
                                    padding: "20px",
                                    borderRadius: "5px",
                                    backgroundColor: "#f8f9fa",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                    border: "1px solid #ddd",
                                    width: "80%",
                                    height: "fit-content",
                                  }}
                                >
                                  Unable to display the PDF file.{" "}
                                  <a
                                    href={`${assetData?.asset?.downloadUrl}?stream=1&api=1`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "#0056b3",
                                      textDecoration: "none",
                                      fontWeight: "bold",
                                      boxShadow: "0 1px 0 rgba(0,0,0,0.2)",
                                      backgroundColor: "#e7f1ff",
                                      borderRadius: "3px",
                                      padding: "5px 10px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Download
                                  </a>{" "}
                                  instead.
                                </p>
                              </object>
                            </>
                          ) : ["png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(extension) &&
                            !assetData.asset.remotePath ? (
                            <img
                              src={`${assetData?.asset?.downloadUrl}?stream=1&api=1`}
                              alt="img"
                              style={{
                                maxWidth: "60%",
                                "max-width": "60% !important",
                                marginTop: "20px",
                              }}
                            />
                          ) : ["png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(extension) &&
                            assetData.asset.remotePath ? (
                            <img
                              src={`${assetData?.asset?.remotePath}`}
                              alt="img"
                              style={{
                                maxWidth: "60%",
                                "max-width": "60% !important",
                                marginTop: "20px",
                              }}
                            />
                          ) : (
                            <i style={{ fontSize: "100px" }} className={`${assetData.asset.iconClass} `}></i>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div className=" grid-col w-full">
                          <CustomTable
                            columns={columns}
                            data={data?.contacts?.results ? Object.values(data?.contacts?.results) : []}
                            query={query}
                            setQuery={setQuery}
                            viewTable={true}
                            showDetailPanel
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwoNoMargin"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton applyPadding />
            ) : (
              <>
                <CustomizedAccordions accordions={accordions} />
                {assetData?.asset && assetData?.asset?.description ? (
                  <CustomizedAccordions accordions={accordionsDesc} isOpen={true} />
                ) : null}
                <div className="px-20 mt-20px">
                  <div
                    style={{
                      paddingBottom: "20px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {data?.previewURL?.title}
                  </div>
                  <div class="input-container ic1 flex">
                    <input
                      id="download-url"
                      style={{
                        borderRadius: "6px 0 0 6px",
                        background: "#f4f4f4",
                      }}
                      className="jump-input cursor-disabled"
                      type="text"
                      disabled
                      value={data?.previewURL?.assetDownloadUrl}
                      placeholder=""
                    />
                    <a
                      rel="noreferrer"
                      href={assetData?.asset?.downloadUrl}
                      target="_blank"
                      className="download-button"
                    >
                      <BiLinkExternal color="#333" />
                    </a>
                  </div>
                  <hr />
                  <div className="card-body activity-scroll">
                    <p style={{ color: "#555", fontWeight: "bold" }}>{t("caymland.core.recent.activity")}</p>
                    <div className="activity">
                      {data?.recentActivity?.map((activity, index) => (
                        <div key={index} className="d-flex">
                          <div
                            className="flex-shrink-0 gradient-round gradient-line-1"
                            style={{
                              marginRight: "15px",
                              background: activity.action === "create" ? "limegreen" : "#e9e7ed",
                            }}
                          >
                            {activity.action === "create" && <FaCheck />}
                          </div>
                          <div className="flex-grow-1">
                            <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                              {activity?.action === "create"
                                ? t("caymland.core.create.by.past.tense")
                                : t("caymland.core.update.by.past.tense")}{" "}
                              <strong>{activity?.userName}</strong>
                            </p>
                            <small style={{ fontSize: "12px" }}>
                              {moment(activity?.dateAdded).format("DD.MM.YY HH:mm")}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )
          }
        />
      </>
    </Fragment>
  );
}

export default ViewAsset;
