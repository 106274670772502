import { t } from "i18next";
import React from "react";

function NoResultsFound() {
  return (
    <center>
      <div
        style={{
          width: "50%",
          border: "1px solid transparent",
          borderRadius: "3px",
          padding: "15px",
          marginTop: "15px",
          backgroundColor: "rgba(245, 166, 35, 0.2)",
          borderColor: "#e19920",
          color: "#ba7e1b",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <h3>{t("caymland.webhook.no.logs")}</h3>
        <p
          style={{
            textAlign: "start",
          }}
        >
          {t("caymland.webhook.no.logs_desc")}
        </p>
      </div>
    </center>
  );
}

export default NoResultsFound;
