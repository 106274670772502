import React, { Fragment, useEffect, useState } from "react";
import { Text, TextArea, Select } from "../../../reusableComponents/Inputs";
import { reportsApi, useGetSourcesQuery } from "../../../../redux/api/reportsApi";
import { t } from "i18next";

function Details({ errors, setErrors, modifiedData, setModifiedData }) {
  const { data: sourcesData } = useGetSourcesQuery();

  const [trigger] = reportsApi.useLazyGetSourcesQuery();

  const retrieveSources = async () => {
    let sources = await trigger();
    return sources.data;
  };

  const findSelectedOption = (sources, value) => {
    if (!Array.isArray(sources)) {
      return null;
    }
    for (const group of sources) {
      for (const option of group.options) {
        if (option.value === value) {
          return option;
        }
      }
    }
    return null;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "name") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }
    if (type === "text")
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  const handleSelectChange = (e) => {
    const value = e?.value ?? null;
    setModifiedData((prev) => ({
      ...prev,
      source: value,
    }));

    if (value) {
      errors["source"] = false;
      delete errors["source"];
      setErrors({ ...errors });
    } else {
      errors["source"] = true;
      setErrors({ ...errors });
    }

    setModifiedData((prevData) => ({
      ...prevData,
      aggregators: [],
      columns: [],
      filters: [],
      groupBy: [],
      tableOrder: [],
      graphs: [],
    }));
  };

  return (
    <Fragment>
      <div className="flex flex-row w-full gap-10 mb-3">
        <div className="w-50" style={{ alignSelf: "flex-end" }}>
          <Text
            name={"name"}
            value={modifiedData?.name}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.core.name")}
            errors={errors.name}
            required={true}
            type="text"
          />
        </div>
        <div className="w-50">
          <Select
            name="sources"
            label={t("caymland.report.report.form.source")}
            required={true}
            errors={errors.source}
            options={retrieveSources}
            value={findSelectedOption(sourcesData, modifiedData?.source)}
            placeholder={`${t("caymland.core.select")}...`}
            tooltip={t("caymland.report.report.form.source.help")}
            onChange={(selectedOption) => handleSelectChange(selectedOption)}
          />
        </div>
      </div>
      <div className="w-100">
        <TextArea
          label={t("caymland.sms.form.internal.description")}
          value={modifiedData?.description ?? ""}
          onChange={(data) => {
            setModifiedData((prev) => ({
              ...prev,
              description: data,
            }));
          }}
        />
      </div>
    </Fragment>
  );
}

export default Details;
