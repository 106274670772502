import React from "react";
import { FaEnvelope } from "react-icons/fa";
import DateFromTo from "../../../../dashboard/defaultCompo/dateFromTo";
import LineChart from "../../../../reusableComponents/ECharts/LineChart/LineEChart";
import { t } from "i18next";

function PageStats({ extras, handleDateChange, loading }) {
  return (
    <>
      <div className="flex items-center justify-between" style={{ marginBottom: "10px" }}>
        <div
          className="flex gap-5px"
          style={{ width: "30%", color: "#8c8c8c", borderBottom: "20px", alignSelf: "start", alignItems: "center" }}
        >
          <FaEnvelope color="#8c8c8c" size={18} />
          {t("caymland.landingpage.page.stats")}
        </div>
        <div className="flex items-center gap-5px">
          <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} loading={loading} />
        </div>
      </div>

      <LineChart
        labelData={extras?.labels || []}
        dataSeries={
          extras?.datasets?.map((dataset, index) => ({
            name: dataset.label,
            data: dataset.data.map((value, i) => ({
              value,
              itemStyle: { color: dataset.backgroundColor },
            })),
          })) || []
        }
      />
    </>
  );
}

export default PageStats;
