export const retrieveCategories = (inputValue, bundle, trigger = null, includeGlobal = true) =>
  new Promise(async (resolve, reject) => {
    if (typeof trigger !== "function" || trigger === null) {
      resolve([]);
      return;
    }

    const val = [];

    if (bundle) {
      val.push({ expr: "eq", col: "bundle", val: bundle });
    }

    if (includeGlobal) {
      val.push({ expr: "eq", col: "bundle", val: "global" });
    }
    const query = {
      where: [
        {
          expr: "orX",
          col: "bundle",
          val: val,
        },
        { expr: "eq", col: "isPublished", val: 1 },
      ],
    };

    if (inputValue.length) {
      query.search = inputValue;
    }

    const response = await trigger(query).unwrap();
    console.log(response);
    resolve(response?.data || response || []);
  });
