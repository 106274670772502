import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";
import moment from "moment";

export const pagesApi = createApi({
  reducerPath: "pagesApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Pages"],
  endpoints: (build) => ({
    getPages: build.query({
      query: customBaseQuery((q) => `/api/pages?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: (id) => [{ type: "Pages", id }],
    }),
    updatePage: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/pages/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Pages"],
    }),
    deletePage: build.mutation({
      query: (id) => ({
        url: `/api/pages/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          pagesApi.util.updateQueryData("getPages", undefined, (draft) => {
            draft.data = draft.data.filter((pages) => pages.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: ["Pages"],
    }),
    addLandingPage: build.mutation({
      query: (body) => ({
        url: `/api/pages/new`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "Pages", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    getPage: build.query({
      query: (id) => `/api/pages/${id}`,
      providesTags: (id) => [{ type: "Pages", id }],
      lazy: true,
    }),
    getLandingPageData: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        let featureThemesData,
          pagesListData,
          groupListData,
          extrasData = null;

        if (id) {
          const extrasResult = await baseQuery({
            url: `api/pages/${id}/extras`,
            signal,
          });
          extrasData = extrasResult.data || null;
        }

        const pagesResponse = await baseQuery({
          url: "api/pages",
          signal,
        });

        let pages = pagesResponse.data?.pages ?? {};
        let groupList = [];
        Object.values(pages).forEach((x) => {
          let index = groupList.findIndex((gl) => gl.label === x.language);
          if (index !== -1 && !x.translationChildren.includes(Number(id)) && x.id !== Number(id)) {
            groupList[index].options.push(x);
          } else {
            if (!x.translationChildren.includes(Number(id)) && x.id !== Number(id)) {
              groupList.push({
                label: x.language,
                options: [x],
              });
            }
          }
        });

        groupListData = groupList;

        const featureThemesResult = await baseQuery({
          url: `api/featurethemes?feature=page`,
        });
        featureThemesData = featureThemesResult.data || null;

        const pagesListResult = await baseQuery({
          url: "api/pages",
          params: {
            "where[0][expr]": "eq",
            "where[0][col]": "isPreferenceCenter",
            "where[0][val]": "1",
          },
          signal,
        });
        pagesListData = pagesListResult.data ?? null;

        const data = {
          featureThemesData,
          pagesListData,
          groupListData,
          extrasData,
        };

        return { data };
      },
      providesTags: ["Pages"],
    }),
    getPageView: build.query({
      query: ({ id, dateFrom, dateTo }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        });
        return `api/pages/${id}/pageViews?${params}`;
      },
      providesTags: ["Pages"],
    }),
    getPagesEditorOption: build.query({
      query: () => ({
        url: `/api/pages/editor/options`,
      }),
      keepUnusedDataFor: 60,
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderPages: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=pages`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderPages: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderPages: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Pages"],
    }),
    //DELETE FOLDER
    deleteFolderPages: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updatePagesToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/pages/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Pages"],
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removePagesToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/pages/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPagesQuery,
  useGetPagesEditorOptionQuery,
  useGetPageQuery,
  useDeletePageMutation,
  useUpdatePageMutation,
  useAddLandingPageMutation,
  useGetLandingPageDataQuery,
  useGetPageViewQuery,
  //FOLDERS
  useGetFolderPagesQuery,
  useUpdateFolderPagesMutation,
  useDeleteFolderPagesMutation,
  useAddFolderPagesMutation,
  useUpdatePagesToFoldersMutation,
  useRemovePagesToFoldersMutation,
} = pagesApi;
