import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewCategoryPopUp from "./../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Error, Success, handleCancel } from "../../reusableComponents/Alerts";
import Details from "./addEditStage/Details";
import Sidebar from "./addEditStage/Sidebar";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import { useAddStageMutation, useGetStageQuery, useUpdateStageMutation } from "../../../redux/api/stagesApi";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import Swal from "sweetalert2";
import TitleHeader from "../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

function AddStage() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const clonePath = location.pathname.includes("clone");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [showPopUpCategory, setShowPopUpCategory] = useState(false);
  const [errors, setErrors] = useState({});

  const [createStage, { isLoading: isAddLoading }] = useAddStageMutation();
  const [updateStage, { isLoading: isEditLoading }] = useUpdateStageMutation(id);
  const isSaving = isEditLoading || isAddLoading;

  const { data, isLoading: mainLoading } = useGetStageQuery(id, { skip: !id });

  const [modifiedData, setModifiedData] = useState({
    isPublished: true,
    name: "",
    category: null,
    weight: null,
    description: null,
    publishUp: null,
    publishDown: null,
    color: "#000000",
    icon: null,
  });

  useEffect(() => {
    if (id && data) {
      let stageData = data?.stage;

      if (clonePath) {
        stageData = { ...stageData, isPublished: false };
      }
      setModifiedData({
        ...stageData,
        category: stageData?.category
          ? {
              label: stageData?.category?.title,
              value: stageData?.category?.id,
            }
          : null,
        color: `#${data?.stage?.color}`,
      });
    }
  }, [id, data]);

  const handleAddEditStage = async (params) => {
    console.log(params);
    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    const hasErrors = Object.values(errors).some((value) => value === true);
    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }

    if (Object.keys(errors).length === 0) {
      console.log("no errors");
      try {
        console.log("try");

        if (modifiedData?.category) modifiedData.category = modifiedData.category.value;

        if (modifiedData?.color) modifiedData.color = modifiedData.color.split("#")[1];

        let response;
        if (id && !clonePath) {
          response = await updateStage(modifiedData);
        } else {
          response = await createStage(modifiedData);
        }

        if (!response?.error) {
          Success(
            `${t("caymland.message.type.successfully", {
              type: id ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
            })}!`
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navigate("/stages");
            } else {
              navigate(`/stages/edit/${response.data.stage.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/stages", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditStage("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditStage(""),
    },
  ];

  return (
    <>
      {mainLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          showAlert={showAlert}
          alertMessage={alertMessage}
          buttons={buttons}
          loading={isSaving}
          // showIsPublished={true}
          data={modifiedData}
          title={
            <TitleHeader
              name={t("caymland.stage.stage")}
              clone={clonePath}
              title={modifiedData?.name}
              loading={mainLoading}
              id={id}
            />
          }
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          mainLoading ? (
            <DetailsSkeleton />
          ) : (
            <Details
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          mainLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              setShowPopUpCategory={setShowPopUpCategory}
            />
          )
        }
      />
    </>
  );
}

export default AddStage;
