import React, { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import md5 from "blueimp-md5";
import Header from "../../reusableComponents/Header/Header";
import { BiAddToQueue } from "react-icons/bi";
import { useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from "../../../redux/api/usersApi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";

function Users() {
  const [localLoading, setLocalLoading] = useState(-1);
  const name = "Users";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const navigate = useNavigate();
  const locale = useSelector((state) => state?.auth?.user?.locale);
  const { data, isFetching, refetch } = useGetUsersQuery(query);
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const { t } = useTranslation();

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { ...data, isPublished: !data.isPublished };
    await updateUser(updatedData);
    await refetch();
    setLocalLoading(-1);
  };

  function getGravatarURL(email, size = 50) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }

  const UserImageComponent = (props) => {
    return (
      <div style={{ width: "50px", height: "50px", transform: "translateY(6px)" }}>
        <img
          className="pro"
          alt=""
          src={getGravatarURL(props.data.email)}
          data-intro="This is Profile image"
          width="100%"
          style={{ borderRadius: "50px" }}
        />
      </div>
    );
  };

  const columns = [
    {
      accessorKey: "email",
      accessorFn: (row) => row.email,
      header: "",
      size: 50,
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => <UserImageComponent data={cell.row.original} />,
    },
    {
      accessorKey: "firstName",
      accessorFn: (row) => row.firstName,
      header: t("caymland.core.name"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="users"
        />
      ),
    },
    {
      accessorKey: "username",
      accessorFn: (row) => row.username,
      header: t("caymland.core.username"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.username,
    },
    {
      accessorKey: "email",
      accessorFn: (row) => row.email,
      header: t("caymland.core.type.email"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.email,
    },
    {
      accessorKey: "role.name",
      accessorFn: (row) => row.role.name,
      header: t("caymland.user.role"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.role.name,
    },
    {
      accessorKey: "onlineStatus",
      accessorFn: (row) => row.onlineStatus,
      header: t("caymland.core.update.heading.status"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.onlineStatus,
    },
    {
      accessorKey: "lastActive",
      accessorFn: (row) => row.lastActive,
      header: t("caymland.lead.lastactive"),

      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => moment(cell.row.original.lastActive).format("DD.MM.YY HH:mm"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "users",
    deleteItem: deleteUser,
    refetch: refetch,
    clone: false,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "users",
    refetch: refetch,
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => navigate("/users/new"),
          },
        ]}
        title={t("caymland.user.users")}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate="edit"
        toolbarActions={{
          search: true,
        }}
        isFetching={isFetching}
      />
    </Fragment>
  );
}

export default Users;
