import React, { useEffect, useState } from "react";
import { BiDownload, BiWorld } from "react-icons/bi";
import { Card, CardBody, CardTitle } from "reactstrap";
import LineChart from "../../../reusableComponents/ECharts/LineChart/LineEChart";
import PieChart from "../../../reusableComponents/ECharts/PieChart/PieChart";
import CustomTable from "../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function Downloads({ extras, dataTable, report, filters, setFilters, isFetching }) {
  const [graph, setGraph] = useState([]);

  useEffect(() => {
    if (extras) {
      const transformedExtras = transformDataForChart(extras);
      setGraph(Object.values(transformedExtras));
    }
  }, [extras]);

  const transformDataForChart = (extras) => {
    const transformedData = {};
    Object.entries(extras)?.map(([key, graphData]) => {
      if (graphData?.type === "pie") {
        const dataSeries = graphData?.data?.data?.labels?.map((label, index) => ({
          name: label,
          value: parseInt(graphData?.data?.data?.datasets[0]?.data[index], 10),
        }));
        transformedData[key] = { ...graphData, data: { ...graphData.data, dataSeries } };
      } else {
        transformedData[key] = graphData;
      }
    });
    return transformedData;
  };

  const renderSection = (graph, index) => {
    let gridColumn = "";
    if (graph?.type === "line") {
      gridColumn = index === 1 ? "span 3" : index === 2 ? "span 2" : "span 4";
    }
    switch (graph?.type) {
      case "line":
        return (
          <div
            className={`widget-shadow`}
            style={{ height: "500px", borderRadius: 0, gridColumn: gridColumn }}
            key={index}
          >
            <Card
              style={{
                padding: "0",
                height: "500px",
              }}
            >
              <CardBody>
                <div className="flex items-center justify-between">
                  <div
                    className="flex items-center gap-5px"
                    style={{ width: "30%", color: "#8c8c8c", borderBottom: "20px" }}
                  >
                    <BiDownload color="#8c8c8c" size={18} />
                    <b>{graph?.data?.name}</b>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <LineChart
                    labelData={graph?.data?.labels || []}
                    dataSeries={
                      extras?.stats?.datasets?.map((dataset, index) => ({
                        name: dataset.label,
                        data: graph?.data?.datasets?.map((value, i) => ({
                          value,
                          itemStyle: { color: dataset.backgroundColor },
                        })),
                      })) || []
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        );
      case "table":
        return (
          <div
            className="widget-shadow"
            key={index}
            style={{ height: "500px", borderRadius: 0, gridColumn: `${"span 1"}` }}
          >
            <Card style={{ padding: "0" }}>
              <CardBody style={{ padding: "0", overflowY: "auto" }}>
                <CardTitle className="m-0">
                  <div className="flex items-center justify-between w-100" style={{ backgroundColor: "white" }}>
                    <div
                      className="flex items-center w-100 justify-content-between"
                      style={{ height: "80px", padding: "0 20px" }}
                    >
                      <b>{graph?.data?.name}</b>
                      <BiDownload color="#8c8c8c" size={18} />
                    </div>
                  </div>
                  {graph?.data?.data.length !== 0 && (
                    <div
                      className="flex items-center justify-between"
                      style={{
                        backgroundColor: "white",
                        padding: "10px 20px",
                        borderTop: "1px solid rgb(221, 221, 221)",
                      }}
                    >
                      <div style={{ width: "70%", fontWeight: "600" }}>{graph?.data?.leftSide}</div>
                      <div style={{ width: "30%", fontWeight: "600" }}>{graph?.data?.rightSide}</div>
                    </div>
                  )}
                </CardTitle>
                {graph?.data?.data.length !== 0 ? (
                  graph?.data?.data.map((e, i) => (
                    <div
                      className="flex items-center text-muted"
                      style={{ borderTop: "1px solid rgb(221, 221, 221)", padding: "15px" }}
                      key={i}
                    >
                      <div style={{ width: "70%" }}>{e?.[graph?.data?.leftSide]}</div>
                      <div className="text-center" style={{ width: "30%" }}>
                        {e?.downloads}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="flex items-center text-muted"
                    style={{ borderTop: "1px solid rgb(221, 221, 221)", padding: "15px" }}
                  >
                    {t("caymland.report.table.noresults")}
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        );
      case "pie":
        return (
          <div
            className="widget-shadow"
            key={index}
            style={{ height: "500px", borderRadius: 0, gridColumn: index === 1 ? "span 1" : "span 2" }}
          >
            <Card style={{ padding: "0" }}>
              <CardBody className="w-full" style={{ padding: "0", overflowY: "auto", margin: "0 auto" }}>
                <CardTitle className="m-0">
                  <div className="flex items-center justify-between w-100">
                    <div
                      className="flex items-center w-100 justify-content-between"
                      style={{ height: "80px", padding: "0 20px" }}
                    >
                      <b>{graph?.data?.name}</b>
                      <BiWorld color="#8c8c8c" size={18} />
                    </div>
                  </div>
                </CardTitle>
                <PieChart height="300px" dataSeries={graph.data.dataSeries} />
              </CardBody>
            </Card>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="d-flex gap-20"
      style={{ flexFlow: report?.settings?.showGraphsAboveTable ? "column" : "column-reverse" }}
    >
      {Object.entries(graph)?.length > 0 && (
        <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }} className="gap-20">
          {graph?.map((graph, index) => renderSection(graph, index))}
        </div>
      )}
      <CustomTable
        linkNavigate={null}
        query={filters}
        data={dataTable}
        setQuery={setFilters}
        viewTable={true}
        dynamicColumns={true}
        isFetching={isFetching}
      />
    </div>
  );
}

export default Downloads;
