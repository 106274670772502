import React from "react";
import Moment from "moment";
import { t } from "i18next";

function Details({ form }) {
  // Check if form is available
  if (!form) return null;

  // Create an array of detail objects
  const details = [
    { label: t("caymland.page.tracking.form.category"), value: form?.category ? form.category.name : t("caymland.core.form.uncategorized") },
    { label: t("caymland.showare.field.created_by"), value: form?.createdByUser },
    { label: t("caymland.core.created"), value: form?.dateAdded ? Moment(form.dateAdded).format("DD.MM.YY hh:mm") : "" },
    { label: t("caymland.core.modifiedby"), value: form?.modifiedByUser },
    { label: t("caymland.core.modified"), value: form?.dateModified ? Moment(form.dateModified).format("DD.MM.YY hh:mm") : "" },
    { label: t("caymland.page.publish.up"), value: form?.publishUp ? Moment(form.publishUp).format("DD.MM.YY hh:mm") : "" },
    { label: t("caymland.page.publish.down"), value: form?.publishDown ? Moment(form.publishDown).format("DD.MM.YY hh:mm") : t("caymland.mailbox.list.action.never") },
    { label: t("caymland.core.id"), value: form?.id },
    { label: t("caymland.form.stats.submission_counts"), value: form?.submissionCount },
    { label: t("caymland.infobip.sms.associated.contacts"), value: form?.contact?.results || 0 },
    // Add other details as needed
  ];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px" style={item.style || {}}>
          <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
        </div>
      ))}
    </div>
  );
}

export default Details;
