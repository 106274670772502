import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { MouseEvent } from "react";

export default function MRT_CustomSelectCheckBox({ row, selectAll, table }) {
  const {
    getState,
    options: {
      localization,
      enableMultiRowSelection,
      muiSelectCheckboxProps,
      muiSelectAllCheckboxProps,
      selectAllMode,
    },
  } = table;

  const { density, isLoading } = getState();
  const checkboxProps = !row
    ? muiSelectAllCheckboxProps instanceof Function
      ? muiSelectAllCheckboxProps({ table })
      : muiSelectAllCheckboxProps
    : muiSelectCheckboxProps instanceof Function
    ? muiSelectCheckboxProps({ row, table })
    : muiSelectCheckboxProps;

  const allRowsSelected = selectAll
    ? selectAllMode === "page"
      ? table.getIsAllPageRowsSelected()
      : table.getIsAllRowsSelected()
    : undefined;

  const commonProps = {
    checked: selectAll ? allRowsSelected : row?.getIsSelected(),
    disabled: isLoading || (row && !row.getCanSelect()),
    inputProps: {
      "aria-label": selectAll ? localization.toggleSelectAll : localization.toggleSelectRow,
    },
    onChange: row
      ? row.getToggleSelectedHandler()
      : selectAllMode === "all"
      ? table.getToggleAllRowsSelectedHandler()
      : table.getToggleAllPageRowsSelectedHandler(),
    size: density === "compact" ? "small" : "medium",
    ...checkboxProps,
    onClick: (e) => {
      e.stopPropagation();
      checkboxProps?.onClick?.(e);
    },
    sx: (theme) => ({
      height: density === "compact" ? "1.75rem" : "2.5rem",
      width: density === "compact" ? "1.75rem" : "2.5rem",
      m: density !== "compact" ? "-0.4rem" : undefined,
      ...(checkboxProps?.sx instanceof Function ? checkboxProps.sx(theme) : checkboxProps?.sx),
    }),
    title: undefined,
  };

  // console.log('getCanMultiSelect', row?.getCanMultiSelect());
  // console.log('getCanSelect', row?.getCanSelect());
  return (
    <>
     
      <Tooltip
        arrow
        enterDelay={1000}
        enterNextDelay={1000}
        title={
          checkboxProps?.title ?? (selectAll ? localization.toggleSelectAll : localization.toggleSelectRow)
        }
      >
        <Checkbox
          indeterminate={
            selectAll ? table.getIsSomeRowsSelected() && !allRowsSelected : row?.getIsSomeSelected()
          }
          {...commonProps}
        />
      </Tooltip>
    </>
  );
}
