import React from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}>
          <span style={styles.bold}>404</span> - {t("caymland.core.pagenotfound")}
        </h1>
        <p style={styles.message}>{t("caymland.core.pagenotfound.message")}</p>
        <Link to="/" style={styles.link}>
          {t("caymland.core.pagenotfound.link")}
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  },
  content: {
    maxWidth: "600px",
  },
  title: {
    fontSize: "3rem",
    marginBottom: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
  message: {
    fontSize: "1.2rem",
    marginBottom: "20px",
  },
  link: {
    fontSize: "1rem",
    color: "#007bff",
    textDecoration: "none",
  },
};

export default NotFoundPage;
