// In case of uninstall
//use
//npm uninstall classnames prop-types react-transition-group @fonticonpicker/react-fonticonpicker --save -f
// Here is the same clone but how to insert to my component no idea
// https://www.jqueryscript.net/other/Simple-FontAwesome-Icon-Picker-Plugin-Bootstrap.html

import React from "react";
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
import { icons as allIcons } from "./allFontAwesomeIcons";
import { t } from "i18next";

function IconPicker({ chosenIcon, setChosenIcon }) {
  const props = {
    icons: allIcons,
    theme: "bluegrey",
    renderUsing: "class",
    value: chosenIcon,
    placeholder: t("caymland.core.select.icon"),
    onChange: (e) => setChosenIcon(e),
    isMulti: false,
    noSelectedPlaceholder: `${t("caymland.core.select")}...`,
    searchPlaceholder: `${t("caymland.core.select")} ${t("caymland.stage.action.icon")}`,
  };

  return (
    <div className="mb-20px">
      <FontIconPicker {...props} />
    </div>
  );
}

export default IconPicker;
