import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRemoveDoNotContactMutation } from "../../../redux/api/contactsApi";
import { Error, Success } from "../../reusableComponents/Alerts";
import { t } from "i18next";

function DoNotContact({ doNotContact, data }) {
  const [pointerStyle, setPointerStyle] = useState("default");
  const [currentDoNotContact, setCurrentDoNotContact] = useState(doNotContact);
  const { id } = useParams();
  useEffect(() => {
    setCurrentDoNotContact(doNotContact);
  }, [doNotContact]);

  const [removeDoNotContact] = useRemoveDoNotContactMutation();

  const removeDoNotContactHandle = async () => {
    const response = await removeDoNotContact({ id });
    if (!response.errors) {
      Success(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
      setCurrentDoNotContact(0);
    } else {
      Error(t("caymland.message.error"));
    }
  };

  return (
    <>
      {data?.contact?.fields?.all?.doi == 1 && !data?.contact?.doNotContact?.length > 0 ? (
        <div className="right-info">
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#04B76B",
              backgroundColor: "white",
              border: "1px solid #04B76B",
              padding: "2px 6px",
              boxShadow: "0 0 5px rgb(0 0 0 / 40%)",
              cursor: "pointer",
            }}
          >
            {t("caymland.lead.timeline.lead.doi.confirm")}
          </span>
        </div>
      ) : data?.contact?.doNotContact?.length > 0 && data?.contact?.doNotContact[0]?.reason === 2 ? (
        <div className="right-info">
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#fdb933",
              backgroundColor: "white",
              border: "1px solid #fdb933",
              padding: "2px 6px",
              boxShadow: "0 0 5px rgb(0 0 0 / 40%)",
              cursor: "pointer",
            }}
          >
            {t("caymland.email.report.bounced")}{" "}
            <span
              className="dncHover"
              onMouseOver={() => setPointerStyle("pointer")}
              onMouseOut={() => setPointerStyle("default")}
              onClick={removeDoNotContactHandle}
              style={{
                fontSize: "1",
                cursor: pointerStyle,
                marginLeft: "3px",
                color: "#fdb933",
              }}
            >
              X
            </span>
          </span>
        </div>
      ) : data?.contact?.doNotContact?.length > 0 && data?.contact?.doNotContact[0]?.reason === 4 ? (
        <div className="right-info">
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#fdb933",
              backgroundColor: "white",
              border: "1px solid #fdb933",
              padding: "2px 6px",
              boxShadow: "0 0 5px rgb(0 0 0 / 40%)",
              cursor: "pointer",
            }}
          >
            {t("caymland.lead.list.filter.dnc_soft")}{" "}
            <span
              className="dncHover"
              onMouseOver={() => setPointerStyle("pointer")}
              onMouseOut={() => setPointerStyle("default")}
              onClick={removeDoNotContactHandle}
              style={{
                fontSize: "1",
                cursor: pointerStyle,
                marginLeft: "3px",
                color: "#fdb933",
              }}
            >
              X
            </span>
          </span>
        </div>
      ) : data?.contact?.doNotContact?.length > 0 ? (
        <div className="right-info">
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#f86b4f",
              backgroundColor: "white",
              border: "1px solid #f86b4f",
              padding: "2px 6px",
              boxShadow: "0 0 5px rgb(0 0 0 / 40%)",
              cursor: "pointer",
            }}
          >
            {t("caymland.lead.do.not.contact")}{" "}
            <span
              className="dncHover"
              onMouseOver={() => setPointerStyle("pointer")}
              onMouseOut={() => setPointerStyle("default")}
              onClick={removeDoNotContactHandle}
              style={{
                fontSize: "1",
                cursor: pointerStyle,
                marginLeft: "3px",
                color: "#f86b4f",
              }}
            >
              X
            </span>
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default DoNotContact;
