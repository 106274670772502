import React from "react";
import Moment from "moment";
import { useTranslation } from "react-i18next";

function Details({ segment, contactsLength }) {
  const { t } = useTranslation();
  const details = [
    { label: t("caymland.showare.field.created_by"), value: segment?.createdByUser },
    {
      label: t("caymland.core.created"),
      value: segment?.dateAdded ? Moment(segment?.dateAdded).format("DD.MM.YY hh:mm") : "Invalid Date",
    },
    { label: t("caymland.core.modifiedby"), value: segment?.modifiedByUser },
    {
      label: t("caymland.core.modified"),
      value: segment?.dateModified ? Moment(segment?.dateModified).format("DD.MM.YY hh:mm") : "Invalid Date",
    },
    { label: t("caymland.core.id"), value: segment?.id },
    { label: t("caymland.contact.segments.fields.contacts"), value: contactsLength },
  ];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px">
          <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
        </div>
      ))}
    </div>
  );
}

export default Details;
