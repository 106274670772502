  import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";

export const batchDeleteApi = createApi({
  reducerPath: "batchDeleteApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["BatchDelete"],
  endpoints: (build) => ({
    batchDelete: build.mutation({
        query: ({ source, params }) => ({
            url: `/api/${source}/batch/delete?${params}`,
            method: "DELETE",
          }),
        invalidatesTags: (result, error, { id }) => [{ type: "BatchDelete", id }],
      }),
  }),
});

export const {
  useBatchDeleteMutation
} = batchDeleteApi;
