import DefaultFields from "./DefaultFields";
import RenderField from "./RenderField";
import _ from "lodash";

export default function Fields({ fields, control, watch, errors, reset, formFields, setId, extraOptions }) {

  return (
    <div style={{ paddingRight: 10, paddingLeft: 10 }}>
      <DefaultFields fields={fields} control={control} watch={watch} />
      <div style={{ paddingTop: 20 }}>
        {fields &&
          Object.values(fields?.formProperties).map((field, index) => (
            <RenderField
              key={field.name + index}
              control={control}
              field={field}
              errors={errors}
              reset={reset}
              setId={setId}
              watch={watch}
              extraOptions={extraOptions}
            />
          ))}
      </div>
    </div>
  );
}
