import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import Button from "../../../../reusableComponents/Buttons/Button";
import Skeleton from "@mui/material/Skeleton";
import { Label } from "../../../../reusableComponents/Inputs";
import { useTranslation } from "react-i18next";

export function CardSkeleton() {
  return (
    <Card
      className="flex flex-col items-center justify-between gap-20"
      style={{ flex: 1, maxWidth: "300px", padding: "30px" }}
    >
      <CardHeader style={{ borderBottom: 0, padding: 0 }}>
        <Skeleton variant="text" width={100} height={20} />
      </CardHeader>
      <CardBody className="w-full p-0">
        <div style={{ overflow: "hidden", maxHeight: "240px" }}>
          <Skeleton variant="rectangular" width="100%" height={240} />
        </div>
      </CardBody>
      <CardFooter style={{ borderBottom: 0, padding: 0 }}>
        <Skeleton variant="rectangular" width={100} height={40} />
      </CardFooter>
    </Card>
  );
}

function ThemeCards({ themes, setModifiedData, modifiedData }) {
  const { t } = useTranslation();
  const [selectedTheme, setSelectedTheme] = useState(-2);

  useEffect(() => {
    if (modifiedData) {
      const selected = themes.find((theme) => theme.name?.toLowerCase() === modifiedData.template?.toLowerCase());
      setSelectedTheme(selected ? selected.id : -2);
    }
  }, [themes, modifiedData?.template]);

  // const handleThemeChange = (theme) => {
  //   setSelectedTheme(theme.id);
  //   setModifiedData((prev) => ({
  //     ...prev,
  //     customJson: theme?.content,
  //     template: theme?.name.toLowerCase()
  //   }));
  // };
  const handleThemeChange = (theme) => {
    const isAlreadySelected = modifiedData.template === theme.name?.toLowerCase();
    setSelectedTheme(isAlreadySelected ? -2 : theme.id);

    if (!isAlreadySelected) {
      setModifiedData((prev) => ({
        ...prev,
        customJson: theme?.content,
        template: theme?.name?.toLowerCase(),
      }));
    }
  };

  return (
    <>
      <div style={{ margin: "10px 0 0 10px" }}>
        <Label name="Theme" label={t("caymland.core.form.theme")} tooltip={t("caymland.page.form.template.help")} />
      </div>
      <div className="flex items-start justify-center gap-30 w-full">
        {themes?.length > 0
          ? themes
              .sort((a, b) => b.id - a.id)
              .map((theme, index) => (
                <Card
                  className="flex flex-col items-center justify-between gap-20 cardShadow"
                  key={index}
                  style={{ flex: 1, maxWidth: "300px", padding: "30px" }}
                >
                  <CardHeader style={{ borderBottom: 0, padding: 0 }}>{theme.name}</CardHeader>
                  <CardBody className="w-full p-0">
                    <div style={{ overflow: "hidden", maxHeight: "240px" }}>
                      {theme.id !== -1 ? (
                        <iframe
                          title="preview"
                          className="frame"
                          frameBorder="0"
                          style={{ pointerEvents: "none", overflow: "hidden" }}
                          src={`//6f7f-77-28-3-33.eu.ngrok.io/m4/email/preview/${theme.id}`}
                        ></iframe>
                      ) : (
                        <img
                          alt="preview"
                          src={require("../../../../../assets/images/BlankPreview.png")}
                          width="100%"
                          height={240}
                        />
                      )}
                    </div>
                  </CardBody>
                  <CardFooter style={{ borderBottom: 0, padding: 0 }}>
                    <Button
                      buttons={[
                        {
                          name: "Select",
                          title:
                            selectedTheme === theme.id
                              ? t("caymland.core.form.filters.selected")
                              : t("caymland.core.select"),
                          onClick: () => handleThemeChange(theme),
                        },
                      ]}
                      textFormat="capitalize"
                    />
                  </CardFooter>
                </Card>
              ))
          : Array.from({ length: 2 }).map((_, index) => <CardSkeleton key={index} />)}
      </div>
    </>
  );
}

export default ThemeCards;
