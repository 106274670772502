import React from "react";
import Button from "../../reusableComponents/Buttons/Button";
import { SlideAlert } from "../Alerts";
import dayjs from "dayjs";
import { t } from "i18next";
import { StatusComponent } from "../../main-menu-sidebar/channels/emails/Emails";

const Header = ({
  buttons,
  title,
  loading,
  extraButtonsComponent,
  extraButtonsRight,
  isPublished,
  alertMessage,
  showAlert,
  showIsPublished,
  childOne,
  childTwo,
  marginDash,
  published,
  showPublished,
  data,
  setShowAlert,
  background,
  shouldShowButton = false,
}) => {
  const publishedUpandDown = data?.publishUp === null && data?.publishDown === null;
  const isPending = dayjs().isBefore(dayjs(data?.publishUp));
  const isExpired = dayjs().isAfter(dayjs(data?.publishDown));
  const isUnpublished = data?.isPublished === false && publishedUpandDown;
  const isUnpublishedCheck = data?.isPublished === false;

  return (
    <div className="header">
      <div className="w-full">
        <SlideAlert message={alertMessage} show={showAlert} setShowAlert={setShowAlert} background={background} />
        <div className="flex flex-wrap items-center justify-between">
          <div className="page-header-left flex items-center gap-10">
            <span style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: 20 }}> {title}</span>
            {data && showIsPublished && (
              <span
                style={{
                  padding: "2px 6px",
                  background: "white",
                  border: isPending
                    ? "1px solid #fdb933"
                    : isExpired
                    ? "1px solid #f86b4f"
                    : isUnpublished || isUnpublishedCheck
                    ? "1px solid #f86b4f"
                    : "1px solid #04B76B",
                  boxShadow: "0 0 10px rgb(0 0 0 / 40%)",
                  color: isPending
                    ? " #fdb933"
                    : isExpired
                    ? "#f86b4f"
                    : isUnpublished || isUnpublishedCheck
                    ? "#f86b4f"
                    : "#04B76B",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                {isPending
                  ? t("caymland.core.form.pending")
                  : isExpired
                  ? t("caymland.core.form.expired")
                  : isUnpublished || isUnpublishedCheck
                  ? t("caymland.core.form.unpublished")
                  : t("caymland.core.form.published")}
              </span>
            )}
            {showPublished && (
              <span
                style={{
                  padding: "2px 6px",
                  background: "white",
                  border: !published ? "1px solid #f86b4f" : "1px solid #04B76B",
                  boxShadow: "0 0 10px rgb(0 0 0 / 40%)",
                  color: !published ? "#f86b4f" : "#04B76B",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                {!published ? t("caymland.core.form.unpublished") : t("caymland.core.form.published")}
              </span>
            )}
            {shouldShowButton && data && <StatusComponent data={data} />}
          </div>

          <div className="d-flex items-center gap-10">
            {childOne}
            {childTwo}
            {extraButtonsComponent}
            <Button buttons={buttons} loading={loading} />
            {extraButtonsRight}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
