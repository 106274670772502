import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import initalTranslation from "../src/assets/i18n/de.json";

const resources = {
  de: {
    translation: initalTranslation,
  },
};

i18next.use(initReactI18next).init({
  returnNull: false,
  debug: false,
  resources,
  fallbackLng: "en",
  lng: "de",
  interpolation: { prefix: "%", suffix: "%" },
});
