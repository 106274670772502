import React, { useState } from "react";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { t } from "i18next";

function Sidebar({ recentActivity }) {
  return (
    <>
      <div
        style={{
          padding: "0px 19px 15px 19px",
          fontSize: "15px",
          fontWeight: "600",
          marginTop: "20px",
        }}
      >
        {t("caymland.core.recent.activity")}
      </div>
      <div className="card-body px-20">
        <div className="activity">
          {recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{
                  marginRight: "15px",
                  background:
                    activity.action === "create" ? "limegreen" : "#e9e7ed",
                }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity.action === "create" ? t("caymland.showare.field.created_by") : t("caymland.core.update.by.past.tense")}{" "}
                  <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>
                  {moment(activity.dateAdded).format("DD.MM.YY HH:mm")}
                </small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
