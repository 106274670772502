import React, { useState } from "react";
import { DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { BiAddToQueue, BiImport, BiHistory } from "react-icons/bi";
import { AiOutlineExport } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Button from "./Button";
import { t } from "i18next";

function AddNew(props) {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const location = useLocation();
  const isCompaniesView = location.pathname.includes("/companies");
  const isContactsView = location.pathname.includes("/contacts");

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <Button
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => navigate(props.link),
          },
        ]}
        loading={props?.loading}
      />
      {props.showMore ? (
        <>
          <DropdownToggle className="no-padding-caret" caret color="white" />
        </>
      ) : (
        <></>
      )}
      {props.showImport ? (
        <>
          <DropdownToggle className="no-padding-caret" caret color="white" />

          <DropdownMenu>
            <DropdownItem className="d-flex items-center gap-5px font-12" onClick={props.handelImport}>
              <BiImport size={16} /> {t("caymland.lead.lead.import")}
            </DropdownItem>
            <DropdownItem className="d-flex items-center gap-5px font-12" onClick={props.ImportHistory}>
              <BiHistory size={16} /> {t("caymland.lead.lead.import.index")}
            </DropdownItem>
          </DropdownMenu>
        </>
      ) : (
        <></>
      )}

      {props.showImportExport ? (
        <>
          <DropdownToggle className="no-padding-caret" caret color="white" />

          <DropdownMenu>
            <DropdownItem className="d-flex items-center gap-5px font-12" onClick={props.handelImport}>
              <BiImport size={16} /> {t("caymland.lead.lead.import")}
            </DropdownItem>
            <DropdownItem className="d-flex items-center gap-5px font-12" onClick={props.ImportHistory}>
              <BiHistory size={16} /> {t("caymland.lead.lead.import.index")}
            </DropdownItem>

            <DropdownItem className="d-flex items-center gap-5px font-12" onClick={props.handelExport}>
              <AiOutlineExport size={16} /> {t("caymland.core.export")}
            </DropdownItem>

            <DropdownItem className="d-flex items-center gap-5px font-12" onClick={props.ExportHistory}>
              <BiHistory size={16} /> {t("caymland.core.export.history")}
            </DropdownItem>
          </DropdownMenu>
        </>
      ) : (
        <></>
      )}
    </ButtonDropdown>
  );
}

export default AddNew;
