import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import DateFromTo from "../../../../dashboard/defaultCompo/dateFromTo"
import LineChart from '../../../../reusableComponents/ECharts/LineChart/LineEChart'
import { BiDownload } from 'react-icons/bi'
import { t } from 'i18next'

function FocusStats({ extras, handleDateChange, loading }) {

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5px" style={{ width: "30%", color: "#8c8c8c" }}>
          <BiDownload color="#8c8c8c" size={18} />
          {t("caymland.focus.graph.stats")}
        </div>
        <div className="flex items-center gap-5px">
          <DateFromTo cls="mid-full-width" saveFromTo={handleDateChange} />
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <LineChart
          labelData={extras?.stats.labels || []}
          dataSeries={
            extras?.stats?.datasets?.map((dataset, index) => ({
              name: dataset.label,
              data: dataset.data.map((value, i) => ({
                value,
                itemStyle: { color: dataset.backgroundColor },
              })),
            })) || []
          }
        />
      </div>
    </>
  );
}

export default FocusStats