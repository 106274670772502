import React, { useEffect } from "react";
import Loading from "../../../reusableComponents/loading";
import Dropzone from "react-dropzone-uploader";
import { Text, TextArea } from "../../../reusableComponents/Inputs";
import { renderContentByTag } from "./renderByTag";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import { useTranslation } from "react-i18next";

const MainContent = ({
  getUploadParams,
  handleChangeStatus,
  errorsMsg,
  uploadImg,
  pdfUploaded,
  modifiedData,
  setModifiedData,
  handleInputChange,
  isFetching,
  errors,
}) => {
  const { t } = useTranslation();
  // const extension = modifiedData?.file.split(".").pop() || null;

  const checkContentType = (url) => {
    return fetch(url, { method: "GET" })
      .then((response) => {
        console.log("response", response);
        if (!response.ok) throw new Error("Network response was not ok");
        return response.headers.get("Content-Type");
      })
      .then((contentType) => {
        if (!contentType) return;
        if (contentType.includes("application/pdf")) return "pdf";
        if (contentType.includes("image/")) return "image";
        return "unknown";
      })
      .catch((error) => {
        console.error("Error:", error.message);
        return "pdf";
      });
  };

  useEffect(() => {
    // Function to determine the extension based on the remotePath or file URL
    const determineExtensionFromRemotePath = () => {
      if (modifiedData?.remotePath?.includes(".pdf")) {
        // If the remotePath includes '.pdf', set extension directly to 'pdf'
        return "pdf";
      } else if (modifiedData?.file) {
        // If there's a file but no clear indication of '.pdf' in the remotePath,
        // check the file extension
        const ext = modifiedData.file.split(".").pop();
        return ext;
      }
      // Return a default or unknown extension if none of the above conditions meet
      return "pdf";
    };

    // Update the extension based on the remotePath or file URL
    const extension = determineExtensionFromRemotePath();
    setModifiedData((prev) => ({
      ...prev,
      extension: extension,
    }));
  }, [modifiedData.remotePath, modifiedData.file, setModifiedData]);

  console.log(modifiedData.downloadUrl);
  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <>
      <div className="flex justify-between">
        {modifiedData.storageLocation === "remote" ? (
          <Text
            wFull="w-full"
            type="url"
            name="file"
            value={modifiedData.file ? modifiedData.file : modifiedData.remotePath}
            required
            onChange={(e) => handleInputChange(e)}
            tooltip="If file is not showing. Please press any key inisde at the end of the url."
            label={t("caymland.asset.asset.form.remotePath")}
          />
        ) : (
          <div className="p-no-margin col-md-6">
            <div className="p-no-margin">
              <p className="font-13">{t("caymland.asset.asset.form.file.upload", { max: "2MB" })}</p>
              <form className="dropzone digits mt-10px" id="singleFileUpload" action="/upload.php">
                <div className="dz-message needsclick h-200-dropzone">
                  <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}
                    inputContent={t("caymland.asset.drop.file.here")}
                    styles={{
                      dropzoneActive: { borderColor: "green" },
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="col-md-6 flex justify-center">
          {errorsMsg ? (
            <p style={{ color: "red", fontSize: "10px" }}>
              {modifiedData.storageLocation === "remote"
                ? t("caymland.asset.asset.error.missing.remote.path")
                : t("caymland.asset.asset.error.missing.local.path")}
            </p>
          ) : modifiedData.extension === "pdf" ||
            modifiedData.extension === "undefined" ||
            modifiedData.extension === null ? (
            modifiedData.extension === "pdf" ? (
              <>
                {modifiedData.storageLocation === "remote" ? (
                  <object
                    data={`${
                      modifiedData.file
                        ? modifiedData.file
                        : Object.keys(modifiedData.downloadUrl).length > 0
                        ? modifiedData?.downloadUrl
                        : ""
                    }?stream=1&api=1`}
                    type="application/pdf"
                    width="80%"
                    height="auto"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <p
                      style={{
                        marginBottom: "0",
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#4a4a4a",
                        padding: "20px",
                        borderRadius: "5px",
                        backgroundColor: "#f8f9fa",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        border: "1px solid #ddd",
                        width: "80%",
                        height: "fit-content",
                      }}
                    >
                      Unable to display the PDF file.{" "}
                      <a
                        href={`${modifiedData.file ? modifiedData.file : modifiedData?.downloadUrl}?stream=1&api=1`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#0056b3",
                          textDecoration: "none",
                          fontWeight: "bold",
                          boxShadow: "0 1px 0 rgba(0,0,0,0.2)",
                          backgroundColor: "#e7f1ff",
                          borderRadius: "3px",
                          padding: "5px 10px",
                          marginLeft: "5px",
                        }}
                      >
                        Download
                      </a>{" "}
                      instead.
                    </p>
                  </object>
                ) : (
                  <object
                    data={
                      pdfUploaded ||
                      (modifiedData?.downloadUrl && Object.keys(modifiedData.downloadUrl).length > 0
                        ? `${modifiedData.downloadUrl}?stream=1&api=1`
                        : "")
                    }
                    type="application/pdf"
                    width="80%"
                    height="auto"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <p
                      style={{
                        marginBottom: "0",
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#4a4a4a",
                        padding: "20px",
                        borderRadius: "5px",
                        backgroundColor: "#f8f9fa",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        border: "1px solid #ddd",
                        width: "80%",
                        height: "fit-content",
                      }}
                    >
                      Unable to display the PDF file.{" "}
                      <a
                        href={pdfUploaded || `${modifiedData?.downloadUrl}?stream=1&api=1`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#0056b3",
                          textDecoration: "none",
                          fontWeight: "bold",
                          boxShadow: "0 1px 0 rgba(0,0,0,0.2)",
                          backgroundColor: "#e7f1ff",
                          borderRadius: "3px",
                          padding: "5px 10px",
                          marginLeft: "5px",
                        }}
                      >
                        Download
                      </a>{" "}
                      instead.
                    </p>
                  </object>
                )}
              </>
            ) : (
              <>
                <object
                  data={`${modifiedData.file ? modifiedData.file : modifiedData?.downloadUrl}?stream=1&api=1`}
                  type="application/pdf"
                  width="80%"
                  height="auto"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <p
                    style={{
                      marginBottom: "0",
                      textAlign: "center",
                      fontSize: "15px",
                      color: "#4a4a4a",
                      padding: "20px",
                      borderRadius: "5px",
                      backgroundColor: "#f8f9fa",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      border: "1px solid #ddd",
                      width: "80%",
                      height: "fit-content",
                    }}
                  >
                    Unable to display the PDF file.{" "}
                    <a
                      href={`${modifiedData.file ? modifiedData.file : modifiedData?.downloadUrl}?stream=1&api=1`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#0056b3",
                        textDecoration: "none",
                        fontWeight: "bold",
                        boxShadow: "0 1px 0 rgba(0,0,0,0.2)",
                        backgroundColor: "#e7f1ff",
                        borderRadius: "3px",
                        padding: "5px 10px",
                        marginLeft: "5px",
                      }}
                    >
                      Download
                    </a>{" "}
                    instead.
                  </p>
                </object>
              </>
            )
          ) : ["png", "jpg", "jpeg", "gif", "bmp", "svg", "image"].includes(modifiedData?.extension) ? (
            modifiedData.storageLocation === "remote" ? (
              <>
                {modifiedData.file ? (
                  <>
                    <img
                      src={`${modifiedData?.file}`}
                      alt="img"
                      style={{
                        maxWidth: "60%",
                        "max-width": "60% !important",
                      }}
                    />
                  </>
                ) : (
                  <img
                    src={`${modifiedData?.downloadUrl}?stream=1&api=1`}
                    alt="img"
                    style={{
                      maxWidth: "60%",
                      "max-width": "60% !important",
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {uploadImg ? (
                  <img
                    src={uploadImg}
                    alt="Uploaded"
                    style={{
                      maxWidth: "60%",
                      "max-width": "60% !important",
                    }}
                  />
                ) : (
                  <img
                    src={`${modifiedData?.downloadUrl}?stream=1&api=1`}
                    alt="img"
                    style={{
                      maxWidth: "60%",
                      "max-width": "60% !important",
                    }}
                  />
                )}
              </>
            )
          ) : (
            <i style={{ fontSize: "100px" }} className={`${modifiedData?.iconClass} `}></i>
          )}
        </div>
      </div>
      <div className="p-no-margin mt-20px">
        <div className="flex gap-10 w-full">
          <Text
            name={"title"}
            errors={errors}
            value={modifiedData?.title}
            onChange={(e) => handleInputChange(e)}
            label={t("caymland.core.title")}
            required={true}
            type="text"
            wFull="w-50"
          />
          <Text
            name={"alias"}
            value={modifiedData?.alias}
            onChange={(e) => handleInputChange(e)}
            label={"Alias"}
            type="text"
            required={true}
            tooltip={t("caymland.asset.asset.help.alias")}
            wFull="w-50"
          />
        </div>
        <TextArea
          value={modifiedData?.description ?? ""}
          onChange={(data) => {
            setModifiedData((prev) => ({
              ...prev,
              description: data,
            }));
          }}
        />
      </div>
    </>
  );
};

export default MainContent;
