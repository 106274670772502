import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

function SingleSegmentUsage({ showCampaignsWithIDS, usage }) {
  return (
    <div className="details-container-accordion">
      <div
        className="details-item"
        style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
        onClick={() => showCampaignsWithIDS(usage.label, usage.ids, usage.route)}
      >
        <span className="details-label mr-auto" style={{ fontWeight: "normal" }}>
          {" "}
          {usage.label}
        </span>
        <Stack direction="row" spacing={5}>
          <Chip
            label={usage.ids.length}
            color="primary"
            variant="outlined"
            size="small"
            style={{
              padding: "2px 5px",
            }}
          />
        </Stack>
      </div>
    </div>
  );
}

export default SingleSegmentUsage;
