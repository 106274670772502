import React, { useState, useEffect, useRef } from "react";
import { Select } from "../../../reusableComponents/Inputs";
import { FaCheck, FaRegTimesCircle, FaTimes } from "react-icons/fa";
import { stagesApi } from "../../../../redux/api";
import { retrieveStages } from "../../../reusableComponents/retrieveStages/retrieveStages";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";
import { Success } from "../../../reusableComponents/Alerts";
import { Controller } from "react-hook-form";
import { AiOutlineSave } from "react-icons/ai";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Stage = ({ data, control, setMessage, setShow, setBackgroundToast }) => {
  const { t } = useTranslation();
  const [stage, setStage] = useState(data?.contact?.stage);
  const [edit, setEdit] = useState(false);
  const [hover, setHover] = useState(false);
  const editRef = useRef(null);

  useEffect(() => {
    setStage(data?.contact?.stage);
  }, [data?.contact?.stage]);

  const [triggerStage] = stagesApi.useLazyGetStagesQuery();
  const [updateContact] = useUpdateContactsMutation();

  const handleStageChange = (selectedOption) => {
    setStage(selectedOption?.value);
  };

  const updateStage = async () => {
    try {
      let updatedStage = stage;
      if (!updatedStage) {
        updatedStage = null;
      }

      const response = await updateContact({ id: data.contact.id, stage: updatedStage });
      if (!response.error) {
        setStage(response.data.contact.stage);
        setEdit(false);
        setShow(true);
        setMessage(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.error.message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      console.error("Error updating stage:", error);
      // Optionally handle reversion of optimistic UI update here
    }
  };

  const isWhite = (str) => {
    const whiteLimit = 200;
    let r = parseInt("0x" + str?.substring(1, 3));
    let g = parseInt("0x" + str?.substring(3, 5));
    let b = parseInt("0x" + str?.substring(5, 7));
    if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
      return false;
    }
    return true;
  };
  const getBorderColor = (hexColor) => {
    return isWhite(hexColor) ? "black" : `#${hexColor}`; // Example: Light grey border for white colors
  };

  return (
    <>
      <div
        ref={editRef}
        style={{ minWidth: "200px" }}
        className={` right-info cursor-pointer w-full`}
        onClick={() => setEdit(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div style={{ padding: "0", display: "flex", gap: "5px" }}>
          <span style={{ padding: "0px", marginBottom: "1rem" }}>{t("caymland.lead.stage.label")}</span>
          {hover && !edit && <Edit fontSize="small" style={{ fontSize: "16px" }} />}
        </div>

        {edit ? (
          <div className="flex items-center gap-10 " style={{ minWidth: "200px" }}>
            <div className="w-full">
              <Controller
                name="stage"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <Select
                    id={name}
                    isSearchable
                    isClearable
                    options={retrieveStages}
                    trigger={triggerStage}
                    value={
                      stage?.id
                        ? {
                            label: stage.name,
                            value: stage.id,
                          }
                        : stage
                    }
                    onChange={handleStageChange}
                  />
                )}
              />
            </div>

            <group className="d-flex gap-5px mb-20px">
              {" "}
              <button
                className="normal-btn"
                onClick={(e) => {
                  e.stopPropagation(), setEdit(false), setStage(data.contact.stage);
                }}
              >
                <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
              </button>
              <button className="normal-btn" onClick={updateStage}>
                <AiOutlineSave color="#135F95" size={20} title="Save" />
              </button>
            </group>
          </div>
        ) : (
          <>
            <p
              className="badge badge-dark tag-pills-sm-mb"
              style={{
                fontSize: "0.75",
                fontWeight: "400",
                border: `1px solid ${getBorderColor(stage?.color)}`,
                color: isWhite(stage?.color) ? "black" : `#${stage?.color}`,
                background: "white",
              }}
            >
              {stage?.name}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default Stage;
