import React from "react";
import Moment from "moment";
import { t } from "i18next";

function Details({ openDetails, setOpenDetails, asset, contacts }) {
  return (
    asset && (
      <div className="details-container-accordion">
        {[
          { label: t("caymland.page.tracking.form.category"), value: asset?.category?.title },
          { label: t("caymland.showare.field.created_by"), value: asset?.createdByUser },
          { label: t("caymland.core.created"), value: Moment(asset?.dateAdded).format("DD.MM.YY hh:mm") },
          { label: t("caymland.core.modifiedby"), value: asset?.modifiedByUser },
          { label: t("caymland.core.modified"), value: Moment(asset?.dateModified).format("DD.MM.YY hh:mm") },
          { label: t("caymland.core.id"), value: asset?.id },
          { label: t("caymland.contact.segments.fields.contacts"), value: contacts?.length },
        ].map((item, index) => (
          <div key={index} className="details-item gap-5px">
            <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
          </div>
        ))}
      </div>
    )
  );
}

export default Details;
