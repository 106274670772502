import React, { useEffect, useState } from "react";
import QueryFiltersReusible from "../../../../reusableComponents/ReusableQueryFilters/QueryFiltersReusible";
import { dwcApi, useGetDynamicContentQuery } from "../../../../../redux/api/dwcApi";

const Filters = ({ query, setQuery, filters }) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const { data } = useGetDynamicContentQuery();
  const [trigger] = dwcApi.useLazyGetDynamicContentQuery();

  useEffect(() => {
    const fetchFilterOptions = async () => {
      setLoading(true);
      let dwc = await trigger();
      if (!data?.error) {
        const filters = data || dwc?.data;
        const generateFilterObject = (filterName, filterData) => {
          return {
            label: filterName,
            options: Object.entries(filterData).map(([key, value]) => ({
              name: key,
              label: value.label,
              object: value.object,
              operators: Object.entries(value?.operators).map(([key2, value2]) => ({
                name: key2,
                label: value2,
              })),
              ...value.properties,
              group: value.object,
            })),
          };
        };

        const tempFields = Object.entries(filters)?.map(([key, value]) => generateFilterObject(key, value));

        setFields([...tempFields]);
        setLoading(false);
      } else {
        console.error(`Unexpected response status: ${data?.status}`);
        setLoading(false);
      }
    };
    fetchFilterOptions();
  }, [data, trigger, filters]);

  function generateRules(filters, fieldOptions) {
    let rules = filters?.map((filter) => {
      let foundOption = null;

      for (let i = 0; i < fieldOptions?.length; i++) {
        let category = fieldOptions[i];
        for (let j = 0; j < category?.options?.length; j++) {
          let option = category?.options[j];
          if (option.name === filter.field) {
            foundOption = option;
            break;
          }
        }

        if (foundOption) {
          break;
        }
      }

      if (foundOption) {
        return {
          field: {
            field: foundOption?.name,
            id: foundOption?.name,
            input: foundOption?.type,
            object: foundOption?.object,
            operators: foundOption?.operators,
            type: foundOption?.type,
          },
          id: `r-${Math.random()}`,
          operator: filter?.operator,
          value: filter?.filter,
          valueSource: "value",
        };
      }

      return null;
    });

    rules = rules?.filter((rule) => rule !== null);

    if (rules?.length > 0) {
      return {
        combinator: filters[filters?.length - 1]?.glue,
        id: `g-${Math.random()}`,
        not: false,
        rules: rules,
      };
    }

    return null;
  }

  useEffect(() => {
    if (filters?.length > 0) {
      let generatedRules = generateRules(filters, fields);
      setQuery(generatedRules);
    }
  }, [fields, filters]);

  return (
    <div className="mt-20">
      <QueryFiltersReusible query={query} setQuery={setQuery} loading={loading} fields={fields} groupButton={false} />
    </div>
  );
};

export default Filters;
