import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DatePicker, Select, Text, TextArea } from "../../../../reusableComponents/Inputs";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";

import Button from "../../../../reusableComponents/Buttons/Button";
import { useNoteByIdQuery } from "../../../../../redux/api/contactsApi";
import { t } from "i18next";

const CustomModal = ({ showModal, setShowModal, addNote, id, refetch, add, updateNote, setNoteTypes }) => {
  const [formData, setFormData] = useState({
    text: "",
    type: "general", // Default type
    dateTime: new Date(),
    lead: id,
  });
  const { data: noteById } = useNoteByIdQuery({ id: showModal.id }, { skip: !showModal.id });

  console.log(formData);
  console.log(showModal);
  useEffect(() => {
    if (noteById) {
      setFormData(noteById.note);
    }
  }, [noteById]);

  const handleTextChange = (e) => {
    setFormData({ ...formData, text: e.target.value });
  };

  const handleTypeChange = (e) => {
    setFormData({ ...formData, type: e.value });
  };

  const handleDateChange = (e) => {
    setFormData({ ...formData, dateTime: e.value ?? null });
  };

  const options = [
    { label: t("caymland.lead.note.type.general"), value: "general" },
    { label: t("caymland.lead.note.type.call"), value: "call" },
    { label: t("caymland.lead.note.type.email"), value: "email" },
    { label: t("caymland.lead.note.type.meeting"), value: "meeting" },
  ];

  const handleClose = () => {
    setShowModal({ isOpen: false });
    setFormData({
      text: "",
      type: "general",
      dateTime: new Date(),
      lead: id,
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: handleClose,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <TbDeviceFloppy size={15} />,
      onClick: () => {
        let updatedFormData = { ...formData };
        if (showModal.type === "edit") {
          delete updatedFormData.lead;
          updateNote({
            id: showModal.id,
            ...updatedFormData,
          });
          setTimeout(() => {
            refetch();
          }, 1000);
          setNoteTypes([]);
        } else {
          addNote({
            ...formData,
          });
          setTimeout(() => {
            refetch();
          }, 1000);

          setNoteTypes([]);
        }
        setFormData({
          text: "",
          type: "general",
          dateTime: new Date(),
          lead: id,
        });

        setShowModal({ isOpen: false });
      },
    },
  ];

  return (
    <>
      <Modal show={showModal.isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showModal.type === "edit" ? t("caymland.lead.note.header.edit") : t("caymland.lead.add.note")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <TextArea
              name="text"
              value={formData?.text ?? ""}
              onChange={(data) => {
                setFormData((prev) => ({
                  ...prev,
                  text: data,
                }));
              }}
              placeholder={t("caymland.core.placeholder.entertext")}
            />
            <div className="d-flex items-center justify-between gap-10">
              <Select name="type" value={formData?.type} onChange={handleTypeChange} options={options}></Select>
              <DatePicker
                name="dateTime"
                value={formData?.dateTime ? new Date(formData?.dateTime) : null}
                onChange={(e) => setFormData({ ...formData, dateTime: e })}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
      <Tooltip arrow title={t("caymland.lead.add.note")} onClick={() => setShowModal({ isOpen: true })}>
        <IconButton>
          <Add />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomModal;
