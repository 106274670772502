import React, { Fragment, useEffect, useMemo, useState } from "react";
import Header from "../../reusableComponents/Header/Header";
import DragAndDropTable from "../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import CampaignForm from "../../reusableComponents/FolderForms/CampaignForm";
import {
  useAddFolderMultipleExportMutation,
  useDeleteMultipleExportMutation,
  useDownloadFileMutation,
  useGenerateExportsMutation,
  useGenerateExportsQuery,
  useGetFolderMultipleExportQuery,
  useGetMultipleExportQuery,
  useUpdateMultipleExportMutation,
  useUpdateMultipleExportToFoldersMutation,
} from "../../../redux/api/multipleExportApi";

const MultipleExport = () => {
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const name = "MultipleExport";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const navigate = useNavigate();

  const { data: multipleData, isFetching, refetch: refetchTableData } = useGetMultipleExportQuery(query);
  const { data: folderData, refetch } = useGetFolderMultipleExportQuery();
  const [downloadFile] = useDownloadFileMutation();
  const [downloadId, setDownloadId] = useState(null);
  const [generateExport, { isLoading: isGenerating }] = useGenerateExportsMutation();

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "multipleexport",
    });
  }, [editingFolder]);

  useEffect(() => {
    if (downloadId) {
      downloadFile(downloadId)
        .then((response) => {
          if (response.data) {
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "export.zip"); // or the desired file name
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloadId(null);
            refetchTableData();
          } else {
            console.error("Download failed: No data received.");
          }
        })
        .catch((error) => {
          console.error("Download failed:", error);
        });
    }
  }, [downloadId, downloadFile, refetchTableData]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "multipleexport",
  };

  const clientSchema = yup.object({
    name: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const body = [<CampaignForm control={control} />];

  const handleGenerateExport = async ({ exportId }) => {
    if (isGenerating) return; // Prevent multiple simultaneous requests
    try {
      const response = await generateExport(exportId);
      if (!response.error) {
        refetchTableData();
      } else {
        console.error("Error generating export:", response.error);
      }
    } catch (error) {
      console.error("Error generating export:", error);
    }
  };

  const handleDownloadExport = ({ exportId }) => {
    setDownloadId(exportId);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) => row.name,
        size: 40,
        header: t("caymland.user.account.permissions.editname"),
        enableColumnActions: false,
      },
      {
        accessorKey: "segment",
        maxSize: 50,
        accessorFn: (row) => row.list.name,
        header: t("caymland.badge.plugin.list"),
        enableColumnActions: false,
      },
      {
        accessorKey: "badge",
        maxSize: 50,
        accessorFn: (row) => row.badge.name,
        header: t("caymland.badge.plugin.badge"),
        enableColumnActions: false,
      },
      {
        accessorKey: "downloadPackage",
        header: t("caymland.badge.plugin.export.download"),
        Cell: ({ row }) => {
          const { status } = row.original;

          switch (status) {
            case 0:
              return (
                <button
                  className="result-component button1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGenerateExport({
                      exportId: row.original.id,
                    });
                  }}
                >
                  {t("caymland.badge.plugin.export.generate")}
                </button>
              );
            case 1:
              return <button className="result-component">{t("caymland.badge.plugin.export.processing")}</button>;
            case 2:
              return (
                <div className="d-flex" style={{ gap: "5px" }}>
                  <button
                    className="result-component"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleGenerateExport({
                        exportId: row.original.id,
                      });
                    }}
                  >
                    {t("caymland.badge.plugin.export.regenerate")}
                  </button>
                  <button
                    className="result-component"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownloadExport({ exportId: row.original.id });
                    }}
                  >
                    {t("caymland.mailbox.list.action.download")}
                  </button>
                </div>
              );
            default:
              return null;
          }
        },
        enableColumnActions: false,
      },
    ],
    [generateExport, isGenerating, t]
  );

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/multiple-exports/new"),
    },
  ];

  const [deleteSerialLetter] = useDeleteMultipleExportMutation();
  const [addFolder] = useAddFolderMultipleExportMutation();
  const [updateFolder] = useUpdateMultipleExportMutation();
  const [deleteFolder] = useDeleteMultipleExportMutation();
  const [updateLetterToFolder] = useUpdateMultipleExportToFoldersMutation();

  const actionButtons = ActionButtons({
    link: "multiple-exports",
    refetch: refetchTableData,
    deleteItem: deleteSerialLetter,
    folderId: activeRowId,
  });
  const actionBatchButtons = ActionBatchButtons({
    link: "serialletters/exports",
    refetch: refetchTableData,
  });

  return (
    <Fragment>
      <Header buttons={buttons} title={t("caymland.plugin.badge.generator.segments")} />
      <DragAndDropTable
        data={multipleData}
        folderData={folderData}
        folderColumns={folderColumns}
        columns={columns}
        isFetching={isFetching}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetchTableData={refetchTableData}
        actionBatchButtons={actionBatchButtons}
        addFolder={addFolder}
        body={body}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        refetch={refetch}
        query={query}
        reset={reset}
        setQuery={setQuery}
        handleSubmit={handleSubmit}
        linkNavigate="edit"
        actionButtons={actionButtons}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        updateCampaignsToFolder={updateLetterToFolder}
      />
    </Fragment>
  );
};

export default MultipleExport;
