import React, { useState, useEffect, useRef } from "react";
import { useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { IoTrashBinOutline } from "react-icons/io5";
import { DragHandle } from "../../widgetGenerator/index";
import { Label } from "../../../../reusableComponents/Inputs";
import { IconButton } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import PieChart from "../../../../reusableComponents/ECharts/PieChart/PieChart";
import EditDeleteMenu from "../../EditDeleteMenu";

function DoughnutChart(props) {
  console.log("props", props)
  const transformedDataSeries = useMemo(() => {
    return props?.chartData?.labels.map((label, index) => ({
      name: label,
      value: props?.chartData?.datasets[0]?.data[index],
    }));
  }, [props.chartData.labels, props.chartData.datasets[0].data]);
  return (
    <>
      <div className="w-100">
        <div className="chart-header">
          <DragHandle />
          <div style={{ marginTop: "8px", marginLeft: "10px" }} className="title-chart">
            <Label name={props.name} label={props.name} />
          </div>
          <EditDeleteMenu
            handleEdit={() => props.handleEdit(props.id)}
            handleDelete={() => props.handleDelete(props.id)}
          />
        </div>
        {props.chartData && (
          <>
            <PieChart dataSeries={transformedDataSeries} height={props.height} />
          </>
        )}
      </div>
    </>
  );
}

export default DoughnutChart;
