const Inserted = (props) => {
  return (
    <div>
      <span
        style={{
          display: "flex",
          color: "black",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "2.5em",
        }}
      >
        {props?.insertedCount}
      </span>
    </div>
  );
};
export default Inserted;
