import {memo, useState} from "react";
import {Handle, Position} from "reactflow";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {IconButton} from '@mui/material';
import {Styles} from "../utils";
import NodeToolbar from "./NodeToolbar";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { t } from "i18next";

const Condition = ({data, actions, setParams, handleEdit, handleAdd, isConnectable, hasJumpNodeActive, jumpNode}) => {
    const [isVisible, setVisible] = useState(false);
    const id = `${data?.params?.id}`
    const isActive = hasJumpNodeActive && !(id === jumpNode.first || id === jumpNode.second);

    return (
        <div className="eventSelect"
             onDoubleClick={() => !isActive && handleEdit(setParams, 'condition', 'edit', 2, actions, data)}
             onMouseEnter={() => !isActive && setVisible(true)}
             onMouseLeave={() => !isActive && setVisible(false)}
             style={{ opacity: isActive ? 0.5 : 1 }}
            >
            <NodeToolbar
                isVisible={isVisible}
                handleEdit={() => handleEdit(setParams, 'condition', 'edit', 2, actions, data)}
                handleDelete={() => actions.deleteNode(data.params)}
            />
            <div
                className="text-updater-node d-flex flex-column align-items-center sourceSelectBuilder"
                style={{border: "1px solid #F5A623"}}
            >
                <Handle
                    type='target'
                    position={Position.Top}
                    style={Styles.handleStyleCenter}
                    className="handleToggle"
                    isConnectable={isConnectable}
                />
                <div className="d-flex w-full justify-between gap-10" style={{height: '38px'}}>
                    <div className="place-self-center">
                    <FilterAltIcon />
                    </div>
                    <div className="place-self-center" style={{maxHeight: '50px', width: '105px', overflow: 'hidden'}}>
                    <p className="mb-0 text-ellipsis" style={{fontSize: "11px", lineHeight: '1.2'}}>
                    {data?.params?.name || t("caymland.report.report.label.filtercondition")}
                    </p>
                    </div>
                </div>
                <Handle
                    type='source'
                    id='yes'
                    position={Position.Bottom}
                    style={Styles.handleStyleLeft}
                    className="handleToggle"
                    isConnectable={isConnectable}
                    onClick={() => !isActive && handleAdd(setParams, 'condition', 'add', 0, 'yes', actions, data)}
                />
                <Handle
                    type='source'
                    id='no'
                    position={Position.Bottom}
                    style={Styles.handleStyleRight}
                    className="handleToggle"
                    isConnectable={isConnectable}
                    onClick={() => !isActive && handleAdd(setParams, 'condition', 'add', 0, 'no', actions, data)}
                />
            </div>
        </div>
    )
}

export default memo(Condition);
