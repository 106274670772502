
export const Nodes = ({positionY, positionX, id}) => {

   const nodes= {
        positionY,
        positionX,
        id
    }

    return nodes;
}

