import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";
import moment from "moment";

export const dwcApi = createApi({
  reducerPath: "dwcApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Dwc"],
  endpoints: (build) => ({
    getdwc: build.query({
      query: customBaseQuery((q) => `/api/dynamiccontents?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Dwc"],
    }),
    getDwcContent: build.query({
      query: (id) => `/api/dynamiccontents/${id}`,
      keepUnusedDataFor: false,
      providesTags: (result, error, id) => [{ type: "Dwc", id }],
    }),
    updateDwc: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/dynamiccontents/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      // keepUnusedDataFor: false,
      invalidatesTags: (result, error, { id }) => [{ type: "Dwc", id }],
    }),
    deleteDwc: build.mutation({
      query: (id) => ({
        url: `/api/dynamiccontents/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          dwcApi.util.updateQueryData("getdwc", undefined, (draft) => {
            draft.data = draft.data.filter((dwc) => dwc.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Dwc", id }],
    }),
    createDwc: build.mutation({
      query: (body) => ({
        url: `/api/dynamiccontents/new`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Dwc", id: "dwc" }],
    }),
    getDynamicContent: build.query({
      query: () => `/api/dynamiccontents/options`,
      providesTags: (result, error, id) => [{ type: "Dwc", id }],
    }),
    getDwcExtras: build.query({
      query: ({ id, dateFrom, dateTo }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        });
        return `api/dynamicContent/${id}/extras?${params}`;
      },
      providesTags: (result, error, { id }) => [{ type: "Dwc", id }],
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderDwc: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=dynamiccontents`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderDwc: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderDwc: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Dwc", id }],
    }),
    //DELETE FOLDER
    deleteFolderDwc: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateDwcToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/dynamiccontents/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeDwcToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/dynamiccontents/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetdwcQuery,
  useDeleteDwcMutation,
  useUpdateDwcMutation,
  useGetDwcContentQuery,
  useCreateDwcMutation,
  useGetDynamicContentQuery,
  useGetDwcExtrasQuery,
     //FOLDERS
     useGetFolderDwcQuery,
     useUpdateFolderDwcMutation,
     useDeleteFolderDwcMutation,
     useAddFolderDwcMutation,
     useUpdateDwcToFoldersMutation,
     useRemoveDwcToFoldersMutation,
} = dwcApi;
