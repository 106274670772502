import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import "react-dropzone-uploader/dist/styles.css";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { t } from "i18next";

const CustomModal = ({
  show,
  handleClose,
  body,
  handleAddFolder,
  modalOptions,
  handleEditFolder,
  handleAddSubFolder,
}) => {
  const buttons = [
    {
      name: "close",
      title: `${t("caymland.core.close")}`,
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleClose,
    },
    {
      name: "save",
      title: modalOptions?.type === "edit" ? t("caymland.core.form.update") : t("caymland.core.form.add"),

      icon: <AiOutlineSave size={15} />,
      onClick:
        modalOptions?.type === "add"
          ? handleAddFolder
          : modalOptions?.type === "edit"
          ? handleEditFolder
          : handleAddSubFolder,
    },
  ];
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {modalOptions?.type === "edit" ? t("caymland.core.form.edit") : t("caymland.core.form.add")}{" "}
          {modalOptions?.type === "addsub" ? "Subfolder" : t("caymland.mailbox.rules.folder")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
