import { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "../../../../../reusableComponents/Inputs";
import { t } from "i18next";

export const SelectField = ({
  label,
  name,
  options = [],
  setEntity,
  entity,
  multiple,
  modalOptions,
  required,
  tooltip,
}) => {
  const [optionValues, setOptionValues] = useState([]);
  const [optionValuesEmail, setOptionValuesEmail] = useState([]);
  const [selectedValues, setSelectedValues] = useState(multiple ? [] : null);

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#135f95",
      primary: "#135f95",
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#135f95" : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  const handleSelectChange = (selected, name) => {
    setSelectedValues((prev) => ({
      ...(prev || {}),
      properties: {
        ...(prev ? prev.properties : {}),
        [name]: Array.isArray(selected) ? selected.map((option) => option.value) : selected.value,
      },
    }));
    setEntity((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        [name]: Array.isArray(selected) ? selected.map((option) => option.value) : selected.value,
      },
    }));
  };

  useEffect(() => {
    let processedOptions = [];

    if (Array.isArray(options)) {
      // Options without headers
      processedOptions = options
        .filter((option) => option.label !== "")
        .map((option) => ({
          value: option.value,
          label: option.label,
        }));
    } else {
      // Options with headers
      processedOptions = Object.entries(options).flatMap(([header, values]) => {
        const processedValues = Array.isArray(values)
          ? values.map((item) => ({
              value: item.value,
              label: item.label,
            }))
          : [{ value: values.value, label: values.label }];
        if (header !== "") {
          return [{ label: header, options: processedValues }];
        }
      });
    }

    let processedOptions2 = Object.values(options).flat();
    setOptionValuesEmail(processedOptions2);

    setOptionValues(processedOptions);
  }, [options]);

  useEffect(() => {
    if (!entity || !entity.properties || options.length === 0) {
      return;
    }

    if (multiple) {
      const entityValues = entity?.properties[name] ?? [];
      if (Array.isArray(entityValues)) {
        setSelectedValues(
          options?.filter((option) => {
            const optionValue = option.value;
            return entityValues.includes(optionValue);
          })
        );
      } else {
        setSelectedValues([]);
      }
    } else {
      const matchingOption = optionValuesEmail?.find((option) => option.value === entity?.properties[name] ?? []);
      setSelectedValues(matchingOption || null);
    }
  }, [entity]);

  return (
    <div className="input-container ic1" style={{ marginBottom: "30px" }}>
      {label && label?.length && <Label name={name} label={label} tooltip={tooltip} required={required} />}
      <Select
        name={name}
        isSearchable
        isMulti={multiple}
        menuPortalTarget={document.body}
        styles={{
          ...selectStyles,
          menuPortal: (base) => ({
            ...base,
            zIndex: 999999999999999999,
          }),
        }}
        menuPlacement="auto"
        isClearable={false}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        class="jump-input"
        options={optionValues}
        placeholder={t("caymland.query.builder.select.placeholder")}
        value={selectedValues}
        onChange={(e) => handleSelectChange(e, name)}
        theme={selectTheme}
        tooltip={tooltip}
      />
    </div>
  );
};
