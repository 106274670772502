import { MouseEvent } from "react";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const MRT_CustomRowActionMenu = ({
  anchorEl,
  handleEdit,
  row,
  setAnchorEl,
  table,
}) => {
  const {
    getState,
    options: {
      icons: { EditIcon },
      enableEditing,
      localization,
      renderRowActionMenuItems,
    },
  } = table;
  const { density } = getState();

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClick={(event) => event.stopPropagation()}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        dense: density === "compact",
      }}
    >
      {enableEditing instanceof Function
        ? enableEditing(row)
        : enableEditing && (
            <MenuItem onClick={handleEdit}>
              <Box>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                {localization.edit}
              </Box>
            </MenuItem>
          )}
      {renderRowActionMenuItems?.({
        row,
        table,
        closeMenu: () => setAnchorEl(null),
      })}
    </Menu>
  );
};
