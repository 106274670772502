import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { MRT_CustomHeaderActionMenu } from "./RowActionMenu/MRT_CustomHeaderActionMenu";
import MRT_CustomSelectCheckBox from "./RowActionMenu/MRT_CustomSelectCheckBox";

const MRT_CustomHeaderDropDownActions = ({ cell, row, table }) => {
  const {
    getState,
    options: {
      editingMode,
      enableEditing,
      icons: { EditIcon, MoreVertIcon },
      localization,
      renderRowActions,
      renderCustomBatchActions,
    },
    setEditingRow,
  } = table;

  // console.log(table.getSelectedRowModel().rows);

  const { editingRow } = getState();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenRowActionMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleStartEditMode = (event) => {
    event.stopPropagation();
    setEditingRow({ ...row });
    setAnchorEl(null);
  };

  return (
    <>
      {renderRowActions ? (
        renderRowActions({ cell, row, table })
      ) : !renderCustomBatchActions && (enableEditing instanceof Function ? enableEditing(row) : enableEditing) ? (
        <Tooltip placement="right" arrow title={localization.edit}>
          <IconButton aria-label={localization.edit} onClick={handleStartEditMode}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : renderCustomBatchActions ? (
        <>
          <Tooltip arrow enterDelay={1000} enterNextDelay={1000} title={localization.rowActions}>
            <IconButton
              aria-label={localization.rowActions}
              onClick={handleOpenRowActionMenu}
              size="small"
              disabled={table.getSelectedRowModel().rows.length ? false : true}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <MRT_CustomHeaderActionMenu
            anchorEl={anchorEl}
            handleEdit={handleStartEditMode}
            row={row}
            setAnchorEl={setAnchorEl}
            table={table}
          />
        </>
      ) : null}
    </>
  );
};

export const MRT_CustomHeaderRowsAction = ({ table, row, cell, selectAll, dontShowSelect }) => {
  return (
    <>
      {!dontShowSelect && (
        <>
          {" "}
          <MRT_CustomSelectCheckBox table={table} selectAll={selectAll} row={row} />
          <MRT_CustomHeaderDropDownActions cell={cell} row={row} table={table} />{" "}
        </>
      )}
    </>
  );
};
