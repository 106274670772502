import { t } from "i18next";
import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

const Fields = ({ extras }) => {
  const sortedFields = [...extras?.fields].sort((a, b) => a.order - b.order);
  return (
    <div>
      {sortedFields.map((x) => (
        <div className="form-actions-card">
          <div className="flex items-center gap-30">
            {x.isRequired ? (
              <FaCheck title={t("caymland.core.required")} color="#d9d9d9" size={22} />
            ) : (
              <FaTimes title={t("caymland.core.not_required")} color="#d9d9d9" size={22} />
            )}
            <div className="grid">
              <p
                style={{
                  color: "#4285f4",
                  fontWeight: "bold",
                }}
                className="pb-0 mb-0"
              >
                {x.label}
              </p>
              <p
                className="pb-0 mb-0"
                style={{
                  color: "#959595",
                  fontWeight: "bold",
                }}
              >
                {t("caymland.form.details.field_type", { type: x.type })}
              </p>
            </div>
          </div>
          <em
            style={{
              fontDecoration: "italic",
              color: "#b3b3b3",
            }}
            className="mb-0 pb-0"
          >
            {t("caymland.form.details.field_order", { order: x.order })}
          </em>
        </div>
      ))}
    </div>
  );
};

export default Fields;
