import React from "react";
import { FileManager, FileNavigator } from "@opuscapita/react-filemanager";
import connectorNodeV1 from "@opuscapita/react-filemanager-connector-node-v1";

let hostUrl =
  "https://drive.google.com/drive/folders/1asSI90vAeDdznyn45uI126LiOrcfhjC6?usp=sharing";

function FileManagerFunction() {
  // startSerer();
  const apiOptions = {
    ...connectorNodeV1.apiOptions,
    apiRoot:
      "https://drive.google.com/drive/folders/1asSI90vAeDdznyn45uI126LiOrcfhjC6",
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <div style={{ height: "480px" }}>
        <FileManager>
          <FileNavigator
            id="filemanager-1"
            api={connectorNodeV1.api}
            apiOptions={apiOptions}
            capabilities={connectorNodeV1.capabilities}
            listViewLayout={connectorNodeV1.listViewLayout}
            viewLayoutOptions={connectorNodeV1.viewLayoutOptions}
          />
        </FileManager>
      </div>
    </div>
  );

  // return <div>ilir</div>;
}

export default FileManagerFunction;
