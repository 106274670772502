import React from "react";
import { FaCube } from "react-icons/fa";
import { ClipLoader } from "react-spinners";

function Builder({ submit, loading, buttonColor, title }) {

    return (
        <button disabled={loading} style={{ color: buttonColor ?? "#135f95" }} onClick={submit} className="btn-white flex items-center gap-5px" >
            {loading ?
                <ClipLoader size={15} color={buttonColor ?? "#135f95"} />
                :
                <FaCube color={buttonColor ?? "#135f95"} size={15} />
            }
            {title ?? "Builder"}
        </button>
    );
}

export default Builder;

