import React, { useState, useEffect, Fragment, useRef, useMemo } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { TbEdit } from "react-icons/tb";
import { FaPlus, FaRegTimesCircle } from "react-icons/fa";
import "react-dropzone-uploader/dist/styles.css";
import { countryData, localeData } from "../../../reusableComponents/data";
import { Error } from "../../../reusableComponents/Alerts";
import formatObject from "../../contacts/formatObject";
import Swal from "sweetalert2";
import InputsDetailTabRender from "./InputsDetailTabRender";
import { AiOutlineSave } from "react-icons/ai";
import { Edit } from "@mui/icons-material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { t } from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useUpdateCompanyMutation } from "../../../../redux/api/companiesApi";

const DetailTabsCard = ({ companyFields, data, setShowAlert, setAlertMessage, setPointsValue, id }) => {
  const [currentTab, setCurrentTab] = useState("core");
  const [primaryColorTab, setPrimaryColorTab] = useState(0);
  const [groupedFields, setGroupedFields] = useState({});
  const [editableField, setEditableField] = useState(null);
  const [isEditAll, setIsEditAll] = useState(false); // New state for edit all functionality
  const [resetData, setResetData] = useState();
  const [hover, setHover] = useState(false);
  const [isFieldChanged, setIsFieldChanged] = useState(false); // Track changes
  const [fieldDisplayLimits, setFieldDisplayLimits] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(),
  });

  useEffect(() => {
    if (data) {
      const mergedData = {
        ...data?.all,
      };
      reset(mergedData);
      setInitialValues(mergedData);
    }
  }, [data, reset]);
  // Email validation function using regex
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\d*$/;
    return regex.test(String(email).toLowerCase());
  };

  // Example usage within your component
  const email = watch("companyemail"); // Assuming you're watching the email field

  // Check if the email is valid
  const emailIsValid = useMemo(() => isValidEmail(email), [email]);

  const toggleEditable = (fieldId) => {
    setEditableField(fieldId);
    setIsFieldChanged(false);
  };

  const toggleEditAll = () => {
    setIsEditAll(!isEditAll);
    setEditableField(null);
  };

  const handleFieldChange = () => {
    setIsFieldChanged(true);
  };

  // const formatFieldValue = (field, value) => {
  //   switch (field?.type) {
  //     case "boolean":
  //       return value === 1 || value === true
  //         ? field?.properties?.yes
  //         : value === 0 || value === false
  //         ? field?.properties?.no
  //         : "Neutral";
  //     case "multiselect":
  //       return value
  //         .split("|")
  //         .map((val) => {
  //           const option = field?.properties?.list?.find((item) => item?.value === val);
  //           return option ? option?.label : val;
  //         })
  //         .join(", ");
  //     case "country":
  //       return countryData.find((country) => country?.value === value)?.label;
  //     case "locale":
  //       return localeData.find((locale) => locale?.value === value)?.label;
  //     default:
  //       return value;
  //   }
  // };

  const formatFieldValue = (field, value) => {
    if (value === undefined || value === null) {
      return "";
    }

    switch (field?.type) {
      case "boolean":
        return value === 1 || value === true
          ? field?.properties?.yes
          : value === 0 || value === false
          ? field?.properties?.no
          : "";
      case "multiselect":
        if (typeof value === "string") {
          return value
            .split("|")
            .map((val) => {
              const option = field?.properties?.list?.find((item) => item?.value === val);
              return option ? option?.label : val;
            })
            .join(", ");
        } else {
          return "";
        }
      case "country":
        return countryData.find((country) => country?.value === value)?.label;
      case "locale":
        return localeData.find((locale) => locale?.value === value)?.label;
      case "datetime":
        return moment(value).format("DD.MM.YYYY HH:mm") || value;
      case "date":
        return moment(value).format("DD.MM.YYYY ") || value;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (companyFields) {
      function groupFieldsByTab(fields) {
        const grouped = {};
        fields.forEach((field) => {
          if (field.isPublished && field.isVisible) {
            const groupName = field.group || "other";
            if (!grouped[groupName]) {
              grouped[groupName] = [];
            }
            grouped[groupName].push(field);
          }
        });

        if (grouped["core"]) {
          const orderedGrouped = { core: grouped["core"] };
          Object.keys(grouped).forEach((groupName) => {
            if (groupName !== "core") {
              orderedGrouped[groupName] = grouped[groupName];
            }
          });
          return orderedGrouped;
        }

        return grouped;
      }

      const grouped = groupFieldsByTab(companyFields);
      setGroupedFields(grouped);

      // Initialize display limits for each tab
      const displayLimits = Object.keys(grouped).reduce((acc, groupName) => {
        acc[groupName] = 15;
        return acc;
      }, {});
      setFieldDisplayLimits(displayLimits);
    }
  }, [companyFields]);

  const formRef = useRef(); // Create a ref for the form

  const changeDynamicTab = (index, groupName) => {
    setPrimaryColorTab(index);
    setCurrentTab(groupName);
  };

  const [updateContact] = useUpdateCompanyMutation(); // Initialize the mutation hook

  const updateFields = async () => {
    const payload = Object.keys(dirtyFields).reduce((acc, field) => {
      acc[field] = watch(field);
      return acc;
    }, {});

    try {
      const response = await updateContact({
        id: id,
        ...payload,
      }).unwrap();
      console.log(response);
      if (!response.errors) {
        setShowAlert(true);
        setAlertMessage(
          `${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`
        );
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 3000);
        reset(response?.company?.fields?.all);
        setPointsValue(response?.contact?.points);
        setResetData(response?.company?.fields?.all);
        setEditableField(null);
        setIsEditAll(false);
      }
    } catch (error) {
      // Handle error
      console.error("Error updating contact:", error);
    }
  };
  const toggleFieldDisplay = (groupName) => {
    setFieldDisplayLimits({
      ...fieldDisplayLimits,
      [groupName]: fieldDisplayLimits[groupName] > 15 ? 15 : groupedFields[groupName].length,
    });
  };
  const getFieldNameFromId = (fieldId) => {
    // Placeholder for actual logic
    // This could be a lookup in an array or object that maps field IDs to names
    const field = companyFields.find((f) => f.id === fieldId);
    return field ? field.alias : null; // assuming 'alias' is the form field name
  };
  return (
    <div ref={formRef} className="w-full">
      <div className="flex items-center justify-between">
        <Nav className="nav-primary w-full d-flex" tabs>
          {Object.keys(groupedFields).map((groupName, index) => (
            <NavItem key={index}>
              <NavLink
                href="#"
                className={primaryColorTab === index ? "active" : ""}
                onClick={() => changeDynamicTab(index, groupName)}
              >
                {groupName.charAt(0).toUpperCase() + groupName.slice(1)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <div style={{ width: "fit-content" }} className="cursor-pointer">
          {isEditAll ? (
            <div className="flex items-center gap-10" style={{ marginRight: "6px" }}>
              <div
                className="result-component"
                onClick={() => {
                  setIsEditAll(false);
                  // reset(data.fields.all);
                }}
              >
                {t("caymland.core.form.cancel")}
              </div>
              <div onClick={updateFields} className="result-component">
                <AiOutlineSave color="#135f95" size={14} style={{ marginRight: "2px" }} />{" "}
                {t("caymland.core.form.save")}
              </div>
            </div>
          ) : (
            <div style={{ padding: "8px 12px", display: "flex" }} onClick={toggleEditAll}>
              <TbEdit color="#135f95" size={20} />
            </div>
          )}
        </div>
      </div>
      <TabContent activeTab={primaryColorTab} style={{ padding: "8px" }}>
        {Object?.keys(groupedFields).map((groupName, index) => (
          <TabPane tabId={index} key={index}>
            {groupedFields[groupName].slice(0, fieldDisplayLimits[groupName] || 15).map((field, fieldIndex) => (
              <Fragment key={fieldIndex}>
                {currentTab === field.group && (
                  <div className="col-sm-12 col-md-12" style={{ padding: "10px", position: "relative" }}>
                    <div
                      className={`normal-btn flex items-center ${
                        editableField === field.id ? "" : "hoverable-edit"
                      } w-full`}
                      onClick={() => toggleEditable(field.id)}
                      onMouseOver={() => handleFieldChange()}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          fontWeight: "600",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          padding: "0 8px",
                          width: "40%",
                          background: "white",
                          color: "black",
                          height: "34px",
                          boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
                          borderBottomLeftRadius: "4px",
                          borderTopLeftRadius: "4px",
                        }}
                      >
                        <span title={field.label} style={{ cursor: "vertical-text" }}>
                          {field.label.length > 25 ? field.label.slice(0, 25) + "..." : field.label}
                        </span>
                      </div>
                      <div
                        className="flex items-center justify-between"
                        style={{
                          fontSize: "14px",
                          padding: "0 8px",
                          width: "60%",
                          height: "34px",
                          color: "black",
                          boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
                          borderBottomRightRadius: "4px",
                          borderTopRightRadius: "4px",
                          background: "#fafafa",
                          borderLeft: "none",
                          border:
                            field.alias === "email" && !emailIsValid && field.isRequired
                              ? "2px solid #721c24"
                              : field.isRequired && !watch(field.alias)
                              ? "2px solid #721c24"
                              : dirtyFields[field.alias] && editableField !== field.id
                              ? "2px solid orange"
                              : "none",
                        }}
                        onMouseEnter={() => setHover(field.id)}
                        onMouseLeave={() => setHover(null)}
                      >
                        {/* Render ViewFieldsForm or formatted value */}
                        {editableField === field.id || isEditAll ? (
                          <InputsDetailTabRender
                            field={field}
                            control={control}
                            setValue={setValue}
                            register={register}
                          />
                        ) : (
                          <span>{formatFieldValue(field, watch(field.alias))}</span>
                        )}

                        {/* Optionally render save icon */}
                        {dirtyFields[field.alias] && editableField === field.id && (
                          <div className="d-flex items-center gap-5px">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();

                                const fieldName = getFieldNameFromId(editableField);
                                if (fieldName && initialValues.hasOwnProperty(fieldName)) {
                                  setValue(fieldName, initialValues[fieldName], {
                                    shouldDirty: true,
                                  });
                                  setEditableField(null); // Reset editable field state
                                }
                              }}
                            >
                              <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                updateFields(field.id);
                              }}
                            >
                              <AiOutlineSave color="#135F95" size={20} title={t("caymland.lead.field.save.tooltip")} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
            <div className="d-flex justify-center items-center mt-10">
              {groupedFields[groupName].length > 15 && (
                <button
                  className="result-component"
                  style={{ height: "20px", paddingLeft: "10px", paddingRight: "10px" }}
                  onClick={() => toggleFieldDisplay(groupName)}
                >
                  {fieldDisplayLimits[groupName] > 15 ? (
                    <i className="d-flex justify-center items-center" style={{ fontSize: "12px" }}>
                      <KeyboardDoubleArrowUpIcon fontSize="14px" /> {t("caymland.core.show.less")}
                    </i>
                  ) : (
                    <i className="d-flex justify-center items-center" style={{ fontSize: "12px" }}>
                      <KeyboardDoubleArrowDownIcon fontSize="14px" /> {t("caymland.core.show.more")}
                    </i>
                  )}
                </button>
              )}
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default DetailTabsCard;
