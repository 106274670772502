import { t } from "i18next";
import { Label, Select } from "../../../../reusableComponents/Inputs";
import { Checkbox, Text, TextArea } from "../../../../reusableComponents/Inputs";
import { useState } from "react";

function ContentGeneratorActions({ data, setEntity, entity, modifiedData, modalOptions }) {
  const [editorContents, setEditorContents] = useState({});
  const [editorInstances, setEditorInstances] = useState({});

  const handleInsertField = (editorFieldName, label, alias) => {
    const editor = editorInstances[editorFieldName];
    if (editor) {
      editor.model.change((writer) => {
        const insertPosition = editor.model.document.selection.getFirstPosition();
        writer.insertText(`${label}: {formfield=${alias}}`, insertPosition);

        const newData = editor.getData();
        handleChange(editorFieldName, newData);
      });
    }
  };

  const renderFieldsAsLinks = (editorFieldName) => {
    if (modifiedData.fields && modifiedData.fields.length > 0) {
      return modifiedData.fields
        .map((field, index) => {
          if (field.type === "button") {
            return null;
          }

          return (
            <a
              key={index}
              className="list-group-item ellipsis"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleInsertField(editorFieldName, field.label, field.alias);
              }}
            >
              {field.label}
            </a>
          );
        })
        .filter((item) => item !== null);
    }
    return null;
  };

  const handleChange = (propertyName, propertyValue) => {
    if (propertyName !== "name") {
      setEntity((prevEntity) => ({
        ...prevEntity,
        properties: {
          ...prevEntity.properties,
          [propertyName]: propertyValue || "",
        },
      }));
    } else {
      setEntity((prevEntity) => ({
        ...prevEntity,
        [propertyName]: propertyValue || "",
      }));
    }
  };

  function renderField(field) {
    if (field?.type && field?.type !== "button") {
      const formattedLabel = field.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
      return (
        <div style={{ marginTop: "20px" }}>
          <Text
            label={formattedLabel}
            name={field.label}
            value={entity?.properties[formattedLabel]}
            wFull
            onChange={(e) => handleChange(formattedLabel, e.target.value)}
          />
        </div>
      );
    }
    return null;
  }

  const handleEditorReady = (editor, fieldName) => {
    setEditorInstances((prev) => ({ ...prev, [fieldName]: editor }));
  };

  return (
    <div>
      {data.map((property, index) => {
        return (
          <div key={index}>
            {property.label === "properties" ? (
              <div>
                {Object.entries(property.value).map(([fieldName, fieldData], fieldIndex) => {
                  return (
                    <div key={fieldIndex}>
                      {fieldData.label || fieldData.label === "" ? (
                        <div>
                          {fieldData.childType === "button" ? (
                            <div></div>
                          ) : fieldData.childType === "editor" ? (
                            <div style={{ marginTop: "20px" }}>
                              <TextArea
                                label={fieldData.label}
                                value={entity?.properties[fieldName] || ""}
                                name={fieldName}
                                required={fieldData.required}
                                onReady={(editor) => handleEditorReady(editor, fieldName)}
                                onChange={(data) => {
                                  setEditorContents({
                                    ...editorContents,
                                    [fieldName]: data,
                                  });
                                  handleChange(fieldName, data);
                                }}
                              />
                              {modifiedData.fields && modifiedData.fields.length > 0 && (
                                <Label name={"insertField"} label={t("caymland.form.action.sendemail.dragfield")} />
                              )}
                              <div className="list-group mb-2" style={{ maxHeight: "250px", overflowY: "auto" }}>
                                {renderFieldsAsLinks(fieldName)}
                              </div>
                            </div>
                          ) : fieldData.childType === "toggle" ? (
                            <>
                              <Checkbox
                                label={fieldData.label}
                                name={fieldName}
                                required={fieldData.required}
                                value={entity?.properties?.[fieldName] === "1"}
                                onChange={(e) => {
                                  const newValue = e.target.checked ? "1" : "0";
                                  handleChange(fieldName, newValue);
                                }}
                              />
                            </>
                          ) : fieldData?.choices || fieldData?.childType === "choice" ? (
                            <div>
                              <Select
                                label={fieldData.label}
                                maxMenuHeight={160}
                                required={fieldData.required}
                                menuPosition="absolute"
                                menuPlacement="bottom"
                                id={fieldName}
                                placeholder={t("caymland.core.select")}
                                name={fieldName}
                                isClearable={false}
                                options={
                                  Array.isArray(fieldData?.choices)
                                    ? fieldData?.choices
                                        .filter((choice) => choice?.label.trim() !== "")
                                        .map((choice) => ({
                                          label: choice?.label, 
                                          value: choice?.value,
                                          isDisabled: false,
                                        }))
                                    : fieldData?.choices && typeof fieldData.choices === "object" // Ensure fieldData.choices is an object
                                    ? Object.entries(fieldData.choices).flatMap(([group, choices]) => {
                                        if (group === "label" && choices === "value") {
                                          return [];
                                        }
                                        if (Array.isArray(choices)) {
                                          return [
                                            {
                                              label: group,
                                              options: choices
                                                .filter((choice) => choice.label.trim() !== "")
                                                .map((choice) => ({
                                                  label: choice.label,
                                                  value: choice.value,
                                                  isDisabled: false,
                                                })),
                                            },
                                          ];
                                        } else {
                                          return [];
                                        }
                                      })
                                    : [] // Return an empty array if fieldData.choices is not a proper object
                                }
                                // options={
                                //   Array.isArray(fieldData.choices)
                                //     ? fieldData.choices.map((choice) => ({
                                //         label: choice.label,
                                //         value: choice.value,
                                //         isDisabled: false, // Set this based on your condition
                                //       }))
                                //     : Object.entries(fieldData.choices).flatMap(
                                //         ([group, choices]) => {
                                //           if (
                                //             group === "label" &&
                                //             choices === "value"
                                //           ) {
                                //             // Skip this entry if it's not a group
                                //             return [];
                                //           }
                                //           if (Array.isArray(choices)) {
                                //             return [
                                //               {
                                //                 label: group,
                                //                 options: choices.map(
                                //                   (choice) => ({
                                //                     label: choice.label,
                                //                     value: choice.value,
                                //                     isDisabled: false, // Set this based on your condition
                                //                   })
                                //                 ),
                                //               },
                                //             ];
                                //           } else {
                                //             return [];
                                //           }
                                //         }
                                //       )
                                // }
                                value={
                                  entity.properties?.[fieldName]
                                    ? (Array.isArray(fieldData.choices)
                                        ? fieldData.choices.find(
                                            (choice) => choice.value === entity.properties[fieldName]
                                          )
                                        : Object.entries(fieldData.choices).flatMap(([_, choices]) =>
                                            Array.isArray(choices)
                                              ? choices.find((choice) => choice?.value === entity.properties[fieldName])
                                              : []
                                          )[0]) || {
                                        value: entity.properties[fieldName],
                                        label: "", // Default label if no match is found
                                      }
                                    : { value: "", label: "" } // Default object if fieldName is not in entity.properties
                                }
                                // value={
                                //   entity.properties?.[fieldName]
                                //     ? {
                                //         value: entity.properties[fieldName],
                                //         label: Object.values(fieldData.choices).flatMap(choices =>
                                //           choices?.filter(choice => choice.value === entity.properties[fieldName])
                                //         ).map(choice => choice.label)[0] || ""
                                //       }
                                //     : "aaaa"
                                // }
                                onChange={(selectedOption) => handleChange(fieldName, selectedOption.value)}
                              />
                            </div>
                          ) : (
                            <Text
                              label={fieldData.label === "" ? fieldName : fieldData.label}
                              name={fieldName}
                              required={fieldData.required}
                              value={entity.properties[fieldName] || ""}
                              wFull
                              onChange={(event) => handleChange(fieldName, event.target.value)}
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : property.label === "description" ? (
              <TextArea
                label={property?.value.label}
                required={property?.value.required}
                value={entity?.description ?? ""}
                onChange={(data) => setEntity((prev) => ({ ...prev, description: data || "" }))}
              />
            ) : (
              <div style={{ marginTop: "20px" }}>
                <Text
                  label={property.value.label}
                  required={property?.value.required}
                  name={property.value.name}
                  wFull
                  value={entity[property.value.name] || ""}
                  onChange={(event) => handleChange(property.value.name, event.target.value)}
                />
              </div>
            )}
          </div>
        );
      })}
      {modalOptions.type === "form.repost" && (
        <div style={{ marginTop: "14px" }}>
          <span>{t("caymland.form.action.repost.field_mapping")}</span>
          <div className="mt-10">
            {modalOptions.type === "form.repost" &&
              modifiedData.fields.map((field, index) => {
                return renderField(field);
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentGeneratorActions;
