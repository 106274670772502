import React, { Fragment, useEffect, useState, memo, useCallback } from "react";
import Activities from "./activity/Activities";
import Charts from "./charts";
import NormalTable from "./normaltable";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import GeoChart from "./GeoChart";
import UpcomingEmails from "./upcomingEmails";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import GeoEChart from "../../../reusableComponents/ECharts/WorldChart/GeoEChart";
import { t } from "i18next";

export const DragHandle = sortableHandle(() => (
  <Tooltip arrow followCursor placement="top" TransitionComponent={Zoom} title={t("caymland.core.change.dragdrop")}>
    <IconButton>
      <DragHandleIcon style={{ color: "#757575", cursor: "grab" }} />
    </IconButton>
  </Tooltip>
));

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.id === nextProps.id &&
    prevProps.chartType === nextProps.chartType &&
    JSON.stringify(prevProps.chartData) === JSON.stringify(nextProps.chartData) &&
    prevProps.name === nextProps.name &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    JSON.stringify(prevProps.widgetData) === JSON.stringify(nextProps.widgetData)
  );
};

const getColumnSpan = (width) => {
  if (width <= 25) return 1;
  if (width <= 50) return 2;
  if (width <= 75) return 3;
  return 4;
};

const MemoizedSortableItem = memo(
  SortableElement(
    ({ chartType, chartData, name, width, height, rowIndex, widgetData, handleEdit, handleDelete, id }) => {
      const numericWidth = Number(width);
      const numericHeight = Number(height);

      const gridColumnSpan = getColumnSpan(parseFloat(numericWidth));

      const renderWidget = () => {
        if (widgetData?.chartData) {
          return (
            <Charts
              type={chartType}
              name={name}
              width={numericWidth}
              height={`${numericHeight}px`}
              chartData={chartData}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              id={id}
            />
          );
        } else if (widgetData?.headItems) {
          return (
            <NormalTable
              headItems={widgetData?.headItems}
              bodyItems={widgetData?.bodyItems}
              name={name}
              width={numericWidth}
              height={`${numericHeight}px`}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              id={id}
            />
          );
        } else if (widgetData?.logs) {
          return (
            <Activities
              logs={widgetData?.logs}
              width={numericWidth}
              height={`${numericHeight}px`}
              name={name}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              id={id}
            />
          );
        } else if (widgetData?.height) {
          return (
            <GeoChart
              data={widgetData?.data}
              width={numericWidth}
              height={`${numericHeight}px`}
              name={name}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              id={id}
              DragHandle={DragHandle}
            />
          );
        } else if (widgetData?.upcomingEmails) {
          return (
            <UpcomingEmails
              upcomingEmails={widgetData?.upcomingEmails}
              width={numericWidth}
              height={`${numericHeight}px`}
              name={name}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              id={id}
            />
          );
        }

        return <></>;
      };

      return renderWidget() ? (
        <div key={rowIndex} className="widget-shadow" style={{ gridColumn: `span ${gridColumnSpan}` }}>
          {renderWidget()}
        </div>
      ) : null;
    }
  ),
  areEqual
);

const SortableList = SortableContainer(({ items, handleEdit, handleDelete }) => {
  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", paddingBottom: "20px" }} className="gap-20">
      {items &&
        items?.map((widget, index) => (
          <MemoizedSortableItem
            key={index + "-" + widget?.id}
            rowIndex={index}
            index={index}
            chartData={widget?.data?.chartData}
            chartType={widget?.data?.chartType}
            name={widget?.name}
            width={widget?.width}
            height={widget?.height}
            widgetData={widget?.data}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            id={widget?.id}
          />
        ))}
    </div>
  );
});

function WidgetGenerator({
  widgets,
  handleEdit,
  setFilteredWidgets,
  handleSaveTemplate,
  handleDeleteWidget,
  isFetching,
}) {
  const [reorderedWidgets, setReorderedWidgets] = useState("");

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newArr = arrayMoveImmutable(widgets, oldIndex, newIndex).map((widget, index) => ({
          ...widget,
          ordering: index,
        }));
        setFilteredWidgets(newArr);
        setReorderedWidgets("reordered");
      }
    },
    [widgets]
  );

  useEffect(() => {
    if (reorderedWidgets === "reordered") handleSaveTemplate("", "addWidget");
    setReorderedWidgets("");
  }, [reorderedWidgets]);

  return (
    <SortableList
      items={widgets}
      isFetching={isFetching}
      onSortEnd={onSortEnd}
      onSortStart={(_, event) => event.preventDefault()}
      axis={"xy"}
      useDragHandle
      handleEdit={handleEdit}
      handleDelete={handleDeleteWidget}
    />
  );
}

export default WidgetGenerator;
