import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import { Error, Success } from "../../../reusableComponents/Alerts";
import NewCategoryPopUp from "../../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import { categoriesApi } from "../../../../redux/api/categoriesApi";
import MainContent from "./mainContent";
import Sidebar from "./Sidebar";
import {
  useAddNewAssetMutation,
  useGetAssetOptionsQuery,
  useGetAssetPreviewQuery,
  useGetAssetQuery,
  useUpdateAssetsMutation,
} from "../../../../redux/api/assetsApi";
import Swal from "sweetalert2";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useSelector } from "react-redux";

function AddEditAsset(state) {
  const navigate = useNavigate();
  const location = useLocation();
  const isNew = location.pathname.includes("new");
  const ClonePath = location.pathname.includes("clone");
  const id = location?.state?.id || location?.state || useParams().id;

  const [errors, setErrors] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPopUpCategory, setShowPopUpCategory] = useState(false);
  const [uploadImg, setUploadImg] = useState();
  const [pdfUploaded, setPdfUploaded] = useState();
  const [errorsMsg, setErrorsMsg] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "asset",
  });
  const [modifiedData, setModifiedData] = useState({
    isPublished: true,
    title: "",
    alias: "",
    category: null,
    description: "",
    language: "en",
    storageLocation: "local",
    publishUp: null,
    publishDown: null,
    disallow: false,
    subscribe: false,
    form: null,
    email: null,
    interval: null,
    file: null,
    downloadUrl: {},
  });

  const [newItem, setNewItem] = useState();

  const [triggerCategories] = categoriesApi.useLazyGetCategoriesQuery();
  const { data, isFetching, isLoading } = useGetAssetQuery(id, { skip: !id });
  const { data: assetOptions } = useGetAssetOptionsQuery();
  const [addNewAsset, { isLoading: isAddLoading }] = useAddNewAssetMutation();
  const [updateAsset, { isLoading: isEditLoading }] = useUpdateAssetsMutation(id);
  const token = useSelector((state) => state?.auth?.token);
  const isSaving = isEditLoading || isAddLoading;

  useEffect(() => {
    if (id && data?.asset) {
      let assetData = { ...data?.asset };
      if (ClonePath) {
        assetData = { ...assetData, isPublished: false };
      }
      setModifiedData({
        ...assetData,
        category: assetData?.category
          ? {
              label: assetData?.category?.title,
              value: assetData?.category?.id,
            }
          : null,
      });
      setOriginalData({
        ...assetData,
        category: {
          label: assetData?.category?.title,
          value: assetData?.category?.id,
        },
      });
    }
  }, [id, data]);

  useEffect(() => {
    if (modifiedData?.storageLocation === "remote" && modifiedData?.file?.length > 0) setErrorsMsg(false);
  }, [modifiedData?.storageLocation]);

  const checkIfFormIsModified = () => {
    return JSON.stringify(modifiedData) !== JSON.stringify(originalData);
  };
  const handleCancel = () => {
    if (checkIfFormIsModified() && id) {
      Swal.fire({
        title: t("caymland.badge_contact.form.confirmdelete"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/assets");
        } else {
          return;
        }
      });
    } else {
      navigate("/assets");
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditAsset("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditAsset(),
    },
  ];
  console.log(newItem);
  const handleSelectChange = (selectedOption) => {
    if (selectedOption?.value === "create_new") {
      setModalOptions((prev) => ({ ...prev, isOpen: true }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };
  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);
  const getUploadParams = async ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);

    return {
      url: `${process.env.REACT_APP_DEV_URL}/api/files/assets/new`,
      method: "POST",
      body,
      headers: { Authorization: `JWT ${token}` },
    };
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    setErrorsMsg(false);
    if (status === "done") {
      try {
        let response = JSON.parse(xhr.response);
        if (response?.file?.name) {
          setModifiedData({
            ...modifiedData,
            ...{ title: meta.name, file: response?.file?.name },
          });
        }
        if (meta.type.includes("image")) {
          setUploadImg(meta?.previewUrl);
        } else if (meta.type === "application/pdf") {
          const blobUrl = URL.createObjectURL(file);
          setPdfUploaded(blobUrl);
        }
      } catch (e) {}
    } else if (status === "removed") {
      setErrorsMsg(true);
      setUploadImg();
      setModifiedData((prev) => ({
        ...prev,
        downloadUrl: {},
      }));
    }
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData({
      ...modifiedData,
      [name]: checked,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    // if (name === "name") {
    //   if (value.length > 0) {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [name]: false,
    //     }));
    //   } else {
    //     setErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [name]: true,
    //     }));
    //   }
    // }

    if (type === "text" || type === "url") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFormChange = (field, value) => {
    setModifiedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  console.log(modifiedData);
  const handleAddEditAsset = async (params) => {
    if (modifiedData.title.length < 1) {
      setErrors(true);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    } else {
      setErrors(false);
    }

    try {
      let response;

      const payload = {
        ...modifiedData,
        category:
          modifiedData.category && typeof modifiedData.category === "object"
            ? modifiedData.category.value
            : modifiedData.category,
      };

      if (id && !ClonePath) {
        response = await updateAsset({ id, ...payload });
      } else {
        response = await addNewAsset(payload);
      }
      console.log(response);
      if (!response?.error) {
        Success(
          `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
            type: id ? "edited" : "created",
          })}`
        ).then((result) => {
          if (result.isConfirmed && params === "save") {
            navigate(`/assets/view/${response?.data?.asset?.id}`);
          } else {
            navigate(`/assets/edit/${response.data.asset.id}`);
          }
        });
      } else {
        if (
          response.error.data.error.message ===
          "File of the asset is required. (`error` is deprecated as of 2.6.0 and will be removed in 3.0. Use the `errors` array instead.)"
        )
          setErrorsMsg(true);
        Error(t("caymland.message.error"));
      }
    } catch (e) {
      Error(t("caymland.message.error"));
    }
  };

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isSaving}
          alertMessage={alertMessage}
          showAlert={showAlert}
          title={!ClonePath && id ? t("caymland.asset.asset.menu.edit") : t("caymland.asset.asset.menu.new")}
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          <>
            <MainContent
              errors={errors}
              isFetching={isLoading}
              getUploadParams={getUploadParams}
              handleChangeStatus={handleChangeStatus}
              uploadImg={uploadImg}
              pdfUploaded={pdfUploaded}
              modifiedData={modifiedData}
              handleInputChange={handleInputChange}
              setModifiedData={setModifiedData}
              isNew={isNew}
              errorsMsg={errorsMsg}
            />
          </>
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          <>
            <Sidebar
              isFetching={isLoading}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              handleSelectChange={handleSelectChange}
              triggerCategories={triggerCategories}
              handleFormChange={handleFormChange}
              handleToggleChange={handleToggleChange}
              assetOptions={assetOptions}
              getUploadParams={getUploadParams}
              handleChangeStatus={handleChangeStatus}
              ClonePath={ClonePath}
              isNew={isNew}
              modalOptions={modalOptions}
              setModalOptions={setModalOptions}
              setNewItem={setNewItem}
            />
          </>
        }
      />
    </>
  );
}

export default AddEditAsset;
