import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { MRT_CustomRowActionMenu } from "./RowActionMenu/MRT_CustomRowActionMenu";
import MRT_CustomSelectCheckBox from "./RowActionMenu/MRT_CustomSelectCheckBox";

const MRT_CustomDropDownActions = ({ cell, row, table }) => {
  const {
    getState,
    options: {
      editingMode,
      enableEditing,
      icons: { EditIcon, MoreHorizIcon },
      localization,
      renderRowActionMenuItems,
      renderRowActions,
    },
    setEditingRow,
  } = table;

  const { editingRow } = getState();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenRowActionMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleStartEditMode = (event) => {
    event.stopPropagation();
    setEditingRow({ ...row });
    setAnchorEl(null);
  };

  return (
    <>
      {renderRowActions ? (
        renderRowActions({ cell, row, table })
      ) : !renderRowActionMenuItems &&
        (enableEditing instanceof Function
          ? enableEditing(row)
          : enableEditing) ? (
        <Tooltip placement="right" arrow title={localization.edit}>
          <IconButton
            aria-label={localization.edit}
            onClick={handleStartEditMode}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : renderRowActionMenuItems ? (
        <>
          <Tooltip
            arrow
            enterDelay={1000}
            enterNextDelay={1000}
            title={localization.rowActions}
          >
            <IconButton
              aria-label={localization.rowActions}
              onClick={handleOpenRowActionMenu}
              size="small"
            >
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
          <MRT_CustomRowActionMenu
            anchorEl={anchorEl}
            handleEdit={handleStartEditMode}
            row={row}
            setAnchorEl={setAnchorEl}
            table={table}
          />
        </>
      ) : null}
    </>
  );
};

export const MRT_CustomRowsActionFolder = ({ table, row, cell, selectAll }) => {
  // console.log(table.options);
  return (
    <>
      {cell && (
        <MRT_CustomDropDownActions cell={cell} row={row} table={table} />
      )}
    </>
  );
};
