import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Sidebar from "../emails/addEmailContent/Sidebar";
import Advanced from "./editEmailContent/Advanced";
import CustomJson from "./editEmailContent/CustomJson";
import CustomHtml from "./viewEmail/CustomHtml";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Error, Success } from "../../../reusableComponents/Alerts";
import Loading from "../../../reusableComponents/loading";
import Builder from "./addEmailContent/Builder";
import ThemeCards from "./editEmailContent/ThemeCards";
import Button from "../../../reusableComponents/Buttons/Button";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { FaCube } from "react-icons/fa";
import { Checkbox } from "../../../reusableComponents/Inputs";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import {
  useAddEmailMutation,
  useGetAddEditEmailsQuery,
  useUpdateEmailsMutation,
  useGetEmailEditorOptionQuery,
  useTogglePublicPreviewMutation,
} from "../../../../redux/api/emailsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import Swal from "sweetalert2";
import isEqual from "lodash/isEqual";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

function EditEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(null);
  const [submitBackLoading, setSubmitBackLoading] = useState(false);
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [themes, setThemes] = useState([]);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState();
  const [originalData, setOriginalData] = useState({});
  const [pagesOptions, setPagesOptions] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [modifiedData, setModifiedData] = useState({
    name: "",
    subject: "",
    fromAddress: "",
    fromName: "",
    replyToAddress: "",
    bccAddress: "",
    isPublished: false,
    publishUp: null,
    publishDown: null,
    language: "",
    customHtml: "",
    customJson: null,
    doubleOptIn: false,
    ignoreDNC: false,
    plainText: "",
    previewText: "",
    template: "",
    emailType: "template",
    templateTranslationParent: null,
    segmentTranslationParent: null,
    translationChildren: [],
    translationParent: null,
    category: null,
    unsubscribeForm: null,
    isTemplate: false,
    publicPreview: true,
    preferenceCenter: null,
    lists: [],
    scheduleEmail: false,
    assetAttachments: [],
    headers: [],
    utmTags: {
      utmCampaign: "",
      utmContent: "",
      utmMedium: "",
      utmSource: "",
    },
  });

  const id = location?.state?.id || location?.state || useParams().id;

  const ClonePath = location.pathname.includes("/clone");

  const { data, refetch, isFetching, isLoading } = useGetAddEditEmailsQuery({ id });
  const { data: editorData } = useGetEmailEditorOptionQuery();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const openBuilder = queryParams.get("openBuilder") === "true";
    if (openBuilder && editorData) {
      setIsOpen(true);
    }
  }, [location.search, editorData]);

  const [updateEmail, { isLoading: isEditLoading }] = useUpdateEmailsMutation();
  const [addEmail] = useAddEmailMutation();

  useEffect(() => {
    if (data?.email && id) {
      retrieveData();
      setEmail(data.email.email);
      retrievePreferencePage();
    }
  }, [data, id]);

  const retrieveData = async () => {
    setLoading(true);
    try {
      let emailData = data?.email?.email;
      if (ClonePath) {
        emailData = { ...emailData, isPublished: false };
      }
      setModifiedData({
        ...emailData,
        category: emailData?.category
          ? {
              label: emailData?.category?.title,
              value: emailData?.category?.id,
            }
          : null,
      });
      setOriginalData({
        ...emailData,
        category: emailData?.category
          ? {
              label: emailData?.category?.title,
              value: emailData?.category?.id,
            }
          : null,
      });

      if (email?.headers) {
        const headersList = Object.entries(email?.headers).map(([label, value]) => ({
          label,
          value,
        }));

        setEmail({
          ...email,
          header: {
            list: headersList,
          },
        });
      }

      const templatesArray = Object.entries(data.templates.emails).map((x) => x[1]);
      let arr = templatesArray;
      arr.push({
        id: -1,
        name: data?.themes?.themes?.blank?.name,
        customJson: null, // Assuming no customJson for blank
        customHtml: null, // Assuming no customHtml for blank
      });

      // Push 'full' theme
      arr.push({
        id: -2,
        name: data?.themes?.themes?.full?.name,
        customJson: null, // Update this if there's customJson for full
        customHtml: null, // Update this if there's customHtml for full
      });

      setThemes(arr);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const groupOptionsByLanguage = (pages) => {
    const grouped = pages.reduce((acc, page) => {
      // Group initialization
      if (!acc[page.language]) {
        acc[page.language] = {
          label: page.language.toUpperCase(),
          options: [],
        };
      }

      // Adding the page to the respective group
      acc[page.language].options.push({
        label: page.title,
        value: page.id,
      });

      return acc;
    }, {});

    // Convert the grouped object into an array
    return Object.values(grouped);
  };

  const retrievePreferencePage = async (inputValue) => {
    return new Promise(async (resolve) => {
      const PageList = data?.pages?.pages;
      const groupedPages = groupOptionsByLanguage(PageList);
      resolve(groupedPages);
      setPagesOptions(groupedPages);
    });
  };

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };

  const navBack = () => {
    navigate(`/emails`);
  };

  const handleEditEmail = async (params) => {
    if (modifiedData?.subject.length < 1) {
      errors.subject = true;
      setErrors({ ...errors });
    } else {
      delete errors.subject;
      setErrors({ ...errors });
    }

    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    const hasErrors = Object.values(errors).some((value) => value === true);

    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }
    if (Object.keys(errors).length === 0) {
      try {
        if (params === "back") {
          setSubmitBackLoading(true);
        } else {
          setSubmitLoading(true);
        }

        if (modifiedData?.publicPreview === null) {
          modifiedData.publicPreview = 1;
        }
        function isBase64(str) {
          try {
            return btoa(atob(str)) === str;
          } catch (err) {
            return false;
          }
        }

        if (modifiedData?.customHtml) {
          if (!isBase64(modifiedData.customHtml)) {
            const encodedHtml = Buffer.from(modifiedData.customHtml).toString("base64");
            modifiedData.customHtml = encodedHtml;
          } else {
            // const decodedHtml = Buffer.from(modifiedData.customHtml, "base64").toString("utf-8");
            modifiedData.customHtml = modifiedData.customHtml;
          }
        }
        if (modifiedData?.customJson) {
          if (!isBase64(modifiedData.customJson)) {
            const encodedHtml = Buffer.from(modifiedData.customJson).toString("base64");
            modifiedData.customJson = encodedHtml;
          } else {
            // const decodedHtml = Buffer.from(modifiedData.customHtml, "base64").toString("utf-8");
            modifiedData.customJson = modifiedData.customJson;
          }
        }

        if (modifiedData?.category) {
          modifiedData.category = modifiedData.category.value;
        }

        if (modifiedData.assetAttachments) {
          if (Array.isArray(modifiedData.assetAttachments)) {
            // Handle the case where it's already an array of asset IDs
            modifiedData.assetAttachments = modifiedData.assetAttachments.map((x) => x.id);
          } else if (typeof modifiedData.assetAttachments === "object") {
            // Handle the case where it's an object or single asset
            modifiedData.assetAttachments = [modifiedData.assetAttachments.id];
          }
        } else {
          // Handle the case where modifiedData.assetAttachments is undefined or falsy
          modifiedData.assetAttachments = [];
        }

        if (modifiedData.translationChildren) {
          modifiedData.translationChildren = modifiedData?.translationChildren?.map((x) => x.id);
        }

        // if (modifiedData.translationParent) {
        //   modifiedData.translationParent = modifiedData.translationParent.id;
        // }

        if (modifiedData.unsubscribeForm) {
          modifiedData.unsubscribeForm = modifiedData.unsubscribeForm.id;
        }

        if (modifiedData.preferenceCenter) {
          modifiedData.preferenceCenter = modifiedData.preferenceCenter.id;
        }
        let response;
        if (ClonePath) {
          const { id, ...dataWithoutId } = modifiedData;
          response = await addEmail(dataWithoutId);
        } else {
          response = await updateEmail({ id, ...modifiedData }).unwrap();
        }

        if (!response?.error) {
          Success(
            `${t("caymland.core.update.step.success")}, ${t("caymland.message.type.successfully", {
              type: id && !ClonePath ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
            })}!`
          ).then((result) => {
            if (result.isConfirmed && params === "save") {
              navigate(`/emails/view/${response?.email?.id || response?.data.email?.id}`);
            } else {
              console.log(response);
              navigate(`/emails/edit/${response?.data?.email?.id || response?.email?.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
        setSubmitLoading(false);
        setSubmitBackLoading(false);
      } catch (e) {
        console.log(e);
        setSubmitLoading(false);
        setSubmitBackLoading(false);
        Error(t("caymland.message.error"));
      }
    }
  };
  const checkIfFormIsModified = () => {
    return JSON.stringify(modifiedData) !== JSON.stringify(originalData);
  };

  const handleCancel = () => {
    if (checkIfFormIsModified()) {
      Swal.fire({
        title: t("caymland.mailbox.message.delete.title"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "confirm-component swal-custom",
          cancelButton: "result-component swal-custom",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          navigate("/emails");
        } else {
          return;
        }
      });
    } else {
      navigate("/emails");
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const buttons = [
    {
      name: "builder",
      title: t("caymland.core.builder"),
      icon: <FaCube size={15} />,
      onClick: () => modifiedData?.name && modifiedData?.subject && setIsOpen(true),
      not_active: modifiedData?.name && modifiedData?.subject ? false : true,
    },
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleEditEmail("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleEditEmail(""),
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <HeaderSkeleton hasBuilderButton={true} />
      ) : (
        !isOpen && (
          <Header
            buttons={buttons}
            showAlert={showAlert}
            alertMessage={alertMessage}
            loading={isEditLoading}
            title={
              <TitleHeader
                name={t("caymland.core.type.email")}
                clone={ClonePath}
                title={modifiedData?.name}
                loading={isLoading}
                id={id}
              />
            }
          />
        )
      )}

      {isOpen ? (
        <Builder
          editorData={editorData}
          setModifiedData={setModifiedData}
          modifiedData={modifiedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggle={toggle}
          loading={loading}
          apply={handleEditEmail}
          isFetching={isFetching}
          title={
            <TitleHeader
              name={t("caymland.core.type.email")}
              clone={ClonePath}
              title={modifiedData?.name}
              loading={isLoading}
              id={id}
            />
          }
          // mergeTags={mergeTags}
        />
      ) : (
        <>
          <Wrapper
            childrenClassOne={"w-75 json-html-style"}
            childrenOne={
              isLoading ? (
                <DetailsSkeleton applyPadding />
              ) : (
                <>
                  <Nav className="nav-primary w-full flex" tabs>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 1 ? "active" : ""}
                        onClick={() => changeDynamicTab(1)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.core.filter.themes")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 2 ? "active" : ""}
                        onClick={() => changeDynamicTab(2)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.core.advanced")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 3 ? "active" : ""}
                        onClick={() => changeDynamicTab(3)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.email.custom.json")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={primarycolorTab === 4 ? "active" : ""}
                        onClick={() => changeDynamicTab(4)}
                        style={{
                          color: "#313131",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("caymland.email.custom.html")}
                      </NavLink>
                    </NavItem>
                    <div style={{ marginLeft: "auto", paddingRight: "10px", display: "flex" }}>
                      <div class="d-flex gap-10 items-center">
                        <p style={{ marginBottom: "0", marginTop: "4px" }}>{t("caymland.email.template")}</p>
                        <Checkbox
                          name="isTemplate"
                          alignSelf
                          marginBottom="mb-8px"
                          value={modifiedData?.isTemplate}
                          onChange={(e) =>
                            setModifiedData({
                              ...modifiedData,
                              isTemplate: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Nav>
                  <TabContent activeTab={primarycolorTab} style={{ display: "flex" }}>
                    <TabPane tabId={1} style={{ overflow: "auto" }}>
                      <div className="py-20 px-10">
                        <ThemeCards modifiedData={modifiedData} setModifiedData={setModifiedData} themes={themes} />
                      </div>
                    </TabPane>
                    <TabPane tabId={2} style={{ flex: "1" }}>
                      <div className="py-20 px-10">
                        <Advanced
                          modifiedData={modifiedData}
                          setModifiedData={setModifiedData}
                          setOriginalData={setOriginalData}
                          originalData={originalData}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId={3} style={{ flex: "1" }}>
                      <CustomJson modifiedData={modifiedData} setModifiedData={setModifiedData} />
                    </TabPane>
                    <TabPane tabId={4} style={{ flex: "1" }}>
                      <CustomHtml modifiedData={modifiedData} setModifiedData={setModifiedData} />
                    </TabPane>
                  </TabContent>
                </>
              )
            }
            childrenClassTwo={"wrapper-childTwo"}
            childrenTwo={
              isLoading ? (
                <SidebarSkeleton />
              ) : (
                <>
                  <Sidebar
                    originalData={originalData}
                    setOriginalData={setOriginalData}
                    modifiedData={modifiedData}
                    setModifiedData={setModifiedData}
                    preferenceCenterPages={retrievePreferencePage}
                    centerPageLoading={loading}
                    errors={errors}
                    setErrors={setErrors}
                    pagesOptions={pagesOptions}
                  />
                </>
              )
            }
          />
        </>
      )}
    </Fragment>
  );
}

export default EditEmail;
