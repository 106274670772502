import React from "react";

function Description({ data }) {
  const details = [{ label: data?.description || data?.content || data?.metaDescription }];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px">
          <span style={{fontSize: 10}} dangerouslySetInnerHTML={{__html: item.label}}></span>
        </div>
      ))}
    </div>
  );
}

export default Description;
