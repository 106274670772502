import React, { useState, useEffect, useRef, useMemo } from "react";
import { DragHandle } from "../../widgetGenerator/index";
import { Label } from "../../../../reusableComponents/Inputs";
import CustomTableSmallView from "../../../../reusableComponents/CustomTable/CustomTableSmallView";
import EditDeleteMenu from "../../EditDeleteMenu";

function NormalTable({ headItems, bodyItems, name, handleEdit, handleDelete, id, height }) {
  const transformedData = bodyItems.map((item) => ({
    title: item[0]?.value,
    contacts: item[1]?.value,
  }));

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: headItems[0],
        size: 150,
      },
      {
        accessorKey: "contacts",
        header: headItems[1],
        size: 150,
      },
    ],
    [headItems]
  );

  return (
    <>
      <div className="chart-header">
        <DragHandle />
        <div style={{ marginTop: "8px", marginLeft: "10px" }}>
          <Label name={name} label={name} />
        </div>
        <EditDeleteMenu handleEdit={() => handleEdit(id)} handleDelete={() => handleDelete(id)} />
      </div>
      <CustomTableSmallView data={transformedData} columns={columns} height={height} />
    </>
  );
}

export default NormalTable;
