import React, { useEffect, useState } from "react";
import { Select } from "../../../../reusableComponents/Inputs";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { formsApi, segmentsApi } from "../../../../../redux/api";
import { t } from "i18next";

export default function ({ control, reset, getValues, setValue, params, nodesData, ...props }) {
  const [forms] = formsApi.useLazyGetFormsQuery();
  const [segments] = segmentsApi.useLazyGetSegmentsQuery();

  useEffect(() => {
    const key = params.type;
    let n = params.id === "lists" ? 0 : 1;
    if (_.isEmpty(getValues()[key]) && props.action === "edit") {
      reset({
        ...params,
        [key]: nodesData?.nodes[n]?.data?.params[key],
      });
    }
  }, []);

  const retrieveList = (inputValue) =>
    new Promise(async (resolve, reject) => {
      const query = {
        where: [],
        orderBy: "id",
        orderByDir: "DESC",
        limit: 10,
      };

      if (inputValue.length) {
        query.search = inputValue;
      }

      let response;
      switch (params.type) {
        case "forms":
          query.where[0] = {
            col: "formType",
            expr: "eq",
            val: "campaign",
          };
          response = await forms(query);

          break;
        case "lists":
          query.where[0] = {
            col: "isPublished",
            expr: "eq",
            val: "1",
          };
          response = await segments(query);
          break;
      }

      if (!response?.error) {
        resolve(response.data?.data);
      }
    });

  return (
    <Controller
      control={control}
      name={params.type}
      render={({ field: { name, value, onChange } }) => (
        <Select
          name={name}
          label={params?.type === "forms" ? t("caymland.form.type.campaign") : t("caymland.campaign.leadsource.lists")}
          options={retrieveList}
          value={value}
          onChange={(data) => {
            onChange(data);
            setValue(name, data);
          }}
          labelField="name"
          valueField="id"
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          autoFocus={true}
        />
      )}
    />
  );
}
