import React from "react";
import { countryData, localeData, groupedOptions } from "../../../reusableComponents/data";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { Select, Text, DatePicker, TimePicker, ButtonGroup, TextArea } from "../../../reusableComponents/Inputs";
import { t } from "i18next";

const DefaultValue = ({
  modifiedData,
  setModifiedData,
  toggleButtons,
  boolCheck,
  setBoolCheck,
  noValue,
  yesValue,
  selectKey,
  lookUpList,
  optionsList,
}) => {
  switch (modifiedData.type) {
    case "text":
    case "email":
    case "number":
    case "tel":
    case "url":
      return (
        <Text
          type={modifiedData.type}
          value={modifiedData.defaultValue}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.target.value,
              });
            }
          }}
        />
      );
    case "boolean":
      return (
        <ButtonGroup
          buttons={toggleButtons}
          value={boolCheck}
          onChange={(event, newValue) => {
            let numericValue = parseInt(event.target.value);

            // if (newValue !== null) {
            setBoolCheck(isNaN(numericValue) ? null : numericValue);
            setModifiedData((prevData) => ({
              ...prevData,
              defaultValue: event.target.value,
              properties: {
                no: noValue || "No",
                yes: yesValue || "Yes",
              },
            }));
            // } else {
            // setBoolCheck(null);
            // }
          }}
          margin={false}
        />
      );
    case "datetime":
    case "date":
      return (
        <DatePicker
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              const dateFormat = modifiedData.type === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
              setModifiedData({
                ...modifiedData,
                defaultValue: moment(e).format(dateFormat),
              });
            }
          }}
          showTimeSelect={modifiedData.type === "datetime"}
          showYearDropdown
          showMonthDropdown
          value={modifiedData.defaultValue || null}
        />
      );

    case "country":
      return (
        <Select
          name="validationDefault04"
          isSearchable
          options={countryData}
          placeholder={t("caymland.core.form.chooseone")}
          value={countryData.find((c) => c?.value === modifiedData?.defaultValue)}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.value,
              });
            }
          }}
        />
      );
    case "locale":
      return (
        <Select
          name="validationDefault04"
          isSearchable
          options={localeData}
          placeholder={t("caymland.core.form.chooseone")}
          value={localeData.find((c) => c?.value === modifiedData?.defaultValue)}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.value,
              });
            }
          }}
        />
      );
    case "lookup":
      return (
        <Select
          key={selectKey}
          name="validationDefault04"
          getOptionLabel={(e) => e.value}
          isSearchable
          options={lookUpList.filter((x) => x.value.length > 0)}
          labelField="value"
          valueField="value"
          value={lookUpList.find((v) => v.value === modifiedData.defaultValue)}
          placeholder={t("caymland.core.form.chooseone")}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.value,
              });
            }
          }}
        />
      );
    case "region":
      return (
        <Select
          name="validationDefault010"
          isSearchable
          options={groupedOptions}
          value={groupedOptions
            .flatMap((group) => group.options)
            .find((option) => option.value === modifiedData.defaultValue)}
          placeholder={t("caymland.core.form.chooseone")}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.value,
              });
            }
          }}
        />
      );
    case "select":
    case "multiselect":
      return (
        <Select
          name="validationDefault12"
          isSearchable
          key={JSON.stringify(optionsList)}
          options={optionsList.filter((x) => x.label.length > 0)}
          value={optionsList.find((o) => o.value === modifiedData.defaultValue)}
          placeholder={t("caymland.core.form.chooseone")}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.value,
              });
            }
          }}
        />
      );
    case "textarea":
      return (
        <TextArea
          name={"textarea"}
          value={modifiedData.defaultValue}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e,
              });
            }
          }}
        />
      );
    case "time":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            value={modifiedData.defaultValue}
            onChange={(e) => {
              if (e === null) {
                setModifiedData({
                  ...modifiedData,
                  defaultValue: null,
                });
              } else {
                setModifiedData({
                  ...modifiedData,
                  defaultValue: e,
                });
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      );
    case "timezone":
      return (
        <Select
          name="default-value"
          isSearchable
          options={optionsList.filter((x) => x.label.length > 0)}
          placeholder={t("caymland.core.form.chooseone")}
          onChange={(e) => {
            if (e === null) {
              setModifiedData({
                ...modifiedData,
                defaultValue: null,
              });
            } else {
              setModifiedData({
                ...modifiedData,
                defaultValue: e.value,
              });
            }
          }}
        />
      );
    default:
      return <div>{t("caymland.core.unsupported.type")}</div>;
  }
};

export default DefaultValue;
