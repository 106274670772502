import React, { useEffect, useState, useLayoutEffect } from "react";
import "./App.css";
import "react-dropzone-uploader/dist/styles.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { customRoutes } from "./utils/CustomRoutes";
import { RoutesComponent } from "./utils/renderRoutes";
import { useSettingsQuery } from "../redux/api/settingsApi";
import i18n from "i18next";

const DEFAULT_LOCALE = "de";

const AppLayout = () => {
  const token = useSelector((state) => state.auth.token);
  const { pathname } = useLocation();
  const { data, isLoading } = useSettingsQuery(localStorage.getItem("user") && undefined);

  const [translationsReady, setTranslationsReady] = useState(false);
  const translations = useSelector((state) => state?.settings?.data?.translations);
  const locale = useSelector((state) => state?.auth?.user?.locale || DEFAULT_LOCALE);

  useEffect(() => {
    if (token) {
      if (!translations || Object.keys(translations).length === 0) {
        return;
      }
    }
    if (translations) {
      const resources = Object.keys(translations).reduce((acc, lang) => {
        acc[lang] = { translation: translations[lang] };
        return acc;
      }, {});

      Object.keys(resources).forEach((lang) => {
        if (!i18n.hasResourceBundle(lang, "translation")) {
          i18n.addResourceBundle(lang, "translation", resources[lang].translation, true, true);
        } else {
          i18n.addResourceBundle(lang, "translation", resources[lang].translation, true, true);
        }
      });
    }

    i18n.changeLanguage(locale).then(() => {
      setTranslationsReady(true);
    });
  }, [translations, locale, token]);

  useLayoutEffect(() => {
    if (!localStorage.getItem("fromDate") || !localStorage.getItem("toDate")) {
      localStorage.setItem("fromDate", new Date());
      localStorage.setItem("toDate", new Date());
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("layout_version", "light-only");
    localStorage.setItem("primary_color", "#135f95");
    localStorage.setItem("secondary_color", "#00b1ea");
    localStorage.setItem("color", "");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (!token) {
      sessionStorage.clear();
    }
  }, [token]);

  if (isLoading || !translationsReady) {
    return <></>;
  }

  return <RoutesComponent mainRoutes={customRoutes} token={token} />;
};

export default AppLayout;
