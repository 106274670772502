import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import ModalForCampaigns from "./ModalForCampaigns";
import { SlideAlert } from "../Alerts";
import Button from "./Button";
import { useManageCampaignsToContactsMutation } from "../../../redux/api/contactsApi";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import { Tooltip } from "@mui/material";
import { t } from "i18next";

export default function Campaigns({ firstName, lastName, noText, updateCampaigns, campaignsFields }) {
  const [show, setShow] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const addedRemovedContactFromCampaign = useRef(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [manageCampaigns] = useManageCampaignsToContactsMutation();

  const handleClose = () => {
    setShow(false);
    if (addedRemovedContactFromCampaign.current > 0) {
      setFetchData(!fetchData);
      addedRemovedContactFromCampaign.current = 0;
    }
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    setCampaigns(campaignsFields);
  }, [campaignsFields]);

  // const updateCampaign = () => {
  //   setAlertMessage(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
  //   setShowAlert(true);
  //   setTimeout(() => {
  //     setShowAlert(false);
  //   }, 3000);
  // };

  //ADD/REMOVE
  const addContactToCampaign = async (contactID, campaignID) => {
    addedRemovedContactFromCampaign.current += 1;
    try {
      await manageCampaigns({ contactId: contactID, campaignId: campaignID, action: "add" });

      const campaignIndex = campaignsFields.findIndex((campaign) => campaign.id === campaignID);
      if (campaignIndex !== -1) {
        updateCampaigns(campaignIndex, { ...campaignsFields[campaignIndex], contactIncluded: true });
        setAlertMessage(
          `${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`
        );
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const removeContactFromCampaign = async (contactID, campaignID) => {
    addedRemovedContactFromCampaign.current -= 1;
    try {
      await manageCampaigns({ contactId: contactID, campaignId: campaignID, action: "remove" });

      const campaignIndex = campaignsFields.findIndex((campaign) => campaign.id === campaignID);
      if (campaignIndex !== -1) {
        updateCampaigns(campaignIndex, { ...campaignsFields[campaignIndex], contactIncluded: false });
      }
      if (campaignIndex !== -1) {
        updateCampaigns(campaignIndex, { ...campaignsFields[campaignIndex], contactIncluded: true });
        setAlertMessage(
          `${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`
        );
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      <ModalForCampaigns
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        firstName={firstName}
        lastName={lastName}
        campaigns={campaigns}
        addContactToCampaign={addContactToCampaign}
        removeContactFromCampaign={removeContactFromCampaign}
      />
      <Button
        buttons={[
          {
            name: !noText && "Campaigns",
            title: !noText && "Campaigns",
            icon: (
              <Tooltip title={t("caymland.campaign.campaigns")} arrow>
                <DisplaySettingsOutlinedIcon size={15} />
              </Tooltip>
            ),
            onClick: () => handleShow(),
          },
        ]}
      />
    </>
  );
}
