import React from "react";
import { Text, TextArea } from "../../../../reusableComponents/Inputs";
import { t } from "i18next";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";

function Details({ errors, setErrors, modifiedData, setModifiedData }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }
    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col mt-20">
      <Text
        name={"name"}
        value={modifiedData?.name}
        onChange={(e) => handleInputChange(e)}
        label={t("caymland.sms.form.internal.name")}
        errors={errors?.name}
        required={true}
        type="text"
      />
      <TextArea
        label={t("caymland.core.dynamicContent.alt_content")}
        value={modifiedData?.content ?? ""}
        showSmallEditor={false}
        inserttoken={true}
        onChange={(data) => {
          setModifiedData((prev) => ({
            ...prev,
            content: data,
          }));
        }}
      />
    </div>
  );
}

export default Details;
