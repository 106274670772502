import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Cancel from "../../reusableComponents/Buttons/cancel";
import SaveAndClose from "../../reusableComponents/Buttons/saveAndClose";
import Apply from "../../reusableComponents/Buttons/apply";
import { ColorPicker } from "./../../reusableComponents/colorPicker/ColorPicker";
import { Error, Success } from "../Alerts";
import Select from "react-select";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { Text, TextArea, Select as ReusableSelect, Checkbox } from "../Inputs";
import {
  useAddCategoryMutation,
  useGetParentCategoriesQuery,
  useUpdateCategoryMutation,
} from "../../../redux/api/categoriesApi";
import { t } from "i18next";

function NewCategoryPopUp({ show, setShow, bundle, setModifiedData, setCategories }) {
  const handleClose = (e) => {
    clearForm();
    setShow(false);
  };

  const handleCloseButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
  };

  const [currentColor, setCurrentColor] = useState("#aabbcc");
  const [validated, setValidated] = useState(null);
  const [published, setPublished] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [parentCategories, setParentCategories] = useState([]);
  const [showParentCategory, setShowParentCategory] = useState(bundle === "point");
  const [showfileAsset, setShowFileAsset] = useState(bundle === "asset");
  const [alias, setAlias] = useState("");
  const [tempName, setTempName] = useState("");
  const [submitBackLoading, setSubmitBackLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);

  const [addCategory] = useAddCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();
  const { data } = useGetParentCategoriesQuery({ skip: bundle !== "point" });
  useEffect(() => {
    if (data) {
      setParentCategories(data);
    }
  }, [data]);

  const clearForm = () => {
    setCurrentColor("#aabbcc");
    setValidated(null);
    setPublished(false);
    setCurrentId(null);
    setAlias("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (formData.get("title") !== "") {
      setValidated(true);
    } else {
      setValidated(false);
      return;
    }

    let aliasValue = formData.get("alias");
    if (!aliasValue) {
      aliasValue = formData.get("title").toLowerCase().replace(/ /g, "-");
      setAlias(aliasValue);
    }
    let newCategory;
    if (bundle === "point") {
      newCategory = {
        title: formData.get("title"),
        isPublished: published,
        alias: alias,
        description: formData.get("description"),
        color: currentColor,
        bundle: bundle,
        parentId: parseInt(formData.get("parentCategory")),
      };
    } else if (bundle === "asset") {
      newCategory = {
        title: formData.get("title"),
        isPublished: published,
        alias: alias,
        description: formData.get("description"),
        color: currentColor,
        bundle: bundle,
        tempName: tempName.name,
        tempId: "tmp_" + tempName.id,
      };
    } else {
      newCategory = {
        title: formData.get("title"),
        isPublished: published,
        alias: alias,
        description: formData.get("description"),
        color: currentColor,
        bundle: bundle,
      };
    }

    try {
      let response;
      if (!currentId) {
        setApplyLoading(true);
        response = await addCategory(newCategory);
        if (response.status === 200 || response.status === 201) {
          setCurrentId(response?.data?.category?.id);
          if (bundle === "point") {
            setShowParentCategory(true);
            setShow(false);
          }
        }
      } else {
        setSubmitBackLoading(true);
        response = await updateCategory({ id: currentId, ...newCategory });
        setShow(false);
      }

      if (response.status === 200 || response.status === 201) {
        setModifiedData((prev) => ({
          ...prev,
          category: response?.data?.category,
        }));
        setCategories((prevCategories) => {
          const updatedCategories = [...prevCategories];
          const categoryIndex = updatedCategories.findIndex((cat) => cat.id === currentId);
          if (categoryIndex > -1) {
            updatedCategories[categoryIndex] = response?.data?.category;
          } else {
            updatedCategories.splice(updatedCategories.length - 1, 0, response?.data?.category);
          }
          return updatedCategories;
        });
        Success(`${response?.data?.category?.title} has been ${!currentId ? "created" : "updated"}!`);
        if (currentId) {
          setShow(false);
          clearForm();
          setCurrentId(null);
          setShowParentCategory(false);
          setValidated(null);
        }
      }
      setShow(false);
      setApplyLoading(false);
      setSubmitBackLoading(false);
    } catch {
      Error(t("caymland.message.error"));
    }
  };

  const getUploadParams = async ({ file, meta }) => {
    const body = new FormData();

    return {
      url: `${process.env.REACT_APP_DEV_URL}/files/assets/new`,
      method: "POST",
      body,
      headers: { Authorization: "Basic Q2F5bWxhbmQ6VWx0cmFzb2Z0" },
    };
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      setTempName({ name: meta.name, id: meta.id });
    }
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t("caymland.category.header.new")}</Modal.Title>
        </Modal.Header>

        <Form className="NewCategoryPopUp" onSubmit={handleSubmit}>
          <Modal.Body>
            {showParentCategory && (
              <div className="w-full pb-20">
                <div style={{ marginBottom: "15px" }}>
                  <p className="pb-0 mb-0">{t("caymland.core.parent.category")}</p>
                  <Select
                    id="parentCategory"
                    name="parentCategory"
                    isSearchable
                    isClearable
                    getOptionValue={(e) => e.id ?? e.value}
                    getOptionLabel={(e) => e.title}
                    class="jump-input"
                    options={parentCategories}
                    placeholder={t("caymland.query.builder.select.placeholder")}
                    // onChange={handleSelectChange}
                  />
                </div>
              </div>
            )}
            <Form.Group className={`mb-3 ${validated === false && "error-input-category"}`} controlId="title">
              <Form.Label className="required">{t("caymland.core.title")}</Form.Label>
              <Form.Control name="title" type="text" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>{t("caymland.core.description")}</Form.Label>
              <Form.Control name="description" type="text" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="alias">
              <Form.Label>{t("caymland.core.alias")}</Form.Label>
              <Form.Control name="alias" type="text" value={alias} onChange={(e) => setAlias(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="color">
              <Form.Label>{t("caymland.core.color")}</Form.Label>
              <ColorPicker color={currentColor} onChange={setCurrentColor} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="published">
              <div class="switch-holder pb-10">
                <Checkbox
                  name="isPublished2"
                  label="Published"
                  value={published}
                  onChange={() => setPublished(!published)}
                />
              </div>
            </Form.Group>
            {showfileAsset && (
              <Form.Group className="mb-3" controlId="file">
                <Form.Label>{t("caymland.asset.asset.form.file.upload", { max: "1" })}</Form.Label>
                <div className="p-no-margin col-md-6">
                  <div className="pb-20 p-no-margin mt-20px">
                    <form className="dropzone digits mt-10px" id="singleFileUpload" action="/upload.php">
                      <div className="dz-message needsclick h-200-dropzone">
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent={t("caymland.core.drop.picture")}
                          styles={{
                            dropzoneActive: { borderColor: "green" },
                          }}
                          name="file"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div onClick={handleCloseButtonClick}>
              <Cancel />
            </div>
            <div>
              <SaveAndClose loading={submitBackLoading} color="#686868" />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default NewCategoryPopUp;
