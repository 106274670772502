import React from "react";
import Moment from "moment";
import { t } from "i18next";

function Details({ focus }) {
  const details = [
    {
      label: t("caymland.page.tracking.form.category"),
      value: focus?.category ? focus?.category.name : "Uncategorized",
    },
    { label: t("caymland.showare.field.created_by"), value: focus?.createdByUser },
    {
      label: t("caymland.core.created"),
      value: Moment(focus?.dateAdded).format("DD.MM.YY hh:mm"),
    },
    { label: t("caymland.core.modifiedby"), value: focus?.modifiedByUser },
    {
      label: t("caymland.core.modified"),
      value: Moment(focus?.dateModified).format("DD.MM.YY hh:mm"),
    },
    {
      label: t("caymland.dynamicContent.publish.up"),
      value: focus?.publishUp ? Moment(focus?.publishUp).format("DD.MM.YY hh:mm") : "",
    },
    {
      label: t("caymland.core.permissions.publishown"),
      value: focus?.publishDown ? Moment(focus?.publishDown).format("DD.MM.YY hh:mm") : "Never",
    },
    { label: t("caymland.core.id"), value: focus?.id },
    // {
    //   label: "Status",
    //   value: focus?.isPublished ? "Published" : "Unpublished",
    // },
  ];
  return (
    <>
      <div className="details-container-accordion">
        {details.map((item, index) => (
          <div key={index} className="details-item gap-5px">
            <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default Details;
