import moment from "moment";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../../reusableComponents/loading";
import { FiClock } from "react-icons/fi";
import { ClipLoader } from "react-spinners";
import { t } from "i18next";

function Translations({ extras }) {
  const { id } = useParams();
  const [localLoading, setLocalLoading] = useState(-1);

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    let obj = data;
    obj.isPublished = !data.isPublished;

    if (obj.translationParent) {
      obj.translationParent = data.translationParent.id;
    }

    if (obj.translationChildren) {
      obj.translationChildren = data.translationChildren.map((x) => x.id);
    }

    if (obj.unsubscribeForm) {
      obj.unsubscribeForm = data.unsubscribeForm.id;
    }

    if (obj.lists) {
      obj.lists = data.lists.map((x) => x.id);
    }

    setLocalLoading(-1);
  };

  return (
    <div className="p-30">
      {extras?.translations?.parent && (
        <div>
          <div
            className="flex items-center justify-between"
            style={{ padding: "15px", border: "1px solid #f0f0f0", background: "#fbfbfb" }}
          >
            <div className="flex items-center gap-5px">
              {moment(extras?.translations?.parent?.publishUp).format("DD.MM.YYYY HH:mm") <
                moment(new Date()).format("DD.MM.YYYY HH:mm") && extras?.translations?.parent?.isPublished ? (
                <FiClock
                  title={t("caymland.core.form.expired")}
                  color="#f86b4f"
                  size={20}
                  style={{ marginRight: "25px" }}
                />
              ) : localLoading === extras?.translations?.parent?.id ? (
                <ClipLoader size={14} />
              ) : (
                <label class="switch-published" style={{ marginBottom: "0", marginRight: "25px" }}>
                  <input
                    type="checkbox"
                    checked={extras?.translations?.parent?.isPublished}
                    onChange={() => handleTogglePublished(extras?.translations?.parent)}
                  />
                  <span class="slider-published round-published"></span>
                </label>
              )}
              <Link to={`/dwc/view/${extras?.translations?.parent?.id}`} style={{ color: "#1a1a1a" }}>
                {extras?.translations?.parent?.name}
              </Link>
              <button
                className="info-box-button"
                style={{
                  backgroundColor: "#ffbb33",
                }}
              >
                {t("caymland.core.parent")}
              </button>
              {extras?.translations?.parent?.id == id && (
                <button
                  className="info-box-button"
                  style={{
                    backgroundColor: "#04B76B",
                  }}
                >
                  {t("caymland.core.current")}
                </button>
              )}
            </div>
            <em style={{ color: "#b8b8b8" }}>{extras?.translations?.parent?.language}</em>
          </div>
        </div>
      )}

      {extras?.translations?.children ? (
        <div>
          {Object.entries(extras?.translations?.children).map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between mt-10"
              style={{ padding: "15px", border: "1px solid #f0f0f0", background: "#fbfbfb" }}
            >
              <div className="flex items-center gap-5px">
                {moment(item[1]?.publishUp).format("DD.MM.YYYY HH:mm") <
                  moment(new Date()).format("DD.MM.YYYY HH:mm") && item[1]?.isPublished ? (
                  <FiClock
                    title={t("caymland.core.form.expired")}
                    color="#f86b4f"
                    size={20}
                    style={{ marginRight: "25px" }}
                  />
                ) : localLoading === item[1].id ? (
                  <ClipLoader size={14} />
                ) : (
                  <label class="switch-published" style={{ marginBottom: "0", marginRight: "25px" }}>
                    <input
                      type="checkbox"
                      checked={item[1].isPublished}
                      onChange={() => handleTogglePublished(item[1])}
                    />
                    <span class="slider-published round-published"></span>
                  </label>
                )}
                <Link to={`/dwc/view/${item[1]?.id}`} style={{ color: "#1a1a1a" }}>
                  {item[1]?.name}
                </Link>

                {item[1]?.id == id && (
                  <button
                    className="info-box-button"
                    style={{
                      backgroundColor: "#04B76B",
                    }}
                  >
                    {t("caymland.core.current")}
                  </button>
                )}
              </div>
              <em style={{ color: "#b8b8b8" }}>{item[1]?.language}</em>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default Translations;
