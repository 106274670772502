import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import Loading from "../../../../reusableComponents/loading";
import DropZoneMask from "../../../../reusableComponents/DropZone/DropZoneMask";
import CustomModal from "../../../../reusableComponents/CustomModal/CustomModal";
import { ContentGenerator } from "../helpers/contentGenerator";
import { PiDotsSixBold } from "react-icons/pi";
import { useParams } from "react-router-dom";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { triggersApi, useGetTriggersEventsQuery } from "../../../../../redux/api/triggersApi";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { t } from "i18next";

const Events = ({ loading, setModifiedData, modifiedData, events }) => {
  const [showZoneMask, setShowZoneMask] = useState(false);
  const [eventsData, setEventsData] = useState(null);
  const [eventsFields, setEventsFields] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [value, setValue] = useState({
    type: null,
  });
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "",
    loading: false,
    editData: null,
  });
  const [trigger] = triggersApi.useLazyGetTriggersEventsQuery();

  const closeModal = () => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
      editData: null,
      title: "",
      type: "",
    }));
    setEventsData(eventsData);
    setEditMode(false);
  };

  const handleEventsInfo = (value, label) => {
    return {
      value,
      label,
    };
  };

  const onDragStart = (event, nodeType) => {
    setShowZoneMask(true);
    event.dataTransfer.setData("application/reactflow", JSON.stringify(nodeType));
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragStop = () => {
    setShowZoneMask(false);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(async (event) => {
    event.preventDefault();
    const events = JSON.parse(event.dataTransfer.getData("application/reactflow"));
    setValue((prev) => ({
      type: events.value,
    }));
    setModalOptions((prev) => ({
      ...prev,
      isOpen: true,
      title: events.label,
      type: events.value,
      loading: true,
    }));
    setModalOptions((prev) => ({
      ...prev,
      isOpen: true,
      title: events.label,
      type: events.value,
      loading: true,
    }));

    const response = await trigger({ type: events.value });
    console.log(response);
    let data = response.data.modalData.children;
    console.log(data);
    let filterData = Object.entries(data).map((x) => ({
      label: x[0],
      value: x[1],
    }));

    let finalData = filterData.filter((x) => x.label !== "type" && x.label !== "buttons");
    let properties = finalData.filter((x) => x.label === "properties");

    setEventsFields(finalData);

    setEventsData(
      properties
        ? {
            properties: {},
          }
        : null
    );
    setModalOptions((prev) => ({
      ...prev,
      loading: false,
    }));

    setShowZoneMask(false);
  }, []);

  const handleSubmit = () => {
    if (editMode) {
      setModifiedData((prev) => {
        let updateEvent = prev.events.map((e) => {
          if (e.id === eventsData.id) {
            return eventsData;
          } else {
            return e;
          }
        });
        return { ...prev, events: updateEvent };
      });
    } else {
      // eventsData.id = Math.floor(Math.random() * new Date() + 1)
      eventsData.type = modalOptions.type;
      setModifiedData((prev) => ({
        ...prev,
        events: [...prev.events, eventsData ? eventsData : null],
      }));
    }
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setEventsFields([]);
    setEventsData(null);
    setEventsData({});
    setEditMode(false);
  };

  const handleDelete = (id) => {
    setModifiedData((prev) => ({
      ...prev,
      events: prev.events.filter((event) => event.id !== id),
    }));
  };

  const [currentId, setCurrentId] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setCurrentId(id);
  }, []);

  const handleEdit = async (item) => {
    console.log(item);
    // const events = JSON.parse(item.dataTransfer.getData('application/reactflow'))
    const response = await trigger({ type: item.type });
    setEditMode(true);
    let data = response.data.modalData.children;
    let filterData = Object.entries(data).map((x) => ({
      label: x[0],
      value: x[1],
    }));

    let finalData = filterData.filter((x) => x.label !== "type" && x.label !== "buttons");
    let properties = finalData.filter((x) => x.label === "properties");

    setEventsFields(finalData);
    setEventsData(item);

    setModalOptions((prev) => ({
      ...prev,
      loading: false,
      title: item.name,
      type: item.type,
      isOpen: true,
      description: item.description,
    }));
  };

  return (
    <Fragment>
      <CustomModal
        modalOptions={modalOptions}
        handleSubmit={handleSubmit}
        close={closeModal}
        saveText="Add"
        content={
          <ContentGenerator
            data={eventsFields}
            setEntity={setEventsData}
            entity={eventsData}
            modalOptions={modalOptions}
          />
        }
      />

      {loading ? (
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="flex items-start justfiy-between gap-10">
          <div style={{ width: "25%" }}>
            <div
              className="accordion-list"
              style={{
                maxHeight: "600px",
                position: "relative",
                overflowY: "scroll",
              }}
            >
              {Object.entries(events).map(([key, value]) => (
                <>
                  {Object.entries(value).map(([subKey, subValue]) => (
                    <>
                      <p className="fw-bold" style={{ backgroundColor: "#135f95", padding: "10px", color: "white" }}>
                        {subKey}
                      </p>
                      {Object.entries(subValue).map(([subSubKey, subSubValue]) => (
                        <div
                          onDragStart={(event) => onDragStart(event, handleEventsInfo(subSubKey, subSubValue))}
                          onDragEnd={(e) => onDragStop(e)}
                          onDrop={(e) => onDrop(subSubKey)}
                          key={subSubKey}
                          className="accordion-list-item d-flex"
                          draggable
                          style={{ alignItems: "center" }}
                        >
                          <p style={{ width: "90%", margin: "0" }}>{subSubValue}</p>
                          <IconButton>
                            <DragHandleIcon style={{ color: "#757575", cursor: "grab" }} />
                          </IconButton>
                        </div>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
          <div
            style={{ minHeight: "600px" }}
            className="flex-1 h-full relative"
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => onDragOver(e)}
          >
            {showZoneMask && <DropZoneMask />}
            {modifiedData?.events?.map((event, i) => (
              <div
                key={i}
                className="actions-cards"
                onMouseEnter={() => setHoveredItem(i)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className={hoveredItem === i ? ` action-cards-card` : ""} onDoubleClick={() => handleEdit(event)}>
                  <div className="grid" style={{ gap: "10px" }}>
                    <strong>{event.name}</strong>
                    <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
                  </div>
                  {hoveredItem === i && (
                    <div className="d-flex items-center gap-5px ">
                      <div className="action-cards-button">
                        <FaEdit
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleEdit(event);
                          }}
                          color="#135f95"
                        />
                      </div>
                      <div className="action-cards-button">
                        <FaTrashAlt
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete(event.id);
                          }}
                          color="#f86b4f"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Events;
