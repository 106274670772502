import React from "react";
import QueryFiltersReusible from "../../../../reusableComponents/ReusableQueryFilters/QueryFiltersReusible";

const DynamicContentFilters = ({ query, setQuery, loading, fields }) => {
  return (
    <>
      <QueryFiltersReusible query={query} setQuery={setQuery} loading={loading} fields={fields} groupButton={false} />
    </>
  );
};

export default DynamicContentFilters;
