import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaAsterisk, FaGlobeEurope, FaEyeSlash } from "react-icons/fa";
import { GrKey } from "react-icons/gr";
import { FiFilter } from "react-icons/fi";
import { HiOutlineLockClosed } from "react-icons/hi";
import { BiAddToQueue } from "react-icons/bi";
import {
  useDeleteFieldsMutation,
  useGetFieldsListQuery,
  useReorderFieldsMutation,
  useUpdateFieldsMutation,
} from "../../../redux/api/fieldsApi";
import Header from "../../reusableComponents/Header/Header";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { MRT_CustomHeaderRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomHeaderRowsAction";
import { MRT_CustomRowsAction } from "../../reusableComponents/CustomTable/plugins/MRT_CustomRowsAction";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { t } from "i18next";

function CustomFields(props) {
  const navigate = useNavigate();
  const [fieldsType, setFieldsType] = useState("contact");
  const [order, setOrder] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const name = "CustomFields";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "ASC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "order",
    name: name,
    where: [],
  });

  const { data, isFetching, refetch } = useGetFieldsListQuery({ query, fieldsType });
  const [updateField] = useUpdateFieldsMutation();
  const [deleteField] = useDeleteFieldsMutation();
  const [reorderFields] = useReorderFieldsMutation();

  useEffect(() => {
    if (data) {
      const newOrder = Array.isArray(data.fields) ? data.fields.map((item) => item.id) : [];
      setDataTable(data);
      setOrder(newOrder);
    }
  }, [data]);

  const handleTogglePublished = async (data) => {
    const id = data?.id;
    const updatedData = { ...data, isPublished: !data.isPublished };
    if (updatedData.category && updatedData.category.id) {
      updatedData.category = updatedData.category.id;
    }
    await updateField({ object: fieldsType, objectId: id, field: { isPublished: !data.isPublished } });
    await refetch();
  };

  const handleReorder = async (newOrder) => {
    let newFields = newOrder.map((id) => dataTable.fields.find((item) => item.id === id));
    setDataTable({
      ...dataTable,
      fields: newFields,
    });

    const payload = {
      field: newOrder,
      page: query.pageIndex,
      limit: query.pageSize,
    };

    try {
      await reorderFields({ type: fieldsType, fieldOrder: payload }).unwrap();
    } catch (error) {}
  };

  const columns = useMemo(() => [
    {
      accessorKey: "label",
      accessorFn: (row) => row.label,
      header: t("caymland.form.field.form.label"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent data={cell.row.original} link="contacts/fields" handleTogglePublished={handleTogglePublished} />
      ),
    },
    {
      accessorKey: "alias",
      accessorFn: (row) => row.alias,
      header: t("caymland.core.alias"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.alias,
    },
    {
      accessorKey: "group",
      accessorFn: (row) => row.group,
      header: t("caymland.lead.field.group"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.group,
    },
    {
      accessorKey: "type",
      accessorFn: (row) => row.type,
      header: t("caymland.lead.field.type"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => cell.row.original.type,
    },
    {
      accessorKey: "settings",
      accessorFn: (row) => row.type,
      header: t("caymland.core.settings"),
      size: "unset",
      enableColumnActions: false,
      Cell: ({ cell }) => <SettingsComponent data={cell.row.original} />,
    },
  ]);

  const SettingsComponent = (props) => {
    return (
      <div style={{ paddingLeft: "10px" }}>
        {props.data.isRequired ? <FaAsterisk title={t("caymland.lead.field.tooltip.required")} /> : <></>}
        {props.data.isPubliclyUpdatable ? <FaGlobeEurope title={t("caymland.lead.field.tooltip.public")} /> : <></>}
        {props.data.isUniqueIdentifier ? <GrKey title={t("caymland.lead.field.tooltip.isuniqueidentifer")} /> : <></>}
        {!props.data.isVisible ? <FaEyeSlash title={t("caymland.lead.field.tooltip.invisible")} /> : <></>}
        {props.data.isListable ? (
          <FiFilter style={{ width: "13px", height: "13px" }} title={t("caymland.lead.field.tooltip.listable")} />
        ) : (
          <></>
        )}
        {props.data.isFixed ? (
          <HiOutlineLockClosed
            style={{ width: "13.63px", height: "13.63px" }}
            title={t("caymland.lead.field.tooltip.fixed")}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const buttons = [
    {
      name: "addNew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={15} />,
      onClick: () => navigate("/contacts/fields/new"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "contacts/fields",
    deleteItem: deleteField,
    refetch: refetch,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "fields/contact",
    refetch: refetch,
  });

  const FieldTypeSwitcher = ({ fieldsType, setFieldsType }) => {
    const icon = fieldsType === "contact" ? <BusinessOutlinedIcon /> : <PeopleAltOutlinedIcon />;

    return (
      <Tooltip arrow title={fieldsType === "contact" ? "Company" : "Contact"}>
        <IconButton onClick={() => setFieldsType((current) => (current === "contact" ? "company" : "contact"))}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  };

  const toolbarButtons = useMemo(
    () => [
      {
        name: "type_button",
        component: FieldTypeSwitcher,
        props: {
          fieldsType,
          setFieldsType,
        },
      },
    ],
    [fieldsType]
  );

  return (
    <Fragment>
      <Header
        buttons={buttons}
        title={`${t("caymland.lead.field.header.index")} - ${
          fieldsType === "company"
            ? t("caymland.company.field.header.company")
            : t("caymland.lead.field.header.contact")
        } `}
        loading={isFetching}
      />
      <CustomTable
        key={dataTable}
        data={dataTable}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        fieldsType={fieldsType}
        isFetching={isFetching}
        toolbarButtons={toolbarButtons}
        linkNavigate="edit"
        sort={false}
        handleReorder={handleReorder}
        rowDragging={true}
        order={order}
        setOrder={setOrder}
      />
    </Fragment>
  );
}

export default CustomFields;
