import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { Checkbox, Text } from "../../../reusableComponents/Inputs";
import ColorPicker from "../../../reusableComponents/Inputs/ColorPicker";

const SidebarForm = ({ control, watch, errors }) => {
  const colorPickerRef = useRef();
  const colorPickerRef2 = useRef();
  return (
    <div>
      <div className="w-full" style={{ marginTop: "20px" }}>
        <Controller
          control={control}
          name="name"
          render={({ field: { name, onChange, value } }) => (
            <Text
              name={name}
              value={value}
              onChange={onChange}
              label="Name"
              required={true}
              errors={errors && errors[name]}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="qrcode"
        render={({ field: { name, onChange, value } }) => (
          <Checkbox name={name} value={value} onChange={onChange} label={"QrCode"} />
        )}
      />
      {watch("qrcode") && (
        <>
          <Controller
            control={control}
            name="qrcode_url"
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                value={value}
                onChange={onChange}
                label="QrCode Redirect URL"
                required={false}
                // errors={errors && errors[name]}
              />
            )}
          />
          <Controller
            control={control}
            name="size"
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                value={value}
                onChange={onChange}
                label="QR code size"
                required={false}
                // errors={errors && errors[name]}
              />
            )}
          />
          <Controller
            control={control}
            name="margin"
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                value={value}
                onChange={onChange}
                label="QR code margin"
                required={false}
                // errors={errors && errors[name]}
              />
            )}
          />
          <div className="w-full" ref={colorPickerRef}>
            <Controller
              control={control}
              name="fgcolor"
              render={({ field: { name, onChange, value } }) => (
                <ColorPicker
                  type="text"
                  name={name}
                  id="colorInput"
                  value={value || "#000000"}
                  colorPickerRef={colorPickerRef}
                  onChange={(data) => onChange(data?.hex)}
                  label="QR foreground color"
                />
              )}
            />
          </div>
          <div className="w-full" ref={colorPickerRef2}>
            <Controller
              control={control}
              name="bgcolor"
              render={({ field: { name, onChange, value } }) => (
                <ColorPicker
                  type="text"
                  name={name}
                  id="colorInput"
                  value={value || "#ffffff"}
                  colorPickerRef={colorPickerRef2}
                  onChange={(data) => onChange(data?.hex)}
                  label="QR background color"
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SidebarForm;
