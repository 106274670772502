import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import { Error, Success } from "../../reusableComponents/Alerts";
import Button from "../../reusableComponents/Buttons/Button";
import { Select, Text, Checkbox } from "../../reusableComponents/Inputs";
import { useCreateCategoryMutation, useUpdateCategoryMutation } from "../../../redux/api/categoriesApi";
import reactCSS from "reactcss";
import ColorPicker from "../../reusableComponents/Inputs/ColorPicker";
import { useTranslation, Trans } from "react-i18next";

export const AddorEditCategoryModal = ({
  setModifiedData,
  modifiedData,
  toggle,
  addOrEdit,
  isOpen,
  setIsOpen,
  refetch,
  types,
  isFetching,
  setShowAlert,
  showAlert,
  setAlertMessage,
  alertMessage,
}) => {
  const { t } = useTranslation();
  const translatedString = t("caymland.category.notice.updated", {
    name: modifiedData.bundle,
  });
  const [errors, setErrors] = useState({});
  const [createCategory, { isLoading: isAddLoading }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: isEditLoading }] = useUpdateCategoryMutation();
  const isSaving = isEditLoading || isAddLoading;

  const colorPickerRef = useRef();
  useEffect(() => {
    if (isOpen) {
      setErrors({});
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateFields = () => {
    const newErrors = {};

    if (modifiedData.bundle === null || modifiedData.bundle === undefined || modifiedData.bundle === "") {
      newErrors.bundle = true;
    }
    if (!modifiedData.title) newErrors.title = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSaveCategory = async (type) => {
    if (!validateFields()) return;

    let response;
    const payload = { ...modifiedData };
    if (type === "add") {
      response = await createCategory(payload);
    } else {
      response = await updateCategory(payload);
    }
    if (response && !response.error) {
      Success(
        t("caymland.core.success"),
        `${
          type === "add"
            ? t("caymland.category.notice.created", {
                name: modifiedData.title,
                id: response.data.category.id,
                interpolation: { prefix: "%", suffix: "%" },
              })
            : t("caymland.category.notice.updated", {
                name: modifiedData.bundle,
                interpolation: { prefix: "%", suffix: "%" },
              })
        }`
      ).then((result) => {
        if (result.isConfirmed) {
          setModifiedData({
            bundle: "",
            title: "",
            description: "",
            alias: "",
            color: "#000000",
            published: false,
          });
          setIsOpen(false);
          if (type !== "add") refetch();
        }
      });
    } else {
      Error(t("caymland.message.error"));
    }
  };

  const handleColorChange = (color) => {
    setModifiedData((prev) => ({
      ...prev,
      color: color?.hex,
    }));
  };

  const handleToggle = (e) => {
    setModifiedData((props) => ({
      ...props,
      isPublished: e.target.checked,
    }));
  };

  return (
    <Modal
      size="lg"
      style={{
        maxWidth: "600px",
        dispaly: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      className="category-modal"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={true}
    >
      <form>
        <ModalHeader>
          <h3>
            {addOrEdit === "Add"
              ? t("caymland.category.header.new")
              : t("caymland.category.header.edit", {
                  name: modifiedData.title,
                  type: modifiedData.title,
                  interpolation: { prefix: "%", suffix: "%" },
                })}
          </h3>
        </ModalHeader>
        <ModalBody>
          <div className="form-row px-2 pb-3 pt-3">
            {addOrEdit === "Add" ? (
              <Select
                name="validationDefault04"
                required={true}
                label={t("caymland.core.type")}
                options={types}
                getOptionLabel="value"
                errors={errors.bundle}
                value={types.find((c) => c.value === modifiedData?.bundle)}
                onChange={(e) => {
                  if (e === null) {
                    setModifiedData((props) => ({
                      ...props,
                      bundle: null,
                    }));
                  } else {
                    setModifiedData((props) => ({
                      ...props,
                      bundle: e.value,
                    }));
                  }
                }}
              />
            ) : null}
            <Text
              name="title"
              value={modifiedData?.title}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.title")}
              required={true}
              wFull="w-100"
              errors={errors.title}
            />

            <Text
              name="description"
              value={modifiedData?.description}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.description")}
              wFull="w-100"
            />
            <Text
              name="alias"
              value={modifiedData?.alias}
              onChange={(e) => handleInputChange(e)}
              label={t("caymland.core.alias")}
              wFull="w-100"
              tooltip={t("caymland.category.form.alias.help")}
            />
            <div className="w-full" ref={colorPickerRef}>
              <ColorPicker
                type="text"
                id="colorInput"
                name="colorInput"
                value={modifiedData?.color || "#000000"}
                onChange={handleColorChange}
                colorPickerRef={colorPickerRef}
                label={t("caymland.core.color")}
                wFull="w-full"
              />
            </div>
            <div className="w-100" style={{ marginBottom: "20px" }}>
              <Checkbox
                name="isPublished"
                onChange={(e) => handleToggle(e)}
                label={t("caymland.core.form.published")}
                value={modifiedData.isPublished}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            loading={isSaving}
            buttons={[
              {
                name: "close",
                title: t("caymland.core.form.cancel"),
                icon: <ImCancelCircle size={15} />,
                onClick: () => toggle(),
              },
              {
                name: "save",
                title: t("caymland.core.form.apply"),
                icon: <TbDeviceFloppy size={15} />,
                onClick: () => handleSaveCategory(addOrEdit === "Add" ? "add" : "edit"),
              },
            ]}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};
