import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";
import debounce from "lodash/debounce";

const EChart = ({ option, loading, height = "400px", width = "100%" }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current);
      chartInstance.current.setOption(option);
    }

    const handleResize = debounce(() => {
      chartInstance.current?.resize();
    }, 300);

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [option]);

  return (
    <div
      id="chart-container"
      className={`${loading ? "small-blur" : ""}`}
      ref={chartRef}
      style={{ width: width, height: height }}
    ></div>
  );
};

export default EChart;
