import React from "react";
import { Text, TextArea } from "../../../reusableComponents/Inputs";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import { t } from "i18next";

const MainContent = ({ modifiedData, setModifiedData, isFetching, errors, setErrors }) => {
  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <>
      <div className="p-no-margin mt-20px">
        <div className="flex gap-10 w-full">
          <Text
            name="name"
            errors={errors.name}
            value={modifiedData?.name}
            onChange={(e) => {
              const { name, value, type } = e.target;
              setModifiedData((prev) => ({
                ...prev,
                [name]: value,
              }));

              if (errors[name]) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  [name]: undefined,
                }));
              }
            }}
            label={t("caymland.dynamicContent.form.internal.name")}
            required={true}
            type="text"
            wFull="w-100"
          />
        </div>
        <TextArea
          value={modifiedData?.message ?? ""}
          errors={errors.message}
          name="message"
          required={true}
          showSmallEditor={false}
          inserttoken={true}
          label={t("caymland.sms.form.message")}
          onChange={(data) => {
            if (errors[name]) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
              }));
            }
            setModifiedData((prev) => ({
              ...prev,
              message: data,
            }));
          }}
        />
      </div>
    </>
  );
};

export default MainContent;
