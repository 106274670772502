import React from "react";
import Moment from "moment";

function Decription({ hook, contactsLength }) {
  const details = [{ label: hook?.description }];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px">
          <span className="details-label">
            {item.label ? (
              item.label
                .replace(/<p[^>]*>/g, "")
                .replace(/<\/p>/g, "")
                .trim()
            ) : (
              ""
            )}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Decription;
