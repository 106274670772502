import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Runtime from "./Runtime";

const Status = (props) => {
  const { t } = useTranslation();
  const status = props?.status;

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return { color: "#33b5e5", label: t("caymland.lead.import.status.1") };
      case 2:
      case 6:
        return { color: "#007BFF", label: t("caymland.lead.import.status.2") };
      case 3:
        return { color: "#04B76B", label: t("caymland.lead.import.status.3") };
      case 4:
        return { color: "#DC3545", label: t("caymland.lead.import.status.4") };
      case 5:
      case 7:
        return { color: "#fb3", label: t("caymland.lead.import.status.5") };
      default:
        return { color: "", label: "" };
    }
  };

  const { color, label } = getStatusColor(status);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "150px" }}>
      <span className="label-history" style={{ border: `1px solid ${color}`, color: color, marginTop: "10px" }}>
        {label}
      </span>
      <Runtime {...props} />
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.number.isRequired,
};

export default Status;
