import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./addDynamicContent/Sidebar";
import { Error, SlideAlert, Success, handleCancel } from "../../../reusableComponents/Alerts";
import Details from "./addDynamicContent/Details";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Filters from "./addDynamicContent/Filters";
import { AiFillWarning } from "react-icons/ai";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import Swal from "sweetalert2";
import { useCreateDwcMutation, useGetDwcContentQuery, useUpdateDwcMutation } from "../../../../redux/api/dwcApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";

function AddDynamicContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const ClonePath = location.pathname.includes("/clone");
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [query, setQuery] = useState({
    combinator: "and",
    rules: [
      {
        field: "",
        id: "",
        operator: "~",
        valueSource: "",
      },
    ],
  });

  const [modifiedData, setModifiedData] = useState({
    name: "",
    content: "",
    isPublished: true,
    category: null,
    language: "de",
    variantChildren: [],
    variantParent: null,
    isCampaignBased: false,
    slotName: "",
    filters: [],
    utmTags: {
      utmSource: "",
      utmMedium: "",
      utmCampaign: "",
      utmContent: "",
    },
  });

  function hasOperatorWithTilde(filters) {
    for (const filter of filters) {
      if (typeof filter?.filter === "string" && filter?.filter.trim() === "") {
        filter.filter = [];
      }

      if (
        filter?.field === null ||
        filter?.field.trim() === "" ||
        filter?.operator === null ||
        filter?.operator.trim() === "" ||
        (Array.isArray(filter?.filter) &&
          filter?.filter.length === 0 &&
          !(filter?.operator === "!empty" || filter?.operator === "empty"))
      ) {
        return true;
      }
    }
    return false;
  }

  const { data, isFetching, isLoading, refetch } = useGetDwcContentQuery(id, { skip: !id });
  const [updateDwc, { isLoading: isEditLoading }] = useUpdateDwcMutation(id);
  const [createDwc, { isLoading: isAddLoading }] = useCreateDwcMutation();
  const isSaving = isEditLoading || isAddLoading;

  useEffect(() => {
    if (id && data) {
      let dwcData = data?.dynamicContent;
      if (ClonePath) {
        dwcData = { ...dwcData, isPublished: false };
      }

      setModifiedData({
        ...dwcData,
        category: dwcData?.category
          ? {
              label: dwcData?.category?.title,
              value: dwcData?.category?.id,
            }
          : null,
      });
    }
  }, [id, data, ClonePath]);

  useEffect(() => {
    processQuery(query);
  }, [query, data]);

  const processQuery = async (query) => {
    if (query && query.rules) {
      const processedQuery = query.rules.map((rule) => {
        let operatorData = null;
        let operatorLabel = null;

        if (rule?.field?.operators) {
          operatorData = rule.field.operators.find((op) => op.name === rule.operator);
          operatorLabel = operatorData ? operatorData.name : null;
        }

        let result = {
          glue: query?.combinator ?? null,
          field: rule?.field?.field ?? null,
          object: rule?.field?.object ?? null,
          type: rule?.field?.type ?? null,
          filter: rule?.value ?? null,
          display: null ?? null,
          operator: operatorLabel ?? null,
        };
        return result;
      });

      setModifiedData((prev) => ({
        ...prev,
        filters: processedQuery,
      }));
    }
  };

  const handleAddDC = async (params) => {
    const initialProcess = async () => {
      if (modifiedData?.isCampaignBased === true) {
        setModifiedData((prev) => ({
          ...prev,
          filters: [],
        }));
        const updatedErrors = { ...errors };
        delete updatedErrors.filters;
        return updatedErrors;
      } else if (modifiedData?.isCampaignBased === false) {
        await processQuery(query);
      }
    };

    await initialProcess();
    let tempModifiedData = { ...modifiedData };

    if (tempModifiedData?.isCampaignBased === true) {
      tempModifiedData.filters = [];
    }

    if (modifiedData?.name?.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    if (modifiedData?.slotName.length < 1 && !modifiedData?.isCampaignBased) {
      errors.slotName = true;
      setErrors({ ...errors });
    } else {
      delete errors.slotName;
      setErrors({ ...errors });
    }

    if (modifiedData?.isPublished === true) {
      delete errors.filters;
      setErrors({ ...errors });
    } else {
      errors.filters = true;
      setErrors({ ...errors });
    }

    if (modifiedData?.isCampaignBased === true) {
      delete errors.filters;
      setErrors({ ...errors });
    } else {
      errors.filters = true;
      setErrors({ ...errors });
    }

    if (query?.rules?.length > 0) {
      delete errors.filters;
      setErrors({ ...errors });
    } else {
      errors.filters = true;
      setErrors({ ...errors });
    }
    if (errors === true) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
    }
    if (modifiedData?.isCampaignBased === true) {
      delete errors.filters;
    } else {
      if (tempModifiedData.filters.length === 0 || hasOperatorWithTilde(tempModifiedData.filters)) {
        setShowAlert(true);
        setAlertMessage(t("caymland.core.alert.message.filter"));
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 3500);
        return;
      }
    }

    if (Object.keys(errors).length === 0) {
      try {
        let tempLanguage = null;
        if (tempModifiedData?.language) {
          tempLanguage = tempModifiedData.language;
          tempModifiedData.language = tempModifiedData.language.value;
        }

        let tempTranslationParent = null;
        if (tempModifiedData?.translationParent) {
          tempTranslationParent = tempModifiedData.translationParent;
          tempModifiedData.translationParent = tempModifiedData.translationParent.id;
        }

        let tempCategory = null;
        if (tempModifiedData?.category) {
          tempCategory = tempModifiedData?.category;
          tempModifiedData.category = tempModifiedData.category.value;
        }

        let tempTranslationChildren = [];
        if (tempModifiedData.translationChildren) {
          tempTranslationChildren = tempModifiedData.translationChildren;
          tempModifiedData.translationChildren = tempModifiedData.translationChildren.map((x) => x.id);
        }

        let response;
        if (id && !ClonePath) {
          response = await updateDwc(tempModifiedData);
        } else {
          response = await createDwc(tempModifiedData);
        }

        if (!response?.error) {
          Success(
            `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
              type: id ? "edited" : "created",
            })}`
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navigate(`/dwc/view/${response.data.dynamicContent.id}`);
            } else {
              if (id) {
                setModifiedData((prev) => ({
                  ...prev,
                  category: tempCategory,
                  language: tempLanguage,
                  translationParent: tempTranslationParent,
                  translationChildren: tempTranslationChildren,
                }));
              } else {
                navigate(`/dwc/edit/${response.data.dynamicContent.id}`);
              }
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    } else {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/dwc", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddDC("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddDC(""),
    },
  ];

  return (
    <Fragment>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isSaving}
          title={
            !ClonePath && id
              ? t("caymland.dynamicContent.header.edit", { name: modifiedData.name })
              : t("caymland.dynamicContent.header.new")
          }
        />
      )}
      <Wrapper
        childrenClassOne={"w-75"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton applyPadding={true} />
          ) : (
            <>
              <Nav className="nav-primary w-full flex" tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    className={primarycolorTab === 1 ? "active" : ""}
                    onClick={() => setPrimaryColorTab(1)}
                    style={{
                      color: "#313131",
                      textTransform: "capitalize",
                    }}
                  >
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      {errors?.name ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>{t("caymland.core.details")}</span>
                          <div
                            style={{
                              paddingLeft: "10px",
                              fontSize: "14px",
                              display: "flex",
                            }}
                          >
                            <AiFillWarning color="rgba(255, 0, 0, 0.7)" />
                          </div>
                        </div>
                      ) : (
                        <span>{t("caymland.core.details")}</span>
                      )}
                    </div>
                  </NavLink>
                </NavItem>
                {modifiedData?.isCampaignBased === false ? (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={primarycolorTab === 2 ? "active" : ""}
                      onClick={() => setPrimaryColorTab(2)}
                      style={{
                        color: "#313131",
                        textTransform: "capitalize",
                      }}
                    >
                      <div
                        className="flex"
                        style={{
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        {errors.filters ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span>{t("caymland.core.filters")}</span>
                            <div
                              style={{
                                paddingLeft: "10px",
                                fontSize: "14px",
                                display: "flex",
                              }}
                            >
                              <AiFillWarning color="rgba(255, 0, 0, 0.7)" />
                            </div>
                          </div>
                        ) : (
                          <span>{t("caymland.core.filters")}</span>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                ) : (
                  <></>
                )}
              </Nav>
              <TabContent activeTab={primarycolorTab} style={{ padding: "0 20px" }}>
                <TabPane tabId={1}>
                  <Details
                    modifiedData={modifiedData}
                    setModifiedData={setModifiedData}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </TabPane>
                {!modifiedData?.isCampaignBased && (
                  <TabPane tabId={2}>
                    <Filters
                      query={query}
                      setQuery={setQuery}
                      setErrors={setErrors}
                      filters={data?.dynamicContent?.filters}
                    />
                  </TabPane>
                )}
              </TabContent>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
              setPrimaryColorTab={setPrimaryColorTab}
            />
          )
        }
      />
    </Fragment>
  );
}

export default AddDynamicContent;
