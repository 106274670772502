export const retrieveSegments = (inputValue, bundle, trigger = null, includeGlobal = true) =>
  new Promise(async (resolve, reject) => {
    if (typeof trigger !== "function" || trigger === null) {
      resolve([]);
      return;
    }

    const val = [];

    if (bundle) {
      val.push({ expr: "eq", col: "bundle", val: bundle });
    }

    if (includeGlobal) {
      val.push({ expr: "eq", col: "bundle", val: "global" });
    }
    const query = {};

    if (inputValue.length) {
      query.search = inputValue;
    }
    const response = await trigger(query).unwrap();
    console.log(response?.data);
    if (!response.error) {
      const segmentsData = response?.data?.filter((segment) => segment?.isPublished);
      const segmentsArray = segmentsData?.map((segment) => ({
        label: segment?.name,
        value: segment?.id,
      }));
      resolve(segmentsArray);
      console.log(segmentsArray);
    }
  });
