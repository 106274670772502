import React, { useEffect } from "react";
import { FiClock } from "react-icons/fi";
import ClipLoader from "react-spinners/ClipLoader";
import { Fade, IconButton, Tooltip, Zoom } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";

const PublishStatus = (props) => {
  const { data, loading, handleTogglePublished, index } = props;

  const isPending = dayjs().isBefore(dayjs(data.publishUp));
  const isExpired = dayjs().isAfter(dayjs(data.publishDown));

  return (
    <>
      <Tooltip
        arrow
        followCursor
        placement="top"
        TransitionComponent={Zoom}
        title={
          isExpired
            ? t("caymland.core.form.expired")
            : isPending
            ? t("caymland.message.queue.status.pending")
            : `${t("caymland.core.permissions.publish")} ${t("caymland.core.update.heading.status")}`
        }
        style={{ minWidth: "50px", alignSelf: "flex-start" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton>
          <div className="checkbox-wrapper-22">
            {isPending ? (
              <FiClock color="#fdb933" size={22} style={{ marginBottom: "6px" }} />
            ) : isExpired ? (
              <FiClock color="#f86b4f" size={22} style={{ marginBottom: "6px" }} />
            ) : loading === data.id ? (
              <ClipLoader color="#333" size={12} />
            ) : (
              <label className="switch">
                <input
                  type="checkbox"
                  checked={data.isPublished === true}
                  onChange={() => handleTogglePublished(data)}
                />
                <div className="slider round"></div>
              </label>
            )}
          </div>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PublishStatus;

// import React from 'react';
// import { FiClock } from 'react-icons/fi';
// import ClipLoader from 'react-spinners/ClipLoader';
// import dayjs from 'dayjs';

// const PublishStatus= (props) => {
//   const {
//     data,
//     loading,
//     handleTogglePublished,
//     index
//   } = props;

//   const isPending = dayjs().isBefore(dayjs(data[index]?.publishUp))
//   const isExpired = dayjs().isAfter(dayjs(data[index]?.publishDown))

//   return (
//     <>
//       {isPending ? (
//         <FiClock title="Pending" color="#fdb933" size={18} />
//       ) : isExpired ? (
//         <FiClock title="Expired" color="#f86b4f" size={18} />
//       ) : loading === data[index].id ? (
//         <ClipLoader color="#333" size={12} />
//       ) : (
//         <label className="switch-published" style={{ marginBottom: "0" }}>
//           <input
//             type="checkbox"
//             checked={data[index].isPublished === true}
//             onChange={() => handleTogglePublished(data[index])}
//           />
//           <span className="slider-published round-published"></span>
//         </label>
//       )}
//     </>
//   );
// };

// export default PublishStatus;
