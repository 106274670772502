export const CategoryModalData = {
  name: "Category",
  fields: [
    {
      type: "text",
      label: "Title",
      alias: "title",
      isRequired: true,
    },
    {
      type: "textarea",
      label: "Description",
      alias: "description",
    },
    {
      type: "text",
      label: "Alias",
      alias: "alias",
    },
    {
      type: "color",
      label: "Color",
      alias: "color",
    },
    {
      type: "boolean",
      label: "Published",
      alias: "published",
    },
  ],
};
