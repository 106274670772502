import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewCategoryPopUp from "./../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Error, Success } from "../../reusableComponents/Alerts";
import Details from "./addOrEditHooks/Details";
import Sidebar from "./addOrEditHooks/Sidebar";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import {
  useCreateHookMutation,
  useGetHookQuery,
  useUpdateHookMutation,
  webHooksApi,
} from "../../../redux/api/webHooksApi";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import TitleHeader from "../../reusableComponents/TitleHeader";
import { t } from "i18next";

function AddEditHooks() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [showPopUpCategory, setShowPopUpCategory] = useState(false);
  const [mainLoading, setMainLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [triggers, setTriggers] = useState([]);
  const [modifiedData, setModifiedData] = useState({
    isPublished: false,
    id: null,
    name: "",
    description: null,
    webhookUrl: "",
    eventsOrderbyDir: null,
    category: null,
    triggers: [],
  });

  const [createHook, { isLoading: isAddLoading }] = useCreateHookMutation();
  const [updateHook, { isLoading: isEditLoading }] = useUpdateHookMutation(id);
  const isSaving = isEditLoading || isAddLoading;

  const { data, isFetching, isLoading } = useGetHookQuery(id, { skip: !id });
  const [listAvailableWebHooks] = webHooksApi.useLazyListAvailableWebHooksQuery();

  useEffect(() => {
    if (data && data?.hook && id) {
      setModifiedData({
        ...data?.hook,
        category: data?.hook?.category
          ? {
              label: data.hook?.category?.title,
              value: data.hook?.category?.id || null,
            }
          : null,
      });
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      setMainLoading(true);
      try {
        const response = await listAvailableWebHooks().unwrap();
        if (response) {
          setTriggers(Object.entries(response?.triggers));
        }
      } catch (error) {
        console.error("Error fetching web hooks:", error);
      }
      setMainLoading(false);
    };

    fetchData();
  }, []);

  const handleAddEditHooks = async (params) => {
    let newErrors = {};

    if (modifiedData?.name?.length < 1) {
      newErrors.name = true;
    }
    if (modifiedData?.webhookUrl?.length < 1) {
      newErrors.webhookUrl = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    } else {
      setErrors({});
    }

    if (Object.keys(errors).length === 0) {
      try {
        let response;
        const payload = {
          ...modifiedData,
          category:
            modifiedData.category && typeof modifiedData.category === "object"
              ? modifiedData.category.value
              : modifiedData.category,
          eventsOrderbyDir:
            modifiedData.eventsOrderbyDir && typeof modifiedData.eventsOrderbyDir === "object"
              ? modifiedData.eventsOrderbyDir.value
              : modifiedData.eventsOrderbyDir,
        };

        if (id && !isClone) {
          response = await updateHook(payload);
        } else {
          response = await createHook(payload);
        }
        if (response && !response?.error) {
          Success(
            `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
              type: id ? "edited" : "created",
            })}`
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navigate(`/webhooks/view/${response?.data?.hook?.id}`);
            } else {
              navigate(`/webhooks/edit/${response?.data?.hook?.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/webhooks"),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditHooks("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditHooks(""),
    },
  ];

  return (
    <>
      <NewCategoryPopUp show={showPopUpCategory} setShow={setShowPopUpCategory} />
      {mainLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          loading={isSaving}
          showAlert={showAlert}
          alertMessage={alertMessage}
          title={
            <TitleHeader name="Webhook" clone={isClone} title={modifiedData?.name} loading={mainLoading} id={id} />
          }
        />
      )}

      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          mainLoading ? (
            <DetailsSkeleton />
          ) : (
            <Details
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
              triggers={triggers}
              key={triggers}
            />
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          mainLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              setShowPopUpCategory={setShowPopUpCategory}
            />
          )
        }
      />
    </>
  );
}

export default AddEditHooks;
