import React, { useEffect, useMemo, useState } from "react";
import CustomTable from "../../../../reusableComponents/CustomTable/CustomTable";
import { t } from "i18next";

function ClickCounts({ extras }) {
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "ASC",
    orderBy: "id",
    where: [
      // {
      //   expr: "isNull",
      //   col: "folderId",
      // },
    ],
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    if (extras?.clickCount) {
      // Map each item in the clickCount array to a new object
      const formattedData = extras.clickCount.map((item) => ({
        url: item.url,
        hits: item.hits,
        unique_hits: item.unique_hits,
        redirect_id: item.redirect_id,
      }));
      setData(formattedData);
    }
  }, [extras]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "URL",
        accessorFn: (row) => {
          const url = row.url || "";
          return url.length > 50 ? `${url.substring(0, 50)}...` : url;
        },
        header: t("caymland.core.type.url"),
        size: 150,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <div style={{ cursor: "pointer" }} onClick={() => window.open(cell.row.original.url, "_blank")}>
            {cell.row.original.url.length > 50 ? cell.row.original.url.slice(0, 50) + "..." : cell.row.original.url}
          </div>
        ),
      },
      {
        accessorKey: "hits",
        accessorFn: (row) => row.hits,
        header: t("caymland.dashboard.label.contact.click"),
        size: 150,
        enableColumnActions: false,
      },
      {
        accessorKey: "Unique Click",
        accessorFn: (row) => row.unique_hits,
        header: `${t("caymland.page.unique")} ${t("caymland.trackable.click_count")}`,
        size: 150,
        enableColumnActions: false,
      },
      {
        accessorKey: "Tracking Id",
        accessorFn: (row) => row.redirect_id,
        header: t("caymland.trackable.click_track_id"),
        size: 150,
        enableColumnActions: false,
      },
    ],
    []
  );
  return (
    <div>
      <CustomTable
        columnsClicks={columns}
        data={data}
        query={query}
        setQuery={setQuery}
        linkNavigate={null}
        actions={false}
        searchFilter={false}
      />
    </div>
  );
}

export default ClickCounts;
