import React, { useState } from "react";
import Select from "react-select";
import { countryData, localeData, groupedOptions } from "../../../../../reusableComponents/data";
import { DatePicker as DataSelect, Text } from "../../../../../reusableComponents/Inputs";
import { t } from "i18next";

const ValueEditor = (props) => {
  const { fieldData, value, field, handleOnChange, fields } = props;
  const [localValue, setLocalValue] = useState(value);

  function getOptions(field) {
    if (!field) return [];
    if (field.type === "locale") {
      return localeData;
    }
    if (field.type === "country") {
      return countryData;
    }
    if (field.type === "region") {
      return groupedOptions;
    }

    if (field.input === "select") {
      return transformListToListOptions(field.list);
    }

    return findOptionsByField(field.field, fields);
  }

  function transformListToListOptions(list) {
    if (Array.isArray(list) && list.length > 0 && typeof list[0] === "object") {
      return list;
    }

    if (Array.isArray(list) && list.length > 0 && typeof list[0] === "string") {
      const transformedList = list.map((item, index) => ({ label: item, value: String(index) }));
      return transformedList;
    }

    if (typeof list === "object") {
      const transformedList = Object.entries(list).map(([key, value]) => ({
        value: key,
        label: value,
      }));
      return transformedList;
    }

    return [];
  }

  function findOptionsByField(field, dataArray) {
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].options) {
        for (let j = 0; j < dataArray[i].options.length; j++) {
          if (dataArray[i].options[j].name && dataArray[i].options[j].name === field) {
            let list = dataArray[i].options[j].list || [];
            return transformListToListOptions(list);
          }
        }
      }
    }
    return [];
  }

  function getValueForType(type, value, field) {
    switch (type) {
      case "country":
        return countryData.find((country) => country.value === value);
      case "locale":
        return localeData.find((locale) => locale.value === value);
      case "region":
        return groupedOptions.flatMap((group) => group.options).find((option) => option.value === value);
      case "multiselect":
        return getOptions(field).find((option) => option.value === value);
      case "boolean":
        return getOptions(field).find((option) => option.value === String(value));
      case "select":
        return getOptions(field).find((option) => option.value === value);
      default:
        return null;
    }
  }

  const isDisabled =
    props.operator === "!empty" || props.operator === "empty" || props.isDisabled || props.operator === "not_empty";

  const updatedValue = isDisabled ? " " : getValueForType(field.type, value, field);

  switch (field.type) {
    case "country":
    case "locale":
    case "region":
    case "boolean":
    case "select":
    case "multiselect":
      return (
        <div style={{ width: "30%" }}>
          <Select
            isLoading={props.loading}
            margin={true}
            isDisabled={isDisabled}
            options={getOptions(field) ?? []}
            placeholder={isDisabled ? "Disabled" : "Select..."}
            value={updatedValue ?? null}
            onChange={(e) => {
              const selectedValue = isDisabled ? "" : e?.value;
              handleOnChange(selectedValue);
            }}
            isMulti={fieldData.type === "multiselect" ? true : false}
            theme={props.selectTheme}
            styles={props.selectStyles}
          />
        </div>
      );
    case "date":
    case "datetime":
      return (
        <div style={{ alignSelf: "start", width: "30%" }}>
          <DataSelect
            dateFormat={`dd MMM, yyyy`}
            marginBottom={false}
            isLoading={props.loading}
            onChange={(date) => {
              const formattedDate = date ? date.toISOString().split("T")[0] : null;
              handleOnChange(formattedDate);
            }}
            timeFormat="hh.mm"
            value={props.value}
            showTimeSelect={fieldData.input === "datetime" ? true : false}
            disabled={props.operator === "!empty" || props.operator === "empty" || props.isDisabled}
            placeholderText={props.operator === "!empty" || props.operator === "empty" ? "Disabled" : "Select..."}
          />
        </div>
      );
    default:
      return (
        <div style={{ width: "30%" }}>
          <Text
            name="website"
            value={localValue}
            marginBottom={true}
            onChange={(e) => {
              const selectedValue = props?.operator === "!empty" || props?.operator === "empty" ? "" : e?.target?.value;
              setLocalValue(selectedValue);
            }}
            onBlur={() => handleOnChange(localValue)}
            placeholder={
              props.operator === "!empty" ||
              props.operator === "empty" ||
              props.isDisabled ||
              props.operator === "not_empty"
                ? t("caymland.queue.config.protocol.disabled")
                : t("caymland.core.type.type")
            }
            disabled={
              props.operator === "!empty" ||
              props.operator === "empty" ||
              props.isDisabled ||
              props.operator === "not_empty"
            }
          />
        </div>
      );
  }
};

export default ValueEditor;
