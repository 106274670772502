import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { setData } from "../features/settingsSlice";

export const settingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (build) => ({
    settings: build.query({
      query: (body) => ({
        url: "api/settings",
        method: "GET",
        body,
      }),
      keepUnusedDataFor: 30,
      // async onQueryStarted({ dispatch, queryFulfilled }) {
      //   try {
      //     const data = await queryFulfilled;
      //     console.log("datadata", data);
      //     dispatch(setData(data));
      //   } catch (e) {
      //     console.log(e);
      //   }
      // },
    }),
  }),
});

export const { useSettingsQuery } = settingsApi;
