import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import Button from "../../../../reusableComponents/Buttons/Button";
import Skeleton from "@mui/material/Skeleton";
import Swal from "sweetalert2";
import { CardSkeleton } from "../../../Components/landingPages/addPageContent/ThemeCards";
import { t } from "i18next";

function ThemeCards({ setModifiedData, themes, modifiedData }) {
  const [selectedTheme, setSelectedTheme] = useState(-2);

  useEffect(() => {
    const foundIndex = themes.findIndex((theme) => theme[0] === modifiedData?.template);
    if (foundIndex !== -1) {
      setSelectedTheme(foundIndex);
    }
  }, [themes]);

  const handleThemeChange = async (index) => {
    if (selectedTheme !== -2 && index !== selectedTheme) {
      const result = await Swal.fire({
        title: t("caymland.badge_contact.form.confirmdelete"),
        text: t("caymland.core.delete.theme"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        customClass: {
          confirmButton: "confirm-component swal-custom",
          cancelButton: "result-component swal-custom",
        },
      });

      if (result.isConfirmed) {
        const theme = themes[index];
        setSelectedTheme(index);
        setModifiedData((prev) => ({
          ...prev,
          json: theme[1].template,
          template: theme[0],
        }));
      }
    } else {
      const theme = themes[index];
      setSelectedTheme(index);
      setModifiedData((prev) => ({
        ...prev,
        json: theme[1].template,
        template: theme[0],
      }));
    }
  };

  return (
    <div className="flex flex-wrap justify-center items-start gap-4 w-full">
      {themes?.length > 0
        ? themes.map((theme, index) => (
            <Card
              className="flex flex-col items-center justify-between gap-4 cardShadow"
              key={index}
              style={{ width: "30%", padding: "20px" }}
            >
              <CardHeader style={{ borderBottom: 0, padding: 0 }}>{theme.name}</CardHeader>
              <CardBody className="w-full p-0">
                <div style={{ overflow: "hidden", maxHeight: "350px" }}>
                  <iframe
                    title={t("caymland.core.preview")}
                    class="frame"
                    frameborder="0"
                    style={{ pointerEvents: "none", overflow: "hidden", transform: "scale(0.47)" }}
                    src={`${process.env.REACT_APP_PROD_URL}/themes/popup/${theme[0]}/thumbnail.png`}
                  ></iframe>
                </div>
              </CardBody>
              <CardFooter style={{ borderBottom: 0, padding: 0 }}>
                <Button
                  buttons={[
                    {
                      name: "Selecte",
                      title:
                        selectedTheme === index ? t("caymland.core.form.filters.selected") : t("caymland.core.select"),
                      onClick: () => handleThemeChange(index),
                    },
                  ]}
                  textFormat="capitalize"
                />
              </CardFooter>
            </Card>
          ))
        : Array.from({ length: 3 }).map((_, index) => <CardSkeleton key={index} />)}
    </div>
  );
}

export default ThemeCards;
