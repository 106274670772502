import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { FieldsRenderSection } from "../../main-menu-sidebar/contacts/FieldsRenderer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Error, Success } from "../Alerts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetCompanyFieldsQuery } from "../../../redux/api/fieldsApi";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Button from "../Buttons/Button";
import { useAddCompanyMutation } from "../../../redux/api/companiesApi";

const AddCompanyModal = ({ setIsModalOpen, isModalOpen, setGetNewCompany }) => {
  const { t } = useTranslation();
  const [companiesFields, setCompaniesFields] = useState();
  const [validationSchema, setValidationSchema] = useState(yup.object().shape({}));
  const [activeTab, setActiveTab] = useState("core");
  const [groupedFields, setGroupedFields] = useState({});

  const navigate = useNavigate();
  const { data } = useGetCompanyFieldsQuery();
  const [addCompany] = useAddCompanyMutation();

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };
  useEffect(() => {
    if (data) {
      setCompaniesFields(data.data.filter((field) => field.isPublished && field.group == activeTab));
    }
  }, [data]);
  useEffect(() => {
    if (data && data.data) {
      const fields = data.data.filter((field) => field.isPublished);
      const grouped = fields.reduce((acc, field) => {
        (acc[field.group] = acc[field.group] || []).push(field);
        return acc;
      }, {});
      setGroupedFields(grouped);
      if (Object.keys(grouped).length > 0) {
        setActiveTab(Object.keys(grouped)[0]); // Set the first group as the active tab
      }
    }
  }, [data]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (companiesFields) {
      const schema = generateYupSchema(companiesFields.filter((item) => item.isRequired));
      setValidationSchema(schema);
    }
  }, [companiesFields]);

  const generateYupSchema = (fields) => {
    const schemaFields = fields.reduce((acc, field) => {
      let validator;
      switch (field.type) {
        case "boolean":
          validator = yup.number().required("This field is required");
          break;
        case "email":
          validator = yup.string().email("Invalid email format").required("This field is required");
          break;
        default:
          validator = yup.string().required("This field is required");
      }
      acc[field.alias] = validator;
      return acc;
    }, {});

    return yup.object().shape(schemaFields);
  };

  const {
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  //   useEffect(() => {
  //     if (!_.isEmpty(errors)) {
  //       console.error(errors);
  //       if (errors) {
  //         setShowAlert(true);
  //         setMessage(t("caymland.core.error.not.valid"));
  //         setTimeout(() => {
  //           setMessage("");
  //           setShowAlert(false);
  //         }, 3000);
  //       }
  //     }
  //   }, [errors]);

  const handleCompany = async (data) => {
    const response = await addCompany(data);
    console.log(response);
    if (!response.error) {
      closeModal();
      Success("Company has been added or edited successfully!");
      setGetNewCompany({
        label: response.data.company.fields.all.companyname,
        value: response.data.company.fields.all.id,
      });
    } else {
      Error(response.error.data.errors[0].message);
    }
  };
  const buttons = [
    {
      name: "cancel",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleCompany),
    },
  ];
  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("caymland.core.form.add")} {t("caymland.core.company")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "75vh", overflowY: "auto" }}>
        <Nav tabs>
          {Object.keys(groupedFields).map((group) => (
            <NavItem key={group}>
              <NavLink
                className={activeTab === group ? "active-bg" : ""}
                onClick={() => {
                  toggleTab(group);
                }}
              >
                {group.charAt(0).toUpperCase() + group.slice(1)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {Object.entries(groupedFields).map(([group, fields]) => (
            <TabPane tabId={group} key={group}>
              <FieldsRenderSection filteredFields={fields} control={control} noBorder errors={errors} />
            </TabPane>
          ))}
        </TabContent>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddCompanyModal;
