import React, { useMemo } from "react";
import Moment from "moment";
import { Label } from "../../../../reusableComponents/Inputs";
import { DragHandle } from "../../widgetGenerator/index";
import CustomTableSmallView from "../../../../reusableComponents/CustomTable/CustomTableSmallView";
import EditDeleteMenu from "../../EditDeleteMenu";
import { t } from "i18next";

function UpcomingEmails({ upcomingEmails, name, handleEdit, handleDelete, id, height }) {
  const transformedData = upcomingEmails.map((x) => ({
    campaignName: x.campaign_name,
    contactName: x.lead_name ? x.lead_name : "Undefined",
    date: Moment(x.trigger_date).format("DD.MM.YYYY"),
  }));

  const columns = useMemo(
    () => [
      {
        accessorKey: "campaignName",
        header: t("caymland.lead.report.attribution.campaign_name"),
        size: 150,
      },
      {
        accessorKey: "contactName",
        header: t("caymland.core.contact.name"),
        size: 150,
      },
      {
        accessorKey: "date",
        header: t("caymland.core.type.date"),
        size: 150,
      },
    ],
    []
  );

  return (
    <>
      <div className="chart-header">
        <DragHandle />
        <div style={{ marginTop: "8px", marginLeft: "10px" }}>
          <Label name={name} label={name} />
        </div>
        <EditDeleteMenu handleEdit={() => handleEdit(id)} handleDelete={() => handleDelete(id)} />
      </div>
      <CustomTableSmallView data={transformedData} columns={columns} height={height} />
    </>
  );
}

export default UpcomingEmails;
