import { memo, useCallback, useState } from "react";
import { Handle, Position } from "reactflow";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Styles } from "../utils";
import NodeToolbar from "./NodeToolbar";
import CommitIcon from '@mui/icons-material/Commit';
import SyncIcon from '@mui/icons-material/Sync';
import { t } from "i18next";

const Action = ({ data, actions, handleAdd, handleEdit, setParams, isConnectable, handleJump, hasJumpNodeActive, jumpNode }) => {
    const [isVisible, setVisible] = useState(false);
    const id = `${data?.params?.id}`
    const isActive = hasJumpNodeActive && !(id === jumpNode.first || id === jumpNode.second);


    return (
        <div className="eventSelect"
            onDoubleClick={() => !isActive && handleEdit(setParams, 'action', 'edit', 2, actions, data)}
            onMouseEnter={() => !isActive && setVisible(true)}
            onMouseLeave={() => !isActive && setVisible(false)}
            style={{ opacity: isActive ? 0.5 : 1 }}
        >
            <NodeToolbar
                isVisible={isVisible}
                handleEdit={() => handleEdit(setParams, 'action', 'edit', 2, actions, data)}
                handleDelete={() => actions.deleteNode(data.params)}
            />
            <div
                className="text-updater-node d-flex flex-column align-items-center sourceSelectBuilder"
                style={{ border: data?.params?.type === 'lead.deletecontact' ? '1px solid red' : "1px solid #0B94F7" }}
            >

                <Handle
                    type='target'
                    position={Position.Top}
                    style={Styles.handleStyleTop}
                    isConnectable={isConnectable}
                    className="handleToggle"
                />
                <div className="d-flex w-full justify-between gap-10" style={{ height: '38px' }}>
                    <div className="d-flex align-center place-self-center">
                        <CommitIcon />
                    </div>
                    <div className="place-self-center" style={{ maxHeight: '50px', width: '105px', overflow: 'hidden' }}>
                        <p className="mb-0 text-ellipsis" style={{ fontSize: "11px", lineHeight: '1.2' }}>
                            {data?.params?.name || t("caymland.point.thead.action")}
                        </p>
                    </div>
                </div>
                {
                    data?.params?.type === "campaign.jump_to_event" ?
                        <SyncIcon
                            style={Styles.handleStyleJump}
                            className="handleJump"
                            onClick={() => !isActive && handleJump(data)}
                        />
                        :
                        <Handle
                            type='source'
                            id='bottom'
                            position={Position.Bottom}
                            style={Styles.handleStyleBottom}
                            isConnectable={isConnectable}
                            className="handleToggle"
                            onClick={() => !isActive && handleAdd(setParams, 'action', 'add', 0, 'bottom', actions, data)}
                        />
                }
            </div>
        </div>
    )
}

export default memo(Action)
