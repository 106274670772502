import { t } from "i18next";
import React from "react";
import { BsChevronBarLeft, BsChevronBarRight, BsChevronLeft, BsChevronRight } from "react-icons/bs";

function Pagination(props) {
  let calculatedRowsFrom =
    props.rowsFrom < 1 ? 1 : props.rowsFrom > props.totalLength ? props.totalLength : props.rowsFrom;
  let calculatedRowsTo = props.rowsTo < 1 ? 1 : props.rowsTo;

  return (
    <>
      <div
        className={` items-center ${
          props.simplePagination ? "flex-wrap justify-center" : "flex justify-end"
        } gap-40 custom-pagination
          ${props.paginationClass && "reportsTablePagination"}
          `}
        style={{ marginLeft: "-1px", height: props.heightTable2 }}
      >
        {!props.simplePagination ? (
          <>
            <div>
              <select onChange={props.onPageSizeChanged} id="page-size">
                <option value="10" defaultValue={true}>
                  10
                </option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="flex items-center gap-5px p-no-margin">
              <b>{calculatedRowsFrom}</b>
              <p>{t("caymland.lead.frequency.contact.end.date")}</p>
              <b>{calculatedRowsTo}</b>
              <p>{t("caymland.core.of")}</p>
              <b>{props.totalLength ?? "1"}</b>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          className={`flex items-center gap-10 p-no-margin ${props.simplePagination ? "justify-center" : ""}`}
          style={{ width: props.simplePagination && "100%" }}
        >
          <BsChevronBarLeft className="cursor-pointer" onClick={() => props.firstPage()} size={16} />
          <BsChevronLeft className="cursor-pointer" onClick={() => props.previousPage()} size={14} />
          <p>{t("caymland.page.page")}</p>
          <b>{props.pageCount}</b>
          <p>{t("caymland.core.of")}</p>
          <b>{isNaN(props.totalPageCount) ? "1" : props.totalPageCount}</b>
          <BsChevronRight className="cursor-pointer" onClick={() => props.nextPage()} size={14} />
          <BsChevronBarRight className="cursor-pointer" onClick={() => props.lastPage()} size={16} />
        </div>
        {props.simplePagination && (
          <div className="flex justify-center items-center" style={{ border: "0", width: "100%" }}>
            <p style={{ color: "#333333", fontSize: "10px" }}>
              {props.totalLength} {t("caymland.core.pagination.total.pages", { maxpages: props.maxPages })}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default Pagination;
