import React from "react";
import moment from "moment";
import { t } from "i18next";
import "./Timeline.css";

const Timeline = ({ events }) => {
  // Filter out events with a past trigger date
  const upcomingEvents = events.filter((event) => {
    const eventDateTime = event.extra.log.triggerDate ? moment(event.extra.log.triggerDate) : null;
    return eventDateTime && eventDateTime.isAfter(moment());
  });

  // If there are no upcoming events, return nothing
  if (upcomingEvents.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "inherit",
        boxShadow: "none",
        height: "auto",
      }}
    >
      <div className="w-full justify-between d-flex items-center mb-10">
        <span style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: 16, marginBottom: "6px" }}>
          {t("caymland.contacts.eventtimeline")}
        </span>
      </div>
      <div style={{ padding: "20px 20px", backgroundColor: "white" }}>
        <div className="activity">
          {upcomingEvents.map((event, index) => {
            const eventDateTime = event.extra.log.triggerDate ? moment(event.extra.log.triggerDate) : null;
            const formattedDateTime = eventDateTime ? eventDateTime.format("DD.MM.YY HH:mm") : "N/A";

            return (
              <div key={event.eventId} className="d-flex" style={{ marginBottom: "15px" }}>
                <div
                  className="flex-shrink-0 gradient-round gradient-line-1"
                  style={{
                    marginRight: "15px",
                    background: "#e9e7ed",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    color: "black",
                  }}
                >
                  <i className={`fa ${event.icon}`}></i>
                </div>
                <div className="flex-grow-1">
                  <p className="mb-0 pb-0" style={{ fontSize: "14px" }}>
                    <strong>{event.extra.log.event_name}</strong> - {event.extra.log.campaign_name}
                  </p>
                  <small style={{ fontSize: "13px", color: "#555" }}>
                    {t("caymland.contacts.eventtimeline.eventscheduled")}: {formattedDateTime}
                  </small>
                  <div>
                    <a
                      href={event.eventLabel.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontSize: "13px", color: "#135f95" }}
                    >
                      {t("caymland.contacts.eventtimeline.details")}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
