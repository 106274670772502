import React from "react";
import EChart from "../EChart";

const PieChart = (props) => {
  const {
    loading,
    title,
    subtext,
    saveAsImg = true,
    tooltipShow = true,
    dataView = true,
    fulfilled = false,
    height,
    dataSeries = [
      { value: 1048, name: "Search Engine" },
      { value: 735, name: "Direct" },
      { value: 580, name: "Email" },
      { value: 484, name: "Union Ads" },
      { value: 300, name: "Video Ads" },
    ],
  } = props;

  const legendType = dataSeries.length > 2 ? "scroll" : "plain";

  const option = {
    title: {
      text: title,
      subtext: subtext,
      left: "2%",
      top: "2%",
      zLevel: 99999
    },
    toolbox: {
      show: true,
      orient: "vertical",
      right: "1%",
      top: "20%",
      feature: {
        dataView: {
          show: true,
          readOnly: false,
          optionToContent: function (opt) {
            var series = opt.series[0];
            var table =
              '<table style="width:90%;text-align:left;border-collapse:collapse;margin-left:auto;margin-right:auto;padding-left:10px;padding-right:10px;">' +
              "<thead><tr>" +
              '<th style="border:1px solid #ccc;padding:8px;background-color:#f8f8f8;text-align:center;">Name</th>' +
              '<th style="border:1px solid #ccc;padding:8px;background-color:#f8f8f8;text-align:center;">Value</th>' +
              "</tr></thead>" +
              "<tbody>";
            for (var i = 0; i < series.data.length; i++) {
              table +=
                "<tr>" +
                '<td style="border:1px solid #ccc;padding:8px;">' +
                series.data[i].name +
                "</td>" +
                '<td style="border:1px solid #ccc;padding:8px;">' +
                series.data[i].value +
                "</td>" +
                "</tr>";
            }
            table += "</tbody></table>";
            return table;
          },
        },
        restore: { show: true },
        saveAsImage: { show: saveAsImg },
      },
    },
    tooltip: {
      show: tooltipShow,
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      left: "2%",
      bottom: "1%",
      width: "95%",
      type: legendType,
      formatter: function (name) {
        return name;
      },
    },
    series: [
      {
        zLevel: 9999,
        name: "Access From",
        type: "pie",
        radius: fulfilled ? ["60%"] : ["40%", "70%"],
        center: ["50%", "38%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: fulfilled ? 0 : 10,
          borderColor: "#fff",
          borderWidth: fulfilled ? 0 : 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
            formatter: function (params) {
              return `${params.name}`;
            },
          },
          ...(fulfilled
            ? {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              }
            : {}),
        },

        labelLine: {
          show: false,
        },
        data: dataSeries,
      },
    ],
  };
  return <EChart option={option} loading={loading} height={height} />;
};

export default PieChart;
