import React, { useState, useEffect, Fragment, useRef } from "react";
import { FormControl } from "react-bootstrap";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { FaTrash, FaTrashAlt } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Text, Select } from "../../../../reusableComponents/Inputs";
import Button from "../../../../reusableComponents/Buttons/Button";
import { t } from "i18next";

function Columns({
  modifiedData,
  setModifiedData,
  createHandleSearchChange,
  handleSelectChange,
  createHandleSelectAll,
  createHandleDeselectAll,
  sortOptionsByIndex,
  handleDelete,
  filterOptionsBySearch,
  columns,
}) {
  const [leftColumnSearchValue, setLeftColumnSearchValue] = useState("");
  const [rightSearchValue, setRightSearchValue] = useState("");
  const [rightColumns, setRightColumns] = useState([]);
  const [leftColumns, setLeftColumns] = useState([]);

  const prevSourceRef = useRef(modifiedData.source);

  useEffect(() => {
    const prevSource = prevSourceRef.current;
    const currentSource = modifiedData.source;

    if (prevSource !== null && prevSource !== currentSource) {
      setRightColumns([]);
    }
    prevSourceRef.current = currentSource;
  }, [modifiedData.source, setRightColumns]);

  useEffect(() => {
    if (modifiedData?.columns?.length > 0) {
      const newRightColumns = columns.filter((column) => modifiedData?.columns?.includes(column.value));

      setRightColumns(newRightColumns);
    }
    const newLeftColumns = columns.filter((column) => !modifiedData?.columns?.includes(column.value));
    setLeftColumns(newLeftColumns);
  }, [columns]);

  useEffect(() => {
    if (modifiedData?.columns && rightColumns !== null) {
      setModifiedData((prevModifiedData) => ({
        ...prevModifiedData,
        columns: rightColumns?.map((c) => c.value) || [],
      }));
    }
  }, [rightColumns]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedOptions = Array.from(rightColumns);
    const [movedOption] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedOption);

    setRightColumns(reorderedOptions);
  };

  const handleLeftColumnSearchChange = createHandleSearchChange(setLeftColumnSearchValue);
  const handleRightColumnSearchChange = createHandleSearchChange(setRightSearchValue);

  const handleSelectAllColumns = createHandleSelectAll(leftColumns, rightColumns, setLeftColumns, setRightColumns);
  const handleDeselectAllColumns = createHandleDeselectAll(leftColumns, rightColumns, setLeftColumns, setRightColumns);

  const ORDER_OPTIONS = [
    { label: t("caymland.report.report.label.tableorder_dir.asc"), value: "ASC" },
    { label: t("caymland.report.report.label.tableorder_dir.desc"), value: "DESC" },
  ];

  const orderOptions = (inputValue) => {
    return new Promise((resolve) => {
      const filteredOptions = ORDER_OPTIONS.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions);
    });
  };

  const handleAddToTableOrder = () => {
    setModifiedData((prevData) => {
      const maxKey = Math.max(0, ...Object.keys(prevData.tableOrder).map(Number)) || 0;
      const newKey = maxKey + 1;

      return {
        ...prevData,
        tableOrder: {
          ...prevData.tableOrder,
          [newKey]: { column: null, direction: null },
        },
      };
    });
  };

  const handleTableOrderChange = (key, field, value) => {
    setModifiedData((prevData) => ({
      ...prevData,
      tableOrder: {
        ...prevData.tableOrder,
        [key]: { ...prevData.tableOrder[key], [field]: value },
      },
    }));
  };

  return (
    <Fragment>
      <div className="Columns flex">
        <div className="childColumns w-50" style={{ marginRight: "30px" }}>
          <h4>
            <strong>{t("caymland.report.report.form.columnselector")}</strong>
          </h4>
          <div className="d-flex">
            <div className="w-50">
              <div>
                <Text
                  label={`${t("caymland.core.select")}...`}
                  value={leftColumnSearchValue}
                  onChange={handleLeftColumnSearchChange}
                />
              </div>
              <FormControl
                as="select"
                multiple
                value={sortOptionsByIndex(filterOptionsBySearch(leftColumns, leftColumnSearchValue))?.map(
                  (option) => option.value
                )}
                style={{ height: "200px", fontSize: "15px" }}
              >
                {leftColumns.length &&
                  sortOptionsByIndex(filterOptionsBySearch(leftColumns, leftColumnSearchValue)).map((option) => (
                    <option
                      key={option.value}
                      onClick={() =>
                        handleSelectChange(leftColumns, rightColumns, setLeftColumns, setRightColumns)(option?.label)
                      }
                    >
                      {option.label}
                    </option>
                  ))}
              </FormControl>
              <div className="float-right">
                <Link className="cursor-pointer" onClick={handleSelectAllColumns}>
                  {t("caymland.core.select.all")}
                </Link>
              </div>
            </div>
            <RiArrowLeftRightFill size={35} className="align-self-center" />
            <div className="w-50" style={{ marginLeft: "10px" }}>
              <div>
                <Text
                  label={`${t("caymland.core.select")}...`}
                  value={rightSearchValue}
                  onChange={handleRightColumnSearchChange}
                />
              </div>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <ul
                      {...provided.droppableProps}
                      multiple
                      ref={provided.innerRef}
                      value={
                        rightColumns
                          ? filterOptionsBySearch(rightColumns, rightSearchValue)?.map((option) => option?.value)
                          : null
                      }
                      className="form-control"
                      style={{
                        height: "200px",
                        overflowY: "scroll",
                        textWrap: "nowrap",
                        fontSize: "15px",
                      }}
                    >
                      {filterOptionsBySearch(rightColumns, rightSearchValue)?.map((option, index) => (
                        <Draggable key={option?.value} draggableId={option?.value} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() =>
                                handleSelectChange(
                                  leftColumns,
                                  rightColumns,
                                  setLeftColumns,
                                  setRightColumns
                                )(option?.label)
                              }
                            >
                              {option?.label}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              <Link className="cursor-pointer" onClick={handleDeselectAllColumns}>
                {t("caymland.core.deselect.all")}
              </Link>
            </div>
          </div>
        </div>

        <div className="childColumns w-50" style={{ marginLeft: "30px" }}>
          <h4>
            <strong>{t("caymland.core.order")}</strong>
          </h4>
          <div>
            {Object.entries(modifiedData?.tableOrder ?? {}).map(([key, e], i) => (
              <div className="ReportChoices flex gap-10 w-100 align-items-center mb-20px" key={i}>
                <div className="w-50">
                  <Select
                    name="column"
                    label={t("caymland.report.report.label.filtercolumn")}
                    isClearable={false}
                    options={columns ?? []}
                    value={columns?.find((option) => option.value === e.column) ?? null}
                    onChange={(e) => handleTableOrderChange(key, "column", e.value)}
                    margin={true}
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <Select
                    name="order"
                    label={t("caymland.core.order")}
                    isClearable={false}
                    options={orderOptions}
                    value={ORDER_OPTIONS.find((option) => option.value === e.direction) ?? null}
                    onChange={(e) => handleTableOrderChange(key, "direction", e.value)}
                    margin={true}
                  />
                </div>
                <div className="flex" style={{ height: "40px", alignItems: "flex-end" }}>
                  <FaTrashAlt
                    className="cursor-pointer"
                    onClick={() => handleDelete("tableOrder", key)}
                    color="#f86b4f"
                    size={18}
                  />
                </div>
              </div>
            ))}
            <div className="mb-20px">
              <Button
                buttons={[
                  {
                    name: "addOrder",
                    title: t("caymland.report.report.label.addtableorder"),
                    onClick: () => handleAddToTableOrder(),
                  },
                ]}
                variant="contained"
                textFormat="capitalize"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Columns;
