import React from "react";
import { Controller } from "react-hook-form";
import { Text } from "../Inputs";
import { t } from "i18next";

const CampaignForm = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <Text
            name={t("caymland.core.name")}
            label={`${t("caymland.mailbox.rules.folder")} ${t("caymland.lottery.table.row.name")}`}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </>
  );
};

export default CampaignForm;
